import React from 'react';
import { Button } from 'react-bootstrap';
import { Grade } from './TaskGradeModal';

type props = {
    onGradeSelect: (grade: number) => void;
};

class GradeButtons extends React.Component<props, Grade> {
    constructor(props: props) {
        super(props);
        this.state = {
            selectedGrade: null
        };
    }

    handleGradeClick = (grade: number) => {
        this.setState({ selectedGrade: grade });
        this.props.onGradeSelect(grade);
    };

    render() {
        const gradeList = [1, 2, 3, 4, 5];

        return (
            <div>
                Low
                {" "}
                {gradeList.map((grade) => (
                    <Button
                        key={grade}
                        variant={this.state.selectedGrade === grade ? 'success' : 'secondary'}
                        className={this.state.selectedGrade === grade ? 'selected-grade mr-2 mb-2' : 'mr-2 mb-2'}
                        onClick={() => this.handleGradeClick(grade)}
                    >
                        {grade}
                    </Button>
                ))}
                High
            </div>
        );
    }
}

export default GradeButtons;
