/* Main viewer containing structure set list and roi list on the left and image viewer and image toolbars on the right */

import _ from 'lodash';
import React from 'react';
import { Button, ButtonGroup, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { contextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { connect, ConnectedProps } from 'react-redux';

import SplitPane from 'react-split-pane';
import * as datasetFiles from '../../datasets/dataset-files';
import * as guid from '../../dicom/guid';
import * as image from '../../dicom/image';
import * as structureSet from '../../dicom/structure-set';
import { ViewerState } from '../../rtviewer-core/viewer-state';
import { ContouringTaskState } from '../../store/contouring-task';
import * as sagas from '../../store/sagas';
import { StoreState } from '../../store/store';
import { ContouringClient } from '../../web-apis/contouring-client';
import { NewStructureSetOption } from './NewStructureSetDialog';
import ROITable from './ROITable';
import MainToolbar from './toolbars/MainToolbar';
import ViewGrid from './ViewGrid';
//@ts-ignore
import * as dcmjs from 'dcmjs';

import { Dataset } from '../../datasets/dataset';
import { RoiMapping } from '../../datasets/dataset-files';
import { DatasetImage } from '../../datasets/dataset-image';
import { DatasetStructureSet } from '../../datasets/dataset-structure-set';
import { DatasetGradings } from '../../datasets/roi-grading';
import { generateUid } from "../../dicom/guid";
import { DicomMapAnonReal, unAnonymizeRtstruct } from "../../dicom/image_anonymization";
import { addTime2Rtstruct, StructureSetModalMessages } from '../../dicom/structure-set';
import { getDICOMDate, getDICOMTime } from "../../dicom/utils";
import { getAppName, isDemo, generateNewClientId } from '../../environments';
import { UploadTask } from '../../store/file-transfer-task';
import WorkState from '../../store/work-state';
import { sleep } from '../../util';
import { backends, getBackendClient } from '../../web-apis/auth';
import { AzureFileInfo } from '../../web-apis/azure-files';
import { Backend } from '../../web-apis/backends';
import FinishedAutoContouringDialog from './dialogs/FinishedAutoContouringDialog';
import UndoAllDialog from "./dialogs/UndoAllDialog";
import './RTViewer.css';
import { SessionNotification, NotificationType } from '../common/models/SessionNotification';
import { User } from '../../store/user';
import { Vector3f } from '../../math/Vector3f';
import { MdArrowBack, MdSave, MdUndo } from 'react-icons/md';
import { RouteComponentProps } from 'react-router';
import { TrainingTask } from '../../datasets/training-task';
import StructureSetTable from './StructureSetTable';
import { selectScanById } from '../../store/selectors';
// https://www.dicomlibrary.com/dicom/sop/
let CT_CLASS_SOP_UID = '1.2.840.10008.5.1.4.1.1.2'
let MR_CLASS_SOP_UID = '1.2.840.10008.5.1.4.1.1.4'
let RTSTRUCT_CLASS_SOP_UID = "1.2.840.10008.5.1.4.1.1.481.3"
let STUDY_CLASS_SOP_UID = "1.2.840.10008.3.1.2.3.2"

type OwnProps = {
    scanId: string,
    datasetImage?: DatasetImage,
    canEdit: boolean,
    canCreateRtstruct: boolean,
    hideSaveButtons?: boolean,
    handleBack: () => void,
}

type DispatchProps = {
    storeFullImage(img: image.Image): void,
    deleteStructureSet(structureSet: structureSet.StructureSet): void,
    uploadStructureSet(structureSet: structureSet.StructureSet, img: any): void,
    undoStructureSetChanges(ss: structureSet.StructureSet): structureSet.StructureSet | null,
    sendImageForContouring(arrayBuffers: ArrayBuffer[], scanId: string, contouringAction: string, backend: Backend, dicomMapAnonReal: DicomMapAnonReal): void,
    clearAllContouringRequests(): void,
    storeStructureSet(arrayBuffer: ArrayBuffer, currentScanId: string, filename: string | null, isAutoContoured: boolean, 
        generateNewAzureFileInfo: (seriesId: string, sopId: string) => AzureFileInfo | null, 
        cbReturnId: (scanId: string, ssId: string, structureSet: structureSet.StructureSet) => void, 
        storeOriginalStructureSet: boolean, isComparisonStructureSet: boolean): void,
    seenStructureSet(structureSetId: string): void,
    setContouringTaskState(scanId: string, newContouringState: ContouringTaskState, errorMessage: string): void,
    dismissContouringTask(scanId: string): void,
    syncStructureSetGrading(structureSet: structureSet.StructureSet, dataset: Dataset): void,
    setCurrentTask(task: TrainingTask | undefined): void,
    canUserFinishTask: (task: TrainingTask, user: User) => Promise<[boolean, string | undefined]>,
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
    changeIsContouringAllowedInStructureSetRois(structureSet: structureSet.StructureSet, user: User, task: TrainingTask): void,
    clearIsContouringAllowedOverrideChanges(structureSet: structureSet.StructureSet): void,
    clearTestAndReferenceStructureObjects(): void,
}

// combine redux & selector props
const mapStateToProps = (state: StoreState, ownProps: OwnProps) => Object.assign({}, state,
    {
        // getScan: (scanId: string) => selectScanById(state, scanId),
        currentScan: selectScanById(state, ownProps.scanId),
    });
const connector = connect(mapStateToProps, sagas.mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = OwnProps & StoreState & DispatchProps & ReduxProps & RouteComponentProps;

type OwnState = {
    viewerState: ViewerState,
    refreshSwitch?: any,
    canEditTask: boolean,
    pollTimerId?: any;
    showNewStructureSetDialog: boolean,
    showAddStructuresFromTemplateDialog: boolean,
    showUndoAllDialog: boolean,
    isPreparingToClose: boolean,
    showFinishedAutoContouringModal: boolean,
}

export class RTViewer extends React.Component<AllProps, OwnState> {

    displayName = RTViewer.name;

    constructor(props: AllProps) {
        super(props);

        window.history.pushState({page: 1}, "", "");
        const t = this;
        window.onpopstate = function(event: any) {
            t.handleBackClick();
        }
        const img = this.getImage();
        const ws = this.props.currentWorkState;
        const di = this.props.datasetImage || null;
        const ssList = this.getStructureSets();
        const ss = this.getInitialStructureSet(ssList, this.props.currentWorkState);
        const vs = new ViewerState(img, ss, ws ? ws.dataset : null, di, this.props.canEdit, this.props.canCreateRtstruct);

        const showNewStructureSetDialog = false;
        const showAddStructuresFromTemplateDialog = false;
        const showUndoAllDialog = false;
        this.state = { 
            viewerState: vs, 
            showNewStructureSetDialog,
            showUndoAllDialog,
            showAddStructuresFromTemplateDialog, 
            canEditTask: true,
            isPreparingToClose: false,
            showFinishedAutoContouringModal: false,
        };
    }

    getInitialStructureSet(ssList: structureSet.StructureSet[], currentWorkState?: WorkState): structureSet.StructureSet | undefined {
        if (currentWorkState && (currentWorkState.hasTaskWork() || currentWorkState.hasAnnotationWork() || currentWorkState.hasStructureSet())) {
            return ssList.find(ss => ss.structureSetId === currentWorkState.structureSetId);
        } else {
            // if we're in in training tool, NEVER try to automatically open a random structure set
            if (this.props.currentTask) {
                return undefined;
            } 
            
            return ssList.length ? ssList[0] : undefined;
        }
    }

    componentDidMount() {

        const { currentTask, user } = this.props;

        this.props.hideSidebar(true);
        
        const t = this;

        // Enable navigation prompt when unsaved changes. Note that modern browsers don't actually support showing
        // these messages, but returning a string here makes them show their default "you have unsaved changes"
        // dialog.
        window.onbeforeunload = function () {
            const unsaved = t.getStructureSets().some((ss) => { return ss.unsaved });
            if (unsaved) { return "You have unsaved changes. Are you sure you want to leave?"; }

            if (t.props.isSavingGradings) { return "Grading sheet is currently being auto-saved. Are you sure you want to leave?"; }

            return;
        };

        generateNewClientId();

        const structureSets = this.getStructureSets();

        // change roi.isContouringAllowed depending on current task
        if (currentTask && user) {
            const traineeStructureSet = structureSets.find(ss => ss.structureSetId === currentTask.traineeStructureSet.sopInstanceUid);
            if (traineeStructureSet) {
                this.props.changeIsContouringAllowedInStructureSetRois(traineeStructureSet, user, currentTask);
            }
        }

        const vs = this.state.viewerState;
        vs.addListener(this.updateView);

        // set default structure set to open in MVision Guide
        if (currentTask) {
            const taskStructureSet = structureSets.find(s => s.structureSetId === currentTask.traineeStructureSet.sopInstanceUid);
            if (!taskStructureSet) {
                throw new Error(`No structure set loaded for task ${currentTask.id}`);
            }
            vs.setSelectedStructureSet(taskStructureSet, vs.image);
        }
        /* if (structureSets[0]) {
            vs.setSelectedStructureSet(structureSets[0], vs.image);
        } */
    }

    componentWillUnmount() {
        const { currentTask } = this.props;

        // clear roi canEdit overrides
        if (currentTask) {
            const traineeStructureSet = this.getStructureSets().find(ss => ss.structureSetId === currentTask.traineeStructureSet.sopInstanceUid);
            if (traineeStructureSet) {
                this.props.clearIsContouringAllowedOverrideChanges(traineeStructureSet);
            }
        }

        this.stopPolling();
        this.props.clearAllContouringRequests();
        const vs = this.state.viewerState;
        vs.removeListener(this.updateView);
        vs.setComparisonStructureSet(null);
        this.props.clearTestAndReferenceStructureObjects();
        window.onbeforeunload = null;
    }

    createNewRTSTRUCT = (isComparison: boolean = false) => {
        const { viewerState } = this.state;

        if (isComparison) {
            if(viewerState.comparisonStructureSet) {
                // a comparison structure set has already been created
                return;
            }
        }

        const date = getDICOMDate();
        const time = getDICOMTime();
        const scanId = this.props.scanId;
        const img = this.props.currentScan.image;
        const ssLength = this.getStructureSets().length;
        if (!img) { throw new Error(`No RTViewer image in scan "${this.props.currentScan.scanId}"`); }
        let CLASS_SOP_UID;
        if (img.dicomTags.Modality === 'CT') {
            CLASS_SOP_UID = CT_CLASS_SOP_UID;
        } else if (img.dicomTags.Modality === 'MR') {
            CLASS_SOP_UID = MR_CLASS_SOP_UID;
        }
        const rt_sop_uid = generateUid();
        const rt_series_uid = generateUid();
        const refSeriesUID = img.dicomTags.SeriesInstanceUID;

        let ContourImageSequence = [];
        for (let i=0; i<img.sliceIds.length;i++){
            ContourImageSequence.push({"ReferencedSOPClassUID": CLASS_SOP_UID, "ReferencedSOPInstanceUID": img.sliceIds[i]});
        }

        let dataset = {'PatientName': img.dicomTags.PatientName,
            'PatientID': isComparison ? structureSet.ComparisonStructureSetName : img.dicomTags.PatientID,
            'PatientBirthDate': img.dicomTags.PatientBirthDate,
            'PatientSex': img.dicomTags.PatientSex,
            'StudyInstanceUID': img.dicomTags.StudyInstanceUID,
            'StudyDate': img.dicomTags.StudyDate,
            'StudyTime': img.dicomTags.StudyTime,
            'ReferringPhysicianName': img.dicomTags.ReferringPhysicianName,
            'StudyID': img.dicomTags.StudyID,
            "AccessionNumber": img.dicomTags.AccessionNumber,
            'Modality': "RTSTRUCT",
            'OperatorsName': "",  // https://dicom.innolitics.com/ciods/rt-structure-set/rt-series/00081070

            'Manufacturer': "MVision AI Oy",
            'ManufacturerModelName': "Annotation",
            // 'DeviceSerialNumber': "",  // https://dicom.innolitics.com/ciods/rt-structure-set/general-equipment/00181000

            'SoftwareVersions': "",
            'StructureSetDescription': "",
            'StationName': "",
            "StructureSetLabel": isComparison ? structureSet.ComparisonStructureSetName : `structure-set-${ssLength}`,
            'StructureSetDate': date,
            'StructureSetTime': time,

            'ApprovalStatus': "UNAPPROVED",

            'SeriesInstanceUID': rt_series_uid,
            'SeriesNumber': img.dicomTags.SeriesNumber,

            'SOPClassUID': RTSTRUCT_CLASS_SOP_UID,
            'SOPInstanceUID': rt_sop_uid,
            'SeriesDescription': "",
            // 'SeriesDate': "",
            // 'SeriesTime': "",

            // "is_implicit_VR": true,
            // "is_little_endian": true,

            "ReferencedFrameOfReferenceSequence": [{
                "FrameOfReferenceUID": img.dicomTags.FrameOfReferenceUID,
                "RTReferencedStudySequence": {
                    "ReferencedSOPClassUID": STUDY_CLASS_SOP_UID,
                    "ReferencedSOPInstanceUID": img.dicomTags.StudyInstanceUID,
                    "RTReferencedSeriesSequence": {"SeriesInstanceUID": refSeriesUID,  "ContourImageSequence": ContourImageSequence}}}],
            "RTROIObservationsSequence": [],  // ObservationNumber, ReferencedROINumber, ROIObservationLabel, RTROIInterpretedType, ROIInterpreter
            "StructureSetROISequence": [],  // #ROINumber, ROIName, ROIGenerationAlgorithm, ReferencedFrameOfReferenceUID
            "ROIContourSequence": [],  // ReferencedROINumber, ROIDisplayColor, ContourSequence []


            "_meta": {"FileMetaInformationVersion": {"Value": [{"0": 0, "1": 1}], "vr": "OB"},
                "ImplementationClassUID": {"Value": ["1.2.840.113819.7.1.1997.1.0"], "vr": "UI"},
                "ImplementationVersionName": {"Value": ["MVision AI Oy"], "vr": "SH"},
                "MediaStorageSOPClassUID": {"Value": [RTSTRUCT_CLASS_SOP_UID], "vr": "UI"},
                "MediaStorageSOPInstanceUID": {"Value": [rt_sop_uid], "vr": "UI"},
                "TransferSyntaxUID": {"Value": ["1.2.840.10008.1.2"], "vr": "UI"}
            },
            // "_vrMap": {"PixelData": "OW"},
        };
        const dicomDict = dcmjs.data.datasetToDict(dataset);
        dicomDict.dict = dcmjs.data.DicomMetaDictionary.denaturalizeDataset(dataset);
        let arrayBuffer = dicomDict.write();

        // construct a function we can use to generate a new AzureFileInfo a bit later, but only if we're NOT in local mode and NOT 
        // creating a comparison structure set
        const ws = this.props.currentWorkState;
        const generateNewAzureFileInfo = (seriesId: string, sopId: string) => !isComparison && this.props.canEdit && ws && ws.dataset && ws.datasetImage ? 
            datasetFiles.getStructureSetFileInfo(
                ws.dataset.datasetFile.getShare(),
                ws.datasetImage.patientId,
                ws.datasetImage.frameOfReferenceUid,
                seriesId,
                sopId) : null;

        const successCallback = (scanId: string, ssId: string, structureSet: structureSet.StructureSet) => {
            if (isComparison) {
                // assign comparison structure set to viewerState
                viewerState.generateSdfsIfNeeded(structureSet);
                viewerState.setComparisonStructureSet(structureSet);
            } else {
                // select the new structure set if it's the only one we've got; otherwise do nothing
                this.selectOnlyStructureSet();
                this.forceUpdate();
                viewerState.notifyListeners();

                const ss = this.getStructureSets().find(ss => ss.structureSetId === ssId);
                if (ss) viewerState.generateSdfsIfNeeded(ss);
            }
        };

        this.props.storeStructureSet(arrayBuffer, scanId, null, true, generateNewAzureFileInfo, successCallback, false, isComparison);
        // process.stdout.write(buffer);
    }

    requestContouring = (contouringAction: string) => {

        // re-generate clientId to distinguish each contouring request
        generateNewClientId();

        const scanId = this.props.scanId;
        const scan = this.props.currentScan;
        const arrayBuffers = Object.values(scan.slices).map((slice: any) => slice.arrayBuffer);
        const backend = this.props.user!.currentBackend || backends.getDefaultBackend();
        if (backend) {
            let dicomMapAnonReal: DicomMapAnonReal = new DicomMapAnonReal();
            this.props.sendImageForContouring(arrayBuffers, scanId, contouringAction, backend, dicomMapAnonReal);

            this.startPolling(backend, dicomMapAnonReal);

            // request permission from user to display browser notifications
            Notification.requestPermission();
        }
    }

    startPolling = (backend: Backend, dicomMapAnonReal: DicomMapAnonReal) => {
        if (this.state.pollTimerId) { return; }
        const contouringClient = new ContouringClient(getBackendClient(backend));
        const scanId = this.props.scanId;
        const scan = this.props.currentScan;
        const stop = this.stopPolling;

        if (scan.slices) {
            let areResultAvailable = false;
            let alreadyNotified = false;
            const id = setInterval(() => {
                // first check if the upload has failed. if not, keep polling.
                const upload: UploadTask = this.props.uploads[scanId];
                if (upload && upload.failed) {
                    stop();
                    this.notifyOnFinishedAutoContouring(false);
                } else {
                    contouringClient.poll(this.props.scanId, (result, filename) => {
                        // Receive success
                        areResultAvailable = true;
                        const successCallback = (scanId: string, ssId: string) => {
                            // select the new structure set if it's the only one we've got; otherwise do nothing
                            this.selectOnlyStructureSet();
                            
                            const ss = this.getStructureSets().find(ss => ss.structureSetId === ssId);
                            if (ss) ss.toIM(scan.image);
                            if (ss) this.state.viewerState.generateSdfsIfNeeded(ss);
                        };

                        // construct a function we can use to generate a new AzureFileInfo a bit later, but only if we're NOT in local mode
                        const ws = this.props.currentWorkState;
                        const generateNewAzureFileInfo = (seriesId: string, sopId: string) => this.props.canEdit && ws && ws.dataset && ws.datasetImage ? 
                            datasetFiles.getStructureSetFileInfo(
                                ws.dataset.datasetFile.getShare(),
                                ws.datasetImage.patientId,
                                ws.datasetImage.frameOfReferenceUid,
                                seriesId,
                                sopId) : null;

                        if (isDemo()) {
                            result = unAnonymizeRtstruct(result, dicomMapAnonReal)
                        } else {
                            result = addTime2Rtstruct(result)
                        }
                        this.props.storeStructureSet(result, scanId, filename || null, true, generateNewAzureFileInfo, successCallback, true, false);
                        if (!alreadyNotified) { this.notifyOnFinishedAutoContouring(true); }
                        alreadyNotified = true;
                    }, (error) => {
                        // Receive failure
                        console.log(error);
                        stop();
                        this.props.setContouringTaskState(scanId, ContouringTaskState.Error, error);
                        this.notifyOnFinishedAutoContouring(false);
                    }, () => {
                        if (areResultAvailable) {
                            // we've already gone through all the results, so stop here
                            stop();
                        }
                    });
                }
            }, 3000); // TODO: make it 10000
            this.setState({ pollTimerId: id });
        }
    }

    stopPolling = () => {
        if (this.state.pollTimerId) {
            clearInterval(this.state.pollTimerId);
            this.setState({ pollTimerId: null });
        }
    }

    notifyOnFinishedAutoContouring = (wasSuccessful: boolean) => {
        if (Notification.permission === 'granted') {
            const message = wasSuccessful ? 'Segmentation of structure set was finished.' : 'An error occurred during segmentation.';
            const notification = new Notification(`${getAppName()}: ${message}`);
            notification.onclick = function () { window.focus(); this.close(); };
        }
        this.setState({ showFinishedAutoContouringModal: true });
    }

    updateView = () => {
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    getImage = () : image.Image => {
        const scan = this.props.currentScan;
        if(scan && scan.image) {
            return scan.image;
        }
        else if (scan && scan.slices && Object.keys(scan.slices).length > 0) {
            const img = image.Image.generateFromScan(scan);
            this.props.storeFullImage(img);
            return img;
        }
        else {
            throw new Error("Scan not found");
        }
    }

    getStructureSets = (): structureSet.StructureSet[] => {
        // TODO: memoize this entire method
        const scan = this.props.currentScan;
        const ssIds = scan.structureSets ? Object.keys(scan.structureSets) : [];
        const ssList = [];
        for(let i = 0; i < ssIds.length; ++i) {
            ssList.push(scan.structureSets[ssIds[i]]);
        }

        function compare( a: structureSet.StructureSet, b: structureSet.StructureSet ) {
            if( a.isOriginal && !b.isOriginal) return -1;
            if( b.isOriginal && !a.isOriginal) return 1;
            return 0;
        }

        return ssList.sort(compare);
    }

    // selects a structure set if its the only one present
    selectOnlyStructureSet = () => {
        const scan = this.props.currentScan;
        const ssIds = scan.structureSets ? Object.keys(scan.structureSets) : [];
        if (ssIds.length !== 1) {
            // nothing to do here if we don't have exactly one structure set
            return;
        }

        const ss = scan.structureSets[ssIds[0]];
        this.state.viewerState.setSelectedStructureSet(ss, this.state.viewerState.image);
    }

    getIsAutoContouringInProgress = (): boolean => {
        const inProgressStates = [ContouringTaskState.NotStarted, ContouringTaskState.UploadingFiles, ContouringTaskState.PollingForResults, ContouringTaskState.DownloadingFiles ];
        const { contouringTasks } = this.props;

        const taskKeys = Object.keys(contouringTasks);
        return taskKeys.some(k => inProgressStates.includes(contouringTasks[k].contouringState));
    }

    syncGrading = (ss: structureSet.StructureSet) => {
        const currentWorkState = this.props.currentWorkState;
        if (currentWorkState && currentWorkState.dataset) {
            this.props.syncStructureSetGrading(ss, currentWorkState.dataset);
        }
    }

    handleBackClick = async () => {
        // prevent user from clicking back multiple times
        if (this.state.isPreparingToClose) { return; }

        this.setState({ isPreparingToClose: true });

        let confirmQuit = false;
        let alreadyCancelled = false;

        // start by checking if there's unsaved changes to structure sets
        const structureSets = this.getStructureSets();
        if (!structureSets.some((ss) => { return ss.unsaved }) || window.confirm("Do you want to close the viewer and lose all unsaved changes?")) {
            confirmQuit = true;
        } else {
            confirmQuit = false;
            alreadyCancelled = true;
        }

        // check that grading sheets aren't in the progress of being saved -- give them an extra second
        if (!alreadyCancelled && this.props.isSavingGradings) {
            await sleep(1000);
            if (!this.props.isSavingGradings || window.confirm("Grading sheet is currently being auto-saved. Are you sure you want to close the viewer?")) {
                confirmQuit = true;
            } else {
                confirmQuit = false;
                alreadyCancelled = true;
            }
        }

        if (confirmQuit) {
            this.handleUndoAllClick();
            structureSets.forEach(ss => ss.clearSdfs()); // Free some memory
            this.props.setCurrentTask(undefined);
            this.props.handleBack();
            if(this.props.hideSide) {
                this.props.hideSidebar(false);
            }
        } else {
            window.history.pushState({ page: 1 }, "", "");
            this.setState({ isPreparingToClose: false });
        }
    }

    handleUndoAllClick = () => {
        let vs = this.state.viewerState;
        let ssList = this.getStructureSets();
        for(let i = 0; i < ssList.length; ++i){
            let ss = ssList[i];
            if(ss.unsaved) { this.handleUndoStructureSetChangesClick(ss); }
        }

        if(!vs.selectedStructureSet || vs.selectedStructureSet.deleted) {
            for(let i = 0; i < ssList.length; ++i) {
                let ss = ssList[i];
                if(!ss.deleted) {
                    vs.setSelectedStructureSet(ss, vs.image);
                    break;
                }
                vs.contoursChanged(ss)
            }
        }
        vs.notifyListeners();
        
    }

    handleSaveAllClick = async () => {
        const { currentWorkState } = this.props;

        // start by checking if this is a task and if current user should be allowed to save 
        if (this.props.currentTask && this.props.user) {
            // TODO: this feels wrong, investigate -- we're saving here, not finishing a task
            const [canUserFinishTask, errorMessage] = await this.props.canUserFinishTask(this.props.currentTask, this.props.user);
            if (!canUserFinishTask) {
                this.props.addNotification(new SessionNotification(`save-task-${this.props.currentTask.id}-failed-${Date.now()}`, 'Task could not be saved.', NotificationType.Error, errorMessage));
                return;
            }
        }

        const vs = this.state.viewerState;
        const ssListAll = this.getStructureSets();
        const ssListUnsaved = ssListAll.filter(ss => ss.unsaved && (!ss.isOriginal || !ss.existsInAzure));
        if (ssListUnsaved.length === 0) { return; }

        // Recreate CSV representation objects for structure sets
        const datasetImage = this.props.datasetImage ? this.props.datasetImage.clone() : null;
        const dataset = currentWorkState ? currentWorkState.dataset : null;
        if (datasetImage && dataset) {
            const datasetStructureSets = datasetImage.structureSets;
            datasetImage.structureSets = [];
            for (let i = 0; i < ssListAll.length; ++i) {

                const ss = ssListAll[i];
                if (dataset && !ss.deleted) {
                    const existingMatches = datasetStructureSets.filter((dss: DatasetStructureSet) => dss.sopId === ss.structureSetId);
                    const match = existingMatches.length ? existingMatches[0] : null;

                    // Create roi mappings
                    const oldMappings = match ? match.roiMappings : [];
                    const newMappings: RoiMapping[] = [];
                    ss.getRois().forEach((roi) => {
                        const allowedNames = dataset.metaFiles.allAllowedRoiNames;
                        if (allowedNames && allowedNames.includes(roi.name)) {
                            newMappings.push(new RoiMapping(roi.name, roi.name));
                        } else {
                            let matchFound = false;
                            oldMappings.forEach(mpp => {
                                if (roi.name === mpp.originalName) {
                                    if (!matchFound) {
                                        newMappings.push(mpp);
                                        matchFound = true;
                                    }
                                }
                            });
                            if (!matchFound) {
                                newMappings.push(new RoiMapping(roi.name, ""));
                            }
                        }
                    });

                    const bestMatch = Boolean(match && match.bestMatch);
                    const ds = new DatasetStructureSet(dataset.getDatasetId(), datasetImage.seriesId, ss.scanId, ss.seriesUid, ss.structureSetId, ss.imageSeriesInstanceUid, ss.frameOfReferenceUid,ss.dataset.ApprovalStatus, ss.dataset.StructureSetLabel, bestMatch, newMappings);
                    datasetImage.structureSets.push(ds);
                }
            }
        }

        let lockId: any = "";
        document.body.style.cursor = 'wait';

        ssListUnsaved.forEach(ss => {
            ss.modalMessage = StructureSetModalMessages.Saving;
        });
        vs.notifyListeners();
        await sleep(200);

        const promises: any = [];
        ssListUnsaved.forEach(ss => {
            promises.push(ss.deleted ? this.props.deleteStructureSet(ss) : this.props.uploadStructureSet(ss, vs.image));
        });

        const finished = (success: boolean) => {
            document.body.style.cursor = 'default';
            ssListUnsaved.forEach(ss => {
                ss.modalMessage = null;
            });
            if (success) {
                vs.undoStack.clear();
            }
            vs.notifyListeners();
        }

        const getErrorMessage = (error: any): string => {
            let details = '';
            if (_.isError(error) && error.message) {
                details = ` ${error.message}`;
            }

            return `An error occurred while trying to save${details ? `: ${details}` : `.`}`;
        }

        // TODO: move this out of RTViewer
        if (datasetImage && dataset) {
            Promise.all(promises)
                .then(() => datasetFiles.enterCSVMutex(dataset))
                .then((id) => {
                    lockId = id
                })
                .then(() => datasetFiles.saveDatasetImage(datasetImage, dataset, _.get(this.state, 'viewerState.username', undefined)))
                .then(() => datasetFiles.setStructureSetsEdited(datasetImage, dataset, ssListUnsaved.map(ss => ss.structureSetId), vs.username))
                .then(() => {
                    datasetFiles.leaveCSVMutex(dataset, lockId);
                    lockId = null;
                })
                .then(() => {
                    finished(true);
                })
                .catch(function (error) {
                    finished(false);
                    console.log(error);
                    if (lockId) datasetFiles.leaveCSVMutex(dataset, lockId);
                    alert(getErrorMessage(error));
                });
        } else {
            Promise.all(promises)
                .then(() => {
                    finished(true);
                })
                .catch(function (error) {
                    finished(false);
                    console.log(error);
                    alert(getErrorMessage(error));
                });
        }
    }

    throwError = (msg: string): void => {
        console.log("Error", msg);
        alert(msg);
        throw new Error(msg);
    }


    handleUndoStructureSetChangesClick = (ss: structureSet.StructureSet) => {
        let vs = this.state.viewerState;
        let ssAfterUndo = this.props.undoStructureSetChanges(ss);
        let roisDeleted = ss.getRois()
        if (vs.selectedStructureSet === ss) {
            vs.setSelectedStructureSet(ssAfterUndo, vs.image);
        }
        if (roisDeleted) {
            roisDeleted.forEach(roi => {
                vs.setRoiHidden(roi, true);
            })
        };
        ss.unsaved = false;
        this.syncGrading(ss);
        vs.roisChanged(ss);
    }

    handleNewStructureSetClick = () => {
        this.createNewRTSTRUCT();
    }
    handleUndoAllDialogClick = () => {
        this.setState({ showUndoAllDialog: true });
    };
    closeUndoAllDialogClick = () => {
        this.setState({ showUndoAllDialog: false });
    };

    closeNewStructureSetDialog = () => {
        this.setState({
            showNewStructureSetDialog: false,
        });
    }

    openAddStructuresFromTemplateDialog = () => this.setState({ showAddStructuresFromTemplateDialog: true });

    closeAddStructuresFromTemplateDialog = () => this.setState({ showAddStructuresFromTemplateDialog: false });

    handleCreateNewStructureSetClick = (selectedNewStructureSetOption: NewStructureSetOption, contouringAction: string) => {
        if (selectedNewStructureSetOption === NewStructureSetOption.FromScratch) {
            this.createNewRTSTRUCT();
        } else if (selectedNewStructureSetOption === NewStructureSetOption.AutoContour && contouringAction) {
            this.requestContouring(contouringAction);
        }
    }

    handleAddRoiToStructureSet = (ss: structureSet.StructureSet) => {
        const vs = this.state.viewerState;
        const color = new Vector3f(guid.getRandomInt(0,255), guid.getRandomInt(0,255), guid.getRandomInt(0,255));
        const roi = ss.addRoi("New structure", color); // TODO: Avoid duplicate names
        vs.setRoiHidden(roi, false);
        this.syncGrading(ss);
        vs.roisChanged(ss);
        setTimeout(function() { vs.setSelectedRoi(roi) }, 50);
    }

    handleUndoClick = () => {
        this.handleUndoAllClick();
        this.closeUndoAllDialogClick();
    };

    handleCloseFinishedAutoContouringModal = () => {
        this.setState({ showFinishedAutoContouringModal: false });
    }

    /**
     * Handle some default interaction stuff if events bubble all the way up to the root of RTViewer.
     * If these cause problems in a sub-component, you must capture the relevant events in them
     * and call stopPropagation on them there.
     * 
     * TODO: include keyboard events in this function (or create a similar function for them)
     */
    handleDefaultEvents = () => {
        // close any context menus
        contextMenu.hideAll();
    }

    render() {
        const vs = this.state.viewerState;
        const scanId = this.props.scanId;
        const scan = scanId && this.props.scans ? this.props.scans[scanId] : null;
        if(!scan) { return null; }
        const ss = vs.selectedStructureSet;
        // let ssLen = scan.structureSets ? Object.keys(scan.structureSets).length : 0;
        // let defaultSize = 65 + 20*ssLen;
        // defaultSize = Math.max(defaultSize, 80);

        const ws = this.props.currentWorkState;
        const datasetId = ws && ws.dataset ? ws.dataset.getDatasetId() : null;
        const gradings: DatasetGradings | null = datasetId ? this.props.datasetGradings[datasetId] : null;
        const ssGrading = ss && gradings ? gradings.structureSets[ss.structureSetId] : null;
        const unsaved = this.getStructureSets().some((ss)=>{return ss.unsaved});
        const undoLabel = <OverlayTrigger
            placement="bottom"
            overlay={(<Tooltip id={'Undo All'}>Undo All</Tooltip>)} >
            <MdUndo size={20} /></OverlayTrigger>;
        const saveLabel = <OverlayTrigger
            placement="bottom"
            overlay={(<Tooltip id={'Save All'}>{unsaved ? "Save All" : "No Unsaved Changes"}</Tooltip>)} >
            <MdSave size={20} /></OverlayTrigger>;
        const showCannotEdit = !vs.canEdit;
        //let datasetName = this.props.datasetImage ? this.props.datasetImage.datasetTable.fileShareName : null;
        const leftPanelWidth = ssGrading ? 370 : 220;

        const structureSets = this.getStructureSets();
        //const showPotentialDatasetLockWarning = this.props.datasetImage !== undefined;  // locks are only relevant if we have a dataset
        //const userHasLockToCurrentDataset = showPotentialDatasetLockWarning ? this.props.user && datasetId && ws.datasetImage && this.props.datasetLocks[datasetId][ws.datasetImage.seriesId] === this.props.user.username : true;
        const hideSide = this.props.hideSide;


        return (
            <>

            {/* TODO: disable the NO LOCK alert until structure set-based locks are in use */}
            {/* {vs.canEdit && !userHasLockToCurrentDataset && (
                <Alert variant="danger" >You do not have a lock to this scan. Modifying and saving any structure sets for this scan may result in loss of data for other users!</Alert>
            )} */}

            <Row className="rtviewer-container" onClick={this.handleDefaultEvents}>
                <SplitPane  split="vertical" minSize={leftPanelWidth} maxSize={leftPanelWidth} defaultSize={leftPanelWidth} className="rtviewer-content">
                    
                        <div className={hideSide === true ? 'left-panel expand': 'left-panel'}>
                            <div className="left-top-panel">
                                <Container>
                                    <Row>
                                        <ButtonGroup>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={(<Tooltip id={'Back'}>Back</Tooltip>)} delay={800}>
                                                <Button variant="light" className="btn btn-default btn-sm" onClick={this.handleBackClick}><MdArrowBack size={20} /></Button>
                                            </OverlayTrigger>
                                            {!this.props.hideSaveButtons && vs.canEdit ? <Button variant="light" className="btn btn-default btn-sm" onClick={this.handleUndoAllDialogClick}>{unsaved ? <b>{undoLabel}</b> : undoLabel}</Button> : null}
                                            {!this.props.hideSaveButtons && vs.canEdit ? <Button variant="light" className="btn btn-default btn-sm" onClick={this.handleSaveAllClick}>{unsaved ? <b className='unsaved'>{saveLabel}</b> : saveLabel}</Button>: null}
                                        </ButtonGroup>

                                        { showCannotEdit ? <div className="cannot-edit-notification">Cannot edit</div> : null }
                                    </Row>
                                    <Row className="side-panel">
                                        <StructureSetTable
                                            viewerState={vs}
                                            structureSets={structureSets}
                                            datasetImage={this.props.datasetImage}
                                            newStructureSets={this.props.newStructureSets}
                                            allDatasetGradings={gradings}
                                            handleNewStructureSetClick={this.handleNewStructureSetClick}
                                            handleUndoStructureSetChangesClick={this.handleUndoStructureSetChangesClick}
                                            openAddStructuresFromTemplateDialog={this.openAddStructuresFromTemplateDialog}
                                            onAddRoiClick={this.handleAddRoiToStructureSet}
                                            throwError={this.throwError}
                                            doGradingSync={this.syncGrading}
                                        />
                                    </Row>
                                </Container>
                            </div>
                            {ss && ss !== vs.comparisonStructureSet ? (
                                <>
                                    <div className={`section-title structures-section ${ssGrading ? 'has-grading-sheet' : ''}`}>Structures</div>
                                    <div className="left-bottom-panel side-panel">
                                        <ROITable 
                                            viewerState={vs}
                                            structureSet={ss}
                                            structureSets={structureSets}
                                            grading={ssGrading}
                                            allDatasetGradings={gradings}
                                            openAddStructuresFromTemplateDialog={this.openAddStructuresFromTemplateDialog}
                                            onAddRoiClick={this.handleAddRoiToStructureSet}
                                            doGradingSync={this.syncGrading}
                                            roiGuidelines={this.props.roiGuidelines}
                                        />
                                    </div>
                                </>) : null }
                            
                        </div>

                        <div className="right-panel">
                            <div className="image-viewer-grid">
                                <div className="image-viewer-toolbar">
                                    <MainToolbar viewerState={vs} onSaveAll={this.handleSaveAllClick} createNewStructureSet={this.createNewRTSTRUCT} structureSets={structureSets} />
                                </div>
                                <div className="image-viewer-canvases">
                                    <ViewGrid viewerState={vs} />
                                </div>
                            </div>
                        </div>
                </SplitPane>

                <UndoAllDialog
                     viewerState={vs}
                     isVisible={this.state.showUndoAllDialog}
                     onClose={this.closeUndoAllDialogClick}
                     handleUndoClick={this.handleUndoClick}
                 />

                 <FinishedAutoContouringDialog
                    show={this.state.showFinishedAutoContouringModal}
                    onHide={this.handleCloseFinishedAutoContouringModal}
                    scanId={this.props.scanId}
                    structureSets={structureSets}
                    vs={vs}
                 />

            </Row>
            </>
        );
      }
}

export default connector(RTViewer);
