// helper compare functions for often-used sorts

import * as structureSet from '../dicom/structure-set';

export function roiCompare(roi1: structureSet.Roi, roi2: structureSet.Roi) {
  if (roi1.name.localeCompare(roi2.name) === -1) return -1;
  if (roi2.name.localeCompare(roi1.name) === -1) return 1;
  if (roi1.roiNumber.toString().localeCompare(roi2.roiNumber.toString()) === -1) return -1;
  return 1;
}

// case insensitive test for checking if two strings are the same
export function areStringsSameCi(a: string, b: string): boolean {
  return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
}