// Toolbar above the dicom viewer grid

import React from 'react';
import { Row, DropdownButton, Dropdown, ButtonToolbar, ToggleButton, ToggleButtonGroup, Button, ButtonGroup } from 'react-bootstrap';
import { StoreState } from '../../../store/store';
import { connect } from 'react-redux';
import * as sagas from '../../../store/sagas';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import BooleanOperationsToolbar from './contouring-toolbars/BooleanOperationsToolbar';
import { Checkbox } from '../../misc-components';
import { Axis } from '../../../rtviewer-core/view';

import './ContouringToolbar.css';
import { mouseTools } from '../../../rtviewer-core/mouse-tools/mouse-tools';
import RunInterpolationModal from '../dialogs/RunInterpolationModal';


type OwnProps = {
    viewerState: ViewerState,
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    refreshSwitch?: any,
    brushWidthMm: string | null,
    showInterpolationDialog: boolean,
}

export enum ContouringMenu {
    Line, Brush, Boolean, Crop, BorderMove, Deform, Margin, Smoothing
}

class ContouringToolbar extends React.Component<AllProps, OwnState> {
    displayName = ContouringToolbar.name

    constructor(props: AllProps) {
        super(props);
        this.state = {
            brushWidthMm: null,
            showInterpolationDialog: false,
        };
    }

    componentDidMount() {
        this.props.viewerState.addListener(this.updateView);
    }

    componentWillUnmount() {
        this.props.viewerState.removeListener(this.updateView);
    }

    updateView = () => {
        this.setState({ refreshSwitch: !this.state.refreshSwitch });
    }

    handleUndo = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;
        if (ss && roi) {
            vs.undoStack.undo(roi);
        }
    }

    handleRedo = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;
        if (ss && roi) {
            vs.undoStack.redo(roi);
        }
    }

    handleClearContoursForRoi = (currentSliceOnly: boolean) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;
        if (!ss || !roi) return;
        vs.undoStack.pushRoiStateBeforeEdit(roi);
        if (currentSliceOnly) {
            const img = vs.image;
            const z = vm.getScrolledSlice(Axis.Z);
            const sliceId = img.sliceIds[z];
            ss.clearSdf(roi, sliceId);
        }
        else {
            ss.clearSdf(roi, null);
        }
        // Update brush buffer if brush tool is selected
        if (vs.activeMouseTools.includes(mouseTools.brush)) {
            mouseTools.brush.createDrawBuffer();
        }

        vs.notifyListeners();
    }

    handleBrushWidthChanged = (event: any) => {
        let val = event.target.value;
        let num = parseFloat(val);
        this.setState({ brushWidthMm: val });
        if (!isNaN(num)) {
            this.props.viewerState.setBrushWidth(num);
        }
    }
    handleConfirmInterpolation = () => {
        this.setState({ showInterpolationDialog: true });
    }

    handleInterpolateRoiClick = () => {
        this.handleCloseRunInterpolationModal();
        const vs = this.props.viewerState;
        const roi = vs.selectedRoi;
        if (roi) {
            vs.interpolateRoiClick(roi);
        }
    }

    handleCloseRunInterpolationModal = () => {
        this.setState({ showInterpolationDialog: false });
    }

    renderSelectRoiNotification = () => {
        return (<span className="select-a-roi-notification">Select a structure to start editing</span>);
    }
    renderBrushToolbar = () => {
        let vs = this.props.viewerState;
        let brushWidthMm = this.state.brushWidthMm !== null ? this.state.brushWidthMm : vs.brushWidthMm;
        return (
            <Row className="brush-toolbar">
                {vs.selectedRoi ?
                    <>
                        <div className="brush-width-label">Brush width (mm):</div>
                        <input className="brush-width-value" title="Brush width (Shift+up/down)" type="text" value={brushWidthMm}
                            onChange={this.handleBrushWidthChanged} onBlur={(event) => this.setState({ brushWidthMm: null })} />
                        <Checkbox
                            label={"Erase (Alt to toggle)"}
                            isSelected={vs.erase}
                            onCheckboxChange={(evt: any) => vs.setErase(evt.target.checked)} />
                    </>
                    : this.renderSelectRoiNotification()}
            </Row>
        );
    }

    renderLineToolbar = () => {
        let vs = this.props.viewerState;
        return (
            <Row className="line-toolbar">
                {vs.selectedRoi ?
                    <Checkbox
                        label={"Erase (Alt to toggle)"}
                        isSelected={vs.erase}
                        onCheckboxChange={(evt: any) => vs.setErase(evt.target.checked)} />
                    : this.renderSelectRoiNotification()}
            </Row>
        );
    }

    render() {
        const vs = this.props.viewerState;
        const roi = vs.selectedRoi;
        const selectedContouringTool = vs.contouringMenuSelection;

        // Line, Brush, Boolean, Crop, BorderMove, Deform, Margin, Smoothing
        return (
            <>
                <div>
                    <Row className="contouring-tools toolbar">
                        <ButtonToolbar className="image-toolbar">
                            <ToggleButtonGroup className="mr-2" type="radio" name="options" value={vs.contouringMenuSelection} onChange={(val: ContouringMenu) => vs.setContouringMenuSelection(val)}>
                                <ToggleButton variant="light" className="btn btn-default btn-sm toggle-btn" value={ContouringMenu.Line}>Line draw</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-default btn-sm toggle-btn" value={ContouringMenu.Brush} >Brush</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-default btn-sm toggle-btn" value={ContouringMenu.Boolean} >Boolean</ToggleButton>
                                {/* <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Crop} >Crop</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.BorderMove} >Border move</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Deform} >Deform</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Margin} >Margin</ToggleButton>
                            <ToggleButton variant="light" className="btn btn-default btn-sm" value={ContouringMenu.Smoothing} >Smoothing</ToggleButton>  */}
                            </ToggleButtonGroup>

                            <Button variant="light" className="btn btn-default btn-sm" onClick={this.handleConfirmInterpolation} disabled={!vs.selectedRoi}>Interpolate</Button>
                            {roi ? <DropdownButton id="clear-roi-contours-button" className="clear-dropdown" title={"Clear"} size="sm" variant="light">
                                <Dropdown.Item as="button" key={1} onClick={() => { this.handleClearContoursForRoi(true) }}>{"Clear " + roi.name + " contours on current slice"}</Dropdown.Item>
                                <Dropdown.Item as="button" key={2} onClick={() => { this.handleClearContoursForRoi(false) }}>{"Clear " + roi.name + " contours on all slices"}</Dropdown.Item>
                            </DropdownButton> : null}

                            {roi && (vs.undoStack.canUndo(roi) || vs.undoStack.canRedo(roi)) ? <ButtonGroup className="contouring-undo-buttons">
                                <Button disabled={!vs.undoStack.canUndo(roi)} variant="light" size="sm" onClick={this.handleUndo}>Undo</Button>
                                <Button disabled={!vs.undoStack.canRedo(roi)} variant="light" size="sm" onClick={this.handleRedo}>Redo</Button>
                            </ButtonGroup> : null}
                        </ButtonToolbar>
                    </Row>
                    <Row className="toolbar">
                        {selectedContouringTool === ContouringMenu.Boolean && <BooleanOperationsToolbar viewerState={vs} />}
                        {selectedContouringTool === ContouringMenu.Brush && this.renderBrushToolbar()}
                        {selectedContouringTool === ContouringMenu.Line && this.renderLineToolbar()}
                    </Row>

                </div>

                <RunInterpolationModal
                    roi={vs.selectedRoi}
                    show={this.state.showInterpolationDialog}
                    handleInterpolation={this.handleInterpolateRoiClick}
                    handleClose={this.handleCloseRunInterpolationModal}
                />
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ContouringToolbar);