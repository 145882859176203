import React from 'react';

export class Checkbox extends React.Component<{
  label?: string, 
  isSelected: boolean, 
  onCheckboxChange: (event: any) => void, 
  onClick?: (event: any) => void, 
  disabled?: boolean,
}, {}> {

  displayName = Checkbox.name;

  render() {
    const onClick = this.props.onClick ? this.props.onClick : () => {};
    const disabled = this.props.disabled !== undefined ? this.props.disabled : false;
    const label = this.props.label || '';
    return(
      <div className="form-check">
        <label>
          <input
            type="checkbox"
            name={label}
            checked={this.props.isSelected}
            onChange={this.props.onCheckboxChange}
            className="form-check-input"
            onClick={onClick}
            disabled={disabled}
          />{label}</label>
      </div>
    );
  }
}

export class ApprovalIndicator extends React.Component<{approvalStatus: string},{}> { 
  displayName = ApprovalIndicator.name;
  render() {
    let status = this.props.approvalStatus;
    return (
      <div className="approval-indicator"  title={status}>
        <svg  height="12" width="12" >
          <circle cx="6" cy="6" r="6" strokeWidth="0" fill={status === "APPROVED" ? "green" : (status === "REJECTED" ? "red" : "yellow")} />
        </svg>
      </div>
    );
  }  
}

