import Immerable from "./immerable";

export class AppVersionInfo extends Immerable {
    commit: string;
    date: Date;
    buildEnv: string;

    constructor(commitStr: string, dateStr: string, buildEnvStr: string) {
        super();
        this.commit = commitStr;
        this.date = new Date(dateStr);
        this.buildEnv = buildEnvStr;
    }

    isValid(): boolean {
        return !!(this.commit && this.commit.length > 4
            && this.date && !isNaN(this.date.valueOf())
            && this.buildEnv);
    }

    isEqual(anotherVersion: AppVersionInfo): boolean {
        // don't compare dates (which will be NaN) if both versions are invalid
        return !this.isValid() && !anotherVersion.isValid() ?
            this.commit === anotherVersion.commit
            && this.buildEnv === anotherVersion.buildEnv
            :
            this.commit === anotherVersion.commit
            && this.date.valueOf() === anotherVersion.date.valueOf()
            && this.buildEnv === anotherVersion.buildEnv;
    }

    toString(): string {
        return `commit: ${this.commit}, build date: ${this.date.toString()}, buildEnv: ${this.buildEnv}`;
    }
}
