// class component of a small div with 5 colour options for the grading.
//contains a p tag with the grade

import React, { Component } from 'react'
import { Badge } from 'react-bootstrap';

type BoxProps = {
    grade: string,
}

const getVariant = (grade: string): "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark" => {
    //if the grade is '0' then the colour is red
    if (grade === '1' || grade === '2') {
        return 'danger';
    }
    //if the grade is '1' then the colour is orange
    if (grade === '3') {
        return 'warning';
    }
    if (grade === '4' || grade === '5') {
        return 'success';
    }

    return 'light';
}

export default class GradeColorBox extends Component<BoxProps> {

    render() {
        return (
            <Badge variant={getVariant(this.props.grade)}>
                {this.props.grade}
            </Badge>
        )
    }
}