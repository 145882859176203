import React from 'react';
import Select from 'react-select';

import './RoiSelect.css';

type OwnProps = {
    options: ControlledSelectOption[],
    onChange: (option: ControlledSelectOption | null) => void,
    label: string,
    value: ControlledSelectOption | undefined,
    isDisabled?: boolean,
}

type DispatchProps = {
}

type AllProps = OwnProps & DispatchProps;

export type ControlledSelectOption = {
    label: string;
    value: string;
}

/** A generalized and a controlled component version of RoiSelect. */
class ControlledSelect extends React.Component<AllProps> {

    // override some styles in react-select. rest of the overriding is done in the css file
    static selectStyles = {
        container: (provided: any) => ({
            ...provided,
            display: "inline-block",
            minWidth: "220px"
        }),
        control: (provided: any) => ({
            ...provided,
            minHeight: "unset"
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: "unset"
        }),
        clearIndicator: (provided: any) => ({
            ...provided,
            padding: "0 5px"
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            padding: "0 5px"
        })
    };

    handleChange = (option: any) => {
        this.props.onChange(option);
    }


    /** This component should be controlled, but it's actually not exactly because react-select is buggy. However in practice
     * as long as props.value is ALWAYS changed when the contained select's value is changed (i.e. props.onChange will always do
     * something sensible) then this shouldn't be visible.
     */
    render() {
        const { options, label, value, isDisabled } = this.props;
        const matchingOption = options.find(opt => value && opt.value === value.value && opt.label === value.label);
        return (
            <span className="roi-select">
                <label>{label}:
                    <Select
                        styles={ControlledSelect.selectStyles}
                        className="select-input"
                        options={options}
                        onChange={this.handleChange}
                        isClearable={true}
                        value={matchingOption ? [options.find(opt => opt === matchingOption)] : null}
                        isDisabled={isDisabled}
                    />
                </label>
            </span>
        );
    }
}

export default ControlledSelect;
