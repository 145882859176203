import Immerable from "./immerable";
import { UrlSafeBase64 } from "../util";

// Version 1 live review, will get files from Azure fileshares
export class LiveReviewQueryV1 extends Immerable {
    public storageAccountName: string | null;
    public daemonId: string | null;
    public clientId: string | null;
    public seriesId: string | null;
    public user: string | null;
    public bodyPartExamined: string | null;
    public modality: string | null;
    public timeMilliseconds: string | null;

    constructor(params: URLSearchParams) {
        super();

        this.storageAccountName = params.get('storageAccountName');
        this.daemonId = params.get('daemonId');
        this.clientId = params.get('clientId');
        this.seriesId = params.get('seriesId');
        this.user = params.get('adAccount') || params.get('username');
        this.bodyPartExamined = params.get('bodyPartExamined');
        this.modality = params.get('modality');
        this.timeMilliseconds = params.get('timeMilliseconds');
    }

    public isValid() {
        return !!this.storageAccountName
        && (!!this.daemonId || !!this.clientId)
        && !!this.seriesId
        && !!this.user
        && !!this.bodyPartExamined
        && !!this.modality
        && !!this.timeMilliseconds;
    }
}

// Version 2 live review, will get files from mvbackend livereview API
export class LiveReviewQueryV2 extends Immerable {
    public depl: string | null; // Deployment name (e.g. qa, dev, westeurope)
    public user: string | null; // Username
    public cid: string | null; // Client id (daemon/other client)
    public sid: string | null; // DICOM image series instance UID
    public model: string | null; // modelname used
    public api: string | null; // "Url-safe-Base64"-encoded api url
    public path: string | null; // 
    public time: string | null; // Timestamp for the result

    constructor(params: URLSearchParams) {
        super();
        
        this.depl = params.get('depl');
        this.user = params.get('user');
        this.cid = params.get('cid');
        this.sid = params.get('sid');
        this.model = params.get('model');
        this.api = params.get('api');
        this.path = params.get('path');
        this.time = params.get('time');
    }

    public isValid(): boolean {
        return !!this.depl
        && !!this.user
        && !!this.cid
        && !!this.sid
        && !!this.model
        && !!this.api
        && !!this.path
        && !!this.time;
    }

    public getApiUrl(): string {
        return UrlSafeBase64.decode(this.api as string);
    }
}