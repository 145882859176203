import _ from "lodash";

/** Target user for training task assignments (e.g. a clinic resident). */
export type TrainingUser = {
    userId: string;
    userName: string;
    id: string;
    userInfo: string;
    city: string;
    country: string;
    email: string;
    phone: string;
    tags: string[];
    permissions: string[];
};

export function convertJsonToTrainingUsers(json: any): TrainingUser[] {

    if (!json || !_.isArray(json)) {
        throw new Error('Invalid JSON object provided for Training User conversion.');
    }

    const trainingUsers = json.map((entry: any) => {
        const trainingUser: TrainingUser = {
            userId: entry.user_id,
            userName: entry.user_name,
            id: entry.id,
            userInfo: entry.user_info,
            city: entry.city,
            country: entry.country,
            email: entry.email,
            phone: entry.phone,
            tags: entry.tags,
            permissions: entry.permissions,
        };
        return trainingUser;
    });

    return trainingUsers;
}
