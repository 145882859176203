import _ from "lodash";
import { notEmpty } from "../util";

const DEFAULT_CUSTOMIZATION_NAME = 'default';

export type PredictionModel = {
    bodyPart: string;
    label: string;
    name: string;
    type: string;
    tags: string[];
    isOnline: boolean;
    isFreeTextEntryModel: boolean;
}

export type AvailableModels = {
    allowTextEntry: boolean;
    models: PredictionModel[];
}

export const MVSEG_PREDICTION_MODEL: PredictionModel = {
    name: 'mvseg',
    type: 'segmentation',
    bodyPart: '',
    label: 'mvseg',
    tags: [],
    isOnline: true,
    isFreeTextEntryModel: false,
}

export const getFreeTextEntryModel = (entry: string): PredictionModel => ({
    name: entry,
    type: 'segmentation',
    bodyPart: '',
    label: entry,
    tags: [],
    isOnline: true,
    isFreeTextEntryModel: true
});

export function convertPredictionModelDtoToViewModel(src: any, onlineModelNames?: string[]): PredictionModel[] | undefined {
    if (_.has(src, 'body_part')
        && _.has(src, 'model_label')
        && _.has(src, 'model_name')
        && _.has(src, 'model_type')
        && _.has(src, 'model_tags')
        && _.has(src, 'customization_names')
        && _.isArray(src['model_tags'])
        && _.isArray(src['customization_names'])
    ) {
        const modelName = src['model_name'];
        const isOnline = onlineModelNames ? onlineModelNames.includes(modelName) : false;
        const models: PredictionModel[] = [];
        for (const customizationName of src['customization_names']) {
            models.push({
                name: `${modelName}${customizationName === DEFAULT_CUSTOMIZATION_NAME ? '' : `.${customizationName}`}`,
                bodyPart: src['body_part'],
                label: `${src['model_label']}${customizationName === DEFAULT_CUSTOMIZATION_NAME ? '' : ` (${customizationName})`}`,
                type: src['model_type'],
                tags: src['model_type'],
                isOnline: isOnline,
                isFreeTextEntryModel: false,
            });
        }
        models.sort((a, b) => a.label.localeCompare(b.label));
        return models;
    } else {
        return undefined;
    }
}

export function convertAvailableModelsDtoToViewModel(src: any, allowTextEntry?: boolean): AvailableModels | undefined {
    if (_.has(src, 'user_model_details')
        && _.has(src, 'online_model_names')
        && _.isArray(src['user_model_details'])
        && _.isArray(src['online_model_names'])
    ) {
        const models = src['user_model_details'].map(m =>
            convertPredictionModelDtoToViewModel(m, src['online_model_names']))
            .filter(notEmpty).flat();
        return {
            allowTextEntry: allowTextEntry || false,
            models: models,
        }
    } else {
        return undefined;
    }
}
