import { getRandomInt }  from '../dicom/guid';

export class StructureTemplate {

    name: string;
    templateRois: TemplateRoi[];

    constructor(baseTemplate: any) {
        this.name = baseTemplate.Name || '';    // unnamed templates will not be shown in GUI
        this.templateRois = [];

        const baseTemplateRois = baseTemplate.TemplateRois || [];
        baseTemplateRois.forEach((r: any) => this.templateRois.push(new TemplateRoi(r.Title, r.Color, r.VolumeType)));
    }
}

export class TemplateRoi {
    title: string;
    color: number[];
    volumeType: string | undefined;

    constructor(title: string | undefined, color: number[] | undefined, volumeType: string | undefined) {
        this.title = title || '';       // untitled ROIs will not be shown in GUI
        this.color = color || [ getRandomInt(0,255), getRandomInt(0,255), getRandomInt(0,255)];
        this.volumeType = volumeType || undefined;
    }

    rgb() {
        return "rgb(" + this.color[0] + "," + this.color[1] + "," + this.color[2] + ")";
    }
}