import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { contextMenu } from 'react-contexify';
import { connect } from 'react-redux';

import { PageImage, } from '../models/PagePatient';
import { StoreState } from '../../../store/store';
import * as sagas from '../../../store/sagas';
import GradeColorBox from '../../rtviewer/grading/GradeColorBox';
import { MdClose, MdEdit, MdLock, MdLockOpen, MdDownload, MdUnarchive, MdComment } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { TrainingTask, TrainingTaskState, getTaskDownloadKey, getTrainingTaskListId, handleTaskState, handleTaskType } from '../../../datasets/training-task';
import { LockAction } from '../../../datasets/locks';
import { User } from '../../../store/user';
import './TasksTableRow.css';
import { UrlQuery, UrlQueryType } from '../../../store/url-query';
import { Link } from 'react-router-dom';
import routePaths from '../../../routes';


type DispatchProps = {
    setCurrentTask: (task: TrainingTask) => void,
    setTasksDialogVisibility: (value: boolean) => void,
    setTaskDescriptionDialogVisibility: (value: boolean) => void,
    downloadTaskImageAndStructureSets: (task: TrainingTask) => void,
    unloadTaskImageAndStructureSets: (task: TrainingTask) => void,
    lockStructureSet: (task: TrainingTask, user: User, lockAction: LockAction) => void,
    archiveTask: (task: TrainingTask) => void,
    unarchiveTask: (task: TrainingTask) => void,
    setArchivedTasksDialogVisibility(value: boolean): void
}


type OwnProps = {
    taskNumber: number,
    task: TrainingTask,
    showSupervisorColumns: boolean,
    handleViewTaskClick: (task: TrainingTask) => void,
}

type OwnState = {
    isDescriptionExpanded: boolean,
}


type AllProps = OwnProps & StoreState & DispatchProps;

class TasksTableRow extends React.Component<AllProps, OwnState> {
    displayName = TasksTableRow.name;

    constructor(props: AllProps) {
        super(props);

        this.state = {
            isDescriptionExpanded: false,
        };
    }

    handleShowContextMenu = (menuId: string, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: menuId, event: evt });
    }

    onShowTaskClick = (task: TrainingTask) => {
        this.props.setCurrentTask(task)
        this.props.setTasksDialogVisibility(true);
    }

    // get structure set name and it's roi names from all structure sets for this structure set id

    getStructureSetInfo = (structureSetId: string, image: PageImage) => {
        const ss = image.structureSets.find(s => s.structureSet.sopId === structureSetId);
        if (ss) {
            return {
                name: ss.structureSet.label,
                roiNames: ss.structureSet.roiMappings.join(', ')
            };
        }
        return {
            name: '',
            roiNames: ''
        };
    }

    handleLoadClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        // don't perform this action if user is using ctrl-click to open this task in a new tab
        if (!evt.ctrlKey && !evt.metaKey) {
            evt.preventDefault();
            this.props.downloadTaskImageAndStructureSets(this.props.task);
        }
    }

    handleUnloadClick = () => {
        this.props.unloadTaskImageAndStructureSets(this.props.task);
    }

    handleViewClick = (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>, task: TrainingTask) => {
        // don't perform this action if user is using ctrl-click to open this task in a new tab
        if (!evt.ctrlKey && !evt.metaKey) {
            evt.preventDefault();
            this.props.handleViewTaskClick(task);
            this.props.setArchivedTasksDialogVisibility(false);
        }
    }

    handleLockClick = () => this.handleStructureSetLockActionClick(LockAction.Lock);
    handleUnlockClick = () => this.handleStructureSetLockActionClick(LockAction.Unlock);
    handleStructureSetLockActionClick = (lockAction: LockAction) => {
        const user: User | undefined = this.props.user;
        if (!user) {
            throw new Error('No user defined -- cannot perform lock action');
        }

        this.props.lockStructureSet(this.props.task, user, lockAction);
    }

    renderTaskState = (task: TrainingTask) => {
        return null;
    }

    renderLockCell = (task: TrainingTask) => {
        const structureSetId = task.traineeStructureSet.sopInstanceUid;
        const locks = this.props.structureSetLocks;
        if (locks === undefined) { throw new Error('Locks were not properly initialized'); }
        const lockRequests = this.props.structureSetLockRequests;
        const download = this.props.downloads[getTaskDownloadKey(task)];

        const matchingLock = locks[structureSetId];
        const user = this.props.user;

        if (lockRequests && lockRequests[structureSetId] === LockAction.Lock) {
            return (<b>locking...</b>)
        }
        if (lockRequests && lockRequests[structureSetId] === LockAction.Unlock) {
            return (<b>unlocking...</b>)
        }
        if (!matchingLock) {
            return (
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={'Lock task'}>Lock this task</Tooltip>}
                delay={800}
            >
                <Button className='primary-button' variant={"light"} size="sm" onClick={this.handleLockClick}><MdLock size={15} /></Button>
            </OverlayTrigger>);
        }
        if (user && user.hasRTStructLock(matchingLock)) {
            return (<OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={'Unlock task'}>Unlock this task</Tooltip>}
                delay={800}
            ><Button className='lock-button' variant={"danger"} size="sm" onClick={this.handleUnlockClick} disabled={download && !download.ready && !download.failed}><MdLockOpen size={15} /></Button>
            </OverlayTrigger>);
        }
        return (<>
            <b className='word-wrap'>Locked by: {matchingLock.user_name}</b>
            {/* Force unlock is disabled for now */}
            {/* {rtViewerApiClient.permissions.allowForceUnlock ?
                <Button title="Force unlock" variant={"danger"} size="sm" onClick={this.handleUnlockClick}><MdClose size={15}/></Button> : null
            } */}
        </>);
    }

    renderLoadCell = (task: TrainingTask) => {
        const download = this.props.downloads[getTaskDownloadKey(task)];
        const locks = this.props.structureSetLocks;

        let buttonTxt = "";
        const currentLock = locks ? locks[task.traineeStructureSet.sopInstanceUid] : undefined;
        if (task.archived || !this.props.user || !currentLock || currentLock.user_id !== this.props.user.userId) {
            buttonTxt = "  (read-only)";
        }

        if (!download) {
            return (
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={'Load task'}>Load task</Tooltip>}
                    delay={800}
                >
                    <Link to={{
                        pathname: routePaths.guidelineTraining,
                        search: new UrlQuery(UrlQueryType.TaskWorkQuery, task.id).getQueryParameters(UrlQueryType.TaskWorkQuery),
                    }}>
                        <Button
                            className='primary-button'
                            variant={'light'}
                            size="sm"
                            data-cy="load-button"
                            title="Load DICOM files"
                            onClick={this.handleLoadClick}
                        >
                            <MdDownload size={20} />
                            {buttonTxt}
                        </Button>
                    </Link>
                </OverlayTrigger>
            );
        } else if (download.failed) {
            return <b title="Download failed! Please refresh the page and try again.">Failed!</b>;
        } else if (download.ready) {
            return (
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id={'Unload task'}>Unload task</Tooltip>}
                    delay={800}
                >
                    <Button
                        className='primary-button'
                        size="sm"
                        data-cy="unload-button"
                        title="Unload DICOM files"
                        onClick={this.handleUnloadClick}
                    >
                        <MdClose size={20} />
                    </Button>
                </OverlayTrigger>
            );
        } else {
            let progress = parseInt(download.progressPercentage.toString(), 10) + "%";
            return <b>{progress}</b>;
        }
    };

    handleArchiveClick = (task: TrainingTask) => {
        const archiveAction = task.archived ? 'unarchive' : 'archive';

        if (window.confirm(`Do you want to ${archiveAction} the task:  "${task.name}"`)) {
            if (task.archived) { this.props.unarchiveTask(task); }
            else { this.props.archiveTask(task); }
        }
    }

    getTrainingTaskClassName = (trainingTask: TrainingTask): string => {
        const lock = this.props.structureSetLocks![trainingTask.traineeStructureSet.sopInstanceUid]
        if (lock && this.props.user && lock.user_id === this.props.user.userId && !trainingTask.archived) {
            return 'locked';
        }
        return '';
    }

    renderViewCell = (task: TrainingTask) => {
        const download = this.props.downloads[getTaskDownloadKey(task)];
        const locks = this.props.structureSetLocks;

        let buttonTxt = ""
        if (!locks || locks[task.traineeStructureSet.sopInstanceUid] === undefined || task.archived) {
            buttonTxt = "  (read-only)"
        }

        if (download && !download.failed && download.ready) {
            return (<OverlayTrigger
                placement="bottom"
                overlay={(<Tooltip id={'View task'}>View task {buttonTxt}</Tooltip>)}
                delay={800}>
                <Link to={{
                    pathname: routePaths.guidelineTraining,
                    search: new UrlQuery(UrlQueryType.TaskWorkQuery, task.id).getQueryParameters(UrlQueryType.TaskWorkQuery),
                }}>
                    <Button className='view-button' variant={"light"} size="sm" title="View image" onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => this.handleViewClick(evt, task)}><IoMdEye size={20} /></Button>
                </Link>
            </OverlayTrigger>);
        }
        return null;
    }

    handleDescriptionClick = (task: TrainingTask) => {
        this.props.setCurrentTask(task);
        this.props.setTaskDescriptionDialogVisibility(true);
    };

    renderTaskDescription = (task: TrainingTask) => {
        if (task.description.length <= 10) {
            // Render the full description
            return task.description;
        }
        if (task.description.length > 10) {
            // Render the truncated description with a "Read More" button
            const truncatedDescription = task.description.substring(0, 15) + '...';
            return (
                <>
                    {truncatedDescription}
                    <Button className='primary-button' size="sm" onClick={() => this.handleDescriptionClick(task)}><MdComment size={15}/></Button>
                </>
            )
        };
        if (task.description.length <= 0) {
            return null;
        }
    };

    render() {
        const { taskNumber, task, showSupervisorColumns } = this.props;

        const locked = this.getTrainingTaskClassName(task);


        return (
            <tr data-cy={'tasks-table-row'} className={taskNumber % 2 ? `odd-row task-row ${locked}` : `task-row ${locked}`} id={getTrainingTaskListId(task)}>
                <td>{task.name}</td>
                <td style={{ wordBreak: 'normal' }}>{handleTaskType(task.type)}</td>
                {showSupervisorColumns && <td>{task.trainee.user_name}</td>}
                <td>{task.supervisor.user_name}</td>
                <td>{task.patientId}</td>
                <td className="description-cell" style={{ wordBreak: 'normal' }}>
                    {this.renderTaskDescription(task)}
                </td>
                <td style={{ wordBreak: 'normal' }}>{handleTaskState(task.state)}</td>
                {!task.archived && <td>{this.renderLockCell(task)}</td>}
                <td>{this.renderLoadCell(task)}</td>
                <td>{this.renderViewCell(task)}</td>
                <td>{(showSupervisorColumns || task.grade !== 'UNGRADED') && <h5 style={{ marginBottom: "0.25rem" }}><GradeColorBox grade={task.grade} /></h5>}</td>
                {showSupervisorColumns && (task.archived ? <td><OverlayTrigger
                    placement="bottom"
                    overlay={(<Tooltip id={'Unarchive Task'}>Unarchive task</Tooltip>)}
                    delay={800}><Button data-cy="unarchieve-task-btn" className='primary-button' variant={"light"} size="sm" onClick={() => this.handleArchiveClick(task)}><MdUnarchive size={15} /></Button></OverlayTrigger></td> : <td><OverlayTrigger
                        placement="bottom"
                        overlay={(<Tooltip id={'Edit Task'}>Edit task</Tooltip>)}
                        delay={800}><Button data-cy="edit-task-btn" className='primary-button' variant={"light"} size="sm" onClick={() => this.onShowTaskClick(task)}><MdEdit size={15} /></Button>
                    </OverlayTrigger></td>)}
            </tr>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(TasksTableRow);
