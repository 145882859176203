import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import ReactSlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { GoChevronRight } from 'react-icons/go';

import { StoreState } from '../../store/store';
import * as sagas from '../../store/sagas';

import './BatchJobQueuePage.css';
import { IconContext } from 'react-icons';
import { BatchJobStatus } from '../../web-apis/batch-client';
import BatchJobItem from './BatchJobItem';


type OwnProps = {
};

type DispatchProps = {
    startWatchingBatchJobs(): void,
    setBatchJobPaneVisibility(value: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class BatchJobQueuePage extends React.PureComponent<AllProps> {

    public el: any;

    componentDidMount() {
        Modal.setAppElement(this.el);
    }

    componentDidUpdate(prevProps: AllProps) {
        if (this.props.isBatchJobPaneVisible && !prevProps.isBatchJobPaneVisible) {
            // start watching the batch job queue when the page is opened for the first time
            this.props.startWatchingBatchJobs();
        }
    }

    handleClosePane = () => {
        this.props.setBatchJobPaneVisibility(false);
    }


    static inverseSortJobs(batchJob1: BatchJobStatus, batchJob2: BatchJobStatus) {
        return batchJob2.timeStampStarted - batchJob1.timeStampStarted;
    }

    render() {
        const batchJobs: BatchJobStatus[] = this.props.batchJobs;
        const sortedBatchJobs = Array.from(batchJobs).sort(BatchJobQueuePage.inverseSortJobs);

        return (
            <div ref={ref => this.el = ref}>
                <ReactSlidingPane
                    isOpen={this.props.isBatchJobPaneVisible || false}
                    onRequestClose={this.handleClosePane}
                    className="batch-job-queue-pane"
                    width="50%"
                    title="Batch Job Queue"
                    closeIcon={(<div className="close-icon"><IconContext.Provider value={{ size: '2em' }}><GoChevronRight /></IconContext.Provider></div>)}
                >
                    <div>
                        <div>These are all the batch jobs in the system, both finished ones and ones still in the queue. The data for this view is updated every 5 minutes.</div>
                        <div className="batch-job-list">
                            {sortedBatchJobs.map((batchJob: BatchJobStatus) => (<BatchJobItem key={batchJob.batchId} batchJob={batchJob} />))}
                        </div>
                    </div>
                </ReactSlidingPane>
            </div>
        );
    }
}


export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(BatchJobQueuePage);
