// ROI list component 

import React from 'react';
import { Button, Dropdown, Modal, SplitButton, } from 'react-bootstrap';
import { Menu, Item, Separator, contextMenu, ItemParams, PredicateParams } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { SketchPicker } from 'react-color';
import { connect } from 'react-redux';
import _ from 'lodash';
import { HotKeys } from "react-hotkeys";

import { Checkbox } from '../misc-components';
import { roiCompare } from '../../helpers/compare';
import * as sagas from '../../store/sagas';
import * as structureSet from '../../dicom/structure-set';
import { StructureSetGrading, DatasetGradings } from '../../datasets/roi-grading';

import { ViewerState } from '../../rtviewer-core/viewer-state';
import { NewItemGlyph } from '../glyphs';
import { SimpleBooleanOperand } from './toolbars/contouring-toolbars/BooleanOperationsToolbar';
import { AddMarginDialog } from './dialogs/AddMarginDialog';
import { MarginOptions, MarginOperations } from '../../rtviewer-core/webgl/sdf/margin/margin';
import { StoreState } from '../../store/store';
import ConfirmRoiDeletionDialog from './dialogs/ConfirmRoiDeletionDialog';
import RoiTypeMenu from './RoiTypeMenu';
import ModalDialog from '../common/ModalDialog';

import './ROITable.css';
import { ShareFileClient } from '@azure/storage-file-share';
import { RoiGuidelines } from '../../web-apis/rtviewer-api-client';
import RoiGuidelineMenu from './RoiGuidelineMenu';
import { SessionNotification, NotificationType, DEFAULT_SESSION_TIMEOUT_IN_MS } from '../common/models/SessionNotification';
import GradingSheets from './GradingSheets';
import { notEmpty } from '../../util';
import { Workspace } from '../../store/work-state';
import routePaths from '../../routes';
type OwnProps = {
    viewerState: ViewerState,
    structureSet: structureSet.StructureSet | null,
    structureSets: structureSet.StructureSet[],
    grading: StructureSetGrading | null,
    allDatasetGradings: DatasetGradings | null,
    openAddStructuresFromTemplateDialog: () => void,
    onAddRoiClick: (ss: structureSet.StructureSet) => void,
    doGradingSync: (ss: structureSet.StructureSet) => void,
    roiGuidelines?: RoiGuidelines,
}

type DispatchProps = {
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
}

type AllProps = StoreState & OwnProps & DispatchProps;

type OwnState = {
    // TODO: all these roiToSomethings could be done with selectedIndices
    roiToRename?: structureSet.Roi | null,
    roiForColorSelect?: structureSet.Roi | null,
    newName?: string,
    roiForMarginTool?: structureSet.Roi | null,
    selectedColor?: any,
    refreshSwitch?: any,
    showDeleteRoiDialog: boolean,
    showInterpolationDialog: boolean,

    /** The list index numbers of ROIs that user has currently selected. 0, 1, or more. */
    selectedIndices: number[],

    /** The last selected ROI item index user actively clicked on. This can include when clicked on with the CTRL modifier, but does
     * NOT include when clicked on with the SHIFT key modifier. This value is used as a starting index when performing mass list
     * selections (e.g. when user continously clicks on the ROI list with shift-clicks) to ensure the selections stay sensible.
     * Initializes and should default to '0' (first item in the list).
     */
    lastIndexClicked: number,

    /** An unfortunate hack we need to keep track when viewerState.selectedRoi is changed. Tracking this is not possible in any other
     * way as viewerState is not an immutable object and it lives outside of React lifecycle.
     */
    previousSelectedRoiInViewerStateHack: structureSet.Roi | null,
    previousSelectedStructureSetInViewerStateHack: structureSet.StructureSet | null,
}

/** keyboard shortcut map for this component */
const keyMap = {
    DELETE_ITEM: ["del"],
    SELECT_ALL: ["ctrl+a"],
};

enum RoiContextMenuAction { Focus, Rename, Duplicate, CopyToClipboard, ChangeColor, CreateMargin, Interpolate, Delete };
type RoiContextMenuProps = { roi: structureSet.Roi, sharedFile: ShareFileClient | string | null };
type RoiContextMenuData = { action: RoiContextMenuAction, multipleRoisSelected: boolean, singleRoiEditsAllowed: boolean, canEditStructureSet: boolean, isReadOnlyGuideRoiSelected: boolean };

class ROITable extends React.Component<AllProps, OwnState> {
    displayName = ROITable.name

    roiContextMenuId = "roi-context-menu";

    constructor(props: AllProps) {
        super(props);
        this.render = this.render.bind(this);

        this.state = {
            selectedIndices: [],
            showDeleteRoiDialog: false,
            showInterpolationDialog: false,
            lastIndexClicked: 0,
            previousSelectedRoiInViewerStateHack: null,
            previousSelectedStructureSetInViewerStateHack: null,
        };
    }

    componentDidMount() {
        let vs = this.props.viewerState;
        vs.addListener(this.updateView);
        this.updateView();
    }

    componentWillUnmount() {
        let vs = this.props.viewerState;
        vs.removeListener(this.updateView);
    }

    componentDidUpdate = (prevProps: AllProps, prevState: OwnState) => {
        let viewerStateSelectedRoiWasChanged = false;

        if (this.state.previousSelectedRoiInViewerStateHack !== this.props.viewerState.selectedRoi) {
            // ALWAYS update our viewerState.selectedRoi hack. Flag that selectedRoi change path
            // must be processed.
            viewerStateSelectedRoiWasChanged = true;
            this.setState({
                previousSelectedRoiInViewerStateHack: this.props.viewerState.selectedRoi,
            });
        }

        if (prevProps.structureSet !== this.props.structureSet) {
            // structure set was changed -- clear some structure set specific state data, don't do
            // anything else
            this.setState({
                selectedIndices: [],
                lastIndexClicked: 0,
            });
            return;
        }

        if (viewerStateSelectedRoiWasChanged) {
            // ROI was changed without clicking on the ROI table (e.g. by clicking on the contours)
            // TODO: allow user to ctrl-click multiple ROIs into selection. Currently the selection is just cleared.
            const newSelection: number[] = [];
            let index = 0;
            const { selectedRoi } = this.props.viewerState;
            if (selectedRoi) {
                const roiList = this.getRoiList();
                if (roiList) {
                    index = roiList.findIndex(r => r === selectedRoi);
                    if (index !== -1) {
                        newSelection.push(index);
                    }
                }
            }

            this.setState({
                selectedIndices: newSelection,
                lastIndexClicked: index !== -1 ? index : 0,
            });
        }

        // TODO: is this being called too aggressively?
        this.selectedRoiToViewport();
    }

    static getDerivedStateFromProps = (props: AllProps, state: OwnState): Partial<OwnState> | null => {
        if (state.previousSelectedStructureSetInViewerStateHack !== props.viewerState.selectedStructureSet) {
            // clear current selectedIndices if structure set was changed; also keep track of the previous viewer
            // state selected structure set value
            return { previousSelectedStructureSetInViewerStateHack: props.viewerState.selectedStructureSet, selectedIndices: [] };
        }

        return null;
    }

    updateView = () => {
        this.setState({ refreshSwitch: !this.state.refreshSwitch });
    }

    setOperandForSimpleBooleanOperationFromRoi = (operandIndex: SimpleBooleanOperand, roi: structureSet.Roi) => {
        const vs = this.props.viewerState;
        vs.setSimpleBooleanOperand(operandIndex, roi);
    }

    handleSelectRoiClick = (roi: structureSet.Roi, evt: React.MouseEvent<HTMLDivElement, MouseEvent> | null) => {
        const vs = this.props.viewerState;
        vs.setSelectedRoi(roi);

        this.setOperandForSimpleBooleanOperationFromRoi(SimpleBooleanOperand.First, roi);

        const ss = roi.structureSet;
        const roiList = this.getRoiList(ss)!;
        const selectedIndex = roiList.indexOf(roi);
        let lastIndexClicked = selectedIndex;
        let newIndices: number[] = [selectedIndex];

        // ctrl-click -> add or remove this single roi from selection
        if (evt && evt.ctrlKey) {
            if (this.state.selectedIndices.includes(selectedIndex)) {
                newIndices = _.without(this.state.selectedIndices, selectedIndex);
                this.setOperandForSimpleBooleanOperationFromRoi(SimpleBooleanOperand.First, roi);
            } else {
                newIndices = this.state.selectedIndices.concat(selectedIndex);
            }
        }

        // shift-click -> choose a list of elements, either up or down
        if (evt && evt.shiftKey) {
            // the previous item we clicked on is the last item from the current
            // indices selection array
            const previousIndexSelection = this.state.lastIndexClicked;

            // set the new last index clicked as whatever it was previously.
            // this ensures repeated shift-click selections behave well.
            lastIndexClicked = this.state.lastIndexClicked;

            // special case: if we just clicked on the same item again as previously,
            // ensure it's in the list and do nothing else
            if (previousIndexSelection === selectedIndex) {
                if (!_.includes(newIndices, selectedIndex)) { newIndices.push(selectedIndex); }
            }
            else {
                const direction = selectedIndex - previousIndexSelection > 0 ? 1 : -1;

                if (!evt.ctrlKey) {
                    // clear previous selection if ctrl was NOT pressed
                    newIndices = [];
                }

                // always loop from previous selection towards current selection so the current
                // one will be the last one to be added to the new indices selection. duplicates
                // will be removed in the next step.
                newIndices.push(previousIndexSelection);
                let i = previousIndexSelection;
                while (i !== selectedIndex) {
                    i += 1 * direction;
                    newIndices.push(i);
                }

                // remove any duplicates, retain first-in order
                newIndices = _.uniq(newIndices);
            }
        }

        this.setState({ selectedIndices: newIndices, lastIndexClicked: lastIndexClicked, previousSelectedRoiInViewerStateHack: roi, });
    }

    handleFocusToRoiClick = (roi: structureSet.Roi) => {
        let ss = this.props.structureSet as structureSet.StructureSet;
        let vs = this.props.viewerState;
        this.handleSelectRoiClick(roi, null);
        vs.focusOnStructure(roi, ss)
    }

    handleRoiVisibleChange = (event: any, roi: structureSet.Roi) => {
        let val = event.target.checked;
        let vs = this.props.viewerState;
        vs.setRoiHidden(roi, !val);
    }

    handleAllRoisVisibleChange = () => {
        const vs = this.props.viewerState;
        const shouldAllRoisBeHidden = vs.getAreAllRoisVisible();
        vs.setAllRoisHidden(shouldAllRoisBeHidden);
    }

    handleRenameRoiClick = (roi: structureSet.Roi) => {
        this.setState({ roiToRename: roi, newName: roi.name }, function () {
            const arr = document.getElementsByClassName("roi-name-edit");
            (arr[arr.length - 1] as HTMLElement).focus();
        });
    }

    handleDuplicateRoiClick = (roi: structureSet.Roi) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const ss = vs.selectedStructureSet;
        if (ss) {
            ss.duplicateRoi(vm, roi);
            vs.notifyListeners();
        }
    }

    handleCopyToClipboard = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roiList = this.getRoiList();
        if (ss && roiList) {
            const rois = this.state.selectedIndices.map(i => roiList[i]);
            vs.setCopiedRois(rois);
        }
    }

    handleConfirmInterpolation = () => {
        this.setState({ showInterpolationDialog: true });
    }

    handleInterpolateRoiClick = (roi: structureSet.Roi) => {
        this.setState({ showInterpolationDialog: false });
        const vs = this.props.viewerState;
        if(roi){
            vs.interpolateRoiClick(roi);
        }
    }

    handleChangeRoiColorClick = (roi: structureSet.Roi) => {
        const color = { r: roi.lineColor.x, g: roi.lineColor.y, b: roi.lineColor.z };
        this.setState({ roiForColorSelect: roi, selectedColor: color });
    }

    /** deletes whatever the current selection of ROIs is */
    handleRoiDeletion = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const roiList = this.getRoiList();
        if (ss && roiList) {
            const roiNumbers = this.state.selectedIndices.map(i => roiList[i].roiNumber);
            ss.deleteRois(roiNumbers);
            vs.setSelectedRoi(null);
            this.props.doGradingSync(ss);
            vs.roisChanged(ss);
        }
        this.setState({ selectedIndices: [] });
        this.handleCloseDeleteRoiDialog();
    }

    handleRoiNameChanged = (event: any) => {
        this.setState({ newName: event.target.value })
    }

    handleRoiNameKeyPress = (event: any, roi: structureSet.Roi) => {
        if (event.keyCode === 13) {// Enter
            this.handleRoiNameEditFinished(roi);
        }
        else if (event.keyCode === 27) {// Esc
            this.setState({ roiToRename: null });
        }
    }

    handleRoiNameEditFinished = (roi: structureSet.Roi) => {
        const vs = this.props.viewerState;
        const ss = this.props.structureSet as structureSet.StructureSet;
        if (this.state.newName && this.state.newName !== roi.name) {
            const newRoiName = this.state.newName.trim();
            if (ss.getRois().some(r => r.name === newRoiName)) {
                // prevent renaming ROIs to duplicate values
                this.props.addNotification(new SessionNotification(`rename-duplicate-name-warning-${Date.now()}`, 'A structure with the same name already exists in this structure set', NotificationType.Warning, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS));
            } else {
                const previousRoiList = this.getRoiList(ss);
                roi.name = newRoiName;
                roi.unsaved = true;
                this.props.doGradingSync(ss);
                vs.roisChanged(ss);
                const nextRoiList = this.getRoiList(ss);

                // update roi selection indices after name changes
                if (previousRoiList && nextRoiList) {
                    const previousRois = this.state.selectedIndices.map(i => previousRoiList[i]);
                    const nextRoiIndices = nextRoiList.map((nextRoi, i) => previousRois.find(prevRoi => prevRoi.roiId === nextRoi.roiId) ? i : undefined).filter(notEmpty);
                    this.setState({ selectedIndices: nextRoiIndices });
                }
            }
        }
        this.setState({ roiToRename: null });
    }

    handleSelectColor = (color: any) => {
        this.setState({ selectedColor: color });
    }

    handleCloseColorModal = () => {
        const vs = this.props.viewerState;

        const ss = vs.selectedStructureSet;
        const roi = this.state.roiForColorSelect;
        const rgb = this.state.selectedColor.rgb;
        if (ss && roi && rgb) {
            roi.lineColor.set(rgb.r, rgb.g, rgb.b);
            ss.unsaved = true;
            roi.unsaved = true;
            vs.setSelectedRoi(roi);
            vs.notifyListeners();
        }
        this.setState({ roiForColorSelect: null });
    }

    handleStartMarginDialog = (roi: structureSet.Roi) => {
        // margin tool is disabled in release 1.0 -- we should never get here
        throw new Error('Margin tool is disabled');

        // if (!roi.sdf) {
        //     alert(roi.name + " does not have contours!");
        // }
        // else {
        //     this.setState({ roiForMarginTool: roi });
        // }
    }

    handleMarginApply = (opt: MarginOptions) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const ss = opt.targetRoi.structureSet;

        document.body.style.cursor = 'wait';
        ss.modalMessage = structureSet.StructureSetModalMessages.CalculatingMargin;
        vs.notifyListeners();
        this.setState({ roiForMarginTool: null });

        setTimeout(function () {
            new MarginOperations(vm).addMargin(opt);
            document.body.style.cursor = 'default';
            ss.modalMessage = null;
            vs.notifyListeners();
        }, 200);
    }

    handleChangeRoiInterpretedType = (roi: structureSet.Roi, roiType: string) => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        if (ss && Object.values(ss.rois).includes(roi)) {
            roi.interpretedType = roiType;
            ss.unsaved = true;
            roi.unsaved = true;
            vs.notifyListeners();
        }
    }

    handleAddRoiClick = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        if (!ss) { return; }
        this.props.onAddRoiClick(ss);
    }

    handleAddRoisFromTemplateOpenModalClick = () => {
        this.props.openAddStructuresFromTemplateDialog();
    }

    handleDeleteRoiDialogClick = () => {
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet as structureSet.StructureSet;
        const canEdit = vs.canEdit && ss.canEdit();
        const roiList = canEdit ? this.getRoiList(ss) : undefined;
        const selectedRois = roiList && this.state.selectedIndices.length > 0 ? this.state.selectedIndices.map(i => roiList[i]) : undefined;
        const isRoiDeletionAllowed = selectedRois !== undefined && selectedRois.length > 0 &&
            selectedRois.every(r => r.canEdit() && (
                !this.props.currentTask || this.props.currentTask.traineeStructureSet.rois.every(taskRoi => taskRoi.roiName !== r.name)
            ));
        if (isRoiDeletionAllowed) {
            this.setState({ showDeleteRoiDialog: true });
        }
    }

    handleCloseDeleteRoiDialog = () => {
        this.setState({ showDeleteRoiDialog: false });
    }

    handleShortcutDelete = () => {
        this.handleDeleteRoiDialogClick();
    }

    handleShortcutSelectAll = (keyEvent?: KeyboardEvent) => {

        // stop ctrl+a from selecting everything
        if (keyEvent) {
            keyEvent.preventDefault();
        }

        const roiList = this.getRoiList() || [];

        // add all indices from 0 to roiList.length to array
        const list = roiList.map((_, i) => i);
        this.setState({ selectedIndices: list });
    }

    handleShowRoiContextMenu = (roi: structureSet.Roi, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: this.roiContextMenuId, props: { roi: roi }, event: evt });
    }

    handleRoiContextMenuItemClick = ({ props, data }: ItemParams<RoiContextMenuProps, RoiContextMenuData>) => {
        if (!props || !data) {
            // do nothing if we somehow get undefined props or data
            return;
        }

        const { roi } = props;
        const { action } = data;

        switch (action) {
            case RoiContextMenuAction.Focus:
                this.handleFocusToRoiClick(roi);
                break;
            case RoiContextMenuAction.Rename:
                this.handleRenameRoiClick(roi);
                break;
            case RoiContextMenuAction.Duplicate:
                this.handleDuplicateRoiClick(roi);
                break;
            case RoiContextMenuAction.CopyToClipboard:
                this.handleCopyToClipboard();
                break;
            case RoiContextMenuAction.ChangeColor:
                this.handleChangeRoiColorClick(roi);
                break;
            case RoiContextMenuAction.CreateMargin:
                this.handleStartMarginDialog(roi);
                break;
            case RoiContextMenuAction.Interpolate:
                this.handleInterpolateRoiClick(roi);
                break;
            case RoiContextMenuAction.Delete:
                this.handleDeleteRoiDialogClick();
                break;
            default:
                if (_.has(RoiContextMenuAction, action)) {
                    throw new Error(`Unimplemented ROI context menu action "${RoiContextMenuAction[action]}"`);
                } else {
                    throw new Error(`Undefined ROI context menu action "${action}"`);
                }
        }
    }

    isMenuItemHidden = ({ props, data }: PredicateParams<RoiContextMenuProps, RoiContextMenuData>): boolean => {
        return this.isMenuItemHiddenFunc(props, data);
    }

    /** The main implementation of isMenuItemHidden is separated from its react-contextify-based framework so we can reuse the code
     * outside react-contextify also.
     */
    isMenuItemHiddenFunc = (props: RoiContextMenuProps | undefined, data: RoiContextMenuData | undefined): boolean => {
        if (!props || !data) {
            throw new Error('Invalid data supplied to ROI context menu hide toggle');
        }

        const { roi } = props;
        const { action, multipleRoisSelected, singleRoiEditsAllowed, canEditStructureSet, isReadOnlyGuideRoiSelected } = data;
        const workspace = this.props.currentWorkState ? this.props.currentWorkState.workspace : Workspace.None;
        const isReferenceLibraryWorkspace = workspace === Workspace.ReferenceLibrary;
        const isQAToolWorkspace = workspace === Workspace.QATool;
        const isGuideWorkspace = workspace === Workspace.GuidelineTraining;

        switch (action) {
            case RoiContextMenuAction.Focus:
                return multipleRoisSelected;
            case RoiContextMenuAction.Duplicate:
                return !singleRoiEditsAllowed || !canEditStructureSet;
            case RoiContextMenuAction.CopyToClipboard:
                return isReferenceLibraryWorkspace || isQAToolWorkspace || isGuideWorkspace;
            case RoiContextMenuAction.Rename:
                return !singleRoiEditsAllowed || !roi.canEdit() || isReadOnlyGuideRoiSelected;
            case RoiContextMenuAction.ChangeColor:
            case RoiContextMenuAction.CreateMargin:
            case RoiContextMenuAction.Interpolate:
                return !singleRoiEditsAllowed || !roi.canEdit();
            case RoiContextMenuAction.Delete:
                return !canEditStructureSet || !roi.canEdit() || (this.props.currentTask !== undefined && this.props.currentTask.traineeStructureSet.rois.find(r => r.roiName === roi.name) !== undefined);
            default:
                if (_.has(RoiContextMenuAction, action)) {
                    throw new Error(`Unimplemented ROI context menu hide toggle for "${RoiContextMenuAction[action]}"`);
                } else {
                    throw new Error(`Undefined ROI context menu hide toggle for "${action}"`);
                }
        }
    }

    /** Returns a sorted list of structures (ROIs) from either the given structure set or from current
     *  viewer-state selected structure set.
     */
    getRoiList = (structureSet: structureSet.StructureSet | undefined = undefined): structureSet.Roi[] | null => {
        let ss: structureSet.StructureSet;

        if (structureSet) {
            ss = structureSet;
        }
        else {
            const vs = this.props.viewerState;
            const ssOrNull = vs.selectedStructureSet;
            if (!ssOrNull) { return null; }
            ss = ssOrNull;
        }
        return Object.keys(ss.rois).map(roiNr => ss.rois[roiNr]).sort(roiCompare);
    }

    selectedRoiToViewport = () => {
        const selectedRoiItem = document.getElementsByClassName("selected-roi")[0];
        const roiPanel = document.getElementsByClassName("left-bottom-panel")[0];
        if (!selectedRoiItem) { return; }
        const selectionRect = selectedRoiItem.getBoundingClientRect();
        const roiPanelRect = roiPanel.getBoundingClientRect();

        if (selectionRect.top < roiPanelRect.top) {
            roiPanel.scrollTop -= (roiPanelRect.top - selectionRect.top);
        }
        else if (selectionRect.bottom > document.documentElement.clientHeight) {
            roiPanel.scrollTop += (selectionRect.bottom - document.documentElement.clientHeight);
        }
    }

    renderRoiName(roi: structureSet.Roi, canEdit: boolean, renameDisabled: boolean) {
        if (this.state.roiToRename === roi) {
            return (
                <input className="roi-name-edit" type="text" value={this.state.newName}
                    onChange={(event) => this.handleRoiNameChanged(event)}
                    onKeyDown={(event) => this.handleRoiNameKeyPress(event, roi)}
                    onBlur={() => this.handleRoiNameEditFinished(roi)} />
            );
        }

        return (
            <div>
                <div onDoubleClick={() => (canEdit && !renameDisabled) && this.handleRenameRoiClick(roi)}>
                    {roi.unsaved ? <b>{roi.name}</b> : <>{roi.name}</>}
                </div>
            </div>
        );
    }

    /** keyboard shortcut handlers */
    handlers = {
        DELETE_ITEM: this.handleShortcutDelete,
        SELECT_ALL: this.handleShortcutSelectAll,
    }

    isExpertContoursRoiSelected = (vs: ViewerState) => {
        if (!vs.selectedStructureSet) { return false; }
        return vs.selectedStructureSet.dataset.StructureSetLabel === structureSet.ExpertContoursName;
    };

    isTrainingTaskRoiSelected = (vs: ViewerState) => {
        if (!vs.selectedRoi || !this.props.currentTask) { return false; }
        const traineeRois = this.props.currentTask.traineeStructureSet.rois.map(r => r.roiName);
        // check if selected ROI is in the trainee structure set
        return traineeRois.includes(vs.selectedRoi.name);
    };

    render() {
        const vs = this.props.viewerState;
        const ssOrNull = vs.selectedStructureSet;
        if (!ssOrNull) { return null; }
        const ss = ssOrNull as structureSet.StructureSet;
        const grading = this.props.grading;
        const matchingTask = this.props.currentTask &&
            this.props.currentTask.traineeStructureSet.sopInstanceUid === ss.structureSetId &&
            this.props.currentTask.traineeStructureSet.seriesInstanceUid === ss.seriesUid ? this.props.currentTask : undefined;

        let canEditStructureSet = vs.canEdit && ss.canEdit() && ss.dataset.StructureSetLabel !== structureSet.ExpertContoursName;  // true -> RTSTRUCT can be edited (i.e. add/rename ROIs, add/alter contours), false -> no changes to RTSTRUCT allowed
        // show a different ROI context menu if we have multiple selections
        const multipleRoisSelected = this.state.selectedIndices.length > 1;
        // are modifications allowed when a single ROI is selected?
        const singleRoiEditsAllowed = canEditStructureSet && !multipleRoisSelected;
        const roiList = this.getRoiList(ss)!;
        const singleSelectedRoi = this.state.selectedIndices.length === 1 ? roiList[this.state.selectedIndices[0]].name : undefined;

        //  if pathname include '/reference-library' then show only the structure sets with label 'expert-contours'
        if (window.location.pathname.includes(routePaths.referenceLibrary) || window.location.pathname.includes(routePaths.referenceLibrary) || ss.getLabel() === structureSet.ExpertContoursName) {
            canEditStructureSet = false;
        }

        // add or change current item to selection on right-click unless we're shift/ctrl-clicking or we have 2 or more selections already
        const shouldNotSelectOnRightClick = (evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => this.state.selectedIndices.length > 1 || evt.shiftKey || evt.ctrlKey;

        // check if we're in Guide & any "forbidden" ROIs have been selected
        const isReadOnlyGuideRoiSelected = this.props.currentTask ? this.isExpertContoursRoiSelected(vs) || this.isTrainingTaskRoiSelected(vs) : false;

        // data for react-contextify to show or hide certain menu items
        const contextMenuData: Partial<RoiContextMenuData> = { multipleRoisSelected, singleRoiEditsAllowed, canEditStructureSet, isReadOnlyGuideRoiSelected };

        // dynamic & default settings that we must use for getting context menu visibility data for the rename operation, as we need
        // the boolean visibiltiy result also outside of react-contextify (to disable renaming as needed)
        const contextMenuDataForRoi: RoiContextMenuData = { 
            action: RoiContextMenuAction.Rename, 
            canEditStructureSet: _.defaultTo(contextMenuData.canEditStructureSet, false), 
            multipleRoisSelected: _.defaultTo(contextMenuData.multipleRoisSelected,false),
            singleRoiEditsAllowed: _.defaultTo(contextMenuData.singleRoiEditsAllowed, false),
            isReadOnlyGuideRoiSelected: _.defaultTo(contextMenuData.isReadOnlyGuideRoiSelected, true),
         };
        const isRenameDisabled = (roi: structureSet.Roi) => this.isMenuItemHiddenFunc({ roi: roi, sharedFile: null }, contextMenuDataForRoi);

        // grading sheets can be edited if a) this is a non-training task work, in which case we defer to whatever is the default from viewerstate,
        // or b) we have a training task and the user is a supervisor
        const canEditGradingSheets = matchingTask && this.props.user ? this.props.user.permissions.isSupervisor : this.props.viewerState.canEdit;

        const canAddStructureSetTemplates = this.props.currentWorkState && this.props.currentWorkState.workspace === Workspace.Annotation;


        return (
            <>
                <div className="vertical-scrollable">
                    <HotKeys keyMap={keyMap} handlers={this.handlers}>
                        <GradingSheets
                            canEdit={canEditGradingSheets}
                            structureSet={this.props.structureSet}
                            structureSets={this.props.structureSets}
                            grading={grading || undefined}
                            task={matchingTask}
                            allDatasetGradings={this.props.allDatasetGradings}
                            onRender={(gradingRenderFuncs) => (
                                <table className="roi-table">
                                    <thead>
                                        <tr>
                                            <th className="visibility-column">
                                                <Checkbox
                                                    label={""}
                                                    isSelected={vs.getAreAllRoisVisible()}
                                                    onCheckboxChange={this.handleAllRoisVisibleChange}
                                                />
                                            </th>
                                            <th className="color-column" />
                                            <th className="roi-name-column" />
                                            {gradingRenderFuncs.renderSheetHeader()}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {roiList.map((roi, index, rois) => (
                                            <tr className={`roi-item ${this.state.selectedIndices.includes(index) ? "selected-roi" : ""} ${index + 1 === rois.length && "last-roi-row"}`}
                                                key={roi.roiNumber}
                                                onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                    !shouldNotSelectOnRightClick(evt) && this.handleSelectRoiClick(roi, evt);
                                                    this.handleShowRoiContextMenu(roi, evt);
                                                }}>
                                                <td className="visibility-column">
                                                    <Checkbox
                                                        label={""}
                                                        isSelected={!(vs.hiddenRois[ss.structureSetId] && vs.hiddenRois[ss.structureSetId].has(roi.roiId))}
                                                        onCheckboxChange={(evt) => { this.handleRoiVisibleChange(evt, roi) }} />
                                                </td>
                                                <td className="color-column"><div className="color-square" style={{ backgroundColor: roi.rgb() }}
                                                    title="Click to focus on structure"
                                                    onClick={() => this.handleFocusToRoiClick(roi)}
                                                    onDoubleClick={() => { if (canEditStructureSet) { this.handleChangeRoiColorClick(roi); } }} /></td>
                                                <td className="roi-name-column" title={ss.rois[roi.roiNumber].name}
                                                    onClick={(evt) => { this.handleSelectRoiClick(roi, evt) }}>{this.renderRoiName(ss.rois[roi.roiNumber], canEditStructureSet, isRenameDisabled(roi))}</td>
                                                {gradingRenderFuncs.renderRoiColumn(roi)}
                                            </tr>
                                        ))}
                                        {canEditStructureSet &&
                                            (canAddStructureSetTemplates ?
                                                (<tr className="new-roi-button">
                                                    <td colSpan={grading || matchingTask ? 4 : 3}>
                                                        <SplitButton
                                                            id="add-roi-buttons"
                                                            variant="light"
                                                            title={(<span><NewItemGlyph /> Add structure</span>)}
                                                            onClick={this.handleAddRoiClick}
                                                        >
                                                            <Dropdown.Item onClick={this.handleAddRoisFromTemplateOpenModalClick}>Add structures from template...</Dropdown.Item>
                                                        </SplitButton>
                                                    </td>
                                                </tr>) :
                                                (<tr className="new-roi-button">
                                                    <td colSpan={grading || matchingTask ? 4 : 3}>
                                                        <Button
                                                            id="add-roi-button"
                                                            variant="light"
                                                            onClick={this.handleAddRoiClick}
                                                        >
                                                            <span><NewItemGlyph /> Add structure</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                                )
                                            )
                                        }
                                    </tbody>
                                </table>
                            )}
                        />
                    </HotKeys>

                    <ModalDialog show={Boolean(this.state.roiForColorSelect)} onHide={this.handleCloseColorModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Choose color</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SketchPicker
                                color={this.state.selectedColor}
                                onChangeComplete={this.handleSelectColor}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleCloseColorModal}>OK</Button>
                        </Modal.Footer>
                    </ModalDialog>

                    <ModalDialog show={Boolean(this.state.roiForMarginTool)} onHide={() => this.setState({ roiForMarginTool: null })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add margin</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddMarginDialog
                                roi={this.state.roiForMarginTool as structureSet.Roi}
                                cancel={() => this.setState({ roiForMarginTool: null })}
                                apply={this.handleMarginApply}
                            />
                        </Modal.Body>
                    </ModalDialog>

                    <ConfirmRoiDeletionDialog
                        isVisible={this.state.showDeleteRoiDialog}
                        onClose={this.handleCloseDeleteRoiDialog}
                        handleRoiDeletion={this.handleRoiDeletion}
                        selectedIndices={this.state.selectedIndices}
                        roiList={roiList}
                    />
                </div>

                <Menu id={this.roiContextMenuId} style={{ zIndex: 1000 }} animation={false} className="roi-context-menu">
                    {singleSelectedRoi && (<>
                        <Item disabled={true} className="context-menu-header"><b>{singleSelectedRoi}</b></Item>
                        <Separator /></>
                    )}
                    {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Focus, ...contextMenuData }} hidden={this.isMenuItemHidden}>Focus on structure</Item>}
                    {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Rename, ...contextMenuData }} hidden={this.isMenuItemHidden}>Rename</Item>}
                    {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Duplicate, ...contextMenuData }} hidden={this.isMenuItemHidden}>Duplicate</Item>}
                    {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.CopyToClipboard, ...contextMenuData }} hidden={this.isMenuItemHidden}>Copy to clipboard</Item>}
                    {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.ChangeColor, ...contextMenuData }} hidden={this.isMenuItemHidden}>Change color...</Item>}
                    {!multipleRoisSelected && <RoiTypeMenu roi={vs.selectedRoi} onChangeRoiType={this.handleChangeRoiInterpretedType} canEdit={singleRoiEditsAllowed} />}
                    {!multipleRoisSelected && <RoiGuidelineMenu roi={vs.selectedRoi} guidelines={this.props.roiGuidelines} />}
                    {singleRoiEditsAllowed && <Separator />}
                    {/* Margin tool is disabled in Training Platform release 1.0 */}
                    {/* {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.CreateMargin, ...contextMenuData }} hidden={this.isMenuItemHidden}>Create margin...</Item>} */}
                    {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Interpolate, ...contextMenuData }} hidden={this.isMenuItemHidden}>Interpolate</Item>}
                    {singleRoiEditsAllowed && <Separator />}
                    {<Item onClick={this.handleRoiContextMenuItemClick} data={{ action: RoiContextMenuAction.Delete, ...contextMenuData }} hidden={this.isMenuItemHidden}>Delete</Item>}
                </Menu>
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ROITable);
