import React from 'react';
import './LinkButton.css';

type OwnProps = {
    onClick: () => void;
    title?: string,
    className?: string,
};

/**
 * A button that's styled to look like a hyperlink but without any unnecessary anchor functionality.
 */
class LinkButton extends React.PureComponent<OwnProps>{

    handleClick = () => {
        this.props.onClick();
    }

    render() {
        const className = `link-button ${this.props.className || ''}`
        return (<button className={className} title={this.props.title || ''} onClick={this.handleClick}>{this.props.children}</button>)
    }
}

export default LinkButton;
