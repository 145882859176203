import React, { Component } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './SidebarButton.css'
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

interface IProps extends RouteComponentProps<any> { }


type OwnProps = {
    text?: string,
    icon: string | React.ReactNode,
    link?: string,
    dataCy?: string,
    onClick?: () => void,
}

type AllProps = OwnProps & IProps;

export default class SidebarButton extends Component<AllProps> {
    // check if the link is the home page and if it is, then check if the current location is the home page
    // if it is, then set the button to unactive

    getStyle = () => {
        if (this.props.link === this.props.location.pathname && this.props.link !== '/') {
            return 'sidebar-button active'
        }
        else if (this.props.link === '/') {
            return 'sidebar-button home-hover'
        }
        else {
            return 'sidebar-button'
        }
    }

    handleLinkClick = () => {   
        if (this.props.onClick) {
            this.props.onClick();
        }
    }
    

    render() {
        return (
            <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip-disabled">{this.props.text}</Tooltip>}>
                <Link
                    onClick={this.handleLinkClick}
                    className={this.getStyle()}
                    to={this.props.link! || '#'}
                    data-cy={this.props.dataCy}>
                    {this.props.icon}
                </Link>
            </OverlayTrigger>
        )
    }
}