// ROI list component 

import React from 'react';
import * as sagas from '../../store/sagas';
import { connect } from 'react-redux';
import { Button, Row, Col, DropdownButton, Dropdown, Modal, Form } from 'react-bootstrap';
import { MdMessage, MdSave } from 'react-icons/md';

import { StoreState } from '../../store/store';
import { StructureSetGrading, GradingWorkflowState, getGradingVariant, GradingMeanings, RoiGrading, checkForMultipleSameGradeRois, DatasetGradings, DuplicateRoiGradingError } from '../../datasets/roi-grading';
import GradingStateDropdown from './grading/GradingStateDropdown';
import { Roi, StructureSet } from '../../dicom/structure-set';
import { Dataset } from '../../datasets/dataset';
import { Checkbox } from '../misc-components';
import ConfirmGradingSaveDialog from './dialogs/ConfirmGradingSaveDialog';
import { GradingToSave } from '../../datasets/dataset-files';
import { areStringsSameCi } from '../../helpers/compare';
import ModalDialog from '../common/ModalDialog';
import { TrainingTask } from '../../datasets/training-task';
import _ from 'lodash';

type OwnProps = {
    onRender: (inputs: GradingSheetRenderFuncs) => JSX.Element,
    canEdit: boolean,
    structureSet: StructureSet | null,
    structureSets?: StructureSet[],
    grading?: StructureSetGrading,
    task?: TrainingTask,
    allDatasetGradings: DatasetGradings | null,
}

type DispatchProps = {
    saveGrading: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) => void,
    setGradingWithoutSaving: (structureSetId: string, ssGrading: StructureSetGrading | null, dataset: Dataset) => void,
    saveDatasetGradings: (gradingsToSave: GradingToSave[], dataset: Dataset) => void,
    updateTask: (task: TrainingTask, update: Partial<TrainingTask>, noSuccessNotification: boolean) => void,
}

type AllProps = StoreState & OwnProps & DispatchProps;

type OwnState = {
    // TODO: all these roiToSomethings could be done with selectedIndices
    roiToComment: Roi | null,
    taskToComment: TrainingTask | null,
    comment?: string,
    showCommentModal?: boolean,
    duplicateGoodGradedRoisErrors: DuplicateRoiGradingError[] | null,
    previousRoiGrading: StructureSetGrading | null,
    roiNumberNeedingConfirmation: string | null,
}

export type GradingSheetRenderFuncs = {
    renderSheetHeader: () => JSX.Element | null,
    renderRoiColumn: (roi: Roi) => JSX.Element | null,
}

class GradingSheets extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            roiToComment: null,
            taskToComment: null,
            duplicateGoodGradedRoisErrors: null,
            previousRoiGrading: null,
            roiNumberNeedingConfirmation: null,
        };
    }

    // TODO: add componentDid(un)Mount and subscriptions to viewerstate if needed

    isTaskGradingSheet = () => {
        return this.props.task !== undefined;
    }

    isAnnotationGradingSheet = () => {
        // task grading takes precedence over annotation grading -- we shouldn't have both on at the same time though
        return this.props.grading !== undefined && this.props.task === undefined;
    }

    getMatchingTaskRoi = (roi: Roi, task: TrainingTask) => {
        return task.traineeStructureSet.rois.find(r => r.roiName === roi.name);
    }

    handleGradingStateChange = (workflowState: GradingWorkflowState) => {
        if (!this.props.currentWorkState || !this.props.currentWorkState.dataset) {
            throw new Error('No work state or dataset defined -- cannot change grading');
        }

        const ss = this.props.structureSet;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading) { return; }

        const newGrading = originalGrading.clone();
        newGrading.workflowState = workflowState;
        this.props.saveGrading(ss.structureSetId, newGrading, this.props.currentWorkState.dataset);
    }

    handleGradeRoi = (roi: Roi, val: any) => {
        const ss = this.props.structureSet;
        const dataset: Dataset | null = this.props.currentWorkState ? this.props.currentWorkState.dataset : null;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading || !dataset) { return; }

        const previousRoiGrading = originalGrading.clone();
        const newGrading = originalGrading.clone();

        newGrading.rois[roi.roiNumber] = newGrading.rois[roi.roiNumber] || new RoiGrading(roi.name);
        newGrading.rois[roi.roiNumber].value = val;
        newGrading.rois[roi.roiNumber].valueMeaning = GradingMeanings[val];

        // check that there are no multiple ROIs in this scan that have been graded "1 - Good"
        const datasetGradings = this.props.datasetGradings[dataset.getDatasetId()];
        const structureSets = this.props.structureSets ? this.props.structureSets : null;
        if (datasetGradings && structureSets) {
            const duplicateGoodGradedRoisErrors = checkForMultipleSameGradeRois(ss, newGrading, datasetGradings, structureSets, "1");
            if (duplicateGoodGradedRoisErrors && duplicateGoodGradedRoisErrors.length > 0) {
                // there are, so prevent saving from now and request confirmation from user.
                // store current state so we can force either the save or the revert after user has made their choice.
                // note that this previous state remains applicable only as long as the current structure set context
                // doesn't change -- this should not be an issue, the dialog requiring user confirmation is blocking.
                this.setState({
                    previousRoiGrading,
                    duplicateGoodGradedRoisErrors,
                    roiNumberNeedingConfirmation: roi.roiNumber
                });

                // nevertheless, temporary apply the current grading so it shows up correctly in the UI
                this.props.setGradingWithoutSaving(ss.structureSetId, newGrading, dataset);

                return;
            }
        }

        this.props.saveGrading(ss.structureSetId, newGrading, dataset);
    }

    handleGradingUnsureChange = (event: any, roi: Roi) => {
        if (!this.props.currentWorkState || !this.props.currentWorkState.dataset) {
            throw new Error('No work state or dataset defined -- cannot change grading');
        }

        const ss = this.props.structureSet;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading) {
            event.preventDefault();
            return;
        }

        const newGrading = originalGrading.clone();

        newGrading.rois[roi.roiNumber] = newGrading.rois[roi.roiNumber] || new RoiGrading(roi.name);
        newGrading.rois[roi.roiNumber].unsure = Boolean(event.target.checked);
        this.props.saveGrading(ss.structureSetId, newGrading, this.props.currentWorkState.dataset)
    }

    handleCancelGradingChange = () => {
        if (!this.props.currentWorkState || !this.props.currentWorkState.dataset) {
            throw new Error('No work state or dataset defined -- cannot change grading');
        }

        const { previousRoiGrading } = this.state;
        const ss = this.props.structureSet;
        const dataset = this.props.currentWorkState.dataset;

        if (!previousRoiGrading || !ss || !dataset) { return; }

        // revert to previous grading
        this.props.setGradingWithoutSaving(ss.structureSetId, previousRoiGrading, dataset);

        this.setState({ previousRoiGrading: null, duplicateGoodGradedRoisErrors: null, roiNumberNeedingConfirmation: null });
    }

    handleCommentChanged = (event: any) => {
        const comment = event.target.value;
        this.setState({ comment: comment });
    }

    /** Close comment modal and update matching comment field in grading sheet */
    handleCloseCommentModal = () => {
        if (this.isTaskGradingSheet()) {
            // update a comment in a task
            const { roiToComment, taskToComment } = this.state;
            if (!taskToComment) {
                throw new Error('No task to comment');
            }

            const updatedTask = _.cloneDeep(taskToComment);

            if (roiToComment) {
                // update a roi comment
                const updatedTaskRoi = this.getMatchingTaskRoi(roiToComment, updatedTask);

                if (!updatedTaskRoi) {
                    throw new Error('Could not find matching task roi');
                }

                updatedTaskRoi.comments = this.state.comment || '';
            } else {
                // update overall comment
                updatedTask.comments = this.state.comment || '';
            }

            // auto-save updated task
            this.props.updateTask(taskToComment, updatedTask, true);

        } else if (this.isAnnotationGradingSheet()) {
            // update & auto-save annotation grading sheet changes

            if (!this.props.currentWorkState || !this.props.currentWorkState.dataset) {
                throw new Error('No work state or dataset defined -- cannot change grading');
            }

            const ss = this.props.structureSet;
            const originalGrading = this.props.grading;
            if (!ss || !originalGrading) { return; }

            const newGrading = originalGrading.clone();

            const comment = this.state.comment || undefined;
            if (this.state.roiToComment) {
                const roi = this.state.roiToComment;
                newGrading.rois[roi.roiNumber] = newGrading.rois[roi.roiNumber] || new RoiGrading(roi.name);
                newGrading.rois[roi.roiNumber].comment = comment;
            }
            else {
                newGrading.comment = comment;
            }

            this.props.saveGrading(ss.structureSetId, newGrading, this.props.currentWorkState.dataset);
        } else {
            throw new Error('Unsupported grading sheet type');
        }

        this.closeCommentModal();
    }

    handleCancelCommentModal = () => {
        this.closeCommentModal();
    }

    closeCommentModal = () => {
        this.setState({ showCommentModal: false, roiToComment: null, taskToComment: null, comment: undefined });
    }

    handleShowCommentModalForAnnotationGrading = (roi: Roi | null) => {
        const g = this.props.grading;
        if (!g) { return; }

        let comment = '';
        if (roi) {
            if (g.rois[roi.roiNumber]) {
                comment = g.rois[roi.roiNumber].comment || '';
            }
        }
        else {
            if (g.comment) {
                comment = g.comment;
            }
        }

        this.setState({ showCommentModal: true, roiToComment: roi, comment: comment });
    }

    handleShowCommentModalForTask = (roi: Roi | null, task: TrainingTask) => {
        let comment = '';

        if (roi) {
            const taskRoi = this.getMatchingTaskRoi(roi, task);
            comment = taskRoi ? taskRoi.comments : '';
        }
        else {
            comment = task.comments || '';
        }

        this.setState({ showCommentModal: true, roiToComment: roi, taskToComment: task, comment: comment });
    }

    handleConfirmSaveAndObsoleteOldGradingsChange = () => {
        if (!this.props.currentWorkState || !this.props.currentWorkState.dataset) {
            throw new Error('No work state or dataset defined -- cannot change grading');
        }

        const { duplicateGoodGradedRoisErrors, roiNumberNeedingConfirmation } = this.state;
        const ss = this.props.structureSet;
        const originalGrading = this.props.grading;
        if (!ss || !originalGrading) { return; }

        // we need to keep track of all the gradings we're about to modify so we can save them
        const gradingsToSave: GradingToSave[] = [];

        // only anything to do here if we have the state values
        if (duplicateGoodGradedRoisErrors && roiNumberNeedingConfirmation && Object.keys(originalGrading.rois).includes(roiNumberNeedingConfirmation)) {

            const datasetGradings = this.props.allDatasetGradings;
            const roiBeingChanged = originalGrading.rois[roiNumberNeedingConfirmation];
            const roiGradingDuplicates = duplicateGoodGradedRoisErrors.find(d => d.roiName === roiBeingChanged.roiName);
            if (roiGradingDuplicates) {
                for (const structureSetId of Object.keys(roiGradingDuplicates.duplicateStructureSetLabels)) {
                    if (structureSetId === ss.structureSetId) {
                        // don't change currently active structure set's grading for this roi
                        continue;
                    }
                    if (datasetGradings && Object.keys(datasetGradings.structureSets).includes(structureSetId)) {
                        const newGradings = datasetGradings.clone();
                        const roi = Object.values(newGradings.structureSets[structureSetId].rois).find(r => areStringsSameCi(r.roiName, roiBeingChanged.roiName));
                        if (roi) {
                            roi.value = "6";
                            roi.valueMeaning = GradingMeanings["6"];
                            const structureSetToSave = this.props.structureSets ? this.props.structureSets.find(ss => ss.structureSetId === structureSetId) : undefined;
                            if (structureSetToSave !== undefined) {
                                gradingsToSave.push({ ssId: structureSetToSave.structureSetId, ssGrading: newGradings.structureSets[structureSetId] });
                            }
                        }
                    }
                }
            }
        }

        // finally, always include the current structure set and its grading
        gradingsToSave.push({ ssId: ss.structureSetId, ssGrading: originalGrading });

        this.props.saveDatasetGradings(gradingsToSave, this.props.currentWorkState.dataset)
        this.setState({ previousRoiGrading: null, duplicateGoodGradedRoisErrors: null, roiNumberNeedingConfirmation: null });
    }

    getGrading(grading: StructureSetGrading, roi: Roi): RoiGrading {
        const roiGrading = grading.rois[roi.roiNumber];
        return roiGrading || new RoiGrading(roi.name);
    }



    renderSheetHeader = () => {
        if (this.isTaskGradingSheet()) {
            return this.renderSheetHeaderForTask(this.props.task!);
        } else if (this.isAnnotationGradingSheet()) {
            return this.renderSheetHeaderForAnnotationGrading(this.props.grading!);
        } else {
            return null;
        }
    }

    renderSheetHeaderForTask = (task: TrainingTask) => {
        const isGradingSheetCurrentlySaving = this.props.isUpdatingTask;

        return (
            <th className="grading-column task-grading">
                <div className="grading-sheet-header">
                    <div
                        className={`grading-sheet-saving-icon ${isGradingSheetCurrentlySaving ? 'pop-in' : 'pop-out'}`}
                        title={isGradingSheetCurrentlySaving ? "Auto-saving task comments..." : ""}>
                        <MdSave />
                    </div>

                    {/* <div className="title"></div> */}

                    <Button variant={task.comments ? "outline-info" : "outline-secondary"}
                        size="sm"
                        title="Overall comment"
                        onClick={() => { this.handleShowCommentModalForTask(null, task) }}
                        className="grading-button icon-button"
                    ><MdMessage /></Button>
                </div>
            </th>
        );
    }

    renderSheetHeaderForAnnotationGrading = (grading: StructureSetGrading) => {
        const { canEdit } = this.props;
        const isGradingSheetCurrentlySaving = this.props.isSavingGradings;

        return (
            <th className="grading-column">
                <div className="grading-sheet-header">
                    <div className="title">Grading: </div>

                    <Button variant={grading.comment ? "outline-danger" : "outline-secondary"}
                        size="sm"
                        title="Overall comment"
                        onClick={() => { this.handleShowCommentModalForAnnotationGrading(null) }}
                        className="grading-button icon-button"
                    ><MdMessage /></Button>

                    <div
                        className={`grading-sheet-saving-icon ${isGradingSheetCurrentlySaving ? 'pop-in' : 'pop-out'}`}
                        title={isGradingSheetCurrentlySaving ? "Auto-saving grading sheet..." : ""}>
                        <MdSave />
                    </div>
                </div>
                <GradingStateDropdown gradingWorkflowState={grading.workflowState} onChange={this.handleGradingStateChange} disabled={!canEdit} />
            </th>
        );
    }

    renderRoiColumn = (roi: Roi) => {
        if (this.isTaskGradingSheet()) {
            return this.renderRoiColumnForTask(roi, this.props.task!);
        } else if (this.isAnnotationGradingSheet()) {
            return this.renderRoiColumnForAnnotationGrading(roi, this.props.grading!);
        } else {
            return null;
        }
    }

    renderRoiColumnForTask = (roi: Roi, task: TrainingTask) => {
        if (task.traineeStructureSet.sopInstanceUid === roi.structureSet.structureSetId &&
            task.traineeStructureSet.seriesInstanceUid === roi.structureSet.seriesUid &&
            this.getMatchingTaskRoi(roi, task) !== undefined) {
            const taskRoi = this.getMatchingTaskRoi(roi, task)!;
            return (
                <td className="grading-column task-grading">
                    <Button variant={taskRoi.comments ? "outline-info" : "outline-secondary"}
                        size="sm"
                        title="Comment"
                        onClick={() => this.handleShowCommentModalForTask(roi, task)}
                        className="grading-button icon-button"
                    ><MdMessage /></Button>
                </td>
            );
        }
        else {
            return (<td className="grading-column task-grading" />);
        }
    }

    renderRoiColumnForAnnotationGrading = (roi: Roi, grading: StructureSetGrading) => {
        const { canEdit } = this.props;

        return (
            <td className="grading-column">
                <Row>
                    <Col sm="3">
                        <DropdownButton title={this.getGrading(grading, roi).value} id={"grading-value-" + roi.roiNumber}
                            disabled={!canEdit}
                            variant={getGradingVariant(this.getGrading(grading, roi).value)}
                            size="sm"
                            className="grading-button">
                            {
                                Object.keys(GradingMeanings).map((val) =>
                                    <Dropdown.Item as="button" key={val}
                                        onClick={() => this.handleGradeRoi(roi, val)}> {`${val}: ${GradingMeanings[val]}`}
                                    </Dropdown.Item>
                                )}
                        </DropdownButton>
                    </Col>
                    <Col sm="2" className="grading-section-column">
                        <Button variant={this.getGrading(grading, roi).comment ? "outline-danger" : "outline-secondary"}
                            size="sm"
                            title="Comment"
                            onClick={() => this.handleShowCommentModalForAnnotationGrading(roi)}
                            className="grading-button icon-button"
                        ><MdMessage /></Button>
                    </Col>
                    <Col sm="4" className="grading-section-column">
                        <div className="grading-button unsure">
                            <Checkbox
                                disabled={!canEdit}
                                label={"Unsure"}
                                isSelected={this.getGrading(grading, roi).unsure}
                                onCheckboxChange={(evt) => { this.handleGradingUnsureChange(evt, roi) }} />
                        </div>
                    </Col>
                </Row>
            </td>
        );
    }

    render() {
        const { structureSet, grading, canEdit, user } = this.props;
        const isTrainee = user ? user.permissions.isTrainee : false;
        const isSupervisor = user ? user.permissions.isSupervisor : false;

        const title = isTrainee ? (this.state.roiToComment ? `Comment on ${this.state.roiToComment.name} from supervisor:` : "An overall comment from supervisor:")
         : isSupervisor ? (this.state.roiToComment ? `Leave a comment on ${this.state.roiToComment.name} for trainee:` : 'Leave an overall comment for trainee:')
         : (this.state.roiToComment ? `Comment on ${this.state.roiToComment.name}` : 'Overall comment');

        return (
            <>
                <>
                    {this.props.onRender({
                        renderSheetHeader: this.renderSheetHeader,
                        renderRoiColumn: this.renderRoiColumn,
                    })}
                </>

                <>
                    <ModalDialog show={!!this.state.showCommentModal} onHide={this.handleCancelCommentModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows={5} value={this.state.comment} onChange={this.handleCommentChanged} disabled={!canEdit} />
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            {isTrainee ? 
                            (
                                <Button variant="outline-secondary" onClick={this.handleCancelCommentModal}>Close</Button>
                            ) : (
                                <>
                                    <Button variant="primary" onClick={this.handleCloseCommentModal} disabled={!canEdit}>Save changes</Button>
                                    <Button variant="outline-secondary" onClick={this.handleCancelCommentModal}>Cancel</Button>
                                </>
                            )}
                        </Modal.Footer>
                    </ModalDialog>
                </>

                <>
                    {structureSet && (
                        <ModalDialog backdrop="static" show={!!this.state.duplicateGoodGradedRoisErrors && this.state.duplicateGoodGradedRoisErrors.length > 0}
                            onHide={() => { }}>
                            <Modal.Header>
                                <Modal.Title>Duplicate structures graded "1: {GradingMeanings["1"]}"</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ConfirmGradingSaveDialog
                                    errors={this.state.duplicateGoodGradedRoisErrors}
                                    currentStructureSet={structureSet}
                                    currentGrading={grading}
                                    roiNumberNeedingConfirmation={this.state.roiNumberNeedingConfirmation}
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" onClick={this.handleConfirmSaveAndObsoleteOldGradingsChange}>Confirm grading change</Button>
                                <Button variant="outline-secondary" onClick={this.handleCancelGradingChange}>Cancel</Button>
                            </Modal.Footer>
                        </ModalDialog>
                    )}
                </>
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(GradingSheets);
