import React from 'react';
import { RouteComponentProps, Route, withRouter, Switch } from 'react-router-dom';

import Layout from './components/Layout';
import AuthenticatedPage from './components/AuthenticatedPage';
import LoggedOutPage from './components/logged-out-page/LoggedOutPage';

import HomePage from './components/home-page/HomePage';
import VerifyPage from './components/verify-page/VerifyPage';
import TasksPage from './components/tasks-page/TasksHomePage';
import ReferencePage from './components/reference-library-page/ReferencePage';
import routePaths from './routes';

import './App.css'
import './Animations.css'
import './branding.css';

interface IProps extends RouteComponentProps<any> {
}


class App extends React.Component<IProps> {
  public render() {

    return (

      <Switch>
        <Route exact path='/logged-out' component={LoggedOutPage} />

        <AuthenticatedPage>
          <Layout>
            <Switch>
              <Route exact path='/' component={HomePage} />
              <Route path={routePaths.guidelineTraining} component={TasksPage} />
              <Route path={routePaths.referenceLibrary} component={ReferencePage} />
              <Route path={routePaths.verify} component={VerifyPage} />
            </Switch>
          </Layout>
        </AuthenticatedPage>

      </Switch>
    );
  }
}

export default withRouter<IProps, any>(App);
