import React from 'react';

type OwnProps = {
    toolbar?: React.ReactNode,
    className?: string,
}


class LandingPageLayout extends React.Component<OwnProps> {
    render() {

        return (
            <div className={`local-page-container home-page ${this.props.className || ''}`}>
                <div className="local-background" />

                <div className="local-foreground">

                    {this.props.toolbar}

                    <div className="panels">

                        <div className="home-panel">

                            <div className="home-logo">
                                <img src="/img/logo-mvision-w.svg" alt="MVision Logo" width="600px" />
                            </div>

                            {this.props.children}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingPageLayout;
