import React from 'react';

import { StoreState } from '../../../store/store';

import './TasksTable.css';
import { Table } from 'react-bootstrap';
import { TrainingTask, TrainingTaskGroup } from '../../../datasets/training-task';
import { connect, ConnectedProps } from 'react-redux';
import { selectFilteredTasks } from '../../../store/selectors';
import TaskGroupRow from './TaskGroupRow';


type OwnProps = {
    handleViewTaskClick: (task: TrainingTask) => void,
}

// combine redux & selector props
const mapStateToProps = (state: StoreState) => Object.assign({}, state,
    {
        taskGroups: selectFilteredTasks(state),
    });
const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = OwnProps & StoreState & ReduxProps;

class TaskGroupTable extends React.Component<AllProps> {
    displayName = TaskGroupTable.name;

    render() {
        return (
            <Table className="tasks-group-table task-group-table" borderless size="md" responsive="sm">
                <tbody>
                    {this.props.taskGroups.map((g: TrainingTaskGroup, i: number) => (
                        <TaskGroupRow
                            key={`${g.name}+${g.collection}`}
                            trainingTaskGroup={g}
                            rowIndex={i}
                            handleViewTaskClick={this.props.handleViewTaskClick}
                        />
                    ))}
                </tbody>
            </Table>
        );
    }
}

export default connector(TaskGroupTable);
