import * as twgl from 'twgl.js';
import { ViewManager } from "../../../view-manager";
import * as xShaders from './margin-shaders-sweep-x';
import * as yShaders from './margin-shaders-sweep-y';
import * as zShaders from './margin-shaders-sweep-z';
import * as smoothingShaders from './margin-shaders-smoothing';
import { Roi } from "../../../../dicom/structure-set";
import { SDFRenderer } from '../../rendering/SDFRenderer';

export const maxMarginMM = 50;

export class MarginOptions {
    sourceRoi: Roi;
    targetRoi: Roi;
    isInnerMargin: boolean;
    
    leftMM: number;
    rightMM: number;
    cranialMM: number;
    caudalMM: number;
    anteriorMM: number;
    posteriorMM: number;

    constructor(sourceRoi: Roi, targetRoi: Roi,
         isInner: boolean, leftMM: number, rightMM: number, cranialMM: number, caudalMM: number, 
         anteriorMM: number, posteriorMM: number) {

        if(!sourceRoi.sdf) throw new Error("Source structure '" + sourceRoi.name + "' does not have contours!");
        [leftMM, rightMM, cranialMM, caudalMM, anteriorMM, posteriorMM].forEach( (m: number) => {
            if(m < 0 || m > maxMarginMM) throw new Error("Margins must be between 0 and " + maxMarginMM.toString() + " mm") 
        });

        this.sourceRoi = sourceRoi;
        this.targetRoi = targetRoi;
        this.isInnerMargin = isInner;

        this.leftMM = leftMM;
        this.rightMM = rightMM;
        this.cranialMM = cranialMM;
        this.caudalMM = caudalMM;
        this.anteriorMM = anteriorMM;
        this.posteriorMM = posteriorMM;
    }

    /*public marginsToNearestPatientSpace(toPatientSpace: Matrix4f){
        const left = this.getMargin(new Vector3f(-1, 0, 0).applyTransformation(toPatientSpace).closestAxis());
        const right = this.getMargin(new Vector3f(1, 0, 0).applyTransformation(toPatientSpace).closestAxis());
        const cranial = this.getMargin(new Vector3f(0, 0, -1).applyTransformation(toPatientSpace).closestAxis());
        const caudal = this.getMargin(new Vector3f(0, 0, 1).applyTransformation(toPatientSpace).closestAxis());
        const posterior = this.getMargin(new Vector3f(0, -1, 0).applyTransformation(toPatientSpace).closestAxis());
        const anterior = this.getMargin(new Vector3f(0, 1, 0).applyTransformation(toPatientSpace).closestAxis());
        this.leftMM = left;
        this.rightMM = right;
        this.cranialMM = cranial;
        this.caudalMM = caudal;
        this.posteriorMM = posterior;
        this.anteriorMM = anterior;
    }

    private getMargin(vector: Vector3f): number {
        if(vector.x != 0){
            return vector.x < 0 ? this.leftMM : this.rightMM;
        } else if(vector.y != 0){
            return vector.y < 0 ? this.posteriorMM : this.anteriorMM;
        } else if(vector.z != 0){
            return vector.z < 0 ? this.cranialMM : this.caudalMM;
        }
        return 0;
    }*/
}

export class MarginOperations {

    private readonly viewManager: ViewManager;
    private readonly xProgram: WebGLProgram;
    private xUniformLoc: any;
    private readonly yProgram: WebGLProgram;
    private yUniformLoc: any;
    private readonly zProgram: WebGLProgram;
    private zUniformLoc: any;
    private readonly smoothingProgram: WebGLProgram;
    private smoothingUniformLoc: any;
    
    constructor(viewManager: ViewManager) {
        this.viewManager = viewManager;
        let gl = viewManager.getWebGlContext();
        this.xProgram = twgl.createProgramInfo((gl as any), [xShaders.MARGIN_SWEEP_X_VS, xShaders.MARGIN_SWEEP_X_FS]).program;
        this.xUniformLoc = {
            textureOrig: gl.getUniformLocation(this.xProgram, 'textureOrig'),
            textureBuffer: gl.getUniformLocation(this.xProgram, 'textureBuffer'),
            textureSize: gl.getUniformLocation(this.xProgram, 'textureSize'),
            maxDistancePixels: gl.getUniformLocation(this.xProgram, 'maxDistancePixels'),
            distancePixels1: gl.getUniformLocation(this.xProgram, 'distancePixels1'),
            distancePixels2: gl.getUniformLocation(this.xProgram, 'distancePixels2'),
            x1MarginPixels: gl.getUniformLocation(this.xProgram, 'x1MarginPixels'),
            x2MarginPixels: gl.getUniformLocation(this.xProgram, 'x2MarginPixels'),
            isInnerMargin: gl.getUniformLocation(this.xProgram, 'isInnerMargin'),
        }

        this.yProgram = twgl.createProgramInfo((gl as any), [yShaders.MARGIN_SWEEP_Y_VS, yShaders.MARGIN_SWEEP_Y_FS]).program;
        this.yUniformLoc = {
            textureOrig: gl.getUniformLocation(this.yProgram, 'textureOrig'),
            textureBuffer: gl.getUniformLocation(this.yProgram, 'textureBuffer'),
            textureSize: gl.getUniformLocation(this.yProgram, 'textureSize'),
            maxDistancePixels: gl.getUniformLocation(this.yProgram, 'maxDistancePixels'),
            distancePixels1: gl.getUniformLocation(this.yProgram, 'distancePixels1'),
            distancePixels2: gl.getUniformLocation(this.yProgram, 'distancePixels2'),
            y1MarginPixels: gl.getUniformLocation(this.yProgram, 'y1MarginPixels'),
            y2MarginPixels: gl.getUniformLocation(this.yProgram, 'y2MarginPixels'),
            isInnerMargin: gl.getUniformLocation(this.yProgram, 'isInnerMargin'),
        }

        this.zProgram = twgl.createProgramInfo((gl as any), [zShaders.MARGIN_SWEEP_Z_VS, zShaders.MARGIN_SWEEP_Z_FS]).program;
        this.zUniformLoc = {
            textureOrig: gl.getUniformLocation(this.zProgram, 'textureOrig'),
            textureBuffer: gl.getUniformLocation(this.zProgram, 'textureBuffer'),
            isInnerMargin: gl.getUniformLocation(this.zProgram, 'isInnerMargin'),
            textureMatrix1: gl.getUniformLocation(this.zProgram, 'orientation1'),
            textureMatrix2: gl.getUniformLocation(this.zProgram, 'orientation2'),
            textureMatrix3: gl.getUniformLocation(this.zProgram, 'orientation3'),
            textureMatrix4: gl.getUniformLocation(this.zProgram, 'orientation4'),
            maxDistancePixels: gl.getUniformLocation(this.zProgram, 'maxDistancePixels'),
            distancePixels1: gl.getUniformLocation(this.zProgram, 'distancePixels1'),
            distancePixels2: gl.getUniformLocation(this.zProgram, 'distancePixels2'),
            z1MarginPixels: gl.getUniformLocation(this.zProgram, 'z1MarginPixels'),
            z2MarginPixels: gl.getUniformLocation(this.zProgram, 'z2MarginPixels'),
            interpolationWeight1: gl.getUniformLocation(this.zProgram, 'interpolationWeight1'),
            interpolationWeight2: gl.getUniformLocation(this.zProgram, 'interpolationWeight2'),
        }

        this.smoothingProgram = twgl.createProgramInfo((gl as any), [smoothingShaders.MARGIN_SMOOTHING_VS, smoothingShaders.MARGIN_SMOOTHING_FS]).program;
        this.smoothingUniformLoc = {
            textureData: gl.getUniformLocation(this.smoothingProgram, 'textureData'),
            textureSize: gl.getUniformLocation(this.smoothingProgram, 'textureSize'),
        }
    }

    public addMargin(opt: MarginOptions) {
        const sourceSdf = opt.sourceRoi.sdf;
        if(!sourceSdf){
            console.error("Attempting to generate margin for Roi that has no content");
            return;
        }
        const viewManager = sourceSdf.viewManager;
        viewManager.viewerState.undoStack.pushRoiStateBeforeEdit(opt.targetRoi);
        const renderer = new SDFRenderer(viewManager.getWebGlContext());
        opt.targetRoi.sdf = renderer.renderMargin(sourceSdf, opt.isInnerMargin, opt.rightMM, opt.leftMM, opt.posteriorMM, opt.anteriorMM, opt.cranialMM, opt.caudalMM);
        opt.targetRoi.setContoursChanged(null);
        viewManager.updateBrushBuffer();
    }
}