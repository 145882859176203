import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default class UtilityToolsToolbar extends React.PureComponent<{}>{
    render() {
        return (
            <Navbar bg="light">
                <Navbar.Brand>Tools & utilities</Navbar.Brand>
                <Nav>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/tools/color" eventKey="color-extraction">Color extraction</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/tools/models" eventKey="model-selection">Model selection mapper</Nav.Link>
                    </Nav.Item>
                </Nav>
            </Navbar>
        );
    }
}