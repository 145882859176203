import { AzureFileInfo } from "../web-apis/azure-files";
import Immerable from "./immerable";
import { DownloadKeyTrackable } from "./requests";

export interface ReceivedFile extends DownloadKeyTrackable {
    scanId: string;
    structureSetId?: string;
}

export class ReceivedAzureFile extends Immerable implements ReceivedFile {
    fileInfo: AzureFileInfo;
    downloadKey: string;
    scanId: string;
    structureSetId?: string;

    constructor(fileInfo: AzureFileInfo, downloadKey: string, scanId: string, structureSetId?: string) {
        super();

        this.fileInfo = fileInfo;
        this.downloadKey = downloadKey;
        this.scanId = scanId;
        this.structureSetId = structureSetId;
    }
}

export class ReceivedApiFile extends Immerable implements ReceivedFile {
    path: string;
    downloadKey: string;
    scanId: string;
    structureSetId?: string;

    constructor(path: string, downloadKey: string, scanId: string, structureSetId?: string) {
        super();

        this.path = path;
        this.downloadKey = downloadKey;
        this.scanId = scanId;
        this.structureSetId = structureSetId;
    }
}