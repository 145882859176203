import React, { ReactNode } from 'react';
import { GradingWorkflowState, convertWorkflowStateToText } from '../../../datasets/roi-grading';
import { BsPencil, BsDash, BsExclamation, BsExclamationTriangle, BsCheck, BsCheckAll, BsX, BsArrowRightShort } from 'react-icons/bs';
import { IconContext } from 'react-icons';

import "./GradingState.css";

export default class GradingState extends React.PureComponent<{
    gradingWorkflowState: GradingWorkflowState, noColor?: boolean, colorText?: boolean, iconOnly?: boolean,
}> {
    convertWorkflowStateToIcon = (state: GradingWorkflowState): ReactNode => {
        switch (state) {
            case GradingWorkflowState.Undefined:
                return (<span />);
            case GradingWorkflowState.NotStarted:
                return (<BsDash />);
            case GradingWorkflowState.InProgress:
                return (<BsPencil />);
            case GradingWorkflowState.ReadyForNextPhase:
                return (<BsArrowRightShort />);
            case GradingWorkflowState.WaitingForPeerReview:
                return (<BsExclamation />);
            case GradingWorkflowState.WaitingForReview:
                return (<BsExclamation />);
            case GradingWorkflowState.PeerFixesNeeded:
                return (<BsExclamationTriangle />);
            case GradingWorkflowState.FixesNeeded:
                return (<BsExclamationTriangle />);
            case GradingWorkflowState.PeerReviewed:
                return (<BsCheck />);
            case GradingWorkflowState.Reviewed:
                return (<BsCheck />);
            case GradingWorkflowState.ReadyForTraining:
                return (<BsCheckAll />);
            case GradingWorkflowState.ExpertApproved:
                return (<BsCheckAll />);
            case GradingWorkflowState.Obsolete:
                return (<BsX />);
            case GradingWorkflowState.RutherfordUndefined:
                return (<span />);
            case GradingWorkflowState.RutherfordApproved:
                return (<BsCheck />);
            case GradingWorkflowState.RutherfordUnapproved:
                return (<BsX />);
            default:
                return (null);
        }
    }

    getColorStyle = (state: GradingWorkflowState): string => {
        switch (state) {
            case GradingWorkflowState.Undefined:
                return 'grading-undefined';
            case GradingWorkflowState.NotStarted:
                return 'grading-not-started';
            case GradingWorkflowState.InProgress:
                return 'grading-in-progress';
            case GradingWorkflowState.ReadyForNextPhase:
                return 'grading-ready-for-next-phase';
            case GradingWorkflowState.WaitingForPeerReview:
                return 'grading-waiting-for-peer-review';
            case GradingWorkflowState.WaitingForReview:
                return 'grading-waiting-for-review';
            case GradingWorkflowState.PeerFixesNeeded:
                return 'grading-peer-fixes-needed';
            case GradingWorkflowState.FixesNeeded:
                return 'grading-fixes-needed';
            case GradingWorkflowState.PeerReviewed:
                return 'grading-peer-reviewed';
            case GradingWorkflowState.Reviewed:
                return 'grading-reviewed';
            case GradingWorkflowState.ReadyForTraining:
                return 'grading-ready-for-training';
            case GradingWorkflowState.ExpertApproved:
                return 'grading-expert-approved';
            case GradingWorkflowState.Obsolete:
                return 'grading-obsolete';
            case GradingWorkflowState.RutherfordUndefined:
                return 'grading-rutherford-undefined';
            case GradingWorkflowState.RutherfordApproved:
                return 'grading-rutherford-approved';
            case GradingWorkflowState.RutherfordUnapproved:
                return 'grading-rutherford-unapproved';
            default:
                return 'grading-default';
        }
    }

    render() {
        const { gradingWorkflowState } = this.props;
        const noColor = this.props.noColor ? this.props.noColor : false;
        const colorText = this.props.colorText ? this.props.colorText : false;
        const iconOnly = this.props.iconOnly ? this.props.iconOnly : false;

        const colorStyle = noColor ? 'grading-default' : this.getColorStyle(gradingWorkflowState);
        let titleStyle: any = {};
        if (colorText) { titleStyle.className = colorStyle; }

        const altText = convertWorkflowStateToText(gradingWorkflowState);
        const title = gradingWorkflowState === GradingWorkflowState.Undefined ? (<span>&nbsp;</span>) : altText;
        const icon = this.convertWorkflowStateToIcon(gradingWorkflowState);

        return (
            <span className="grading-state" title={altText}>
                <IconContext.Provider value={{ size: "18px", className: colorStyle }}>{icon} {!iconOnly && (<span {...titleStyle}>{title}</span>)}</IconContext.Provider>
            </span>);

    }
}