import React, { PureComponent } from "react";
import { Table } from "react-bootstrap";
import SimilarityMetricsTableRow from "./SimilarityMetricsTableRow";
import "./SimilarityMetricsTable.css";
import { TrainingTaskRoi, CalculatedMetrics } from "../../../../datasets/training-task";

type OwnProps = {
    taskAcceptanceCriteria: TrainingTaskRoi[];
    changeAcceptanceCriteria(roiToUpdate: string, updates: Partial<CalculatedMetrics>): void;
};

export default class SimilarityMetricsTable extends PureComponent<OwnProps> {

    handleDiceChanged = (roiName: string, value: number | undefined) => {
        this.props.changeAcceptanceCriteria(roiName, { dice: value });
    }

    handleSDiceChanged = (roiName: string, value: number | undefined) => {
        this.props.changeAcceptanceCriteria(roiName, { sdice2: value });
    }

    handleHD95Changed = (roiName: string, value: number | undefined) => {
        this.props.changeAcceptanceCriteria(roiName, { hd95: value });
    }

    render() {
        return (
            <Table
                className="roi-table"
                hover
                borderless
                striped
                size="sm"
                responsive
            >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Structure Name</th>
                        <th>DSC</th>
                        <th>s-DSC @2mm</th>
                        <th>HD95 (mm)</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.taskAcceptanceCriteria ? this.props.taskAcceptanceCriteria.map((roi, index) => (
                        <SimilarityMetricsTableRow
                            key={roi.roiName}
                            roiNumber={index}
                            roiName={roi.roiName}
                            dice={roi.acceptanceCriteria && roi.acceptanceCriteria.dice}
                            sdice={roi.acceptanceCriteria && roi.acceptanceCriteria.sdice2}
                            hd95={roi.acceptanceCriteria && roi.acceptanceCriteria.hd95}
                            onDiceChanged={this.handleDiceChanged}
                            onSDiceChanged={this.handleSDiceChanged}
                            onHD95Changed={this.handleHD95Changed}
                        />
                    )): null}
                </tbody>
            </Table>
        );
    }
}
