import _ from 'lodash';

export type SimilarityMetrics = {
    user_vol_mm3: number,
    gt_vol_mm3: number,
    dice: number,
    sdice2: number,
    hd95: number,
    user_roi_name?: string,
}

export const convertJsonToSimilarityMetrics = (json: any): SimilarityMetrics[] => {
    const expectedProperties = ['user_roi_name', 'user_vol_mm3', 'gt_vol_mm3', 'dice', 'sdice2', 'hd95'];
    if (!_.isArray(json) || json.every(m => !expectedProperties.every(prop => _.has(m, prop)))) {
        const errorMessage = 'Could not convert received JSON file to similarity metrics';
        console.log(errorMessage);
        console.log(json);
        throw new Error(errorMessage);
    }

    return json as SimilarityMetrics[];
}

// type ModalProps = {
//     showModal: boolean,
//     onHide: any,
//     metrics: SimilarityMetrics[],
//     showCalculationModal: boolean,
//     handleCloseCalculationModal(): void,
//     vs: ViewerState
// }

// type CalculateModalState = {
//     onHide: any,
//     vs: ViewerState | undefined,
// }

// type AllProps = ModalProps & StoreState 


// class CalculateMetricsModal extends Component<AllProps, CalculateModalState> {

//     constructor(props: AllProps) {
//         super(props);

//         this.state = {
//             onHide: undefined,
//             vs: undefined,
//         };
//     }
//     render() {
//         return (
//             <ModalDialog className="modalTable" size='lg'>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Calculation Results</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {this.props.metrics && (
//                         <Table className="modalTable">
//                             <thead>
//                                 <tr>
//                                     <th>Structure Name</th>
//                                     <th>User's Volume (cm<sup>3</sup>)</th>
//                                     <th>Reference's Volume (cm<sup>3</sup>)</th>
//                                     <th>Volume Difference (%)</th>
//                                     <th>DSC</th>
//                                     <th>S-DSC (@2mm)</th>
//                                     <th>HD95 (mm)</th>
//                                 </tr>
//                             </thead>
//                             <tbody>
//                                 {this.props.metrics.map((metric: SimilarityMetrics, index: number) => {
//                                     return (
//                                         <tr key={index}>
//                                             <td>{metric.user_roi_name}</td>
//                                             <td>{metric.user_vol_mm3.toFixed(2)}</td>
//                                             <td>{metric.gt_vol_mm3.toFixed(2)}</td>
//                                             <td>{(100 * (metric.user_vol_mm3 / metric.gt_vol_mm3 - 1)).toFixed(2)}</td>
//                                             <td>{metric.dice}</td>
//                                             <td>{metric.sdice2}</td>
//                                             <td>{metric.hd95}</td>
//                                         </tr>
//                                     )
//                                 }
//                                 )}
//                             </tbody>
//                         </Table>
//                     )}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant="primary" onClick={this.props.handleCloseCalculationModal} disabled={!this.state.vs!.canEdit}>Save Results</Button>
//                     <Button variant="secondary" onClick={this.props.handleCloseCalculationModal} disabled={!this.state.vs!.canEdit}>Export Results</Button>
//                     <Button variant="outline-secondary" onClick={this.props.handleCloseCalculationModal}>Cancel</Button>
//                 </Modal.Footer>
//             </ModalDialog>
//         )
//     }
// }


// export default connect(
//     state => Object.assign({}, state),
//     sagas,
// )(CalculateMetricsModal);