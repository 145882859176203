import React from 'react';
import { Item, Submenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

import './RoiTypeMenu.css';
import { Roi } from '../../dicom/structure-set';
import { RoiGuidelines } from '../../web-apis/rtviewer-api-client';

type OwnProps = {
    roi: Roi | null,
    guidelines?: RoiGuidelines,
}

/**
 * Sub menu for displaying and changing a structure's ROIInterpretedType value.
 * Must be used within a react-contexify menu.
 */
export default class RoiGuidelineMenu extends React.PureComponent<OwnProps> {

    render() {

        const { roi, guidelines} = this.props;

        if (!roi || !guidelines || guidelines.length === 0) { return null; }
        //if roi name has "L:" prefix, remove it
        const roiName = roi.name.startsWith("L:") ? roi.name.substring(2) : roi.name;

        const guidelinesMatchingCurrentRoi = guidelines.filter(r => r.roiName.toLocaleLowerCase() === roiName.toLocaleLowerCase());
        const roiGuidelines = guidelinesMatchingCurrentRoi.length > 0 ? guidelinesMatchingCurrentRoi[0].contouringGuidelines : undefined;

        return (
            <Submenu label={(<span>Guidelines</span>)}>
                {roiGuidelines ? roiGuidelines.map(r => (
                    <Item key={r.link} data={r} onClick={() => {window.open(r.link, "_blank")}}>
                        {r.text}
                    </Item>))
                    :
                    (<Item disabled={true}>No guidelines available for this structure</Item>)}
            </Submenu>
        );
    }

}
