/* Structure set table */

import './StructureSetTable.css';
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Menu, Item, Submenu, Separator, contextMenu } from 'react-contexify';

import * as sagas from '../../store/sagas';
import { NewItemGlyph } from '../glyphs';
import * as structureSet from '../../dicom/structure-set';
import { Image } from '../../dicom/image';
import * as guid from '../../dicom/guid';
import * as datasetFiles from '../../datasets/dataset-files';
import { ContouringTask, ContouringTaskState } from '../../store/contouring-task';
import { ViewerState } from '../../rtviewer-core/viewer-state';
import * as grading from '../../datasets/roi-grading'
import { Checkbox } from '../misc-components';
import ContouringRequest from './ContouringRequest';
import { StoreState } from '../../store/store';
import { sleep } from '../../util';
import { mouseTools } from '../../rtviewer-core/mouse-tools/mouse-tools';
import DeleteStructureSetDialog from "./dialogs/DeleteStructureSetDialog";
import { AzureFileInfo } from '../../web-apis/azure-files';
import { DatasetImage } from '../../datasets/dataset-image';
import { Dataset } from '../../datasets/dataset';
import WorkState, { Workspace } from '../../store/work-state';
import { isRutherford, isDemo } from '../../environments';
import { Backend } from '../../web-apis/backends';
import { backends } from '../../web-apis/auth';
import { convertWorkflowStateToText } from '../../datasets/roi-grading';
import ModalDialog from '../common/ModalDialog';
import { stopKeyboardPropagation } from '../../react-util';
import { User } from '../../store/user';
import { StructureSet } from '../../dicom/structure-set';
import { TrainingTask, TrainingTaskState } from '../../datasets/training-task';
import routePaths from '../../routes';

/** These props are used to check if a context menu item should be visible (in addition to current workspace) */
type ContextMenuVisibilityValues = {
    canEdit: boolean;
    canEditStructureSet: boolean;
    noTask: boolean;
    unsavedChanges: boolean;
    showAddGrading: boolean;
    showDeleteGrading: boolean;
    isStructureSetOriginal: boolean;
    isNotCopiedRoi: boolean;
    allowDebugPrints: boolean;
    allowStructureSetExport: boolean;
    allowStructureSetDownload: boolean;
    allowOriginalFileOperations: boolean;
    allowScanDownload: boolean;
    isDemo: boolean;
    isRenameEnabled: boolean;
}

enum ContextMenuItem {
    UndoUnsavedChanges,
    AddGradingSheet,
    DeleteGrading,
    SetDicomApprovalStatus,
    Rename,
    AddStructure,
    AddStructuresFromTemplate,
    PasteRois,
    Duplicate,
    DuplicateCheckedOnly,
    Delete,
    ViewDicomTagsImage,
    ViewDicomTagsStructureSet,
    Export,
    ExportOriginal,
    DownloadStructureSet,
    DownloadOriginalStructureSet,
    DownloadScanAsZip,
    DownloadScanAsZipAnonymized,
}

type OwnProps = {
    viewerState: ViewerState,
    structureSets: structureSet.StructureSet[],
    datasetImage?: DatasetImage,
    newStructureSets: string[],
    allDatasetGradings: grading.DatasetGradings | null,
    openAddStructuresFromTemplateDialog: () => void,
    onAddRoiClick: (ss: structureSet.StructureSet) => void,
    throwError: (msg: string) => void,
    doGradingSync: (ss: structureSet.StructureSet) => void,
}

type DispatchProps = {
    undoStructureSetChanges(ss: structureSet.StructureSet): structureSet.StructureSet | null,
    deleteStructureSet(structureSet: structureSet.StructureSet): void,
    storeNewStructureSet(structureSet: structureSet.StructureSet): void,
    seenStructureSet(structureSetId: string): void,
    dismissContouringTask: (scanId: string) => void,
    handleUndoStructureSetChangesClick: (ss: structureSet.StructureSet) => void,
    handleNewStructureSetClick: () => void,
    saveGrading: (structureSetId: string, ssGrading: grading.StructureSetGrading | null, dataset: Dataset) => void,
    updateCurrentWorkState: (updatedWorkStateProps: Partial<WorkState>) => void,
    exportStructureSet: (structureSet: structureSet.StructureSet, img: Image, grading: grading.StructureSetGrading | undefined, vs: ViewerState,
        task: datasetFiles.ExportTask | null, backend: Backend, originalDicomFile?: { file: ArrayBuffer, filename: string }) => void,
    requestSaveScanToUserDisk: (image: Image, anonymizeScan: boolean) => void,
}

type OwnState = {
    refreshSwitch?: any,
    renameStructureSetId?: string | null,
    newLabel?: string,
    showDeleteStructureSetDialog: boolean,
    showConfirmExportDialog: boolean,
    originalDicomFile: { file: ArrayBuffer, filename: string } | null,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class StructureSetTable extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);
        this.state = {
            showDeleteStructureSetDialog: false,
            showConfirmExportDialog: false,
            originalDicomFile: null,
        };
    }

    componentDidMount() {
        const { viewerState } = this.props;
        viewerState.addListener(this.updateView);
    }

    componentWillUnmount() {
        const vs = this.props.viewerState;
        vs.removeListener(this.updateView);
    }
    updateView = () => {
        this.setState({ refreshSwitch: !this.state.refreshSwitch });
    }

    handleStructureSetLabelKeyPress = (event: any, ss: structureSet.StructureSet) => {
        // prevent keypresses while renaming the structure set from interacting
        // with the rest of the application
        stopKeyboardPropagation(event);

        if (event.keyCode === 13) {// Enter
            ss.dataset.StructureSetLabel = event.target.value;
            ss.unsaved = true;
            this.setState({ renameStructureSetId: null });
            this.props.viewerState.notifyListeners();
        }
        else if (event.keyCode === 27) {// Esc
            this.setState({ renameStructureSetId: null });
        }
    }

    handleStructureSetLabelChanged = (event: any, ss: structureSet.StructureSet) => {
        this.setState({ newLabel: event.target.value })
    }

    handleRenameStructureSetClick = (ss: structureSet.StructureSet) => {
        this.setState({ renameStructureSetId: ss.structureSetId, newLabel: ss.dataset.StructureSetLabel }, function () {
            let arr = document.getElementsByClassName("structure-set-label-edit");
            (arr[arr.length - 1] as HTMLElement).focus();
        });
    }

    toggleStructureSetVisibility = (ss: StructureSet) => {
        const vs = this.props.viewerState;
        const index = vs.visibleStructureSets.indexOf(ss); // -1 if not found in vs.selectedStructureSets
        const isVisible = index !== -1;

        if (isVisible && vs.selectedStructureSet !== ss) {
                // Currently if you want to deselect the current structure set, it will not hide the rois of it
                // TODO: create an array of visible rois and use that to hide them and then show them again when the structure set is selected
                vs.visibleStructureSets.splice(index, 1);
        } else {
            if (ss !== vs.selectedStructureSet) {
                // make sure we have SDFs etc generated correctly
                const previousSelection = vs.selectedStructureSet;
                const image = vs.image;
                vs.setSelectedStructureSet(ss, image);
                vs.setSelectedStructureSet(previousSelection, image);
            } 
            if (!vs.visibleStructureSets.includes(ss)) { vs.visibleStructureSets.push(ss); }
        }

        vs.showMultipleStructureSets = vs.areMultipleStructureSetsVisible();
        vs.notifyListeners();
    };


    handleAddGradingClick = (ss: structureSet.StructureSet) => {
        if (!this.props.currentWorkState || !this.props.currentWorkState.dataset) {
            throw new Error('No work state or dataset defined -- cannot change grading');
        }

        const vs = this.props.viewerState;
        const g = new grading.StructureSetGrading();
        this.props.saveGrading(ss.structureSetId, g, this.props.currentWorkState.dataset);
        vs.sizeChanged();
    }

    handleDeleteGradingClick = (ss: structureSet.StructureSet) => {
        if (!this.props.currentWorkState || !this.props.currentWorkState.dataset) {
            throw new Error('No work state or dataset defined -- cannot change grading');
        }

        const vs = this.props.viewerState;
        this.props.saveGrading(ss.structureSetId, null, this.props.currentWorkState.dataset);
        vs.sizeChanged();
    }

    handleSetApprovalStatus = (ss: structureSet.StructureSet, approvalStatus: string) => {
        const vs = this.props.viewerState;
        ss.dataset.ApprovalStatus = approvalStatus;
        ss.unsaved = true;
        vs.notifyListeners();
    }

    handleAddRoiClick = (ss: structureSet.StructureSet) => {
        this.props.onAddRoiClick(ss);
    }

    handleDeleteStructureSetDialogClick = () => {
        this.setState({ showDeleteStructureSetDialog: true });
    };

    handleCloseDeleteStructureSetDialog = () => {
        this.setState({ showDeleteStructureSetDialog: false });
    };

    handleExportClick = (originalDicomFile?: { file: ArrayBuffer, filename: string }) => {
        this.setState({ showConfirmExportDialog: true, originalDicomFile: originalDicomFile || null });
    }

    handleDownloadScanClick = (anonymizeScan: boolean) => {
        this.props.requestSaveScanToUserDisk(this.props.viewerState.image, anonymizeScan);
    }

    handleCloseExportConfirmationDialog = () => {
        this.setState({ showConfirmExportDialog: false });
    }

    /** Export current structure set to backend */
    handleConfirmExport = () => {
        if (!this.props.currentWorkState || !this.props.currentWorkState.dataset || !this.props.currentWorkState.datasetImage) {
            throw new Error('No work state, dataset, or dataset image defined -- cannot export');
        }

        this.handleCloseExportConfirmationDialog();
        const vs = this.props.viewerState;
        const structureSet = vs.selectedStructureSet;
        if (structureSet !== null) {
            const backend = this.props.user!.currentBackend || backends.getDefaultBackend();
            if (backend) {
                const allGradings = this.props.allDatasetGradings;
                const ssGrading = allGradings ? allGradings.structureSets[structureSet.structureSetId] : undefined;
                const task: datasetFiles.ExportTask | null = _.get(this.props.currentWorkState.dataset.metaFiles.exportDestination,
                    [structureSet.patientId, this.props.currentWorkState.datasetImage.seriesId], null);

                this.props.exportStructureSet(structureSet, vs.image, ssGrading, vs, task, backend, this.state.originalDicomFile || undefined);
            }
        }
    }

    handleAddRoisFromTemplateOpenModalClick = () => {
        this.props.openAddStructuresFromTemplateDialog();
    }

    handlePasteRoiClick = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        const vm = vs.viewManager;
        const rois = vs.copiedRois;
        if (!rois || rois[0].structureSet === ss) return;

        // this shouldn't happen but do a sanity check here
        if (!rois.every(r => r.structureSet === rois[0].structureSet)) {
            throw new Error(`All copied ROIs must be from the same structure set!`);
        }

        setTimeout(() => {

            let lastNewRoi: structureSet.Roi | undefined = undefined;
            for (const roi of rois) {
                const overwrite = ss.getRois().some(r => r.name === roi.name)
                    && window.confirm(`Structure set already contains a structure called '${roi.name}'. Do you want to overwrite it? 'OK' to overwrite, 'Cancel' to add the structure as a new copy.`);

                lastNewRoi = ss.duplicateRoiFromOtherStructureSet(vm, roi, overwrite);
            }

            if (lastNewRoi !== undefined) {
                this.props.doGradingSync(ss);
                vs.roisChanged(ss);
                if (vs.activeMouseTools.includes(mouseTools.brush)) mouseTools.brush.createDrawBuffer();
                setTimeout(function () {
                    vs.setSelectedRoi(lastNewRoi!);
                    vs.setRoiHidden(lastNewRoi!, false);
                    vs.notifyListeners();
                }, 50);
            }
        }, 50);
    }

    handleDuplicateStructureSetClick = async (ss: structureSet.StructureSet, checkedOnly: boolean) => {
        let vs = this.props.viewerState;
        let frameOfReferenceUid = ss.frameOfReferenceUid;
        let sopUid = guid.generateUid();
        let seriesId = sopUid + ".1";
        let approvalStatus = "UNAPPROVED";

        // Check if a structure set with the same label already exists
        let duplicateCount = 1;
        let label = ss.dataset.StructureSetLabel;

        // Function to check if a structure set with the given label exists
        const structureSetExists = (label: string) => {
            return this.props.structureSets.some((existingSS) => existingSS.dataset.StructureSetLabel === label);
        };

        while (structureSetExists(label)) {
            label = `${ss.dataset.StructureSetLabel} ${duplicateCount}`;
            duplicateCount++;
        }

        ss.modalMessage = structureSet.StructureSetModalMessages.Duplicating;
        vs.notifyListeners();
        await sleep(50); // Ensure that the modal is shown before UI hangs.

        let arrayBuffer = ss.unsaved ? structureSet.structureSetToDicom(ss, vs.image) : ss.arrayBuffer;
        let ssNew = structureSet.dicomToStructureSet(arrayBuffer);
        if (!ssNew) return;
        ssNew.scanId = ss.scanId;
        ssNew.seriesUid = seriesId;
        ssNew.structureSetId = sopUid;
        ssNew.dataset.StructureSetLabel = label;
        ssNew.dataset.ApprovalStatus = approvalStatus;
        ssNew.dataset.Manufacturer = structureSet.AnnotationManufacturer;
        ssNew.dataset.ManufacturerModelName = structureSet.AnnotationManufacturerModelName;
        ssNew.isOriginal = false;

        if (this.props.datasetImage) {
            // This should not be needed but there are some auto-contoured structure sets in the datasets where the patientId is changed 
            // in anonymization and will lead to attempt to save the structureset to  path /ANON/... 
            ssNew.dataset.PatientID = this.props.datasetImage.patientId;
        }
        if (ss.azureFileInfo) {
            ssNew.azureFileInfo = this.props.datasetImage ?
                datasetFiles.getStructureSetFileInfo(ss.azureFileInfo.getShare(), ssNew.dataset.PatientID, frameOfReferenceUid, seriesId, sopUid)
                : new AzureFileInfo( // On live review page
                    ss.azureFileInfo.storageAccountName,
                    ss.azureFileInfo.fileShareName,
                    'live-review-edit-RTSTRUCT-' + seriesId,
                    sopUid + ".dcm");
        }
        ssNew.unsaved = true;
        ssNew.existsInAzure = false;
        let roiIds: string[] = [];
        if (vs.hiddenRois[ss.structureSetId]) {
            roiIds = Array.from(vs.hiddenRois[ss.structureSetId]);
        }
        // get the roi numbers that have the same id as the hidden rois
        const roiNumbers = ss.getRois().filter((roi) => roiIds.includes(roi.roiId)).map((roi) => roi.roiNumber);
        if (checkedOnly) {
            if (vs.hiddenRois[ss.structureSetId]) ssNew.deleteRois(roiNumbers);
        }
        this.props.storeNewStructureSet(ssNew);
        ss.modalMessage = null;
        setTimeout(function () {
            vs.setSelectedStructureSet(ssNew, vs.image);
        }, 50);
    };


    handleViewDicomClick = (ss: structureSet.StructureSet) => {
        structureSet.printDicomToConsole(ss);
    }

    handleViewImageClick = () => {
        console.log(this.props.viewerState.image.dicomTags);
    }


    handleDeleteStructureSetClick = (ss: structureSet.StructureSet) => {
        let vs = this.props.viewerState;
        ss.deleted = true;
        ss.unsaved = true;
        if (!ss.existsInAzure) {
            this.props.deleteStructureSet(ss);
        }
        // filter out deleted structure set from vs.visibleStructureSets
        vs.visibleStructureSets = vs.visibleStructureSets.filter(s => !s.deleted);
        let ssList = this.props.structureSets.filter(s => !s.deleted);
        let existingSs = ssList.length > 0 ? ssList[0] : null;
        setTimeout(function () { vs.setSelectedStructureSet(existingSs, vs.image); }, 50);
        this.handleCloseDeleteStructureSetDialog();
    }

    setStructureSetAsSeen = (structureSetId: string) => {
        this.props.seenStructureSet(structureSetId);
    }

    getContouringTasks = (): ContouringTask[] => {
        const { contouringTasks } = this.props;
        const inProgressTasks: ContouringTask[] = [];
        Object.keys(contouringTasks).forEach(k => {
            const task = contouringTasks[k];
            if (task.contouringState !== ContouringTaskState.Success && !task.isDismissed) {
                inProgressTasks.push(task);
            }
        });
        return inProgressTasks;
    }

    handleSelectStructureSet(ss: structureSet.StructureSet) {
        const vs = this.props.viewerState;
        const image = vs.image;
        vs.setSelectedStructureSet(ss, image);

        // update current work state
        this.props.updateCurrentWorkState({ structureSetId: ss.structureSetId });
    }

    handleShowStructureSetContextMenu = (structureSetMenuId: string, evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        contextMenu.show({ id: structureSetMenuId, event: evt });
    }

    isContextMenuItemVisible = (values: ContextMenuVisibilityValues, item: ContextMenuItem) => {
        const workspace = this.props.currentWorkState ? this.props.currentWorkState.workspace : Workspace.None;
        const isAnnotationWorkspace = workspace === Workspace.Annotation;
        const isQAToolWorkspace = workspace === Workspace.QATool;
        const isReferenceLibraryWorkspace = workspace === Workspace.ReferenceLibrary;

        // blanket disable all editing options in reference library
        if (isReferenceLibraryWorkspace) {
            if ([
                ContextMenuItem.UndoUnsavedChanges,
                ContextMenuItem.SetDicomApprovalStatus,
                ContextMenuItem.Rename,
                ContextMenuItem.AddStructure,
                ContextMenuItem.AddStructuresFromTemplate,
                ContextMenuItem.PasteRois,
                ContextMenuItem.Duplicate,
                ContextMenuItem.DuplicateCheckedOnly,
                ContextMenuItem.Delete,
            ].includes(item)) {
                return false;
            }
        }

        if (isQAToolWorkspace) {
            if ([
                ContextMenuItem.UndoUnsavedChanges,
                ContextMenuItem.SetDicomApprovalStatus,
                ContextMenuItem.AddGradingSheet,
                ContextMenuItem.AddStructuresFromTemplate,
            ].includes(item)) {
                return false;
            }
        }
        

        switch (item) {
            case ContextMenuItem.UndoUnsavedChanges:
                return values.canEditStructureSet && values.unsavedChanges;
            case ContextMenuItem.AddGradingSheet:
                return isAnnotationWorkspace && values.showAddGrading;
            case ContextMenuItem.DeleteGrading:
                return isAnnotationWorkspace && values.showDeleteGrading;
            case ContextMenuItem.SetDicomApprovalStatus:
                return isAnnotationWorkspace && values.canEdit && !values.isStructureSetOriginal;
            case ContextMenuItem.Rename:
                return values.isRenameEnabled && values.canEditStructureSet && values.noTask;
            case ContextMenuItem.AddStructure:
                return values.canEditStructureSet && values.noTask;
            case ContextMenuItem.AddStructuresFromTemplate:
                return isAnnotationWorkspace && values.canEditStructureSet && values.noTask;
            case ContextMenuItem.PasteRois:
                return values.canEditStructureSet && values.isNotCopiedRoi;
            case ContextMenuItem.Duplicate:
                return values.canEdit;
            case ContextMenuItem.DuplicateCheckedOnly:
                return values.canEdit;
            case ContextMenuItem.Delete:
                return values.canEditStructureSet;
            case ContextMenuItem.ViewDicomTagsImage:
                return values.allowDebugPrints;
            case ContextMenuItem.ViewDicomTagsStructureSet:
                return values.allowDebugPrints;
            case ContextMenuItem.Export:
                return !isReferenceLibraryWorkspace && values.allowStructureSetExport; 
            case ContextMenuItem.ExportOriginal:
                return !isReferenceLibraryWorkspace && values.allowStructureSetExport && values.allowOriginalFileOperations;
            case ContextMenuItem.DownloadStructureSet:
                return !isReferenceLibraryWorkspace && values.allowStructureSetDownload;
            case ContextMenuItem.DownloadOriginalStructureSet:
                return !isReferenceLibraryWorkspace && values.allowStructureSetDownload && !values.isDemo && values.allowOriginalFileOperations;
            case ContextMenuItem.DownloadScanAsZip:
                return !isReferenceLibraryWorkspace && values.allowScanDownload;
            case ContextMenuItem.DownloadScanAsZipAnonymized:
                return !isReferenceLibraryWorkspace && values.allowScanDownload;
        
            default:
                return false;
        }
    }

    renderStructureSetRow = (ss: structureSet.StructureSet) => {
        const vs = this.props.viewerState;
        const ssId = ss.structureSetId;
        const task: TrainingTask | undefined = this.props.currentTask;

        const selected = ss === vs.selectedStructureSet;
        const workspace = this.props.currentWorkState ? this.props.currentWorkState.workspace : Workspace.None;
        
        // if we're in MVision Guide (i.e. we have a task), only allow modifications to the trainee structure set defined in the task
        const noTaskOrIsTraineeStructureSet = !task || ssId === task.traineeStructureSet.sopInstanceUid;
        
        const canEditStructureSet = vs.canEdit && ss.canEdit() && noTaskOrIsTraineeStructureSet;
        const gradings = this.props.allDatasetGradings;
        const ssGrading = gradings ? gradings.structureSets[ssId] : undefined;
        const showAddGrading = ssGrading === undefined;
        const showDeleteGrading = !showAddGrading;

        let label = ss.dataset.StructureSetLabel;
        if (!vs.canEdit || (ss && ss.dataset.StructureSetLabel === structureSet.ExpertContoursName)) {
            label += " (cannot edit)";
        }
        if (isRutherford() && ssGrading !== undefined && (
            // add possible approved/unapproved workflow state to structure set label (rutherford only)
            ssGrading.workflowState === grading.GradingWorkflowState.RutherfordApproved || ssGrading.workflowState === grading.GradingWorkflowState.RutherfordUnapproved)) {
            label += ` (${convertWorkflowStateToText(ssGrading.workflowState)})`;
        }

        let t = this;
        function renderName() {
            if (t.state.renameStructureSetId === ss.structureSetId) {
                return (
                    <input className="structure-set-label-edit" type="text" value={t.state.newLabel}
                        onChange={(event) => t.handleStructureSetLabelChanged(event, ss)}
                        onKeyDown={(event) => t.handleStructureSetLabelKeyPress(event, ss)}
                        onBlur={(event) => t.setState({ renameStructureSetId: null })} />
                );
            }
            return ss.unsaved ? (<b>{label}</b>) : label;
        }
        if (ss.deleted) { return null; }

        const isNew = this.props.newStructureSets.indexOf(ssId) !== -1;
        const originalDicomFile: { file: ArrayBuffer, filename: string } | undefined = this.props.originalStructureSets[ssId];
        const isOriginalDicomAvailable = originalDicomFile !== undefined;
        const allowStructureSetDownload = (this.props.user && (this.props.user as User).permissions.allowStructureSetDownload) || isDemo();
        const allowOriginalFileOperations = (this.props.user !== undefined && (this.props.user as User).permissions.allowDebugMode) && isOriginalDicomAvailable;
        const allowScanDownload = this.props.user !== undefined && (this.props.user as User).permissions.allowScanDownload;
        const allowDebugPrints = this.props.user !== undefined && (this.props.user as User).permissions.allowDebugMode;
        const allowStructureSetExport = isRutherford();

        let rowStyles = 'structure-set-row';
        if (selected) { rowStyles += ' structure-set-selected' }
        if (isNew) { rowStyles += ' structure-set-is-new' }

        const pasteRoisText = vs.copiedRois ? vs.copiedRois.length === 1 ? `Paste ${vs.copiedRois[0].name}` : `Paste ${vs.copiedRois.length} structures` : '';

        // don't allow this menu in MVision Guide or Reference Library
        const disableMenu = this.props.currentTask !== undefined || 
            (this.props.currentWorkState && (this.props.currentWorkState.workspace === Workspace.ReferenceLibrary || this.props.currentWorkState.workspace === Workspace.GuidelineTraining));

        const isRenameEnabled =
            // Check if the user has permission to edit structure sets
            canEditStructureSet &&
            // Check if a structure set is currently selected
            vs.selectedStructureSet !== null &&
            // Check that we're not in Reference Library or Guide workspaces where all structure set renaming is disabled
            workspace !== Workspace.ReferenceLibrary && workspace !== Workspace.GuidelineTraining &&
            // Check that we're in any of the workspaces where renaming is always allowed
            (workspace === Workspace.Annotation || workspace === Workspace.QATool);

        const visibilityValues: ContextMenuVisibilityValues = {
            canEdit: vs.canEdit,
            canEditStructureSet,
            noTask: this.props.currentTask === undefined,
            unsavedChanges: ss.unsaved,
            showAddGrading,
            showDeleteGrading,
            isStructureSetOriginal: ss.isOriginal,
            isNotCopiedRoi: vs.copiedRois !== null && vs.copiedRois[0].structureSet !== ss,
            allowDebugPrints,
            allowStructureSetExport,
            allowStructureSetDownload,
            allowOriginalFileOperations,
            allowScanDownload,
            isDemo: isDemo(),
            isRenameEnabled: isRenameEnabled,
        };

        const isVisible = (item: ContextMenuItem) => this.isContextMenuItemVisible(visibilityValues, item);

        return (
            <td className={rowStyles} key={ssId}
                onClick={(evt) => { this.handleSelectStructureSet(ss) }}
                onDoubleClick={(evt) => { if (canEditStructureSet && isRenameEnabled) this.handleRenameStructureSetClick(ss) }}
                onMouseOver={(evt) => { if (isNew) this.setStructureSetAsSeen(ssId) }}>
                <a>
                    {!disableMenu && <Menu id={ssId} style={{ zIndex: 1000 }} animation={false} className="structure-set-context-menu">
                        {isVisible(ContextMenuItem.UndoUnsavedChanges) && <Item onClick={(event) => this.props.handleUndoStructureSetChangesClick(ss)}>Undo unsaved changes</Item>}
                        {isVisible(ContextMenuItem.AddGradingSheet) && <Item onClick={(event) => this.handleAddGradingClick(ss)} disabled={!vs.canEdit}>Add grading sheet</Item>}
                        {isVisible(ContextMenuItem.DeleteGrading) && <Item onClick={(event) => this.handleDeleteGradingClick(ss)} disabled={!vs.canEdit}>Delete grading</Item>}
                        {isVisible(ContextMenuItem.SetDicomApprovalStatus) && (<Submenu label="Set approval status">
                            <Item onClick={(event) => this.handleSetApprovalStatus(ss, "UNAPPROVED")}>Unapproved</Item>
                            <Item onClick={(event) => this.handleSetApprovalStatus(ss, "APPROVED")}>Approved</Item>
                            <Item onClick={(event) => this.handleSetApprovalStatus(ss, "REJECTED")}>Rejected</Item>
                        </Submenu>)}
                        {isVisible(ContextMenuItem.Rename) && <Item onClick={(event) => this.handleRenameStructureSetClick(ss)} disabled={!isRenameEnabled}>Rename</Item>}
                        {isVisible(ContextMenuItem.AddStructure) && <Item onClick={(event) => this.handleAddRoiClick(ss)}>Add structure</Item>}
                        {isVisible(ContextMenuItem.AddStructuresFromTemplate) && <Item onClick={(event) => this.handleAddRoisFromTemplateOpenModalClick()}>Add structures from template...</Item>}
                        {isVisible(ContextMenuItem.PasteRois) && <Item onClick={(event) => this.handlePasteRoiClick(ss)}>{pasteRoisText}</Item>}
                        {canEditStructureSet && <Separator />}
                        {isVisible(ContextMenuItem.Duplicate) && <Item onClick={(event) => this.handleDuplicateStructureSetClick(ss, false)}>Duplicate</Item>}
                        {isVisible(ContextMenuItem.DuplicateCheckedOnly) && <Item onClick={(event) => this.handleDuplicateStructureSetClick(ss, true)}>Duplicate (checked structures only)</Item>}
                        {isVisible(ContextMenuItem.Delete) && <Item onClick={this.handleDeleteStructureSetDialogClick}>Delete</Item>}
                        <Separator />
                        {isVisible(ContextMenuItem.ViewDicomTagsImage) && <Item onClick={(event) => this.handleViewImageClick()}>View DICOM tags in console (image)</Item>}
                        {isVisible(ContextMenuItem.ViewDicomTagsStructureSet) && <Item onClick={(event) => this.handleViewDicomClick(ss)}>View DICOM tags in console (structure set)</Item>}
                        {isVisible(ContextMenuItem.Export) && <Item onClick={() => this.handleExportClick()}>Export</Item>}
                        {isVisible(ContextMenuItem.ExportOriginal) && <Item onClick={() => this.handleExportClick(originalDicomFile)}>Export original DICOM file (debug)</Item>}
                        {isVisible(ContextMenuItem.DownloadStructureSet) && <Item onClick={() => structureSet.saveStructureSetOnUserDevice(ss, vs)}>Download structure set as a DICOM file</Item>}
                        {isVisible(ContextMenuItem.DownloadOriginalStructureSet) && <Item onClick={() => structureSet.saveStructureSetOnUserDevice(ss, vs, originalDicomFile)}>Download original structure set DICOM file (debug)</Item>}
                        {isVisible(ContextMenuItem.DownloadScanAsZip) && <Item onClick={() => this.handleDownloadScanClick(false)}>Download scan as a ZIP file</Item>}
                        {isVisible(ContextMenuItem.DownloadScanAsZipAnonymized) && <Item onClick={() => this.handleDownloadScanClick(true)}>Download scan as a ZIP file (anonymized)</Item>}
                    </Menu>}
                </a>
                <a className="structure-set-name">
                    <div onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.handleShowStructureSetContextMenu(ssId, evt); }}>
                        {renderName()}
                    </div>
                </a>
            </td>
        );
    }

    render() {
        const { structureSets, currentTask } = this.props;
        const ssRequiringModal = structureSets.find(ss => ss.modalMessage);
        const modalText = ssRequiringModal ? ssRequiringModal.modalMessage : null;
        const vs = this.props.viewerState;
        const ss = vs.selectedStructureSet;
        const allowDebugPrints = this.props.user && (this.props.user as User).permissions.allowDebugMode;
        const allowScanDownload = this.props.user && (this.props.user as User).permissions.allowScanDownload;
        let canEditStructureSets = vs.canEdit && currentTask === undefined;  // true -> RTSTRUCT can be edited (i.e. add/rename ROIs, add/alter contours), false -> no changes to RTSTRUCT allowed

        let structureSetsToRender: structureSet.StructureSet[] = [];
        if (currentTask) {
            // if in MVision Guide -- only show trainee's own structure set...
            structureSetsToRender = structureSets.filter(ss => ss.seriesUid === currentTask.traineeStructureSet.seriesInstanceUid && ss.structureSetId === currentTask.traineeStructureSet.sopInstanceUid);

            // if task is either finished (or graded) and a Practice, OR if the user is a supervisor, then show the reference contours
            if (((currentTask.state === TrainingTaskState.Finished || currentTask.state === TrainingTaskState.Graded) && currentTask.type === "PRACTICE") || (this.props.user && (this.props.user as User).permissions.isSupervisor)) {
                structureSetsToRender.push(...structureSets.filter(ss => ss.seriesUid === currentTask.gtStructureSet.seriesInstanceUid && ss.structureSetId === currentTask.gtStructureSet.sopInstanceUid));
            }
        } else {
            structureSetsToRender = structureSets;
        }
        //  if pathname include '/reference-library' then show only the structure sets with label 'expert-contours'
        if (window.location.pathname.includes(routePaths.referenceLibrary)) {
            structureSetsToRender = structureSetsToRender.filter(ss => ss.getLabel() === structureSet.ExpertContoursName);
            canEditStructureSets = false;
        }


        return (
            <>
                <div className="section-title" onContextMenu={(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.handleShowStructureSetContextMenu('structure-sets-header-menu', evt); }}>Structure sets</div>
                {(allowDebugPrints || allowScanDownload) && <Menu id='structure-sets-header-menu' style={{ zIndex: 1000 }} animation={false} className="structure-set-context-menu">
                    {allowDebugPrints && <Item onClick={(event) => this.handleViewImageClick()}>View DICOM tags in console (image)</Item>}
                    {allowScanDownload && <Item onClick={() => this.handleDownloadScanClick(false)}>Download scan as a ZIP file</Item>}
                    {allowScanDownload && <Item onClick={() => this.handleDownloadScanClick(true)}>Download scan as a ZIP file (anonymized)</Item>}
                </Menu>}
                <table className="structure-set-table">
                    <thead>
                        <tr>
                            <th />
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {structureSetsToRender.map(ss => (
                            <tr key={ss.structureSetId} className="ss-row">
                                <td>
                                    <Checkbox
                                        isSelected={vs.visibleStructureSets.includes(ss)}
                                        onCheckboxChange={() => this.toggleStructureSetVisibility(ss)}
                                    />
                                </td>
                                {this.renderStructureSetRow(ss)}
                            </tr>
                        ))}
                        {this.getContouringTasks().map(ct => (<ContouringRequest contouringTask={ct} key={ct.scanId} dismissContouringTask={this.props.dismissContouringTask} />))}
                        {canEditStructureSets && <tr className="new-structure-set-button">
                            <td colSpan={2}>
                                <Button variant="light" className="btn btn-default btn-sm" onClick={this.props.handleNewStructureSetClick} disabled={!vs.canCreateRtstruct}>
                                    <NewItemGlyph /> New structure set
                                </Button>
                            </td>
                        </tr>}
                    </tbody>
                </table>

                <ModalDialog show={!!modalText} onHide={() => { }}>
                    <Modal.Header>
                        <Modal.Title>Please wait</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{modalText}</Modal.Body>
                </ModalDialog>

                {ss && (
                    <ModalDialog show={this.state.showConfirmExportDialog} onHide={this.handleCloseExportConfirmationDialog}>
                        <Modal.Header>
                            <Modal.Title>Confirm export</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {ss.unsaved ? (
                                <div>Please save your unsaved changes before exporting.</div>
                            ) : (
                                <div>Are you sure you want to export structure set "{ss.dataset.StructureSetLabel}"?</div>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" disabled={ss.unsaved} onClick={this.handleConfirmExport}>Export</Button>
                            <Button variant="light" onClick={this.handleCloseExportConfirmationDialog}>Cancel</Button>
                        </Modal.Footer>
                    </ModalDialog>
                )}

                <DeleteStructureSetDialog
                    isVisible={this.state.showDeleteStructureSetDialog}
                    onClose={this.handleCloseDeleteStructureSetDialog}
                    handleDeleteStructureSetClick={this.handleDeleteStructureSetClick}
                    ss={ss}
                />
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(StructureSetTable);