import React, { Component } from 'react'
import { FaTasks } from 'react-icons/fa'
import { MdHelpOutline,  MdLocalLibrary, MdSettings } from 'react-icons/md'
import { connect } from 'react-redux'
import * as sagas from '../../../store/sagas';
import { rtViewerApiClient } from '../../../web-apis/rtviewer-api-client';
import SidebarButton from './SidebarButton';
import { StoreState } from '../../../store/store';
import './SidebarMenu.css'
import { RouteComponentProps } from 'react-router';
import { User } from '../../../store/user';
import WorkState, { Workspace } from '../../../store/work-state';
import routePaths from '../../../routes';

type DispatchProps = {
    setBatchJobPaneVisibility(value: boolean): void,
    setUserSettingsDialogVisibility(value: boolean): void,
    setHelpDialogVisibility(value: boolean): void,
    setCurrentWorkState(workState: WorkState | null, resetToWorkspace?: Workspace): void,
}

type AllProps = DispatchProps & StoreState & RouteComponentProps;

class SidebarMenu extends Component<AllProps> {

    handleViewBatchJobsClick = () => {
        this.props.setBatchJobPaneVisibility(true);
    }

    handleViewSettingsClick = () => {
        this.props.setUserSettingsDialogVisibility(true);
    }

    handleViewHelpClick = () => {
        this.props.setHelpDialogVisibility(true);
    }

    handleWorkStateReset = (workspace: Workspace) => {
        this.props.setCurrentWorkState(null, workspace);
    }


    render() {
        const { user }: { user?: User } = this.props;

        const isMVisionUser = rtViewerApiClient.isMVisionUser();
        const showSupervisorColumns = user && user.permissions.isSupervisor;
        const qaToolAccess = user && user.permissions.qaToolAccess;
        const tpAccess = user && user.permissions.tpAccess;
        const hideSide = this.props.hideSide;
        const disableSide = this.props.isAutoloading === true;

        const commonProps = { history: this.props.history, location: this.props.location, match: this.props.match };

        if (disableSide) {
            return null;
        }

        return (
            <div className={hideSide === true ? 'menu hide' : 'menu'}   >
                <div className='sidebar-menu' data-cy="sidebar-menu">
                    <SidebarButton text='Home' link='/' icon={<img src='/img/logo-mvision-w-fav.svg' alt="MVision Logo" width={23}></img>} {...commonProps}
                        dataCy="sidebar-button-home" onClick={() => this.handleWorkStateReset(Workspace.Home)} />
                    {/* {showSupervisorColumns && (<SidebarButton text='Annotation Tool' link='/annotation' icon={<IoIosMedical size={23} />} {...commonProps} />)} */}
                    {showSupervisorColumns && (<SidebarButton text='Guide - Reference Library' link={routePaths.referenceLibrary} icon={<MdLocalLibrary size={23} />} {...commonProps}
                        dataCy="sidebar-button-reference-library" onClick={() => this.handleWorkStateReset(Workspace.ReferenceLibrary)} />)}
                    {tpAccess && (<SidebarButton text='Guide - Guideline Training' link={routePaths.guidelineTraining} icon={<FaTasks size={23} />} {...commonProps}
                        dataCy="sidebar-button-guideline-training" />)}
                    {qaToolAccess && <SidebarButton text='Verify' link={routePaths.verify} icon={<img width={23} src='/img/icon-quality-assurance-sm.svg' alt='MVision Verify'/>} {...commonProps}
                        dataCy="sidebar-button-qa-tool" />}
                    {/* {showSupervisorColumns && (<SidebarButton text='Import/Export UI' link='/import-export' icon={<MdImportExport size={23} />} {...commonProps} />)}
                {showSupervisorColumns && (<SidebarButton text='ConfigUI' link='/configui' icon={<MdStyle size={23} />} {...commonProps} />)} */}
                    {isMVisionUser && (
                        <SidebarButton text='Help' icon={<MdHelpOutline size={23} onClick={this.handleViewHelpClick} />} {...commonProps}
                            dataCy="sidebar-button-help" />)}
                    <SidebarButton text='Settings' icon={<MdSettings size={23} onClick={this.handleViewSettingsClick} />} {...commonProps}
                        dataCy="sidebar-button-settings" />
                </div>
            </div>
        )
    }
}

export default connect(state => Object.assign({}, state), sagas.mapDispatchToProps)(SidebarMenu);
