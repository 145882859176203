import { equal } from "../util";

export function pointToLineDistance(x: number, y: number, x1: number, y1: number, x2: number, y2: number) : number {

    let A = x - x1;
    let B = y - y1;
    let C = x2 - x1;
    let D = y2 - y1;

    let dot = A * C + B * D;
    let len_sq = C * C + D * D;
    let param = -1;
    if (len_sq !== 0) 
        param = dot / len_sq;

    let xx, yy;

    if (param < 0) {
      xx = x1;
      yy = y1;
    }
    else if (param > 1) {
      xx = x2;
      yy = y2;
    }
    else {
      xx = x1 + param * C;
      yy = y1 + param * D;
    }

    let dx = x - xx;
    let dy = y - yy;
    return Math.sqrt(dx * dx + dy * dy);
}

export function arePointsClose(p1: number[], p2: number[], epsilon: number) : boolean {
    let dx = p1[0] - p2[0];
    let dy = p1[1] - p2[1];
    let d = Math.sqrt((dx * dx) + (dy * dy));
    return d <= epsilon;
}

export function countLinePolylineIntersections(polyline: number[][], pt1: number[], pt2: number[]) : number {
    return getLinePolylineIntersections(polyline, pt1, pt2).length;
}

export function getLinePolylineIntersections(polyline: number[][], pt1: number[], pt2: number[]) : number[][] {
    let result: number[][] = [];
    if(polyline.length <= 1) return result;

    for(let i = 0; i <= polyline.length - 2; ++i) {
        let x1 = polyline[i][0];
        let y1 = polyline[i][1];
        let x2 = polyline[i+1][0];
        let y2 = polyline[i+1][1];
        let x3 = pt1[0];
        let y3 = pt1[1];
        let x4 = pt2[0];
        let y4 = pt2[1];
        let intersection = getLineIntersection(x1, y1, x2, y2, x3, y3, x4, y4);
        if(intersection) {
            result.push(intersection);
        };
    }
    return result;
}


export function getLineIntersection(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) : number[] | null
{
    const pointEqual = (p1: number[], p2: number[]) => {
        return equal(p1[0], p2[0]) && equal(p1[1], p2[1]);
    }
    if( pointEqual([x1, y1], [x3, y3]) 
      || pointEqual([x1, y1], [x4, y4])
      || pointEqual([x2, y2], [x3, y3])
      || pointEqual([x2,y2], [x4, y4])){
        return null;
    }

    let s1_x = x2 - x1;
    let s1_y = y2 - y1;
    let s2_x = x4 - x3;
    let s2_y = y4 - y3;
    let s = (-s1_y * (x1 - x3) + s1_x * (y1 - y3)) / (-s2_x * s1_y + s1_x * s2_y);
    let t = ( s2_x * (y1 - y3) - s2_y * (x1 - x3)) / (-s2_x * s1_y + s1_x * s2_y);
    if (s >= 0 && s <= 1 && t >= 0 && t <= 1)
    {
        let ix = x1 + (t * s1_x);
        let iy = y1 + (t * s1_y);
        return [ix, iy];
    }
    return null; // No intersection
}

export function circleToPolygon(origin: number[], r: number, edges: number): number[][] {

    function getPoint(angle: number): number[] {
        let x = origin[0] + r * Math.cos(angle)
        let y = origin[1] + r * Math.sin(angle)
        let z = origin[2]; 
        return [x, y, z];
    }

    let poly = [];
    for(let angle = 0; angle < 2*Math.PI; angle += 2*Math.PI/edges) {
        poly.push(getPoint(angle));
    }
    poly.push(poly[0]);//close the circle
    return poly;
}