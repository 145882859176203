import React from 'react';
import { Row, Button, Modal, Spinner, Table, ButtonGroup, Dropdown, SplitButton, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { connect } from 'react-redux';

import * as sagas from '../../../store/sagas';
import ModalDialog from '../../common/ModalDialog'
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import * as structureSet from '../../../dicom/structure-set';
import { StoreState } from '../../../store/store';
import './DifferencesToolbar.css';
import { rtViewerApiClient } from '../../../web-apis/rtviewer-api-client';
import { User } from '../../../store/user';
import { SessionNotification, NotificationType, DEFAULT_SESSION_TIMEOUT_IN_MS } from '../../common/models/SessionNotification';
import { SimilarityMetrics } from './CalculateDiceModal';
import ComparisonSelector from './ComparisonSelector';
import { TrainingTask, TrainingTaskState, ACCEPTANCE_CRITERIA_DICE, ACCEPTANCE_CRITERIA_HD95, ACCEPTANCE_CRITERIA_SDICE2, handleTaskState } from '../../../datasets/training-task';
import { saveFileOnUserDevice, sleep } from '../../../util';
import TaskGradeModal from '../TaskGradeModal';
import { anonymizeSlice, DicomMapAnonReal } from '../../../dicom/image_anonymization';
import _ from 'lodash';
import { MdCheckCircle, MdWarning } from 'react-icons/md';
import RunCalculationModal from '../dialogs/RunCalculationModal';


type OwnProps = {
    viewerState: ViewerState,
    onSaveAll: () => Promise<void>,
    structureSets: structureSet.StructureSet[],
    createNewStructureSet: (isComparison?: boolean) => void,
}

type DispatchProps = {
    startTask: (task: TrainingTask) => void,
    finishTask: (task: TrainingTask, data: any) => void,
    startUpdatingTask: () => void,
    finishUpdatingTask: () => void,
    canUserFinishTask: (task: TrainingTask, user: User) => Promise<[boolean, string | undefined]>,
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
    updateTask(task: TrainingTask, update: Partial<TrainingTask>): void,
}

interface AcceptanceCriteria {
    dice: number | null;
    sdice2: number | null;
    hd95: number | null;
    user_vol_mm3: number | null;
    gt_vol_mm3: number | null;
}

enum acType { 'dice', 'sdice2', 'hd95' }

export enum DifferencesMenu {
    Contours,
    Calculate
}

enum TaskActionType { Start, Finish, Resume, GradeTask }

type OwnState = {
    metrics: SimilarityMetrics[];
    showCalculationModal: boolean;
    differenceIsLoading: boolean;
    calcDiceIsLoading: boolean;
    showRunCalculationModal: boolean;
    saveTaskIsLoading: boolean;
    showTaskGradeModal: boolean;
}

type AllProps = OwnProps & StoreState & DispatchProps;

// Calculated Results 
const getFixedUserVolMm3 = (userVolCm3: number) => (userVolCm3 / 1000).toFixed(2);
const getFixedGtVolMm3 = (gtVolCm3: number) => (gtVolCm3 / 1000).toFixed(2);
const getFixedVolDiff = (userVolCm3: number, gtVolCm3: number) => (100 * (userVolCm3 / gtVolCm3 - 1)).toFixed(1);
const getFixedDice = (dice: number) => dice.toFixed(3);
const getFixedSDice2 = (sdice2: number) => sdice2.toFixed(3);
const getFixedHd95 = (hd95: number) => hd95.toFixed(1);


class DifferencesToolbar extends React.Component<AllProps, OwnState> {
    displayName = DifferencesToolbar.name

    constructor(props: AllProps) {
        super(props);

        this.state = {
            metrics: [],
            showCalculationModal: false,
            calcDiceIsLoading: false,
            differenceIsLoading: false,
            saveTaskIsLoading: false,
            showTaskGradeModal: false,
            showRunCalculationModal: false,
        };
    }

    componentDidMount = () => {
        // create a transient structure set for ROI comparisons when this component is mounted
        this.props.createNewStructureSet(true);
    }

    handleChangeTaskState = async (action: TaskActionType) => {
        let { testStructureSet, referenceStructureSet, structureSets } = this.props;
        const task = this.props.currentTask;
        const vs = this.props.viewerState;
        const image = vs.image;


        if (!testStructureSet) { throw new Error('No test structure set -- cannot calculate comparison metrics.'); }
        if (!referenceStructureSet) { throw new Error('No reference structure set -- cannot calculate comparison metrics.'); }

        if (!task) {
            throw new Error('No valid task');
        }
        // First, check if the user is allowed to start or resume this task, or is allowed to finish it.
        const isStartingTask = action === TaskActionType.Start && task.state === TrainingTaskState.NotStarted;
        const isWaitingForGrading = action === TaskActionType.GradeTask && task.state === TrainingTaskState.Finished;
        const isResumingTask = task.state === TrainingTaskState.Finished || task.state === TrainingTaskState.Graded;
        const [canUserFinishTask, errorMessage] = await this.props.canUserFinishTask(task, this.props.user!);

        // If the task is of type "TEST", the confirm message should reflect that it cannot be restarted.
        let confirmMessage;
        if (isResumingTask) {
            confirmMessage = 'Do you want to restart this task for further adjustments?';
        }
        if (!isStartingTask && !isWaitingForGrading && isResumingTask && task.type === 'TEST') {
            confirmMessage = 'Do you want to restart this task for further adjustments?';
        } else if (isWaitingForGrading) {
            // Add a separate message for when the task is about to be graded.
            confirmMessage = 'Do you want to grade this task?';
        } else if (!isStartingTask && !isResumingTask && task.type && task.state !== TrainingTaskState.Finished) {
            confirmMessage = 'Do you want to save and finish this task? You can no longer adjust it once it\'s been finished.';
        }


        if (isStartingTask) {
            console.log('Starting task...');
            this.props.startTask(task);
            vs.canEdit = true;
            this.setState({ metrics: [] });
        }
        else if (isWaitingForGrading) {
            console.log('Grading task...');
            this.setState({ showTaskGradeModal: true });
        }
        else if (isResumingTask) {
            console.log('Resuming task...');

            // filter out expert contours and comparison structure set if found. select trainee structure set
            const expertContoursIndex = vs.visibleStructureSets.findIndex(ss => ss.getLabel() === structureSet.ExpertContoursName);
            if (expertContoursIndex >= 0) {
                vs.visibleStructureSets.splice(expertContoursIndex, 1);
            }
            const comparisonRoiIndex = vs.visibleStructureSets.findIndex(ss => ss.getLabel() === structureSet.ComparisonStructureSetName);
            if (comparisonRoiIndex >= 0) {
                vs.visibleStructureSets.splice(comparisonRoiIndex, 1);
            }
            const traineeStructureSet = structureSets.find(ss => ss.structureSetId === task.traineeStructureSet.sopInstanceUid);
            if (traineeStructureSet) {
                vs.setSelectedStructureSet(traineeStructureSet, vs.image);
            } else {
                throw new Error(`Could not find trainee structure set ${task.traineeStructureSet.sopInstanceUid}!`);
            }


            if (window.confirm(confirmMessage)) {
                // restart the task
                if (task.type === 'TEST') {
                    this.props.updateTask(task, { state: TrainingTaskState.Started, grade: "UNGRADED" });
                } else {
                    this.props.startTask(task);
                }
                if (this.props.user && this.props.user.userId === task.trainee.user_id) {
                    vs.canEdit = true;
                }
                this.setState({ metrics: [] });
                this.props.addNotification(new SessionNotification(`task-${task.id}-state-changed-${Date.now()}`, 'Task Resumed', NotificationType.Success, undefined, DEFAULT_SESSION_TIMEOUT_IN_MS));
            }
        }
        else if (canUserFinishTask && window.confirm(confirmMessage)) {
            console.log('Finishing task...');
            // if task is Not Started, start it
            if (task.state === TrainingTaskState.NotStarted) {
                this.props.startTask(task);
            }

            // collect ROI names that we want to calculate metrics for
            const taskTraineeRoiNames = task ? task.traineeStructureSet.rois.map(r => r.roiName) : [];
            const referenceRoiNames = referenceStructureSet.getRois().map(r => r.name);
            const testRoisToCalculateMetricsFor = task ? testStructureSet.getRois().filter(r => taskTraineeRoiNames.includes(r.name) && referenceRoiNames.includes(r.name))
                : testStructureSet.getRois().filter(r => referenceRoiNames.includes(r.name));
            const roinames = testRoisToCalculateMetricsFor.map(r => r.name);

            const data = {
                'pixel_spacing': [image.iSpacing, image.jSpacing, image.kSpacing],
                'orientation': image.orientationMatrix.slice(0, 6),
                'min_patient_pos': image.cubePatient[0],
                'max_patient_pos': image.cubePatient[4],
                'n_slices': image.sliceIds.length,
                'shape': image.imShape,
                'roi_names': roinames.map(roiName => [roiName, roiName]),
            }

            try {
                // task update starts here
                this.props.startUpdatingTask();

                await sleep(500); // temporal: make UI a bit smoother when loading

                // save contouring changes first
                vs.canEdit = false;
                await this.props.onSaveAll();

                // wait a couple of seconds after saving rtstruct for the backend to catch up with its own I/O operations
                await sleep(5000);

                // set current task state to finished using api
                this.props.finishTask(task, data)
                this.setState({ metrics: [] });


                // HACK: currently 'canEdit' is being read from too many places to adjust elegantly just from react code
                // hence we're here manually setting vs.canEdit to FALSE in some specific circumstances, even though
                // that should technically be read from the combination of the state of current task and from the 
                // permissions of the current user. The correct state of the task will be initialized the next time
                // the app is reloaded.
                if (this.props.user && this.props.user.permissions.isTrainee) {
                    // note -- doesn't really matter if the update task API call above is successful or not, because the
                    // task should be FINISHED at this point anyway -- so just set canEdit to false in any case
                    vs.canEdit = false;
                }
            } catch (error) {
                this.props.finishUpdatingTask();
                console.error(error);
                throw new Error('Finishing task failed.');
            }
        }
        else {
            // also give the user an error message if one was received
            if (!canUserFinishTask && !isResumingTask) {
                this.props.addNotification(new SessionNotification(`finish-task-${task.id}-failed-${Date.now()}`, 'Task could not be finished.', NotificationType.Error, errorMessage));
            }
        }
    }

    handleCloseCalculationModal = () => {
        this.setState({ showCalculationModal: false });
    }

    handleSaveMetricsResult = async () => {
        const task = this.props.currentTask;
        if (!task) {
            throw new Error('No task -- cannot save similarity metrics');
        }
        let metrics = this.state.metrics;
        // update task traineeStructureSet rois similarityMetrics
        const traineeStructureSet = JSON.parse(JSON.stringify(task.traineeStructureSet));
        const rois = { ...traineeStructureSet.rois };
        rois.forEach((r: any, i: number) => {
            // assign the similarityMetrics to the corresponding roi
            metrics[i] = r.similarityMetrics;
        });
        this.setState({ metrics: metrics });
    }

    onShowDifferencesClick = async () => {
        const { testStructureSet, testRoi, referenceStructureSet, referenceRoi, viewerState } = this.props;
        if (!testStructureSet || !testRoi || !referenceStructureSet || !referenceRoi) {
            throw new Error('Two comparison structures must be selected to perform visual comparison');
        }

        this.setState({ differenceIsLoading: true });

        const comparisonRois = await viewerState.createComparisonRois(testRoi, referenceRoi);
        if (comparisonRois) {
            viewerState.compareROIs(comparisonRois);
            viewerState.focusOnStructure(comparisonRois.test, testStructureSet)
        }

        this.setState({ differenceIsLoading: false })
    }

    handleIsLoading = (calcDiceIsLoading: boolean) => {
        this.setState({ calcDiceIsLoading: calcDiceIsLoading });
    }

    handleShowDiceCalculation = (showModal: boolean) => {
        this.setState({ showRunCalculationModal: showModal });
        if (!showModal) {
        this.handleIsLoading(false);
        }
    }

    handleCalculateMetrics = async () => {
        const { viewerState, currentTask, testStructureSet, referenceStructureSet } = this.props;
        const { image } = viewerState;

        if (!testStructureSet) { throw new Error('No test structure set -- cannot calculate comparison metrics.'); }
        if (!referenceStructureSet) { throw new Error('No reference structure set -- cannot calculate comparison metrics.'); }

        if (testStructureSet === referenceStructureSet) {
            this.props.addNotification(new SessionNotification(`Same Structure Set Error`, `Test and Reference structure sets are the same -- cannot calculate comparison metrics within the same structure set`, NotificationType.Error));
            this.setState({ showCalculationModal: false })
            return;
        }
        this.handleShowDiceCalculation(false);
        this.handleIsLoading(true);

        // collect and map 'test' and 'reference' ROI names that we want to calculate metrics for
        const roiNameMap: { testRoiName: string, referenceRoiName: string }[] = [];

        const testRoiNames = currentTask ? currentTask.traineeStructureSet.rois.map(r => r.roiName) : testStructureSet.getRois().map(r => r.name);
        const referenceRoiNames = referenceStructureSet.getRois().map(r => r.name);
        for (const testRoiName of testRoiNames) {
            // find matching reference roi with same name, ignoring case AND accents
            // TODO: should accents NOT be ignored?
            const referenceRoiName = referenceRoiNames.find(r => r.localeCompare(testRoiName, undefined, { sensitivity: 'base' }) === 0);
            if (referenceRoiName) {
                roiNameMap.push({ testRoiName, referenceRoiName });
            }
        }
        if (!currentTask && roiNameMap.length === 0) {
            this.props.addNotification(new SessionNotification(`Error: No matching structures`, `There are no structures with matching names between the test and the reference structure sets. Structure set similarity cannot be calculated.`, NotificationType.Warning));
            this.setState({ showCalculationModal: false, calcDiceIsLoading: false })
            return;
        }

        // populate data to be used in the dice calculation
        const data = {
            'pixel_spacing': [image.iSpacing, image.jSpacing, image.kSpacing],
            'orientation': image.orientationMatrix.slice(0, 6),
            'min_patient_pos': image.cubePatient[0],
            'max_patient_pos': image.cubePatient[4],
            'n_slices': image.sliceIds.length,
            'shape': image.imShape,
            'roi_names': roiNameMap.map(m => [m.referenceRoiName, m.testRoiName]),
        }

        await sleep(500); // temporal: make UI a bit smoother when loading

        // anonmap isn't actually used or retained, we just need it for the call to anonymizeSlice
        const anonMap = new DicomMapAnonReal();
        const testBlob = new Blob([anonymizeSlice(structureSet.structureSetToDicom(testStructureSet, image), anonMap, true)]);
        const referenceBlob = new Blob([anonymizeSlice(structureSet.structureSetToDicom(referenceStructureSet, image), anonMap, true)]);

        // Call backend to get the calculation data
        try {
            const metrics = await rtViewerApiClient.computeMetrics(testBlob, referenceBlob, data);
            // set the state for the modal to show the dice calculation result
            this.setState({
                metrics: metrics,
                showCalculationModal: true,
                calcDiceIsLoading: false,
            });
        }
        catch (err) {
            const detailedMessage = _.get(err, 'message', undefined);
            this.props.addNotification(new SessionNotification(`metrics-calculation-failed-${Date.now().toString()}`, 'Could not calculate metrics.', NotificationType.Error, detailedMessage));
            this.setState({
                showCalculationModal: false,
                calcDiceIsLoading: false,
            });
        }
    }

    handleExportMetrics = async () => {
        const { metrics } = this.state;
        const { currentTask } = this.props;

        let includeAcDice = currentTask ? currentTask.gtStructureSet.rois.some(r => _.get(r.acceptanceCriteria, ACCEPTANCE_CRITERIA_DICE, undefined)) : false;
        let includeAcSDice2 = currentTask ? currentTask.gtStructureSet.rois.some(r => _.get(r.acceptanceCriteria, ACCEPTANCE_CRITERIA_SDICE2, undefined)) : false;
        let includeAcHD95 = currentTask ? currentTask.gtStructureSet.rois.some(r => _.get(r.acceptanceCriteria, ACCEPTANCE_CRITERIA_HD95, undefined)) : false;

        // create the header row
        const headerRow = [
            "Structure",
            "Test Structure Volume (cm3)",
            "Reference Structure Volume (cm3)",
            "Volume Difference (%)",
            "DSC",
            "S-DSC (@2mm)",
            "HD95 (mm)",
        ];

        if (includeAcDice) { headerRow.push('Acceptance Criteria for DSC'); }
        if (includeAcSDice2) { headerRow.push('Acceptance Criteria for S-DSC (@2mm)'); }
        if (includeAcHD95) { headerRow.push('Acceptance Criteria for HD95 (mm)'); }

        // create the data rows
        const dataRows = metrics.map((metric) => {
            const {
                user_roi_name,
                user_vol_mm3,
                gt_vol_mm3,
                dice,
                sdice2,
                hd95,
            } = metric;

            const acceptanceCriteria: any = [];
            const matchingRoi = currentTask ? currentTask.gtStructureSet.rois.find(r => r.roiName === user_roi_name) : undefined;
            if (matchingRoi && includeAcDice) { acceptanceCriteria.push(_.get(matchingRoi.acceptanceCriteria, ACCEPTANCE_CRITERIA_DICE, '')); }
            if (matchingRoi && includeAcSDice2) { acceptanceCriteria.push(_.get(matchingRoi.acceptanceCriteria, ACCEPTANCE_CRITERIA_SDICE2, '')); }
            if (matchingRoi && includeAcHD95) { acceptanceCriteria.push(_.get(matchingRoi.acceptanceCriteria, ACCEPTANCE_CRITERIA_HD95, '')); }

            return [
                user_roi_name,
                (user_vol_mm3 / 1000).toFixed(2),
                (gt_vol_mm3 / 1000).toFixed(2),
                (100 * (user_vol_mm3 / gt_vol_mm3 - 1)).toFixed(1),
                dice.toFixed(3),
                sdice2.toFixed(3),
                hd95.toFixed(1),
                ...acceptanceCriteria
            ];
        });

        // combine header and data rows into a single CSV content string
        const csvContent = [headerRow, ...dataRows].map(row => row.join(",")).join("\n");

        // create a blob out of the CSV content string
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        // download the CSV file 
        saveFileOnUserDevice(`similarity_metrics_${Date.now()}.csv`, blob);
    };

    handleTaskStateChange = async (eventKey: string) => {
        const { currentTask } = this.props;
        if (!currentTask) {
            throw new Error('No task -- cannot change task state');
        }
        try {
            if (eventKey === 'start' && currentTask.state !== TrainingTaskState.Started) {
                await this.handleChangeTaskState(TaskActionType.Start)
            } else if (eventKey === 'finish' && currentTask.state !== TrainingTaskState.Finished) {
                await this.handleChangeTaskState(TaskActionType.Finish)
            } else if (eventKey === 'grade') {
                this.setState({ showTaskGradeModal: true });
            }
        } catch (error) {
            console.error(error);
            this.props.addNotification(new SessionNotification(`task-${currentTask.id}-state-changed-${Date.now()}`, `Error changing task state: ${error.message}`, NotificationType.Error));
        }
    };

    handleShowMetrics = () => {
        if (this.props.currentTask) {
            // fill an array with the similarity metrics from each roi in the trainee structure set
            const metrics: SimilarityMetrics[] = [];

            this.props.currentTask.traineeStructureSet.rois.forEach(roi => {
                if (roi.similarityMetrics) {
                    metrics.push({ user_roi_name: roi.roiName, ...roi.similarityMetrics });
                }
            });
            this.setState({ metrics, showCalculationModal: true });
        }
        else {
            if (this.props.testStructureSet && this.props.referenceStructureSet) {
                this.setState({ metrics: [], calcDiceIsLoading: true });
            }

            if (!this.state.metrics) {
                this.setState({ showCalculationModal: false });
            }
            this.handleShowDiceCalculation(true);
        }
    }

    isChangeTaskStateButtonDisabled = (): boolean => {
        const { structureSetLocks, currentTask, user: userAny } = this.props;
        const user: User | undefined = userAny;

        if (this.props.isUpdatingTask) {
            return true;
        }

        if (user && user.permissions.isSupervisor) {
            return false;
        }

        // task state can be changed by supervisors or this task's trainee
        if (!currentTask || !user || (!user.permissions.isSupervisor && !user.isTaskTrainee(currentTask))) {
            return true;
        }

        // user must have lock to current task's trainee structure set in order to change task state
        const taskStructureSetId = currentTask.traineeStructureSet.sopInstanceUid;
        const canChangeTaskState = !!taskStructureSetId && !!structureSetLocks &&
            !!structureSetLocks[taskStructureSetId] && user.hasRTStructLock(structureSetLocks[taskStructureSetId]!);
        if (!canChangeTaskState) {
            return true;
        }

        // task must be in a state where it can be changed
        // (supervisors can adjust the state further in another view)
        if (currentTask.type === 'PRACTICE' && (currentTask.state === TrainingTaskState.NotStarted || currentTask.state === TrainingTaskState.Finished || currentTask.state === TrainingTaskState.Started)) {
            return false;
        } else if (currentTask.type === 'TEST' && (currentTask.state === TrainingTaskState.NotStarted || currentTask.state === TrainingTaskState.Started)) {
            return false;
        }

        return true;
    }

    getTaskActionType = (currentTask: TrainingTask): TaskActionType => {
        if (currentTask.state === TrainingTaskState.NotStarted) {
            return TaskActionType.Start;
        } else if (
            currentTask.state !== TrainingTaskState.Finished &&
            currentTask.state !== TrainingTaskState.Graded
        ) {
            return TaskActionType.Finish;
        } else if (currentTask.state === TrainingTaskState.Finished && this.props.user && this.props.user.permissions.isSupervisor) {
            return TaskActionType.GradeTask;        
        } else {
            return TaskActionType.Resume;
        }
    };

    getTaskButtonText = (currentTask: TrainingTask): string => {
        const actionType = this.getTaskActionType(currentTask);
        switch (actionType) {
            case TaskActionType.Start:
                return 'Start task';
            case TaskActionType.Finish:
                return 'Finish task';
            case TaskActionType.Resume:
                return 'Resume task';
            case TaskActionType.GradeTask:
                return 'Apply Grade...';
            default:
                return '';
        }
    };

    getIsTaskFinished = (): boolean => {
        const { currentTask, testStructureSet, referenceStructureSet } = this.props;
        if (!currentTask) {
            if (!testStructureSet || !referenceStructureSet || testStructureSet === referenceStructureSet) {
                return true;
            }
            return false;
        } else if (currentTask.state === TrainingTaskState.Finished || currentTask.state === TrainingTaskState.Graded) {
            return false;
        }
        return true;
    }

    getAcceptanceCriteria = (metric: SimilarityMetrics) => {
        const { currentTask } = this.props;
        let acceptanceCriteria: any = {};

        if (currentTask) {
            const gtRoi = currentTask.gtStructureSet.rois.find((roi) => roi.roiName === metric.user_roi_name);
            if (gtRoi) {
                acceptanceCriteria = gtRoi;
            }
        }
        return acceptanceCriteria;
    };


    renderIcon = (ac: AcceptanceCriteria, type: acType, value: number): JSX.Element | null => {
        const getIcon = (acProp: number | null, checkFunc: (a: number, b: number) => boolean): JSX.Element | null => {
            if (acProp === null || (type === acType.sdice2 && acProp === 0)) {
                return null;
            }
            const checkResult = checkFunc(value, acProp);
            return checkResult ? (
                <OverlayTrigger placement="left"
                    overlay={(<Tooltip id={'acprop'}>{acProp}</Tooltip>)}
                    delay={100}>
                    <MdCheckCircle size={20} color='#35dc71' style={{ marginLeft: '3px', marginBottom: '2px' }} />
                </OverlayTrigger>
            ) : (
                <OverlayTrigger placement="left"
                    overlay={(<Tooltip id={'acprop'}>{acProp}</Tooltip>)}
                    delay={100}>
                    <MdWarning size={20} color='#dc3545' style={{ marginLeft: '3px', marginBottom: '2px' }} />
                </OverlayTrigger>
            );
        };

        let icon: JSX.Element | null = null;

        switch (type) {
            case acType.dice:
                icon = getIcon(ac.dice, (a, b) => a >= b);
                break;
            case acType.sdice2:
                icon = getIcon(ac.sdice2, (a, b) => a >= b);
                break;
            case acType.hd95:
                icon = getIcon(ac.hd95, (a, b) => a <= b);
                break;
            default:
                break;
        }

        return icon;
    };      





    render() {
        const { testStructureSet, testRoi, referenceStructureSet, referenceRoi, currentTask, viewerState: vs, isUpdatingTask } = this.props;

        const isStructureSetComparisonDataAvailable = testStructureSet && referenceStructureSet && !isUpdatingTask;
        const isAllComparisonDataAvailable = isStructureSetComparisonDataAvailable && testRoi && referenceRoi;
        const isWaitingForGrading = this.props.user && this.props.user.permissions.isSupervisor && this.props.currentTask && this.props.user.userId === this.props.currentTask.supervisor.user_id && this.props.currentTask.state === TrainingTaskState.Finished;
        const isFinishingTaskDisabled = currentTask && (currentTask.state === TrainingTaskState.Finished || currentTask.state === TrainingTaskState.Graded)
        return (
            <Row className='differences-toolbar'>

                <div className='row-span'>

                    <ComparisonSelector viewerState={vs} task={currentTask} structureSets={this.props.structureSets} />

                    <div className='tool-buttons'>
                        <div>
                            <Button variant="light" className="btn btn-default btn-sm right-margin" onClick={this.handleShowMetrics} disabled={this.getIsTaskFinished()}>
                                Structure set similarity
                                {this.state.calcDiceIsLoading &&
                                    <Spinner animation="border" role="status" size="sm" className="inline-spinner">
                                        <span className="sr-only">Loading Results...</span>
                                    </Spinner>
                                }
                            </Button> </div>
                        <div><Button variant="light" className="btn btn-default btn-sm right-margin"
                            onClick={this.onShowDifferencesClick}
                            disabled={!isAllComparisonDataAvailable ||
                                this.getIsTaskFinished() ||
                                (currentTask && testStructureSet === referenceStructureSet)}>
                            Visual comparison
                            {this.state.differenceIsLoading &&
                                <Spinner animation="border" role="status" size="sm" className="inline-spinner">
                                    <span className="sr-only">Loading...</span>
                                </Spinner>
                            }
                        </Button>
                        </div>
                    </div>
                    <div className='float-right tool-buttons'>
                        <div>
                            {this.props.currentTask && (
                                this.props.currentTask.state === TrainingTaskState.Graded ? (
                                    `Task State: Graded (${this.props.currentTask.grade})`
                                ) : (
                                        `Task State: ${handleTaskState(this.props.currentTask.state)}`
                                )
                            )}
                        </div>
                        <div>
                            {this.props.currentTask && (
                                <>
                                    {this.props.user && this.props.user.permissions.isSupervisor && currentTask ? (
                                        <>
                                        <SplitButton
                                            as={ButtonGroup}
                                            title={this.getTaskButtonText(currentTask)}
                                            size="sm"
                                            variant="light"
                                            drop="down"
                                            id="bg-vertical-dropdown-3"
                                            onSelect={this.handleTaskStateChange}
                                            onClick={() => this.handleChangeTaskState(this.getTaskActionType(currentTask))}
                                            disabled={this.isChangeTaskStateButtonDisabled()}>
                                                <Dropdown.Item eventKey="start" disabled={currentTask.state === TrainingTaskState.Started ? true : false} className={currentTask.state === TrainingTaskState.Started ? 'disabled-btn' : ''}>Set task to <b>"Started"</b></Dropdown.Item>
                                                <Dropdown.Item eventKey="finish" disabled={isFinishingTaskDisabled ? true : false} className={isFinishingTaskDisabled  ? 'disabled-btn' : ''}>Set task to <b>"Finished"</b></Dropdown.Item>
                                            {currentTask && currentTask.state !== TrainingTaskState.Started && (
                                                <>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item eventKey="grade" className={isWaitingForGrading ? "grade-ready" : ""}>Grade task...</Dropdown.Item>
                                                </>
                                            )}
                                        </SplitButton>
                                            {isUpdatingTask && (
                                                <Spinner animation="border" role="status" size="sm" className="inline-spinner">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            )}
                                        </>
                                    ) : (
                                        currentTask &&
                                        <Button variant="light" className="btn btn-default btn-sm right-margin btn-darkblue" onClick={() => this.handleChangeTaskState(this.getTaskActionType(currentTask))} disabled={this.isChangeTaskStateButtonDisabled()}>
                                            {this.getTaskButtonText(currentTask)}
                                            {isUpdatingTask && (
                                                <Spinner animation="border" role="status" size="sm" className="inline-spinner">
                                                    <span className="sr-only">Loading...</span>
                                                </Spinner>
                                            )}
                                        </Button>
                                    )
                                    }
                                </>
                            )
                            }
                        </div>
                    </div>
                </div>


                {/* show the modal with the dice calculation result */}
                <ModalDialog show={this.state.showCalculationModal} onHide={this.handleCloseCalculationModal} className="modalTable" size='xl'>
                    <Modal.Header closeButton>
                        <Modal.Title>Calculation Results</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.metrics && (
                            <Table className="modalTable">
                                <thead>
                                    <tr>
                                        <th>Structure</th>
                                        <th>Test volume (cm<sup>3</sup>)</th>
                                        <th>Reference volume (cm<sup>3</sup>)</th>
                                        <th>Volume difference (%)</th>
                                        <th>DSC</th>
                                        <th>S-DSC (@2mm)</th>
                                        <th>HD95 (mm)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.metrics.map((metric, index) => {
                                        const ac = this.getAcceptanceCriteria(metric);
                                        let dice = null;
                                        let sdice2 = null;
                                        let hd95 = null;
                                        if (ac.acceptanceCriteria && currentTask) {
                                            dice = this.renderIcon(ac.acceptanceCriteria, acType.dice, metric.dice);
                                            sdice2 = this.renderIcon(ac.acceptanceCriteria, acType.sdice2, metric.sdice2);
                                            hd95 = this.renderIcon(ac.acceptanceCriteria, acType.hd95, metric.hd95);
                                        }
                                        return (
                                            <tr key={metric.user_roi_name || index}>
                                                <td>{metric.user_roi_name || `Structure ${index + 1}`}</td>
                                                <td>{getFixedUserVolMm3(metric.user_vol_mm3)}</td>
                                                <td>{getFixedGtVolMm3(metric.gt_vol_mm3)}</td>
                                                <td>{getFixedVolDiff(metric.user_vol_mm3, metric.gt_vol_mm3)}</td>
                                                <td>{getFixedDice(metric.dice)}{currentTask && dice}</td>
                                                <td>{getFixedSDice2(metric.sdice2)}{currentTask && sdice2}</td>
                                                <td>{getFixedHd95(metric.hd95)}{currentTask && hd95}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleExportMetrics}>Export Results</Button>
                        <Button variant="outline-secondary" onClick={this.handleCloseCalculationModal}>Close</Button>
                    </Modal.Footer>
                </ModalDialog>
                {this.state.showTaskGradeModal && currentTask && (
                    <TaskGradeModal
                        task={currentTask}
                        show={this.state.showTaskGradeModal}
                        handleClose={() => this.setState({ showTaskGradeModal: false })}
                    />
                )}
                {this.state.showRunCalculationModal && (
                    <RunCalculationModal show={this.state.showRunCalculationModal} handleCalculateMetrics={this.handleCalculateMetrics} handleClose={() => this.handleShowDiceCalculation(false)}  />)}
            </Row>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(DifferencesToolbar);
