import { ShaderProgram } from './ShaderProgram';
import { VAO } from '../objects/VertexArrayObject';
import { FrameBufferObject } from '../objects/FrameBufferObject';
import { UniformBoolean } from './uniforms/UniformBoolean';
import { UniformFloat } from './uniforms/UniformFloat';
import { UniformInt } from './uniforms/UniformInt';
import { Plane } from '../../view';
import { Sdf } from '../sdf/sdf';

export enum ColorModificationAlgorithm {
    NONE = 0,
    SUBTRACT = 1, //sampler.rgb - value
    DISTANCE_SQUARED = 2 //sqrt(sampler.rgb^2 - value^2)
}

export class QuadRenderer {

    private gl: WebGL2RenderingContext;
    private shader: QuadShader;
    private colorModificationShader: ColorModificationShader;
    private orientationShader: OrientationShader;
    private vao: VAO;

    private readonly MAX_DRAW_BUFFERS: number;

    constructor(gl: WebGL2RenderingContext){
        this.gl = gl;
        this.MAX_DRAW_BUFFERS = gl.getParameter(this.gl.MAX_DRAW_BUFFERS);
        this.shader = new QuadShader(gl);
        this.colorModificationShader = new ColorModificationShader(gl);
        this.orientationShader = new OrientationShader(gl, this.MAX_DRAW_BUFFERS);
        this.vao = new VAO(gl);
        this.vao.bind();
        this.vao.createAttribute(0, gl.FLOAT, [-1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0, -1.0], 2);
        this.vao.unbind();
    }

    public render(source?: FrameBufferObject | WebGLTexture, inverse: boolean = false){
        this.shader.start();

        this.vao.bind(0);
        if(source){
            if(source instanceof FrameBufferObject) {
                source.bindRenderTarget(this.gl.COLOR_ATTACHMENT0);
            } else {
                this.gl.bindTexture(this.gl.TEXTURE_2D, source);
            }
        }
        this.shader.inverse.loadBoolean(inverse);
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
        this.vao.unbind(0);
        this.shader.stop();
    }

    public renderColorModification(multiplier: number, source?: FrameBufferObject | WebGLTexture): void;

    public renderColorModification(multiplier: number, algorithm: ColorModificationAlgorithm, value: number, source?: FrameBufferObject | WebGLTexture): void;

    public renderColorModification(multiplier: number, algorithm?: ColorModificationAlgorithm, value?: number, source?: FrameBufferObject | WebGLTexture): void{
        this.colorModificationShader.start();
        this.vao.bind(0);
        if(source){
            if(source instanceof FrameBufferObject){
                source.bindRenderTarget(this.gl.COLOR_ATTACHMENT0);
            } else {
                this.gl.bindTexture(this.gl.TEXTURE_2D, source);
            }
        }
        this.colorModificationShader.algorithm.loadInt(algorithm ? algorithm : ColorModificationAlgorithm.NONE);
        this.colorModificationShader.multiplier.loadFloat(multiplier);
        if(value){
            this.colorModificationShader.value.loadFloat(value);
        }
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
        this.vao.unbind(0);
        this.colorModificationShader.stop();
    }

    /**
     * 
     * @param targetSdf 
     * @param plane 
     * TODO: Could perhaps move this method elsewhere
     */
    public renderOrientationModification(targetSdf: Sdf, plane: Plane){
        const NUMBER_OF_SLICES = targetSdf.size[2];
        this.orientationShader.start();
        this.orientationShader.plane.loadInt(plane);
        this.vao.bind(0);
        const fbo = new FrameBufferObject(this.gl, targetSdf.size[0], targetSdf.size[1]);
        const multiplier = plane !== Plane.Transversal ? targetSdf.resolutionMm / targetSdf.viewManager.image.kSpacing : 1.0; 
        for(let i = 0; i < NUMBER_OF_SLICES; ++i){
            this.orientationShader.sliceCoordinate.loadFloat((i+0.5)/NUMBER_OF_SLICES * multiplier);
            fbo.createTextureLayer(targetSdf.data, i, this.gl.COLOR_ATTACHMENT0);
            //TODO: If WebGL ever starts supporting Geometry Shaders, one instanced rendering call with gl_Layer
            this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
        }
        fbo.unbind();
        this.vao.unbind(0);
        this.orientationShader.stop();
    }

    public delete(){
        this.shader.delete();
        this.colorModificationShader.delete();
        this.orientationShader.delete();
        this.vao.delete();
    }
}

class QuadShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es

    precision highp float;

    in vec2 position;

    out vec2 pass_textureCoordinates;

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
        pass_textureCoordinates = vec2((position.x+1.0)*0.5, (position.y+1.0)*0.5);
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es

    precision highp float;

    in vec2 pass_textureCoordinates;

    out vec4 color;

    uniform sampler2D sampler;
    uniform bool inverse;

    void main() {
        if(inverse){
            color = vec4(vec3(1.0, 1.0, 1.0)-texture(sampler, pass_textureCoordinates).rgb, 1.0);
            //color = vec4(1.0, 0.0, 0.0, 1.0);
        } else {
            color = texture(sampler, pass_textureCoordinates);
        }
    }
    `;

    public inverse: UniformBoolean;

    constructor(gl: WebGL2RenderingContext){
        super(gl, QuadShader.VERTEX_CONTENT, QuadShader.FRAGMENT_CONTENT, "position");
        this.inverse = new UniformBoolean(gl, "inverse");
        super.storeUniformLocations(this.inverse);
    }
}

class ColorModificationShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es

    precision highp float;

    in vec2 position;

    out vec2 pass_textureCoordinates;

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
        pass_textureCoordinates = vec2((position.x+1.0)*0.5, (position.y+1.0)*0.5);
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es

    #define NONE 0
    #define SUBTRACT 1
    #define DISTANCE_SQUARED 2

    precision highp float;

    in vec2 pass_textureCoordinates;

    out vec4 color;

    uniform sampler2D sampler;
    uniform float multiplier;
    uniform float value;
    
    uniform int algorithm;

    void main() {
        vec3 original = texture(sampler, pass_textureCoordinates).rgb * multiplier;
        switch(algorithm){
            case NONE:
                break;
            case SUBTRACT:
                original -= value;
                break;
            case DISTANCE_SQUARED:
                original = sqrt(original * original + value * value);
                break;
        }
        color = vec4(original, 1.0);
    }
    `;

    public value: UniformFloat;
    public multiplier: UniformFloat;
    public algorithm: UniformInt;

    constructor(gl: WebGL2RenderingContext){
        super(gl, ColorModificationShader.VERTEX_CONTENT, ColorModificationShader.FRAGMENT_CONTENT, "position");
        this.value = new UniformFloat(gl, "value");
        this.multiplier = new UniformFloat(gl, "multiplier");
        this.algorithm = new UniformInt(gl, "algorithm");
        super.storeUniformLocations(this.value, this.multiplier, this.algorithm);
    }
}

class OrientationShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es
    #define transversal 0
    #define coronal 1
    #define sagittal 2
    
    precision highp float;

    in vec2 position;

    out vec3 pass_textureCoordinates;

    uniform float sliceCoordinate;
    uniform int plane;

    vec3 getCoordinate(vec2 coord){
        switch(plane){
            case transversal:
                return vec3(coord, sliceCoordinate);
            case coronal:
                return vec3(coord.x, sliceCoordinate, coord.y);
            case sagittal:
                return vec3(coord.y, sliceCoordinate, coord.x);
        }
    }

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
        pass_textureCoordinates = getCoordinate(vec2((position.x+1.0)/2.0, (position.y+1.0)/2.0));
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es
    
    precision highp float;
    precision highp sampler3D;

    in vec3 pass_textureCoordinates;

    out vec4 color;

    uniform sampler3D sampler;

    void main() {
        color = texture(sampler, pass_textureCoordinates);
    }
    `;

    public sliceCoordinate: UniformFloat;
    public plane: UniformInt;

    constructor(gl: WebGL2RenderingContext, MAX_DRAW_BUFFERS: number){
        super(gl, OrientationShader.VERTEX_CONTENT, OrientationShader.FRAGMENT_CONTENT, "position");
        this.sliceCoordinate = new UniformFloat(gl, "sliceCoordinate");
        this.plane = new UniformInt(gl, "plane");
        this.storeUniformLocations(this.sliceCoordinate, this.plane);
    }
}