// libtess doesn't have typings, no use converting this module to typescript

import libtess from 'libtess';

export class Tessellator {

    constructor() {

        const gluEnum = libtess.gluEnum

         // Triangle sub-tesselator, to be fed with boundary tesselation.
        let triTess = new libtess.GluTesselator()

        triTess.gluTessCallback(gluEnum.GLU_TESS_VERTEX_DATA, (vertex, arrays) => {
            arrays.triangles.push(vertex[0], vertex[1], vertex[2])
        });

        triTess.gluTessCallback(gluEnum.GLU_TESS_BEGIN, (type) => {
            if (type !== libtess.primitiveType.GL_TRIANGLES) {
                console.log(`GluTesselator: expected TRIANGLES but got type: ${type}`)
            }
            // TODO: remove this?
        });

        triTess.gluTessCallback(gluEnum.GLU_TESS_ERROR, (errno) => {
            console.log(`GluTesselator error: ${errno}`)
        });

        triTess.gluTessCallback(gluEnum.GLU_TESS_COMBINE, (coords, d, w) => {
//            console.log(`COMBINE: ${coords}, ${d}, ${w}`)
            return [coords[0], coords[1], coords[2]]
            // TODO: what's the default?
        });

        triTess.gluTessCallback(gluEnum.GLU_TESS_EDGE_FLAG, (flag) => {
//            console.log(`EDGE: ${flag}`)
            // TODO: do we need to specify this?
        });


        // Main tesselator produces boundaries (outlines) and feeds the results to
        // the triangle-generating tesselator above.
        let tess = new libtess.GluTesselator()
        tess.gluTessProperty(gluEnum.GLU_TESS_BOUNDARY_ONLY, true)
        let firstVertexIndex = 0

        tess.gluTessCallback(gluEnum.GLU_TESS_BEGIN_DATA, (type, arrays) => {
            if (type !== libtess.primitiveType.GL_LINE_LOOP) {
                console.log(`GluTesselator: expected GL_LINE_LOOP but got type: ${type}`)
            }
            firstVertexIndex = arrays.edges.length
            arrays.edges.push(Number.NaN) // used to recognize line boundaries
            triTess.gluTessBeginContour()
        });

        tess.gluTessCallback(gluEnum.GLU_TESS_VERTEX_DATA, (vertex, arrays) => {
            // Converts LINE_LOOP -> LINES before insertion to arrays.edges.
            const edges = arrays.edges
            const len = edges.length
            switch (len % 6) {
                case 0: // continue previous vertex
                    edges.push(...edges.slice(len - 3, len))
                    // fallthrough
                case 3:
                    edges.push(vertex[0], vertex[1], vertex[2])
                    break
                case 1: // line boundary - replace NaN
                case 4:
                    edges[len - 1] = vertex[0]
                    edges.push(vertex[1], vertex[2])
                    break
                default:
                    break
            }
            arrays.points.push(vertex[0], vertex[1], vertex[2])
            this.triTess.gluTessVertex(vertex, vertex)
        });

        tess.gluTessCallback(gluEnum.GLU_TESS_END_DATA, (arrays) => {
            // connect first and last vertex
            // FIXME: which was the first?
            const edges = arrays.edges
            const len = edges.length
            edges.push(...edges.slice(len - 3, len))
            edges.push(...edges.slice(firstVertexIndex, firstVertexIndex + 3))
            this.triTess.gluTessEndContour()
        });

        tess.gluTessCallback(gluEnum.GLU_TESS_ERROR, (errno) => {
            console.log(`GluTesselator error: ${errno}`)
        });

        tess.gluTessCallback(gluEnum.GLU_TESS_COMBINE, (coords, d, w) => {
//            console.log(`COMBINE: ${coords}, ${d}, ${w}`)
            return [coords[0], coords[1], coords[2]]
            // TODO: what's the default?
        });

        tess.gluTessCallback(gluEnum.GLU_TESS_EDGE_FLAG, (flag) => {
//            console.log(`EDGE: ${flag}`)
            // TODO: do we need to specify this?
        });


        this.triTess = triTess;
        this.tess = tess;
    }

    tessellate(contours, orientationPatient) {
        const normal = cross( orientationPatient.slice(0, 3), orientationPatient.slice(3, 6))
        this.tess.gluTessNormal(...normal);
        const arrays = {
            triangles: [],
            points: [],
            edges: [],
        }
    
        // As overlap is unknown, we tesselate all input plane contours as a single
        // polygon.
    
        this.triTess.gluTessBeginPolygon(arrays)
        this.tess.gluTessBeginPolygon(arrays)
    
        for (const contour of contours) {
            this.tess.gluTessBeginContour()
        
            for (var i = 0; i < contour.length; i += 3) {
                const coords = contour.slice(i, i + 3)
                this.tess.gluTessVertex(coords, coords)
            }
        
            this.tess.gluTessEndContour()
        }
    
        this.tess.gluTessEndPolygon()
        this.triTess.gluTessEndPolygon()
        
        return arrays;
    }
}

export function cross (a, b) {
    return [
      a[1] * b[2] - a[2] * b[1],
      a[2] * b[0] - a[0] * b[2],
      a[0] * b[1] - a[1] * b[0]
    ]
}