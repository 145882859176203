import * as localStorage from '../local-storage';

export class WindowLevel { // Class will be serialized in local storage, don't add methods.
    public ww: number;
    public wc: number;
    constructor(ww: number, wc: number) {
        this.ww = ww;
        this.wc = wc;
    }
}

export const PresetSoftTissue = new WindowLevel(400, 20);
export const DefaultWindowLevelPresets: { [name: string]: WindowLevel } = {
    "CT Abdomen": new WindowLevel(400, 40),
    "CT Bone": new WindowLevel(1600, 500),
    "CT Brain": new WindowLevel(85, 30),
    "CT Breast": new WindowLevel(400, -50),
    "CT Dental": new WindowLevel(3000, 900),
    "CT Liver": new WindowLevel(160, 50),
    "CT Lung": new WindowLevel(1600, -600),
    "CT Metal": new WindowLevel(1, 2800),
    "CT Neck": new WindowLevel(325, 65),
    "CT Pelvis": new WindowLevel(500, 0),
    "CT Prostate": new WindowLevel(210, -35),
    "CT Soft tissue": new WindowLevel(350, 40),
    "MR a": new WindowLevel(385, 190),
    "MR b": new WindowLevel(2900, 1600),
}

export function getWindowLevelPresets(): { [name: string]: WindowLevel } {
    let presets = localStorage.getWindowLevelPresets() || {};
    if (Object.keys(presets).length === 0) {
        presets = Object.assign(presets, DefaultWindowLevelPresets);
    }
    return presets;
}

export function setWindowLevelPreset(name: string, windowLevel: WindowLevel) {
    let presets = getWindowLevelPresets();
    presets[name] = windowLevel;
    localStorage.setWindowLevelPresets(presets);
}

export function deleteWindowLevelPreset(name: string) {
    let presets = getWindowLevelPresets();
    delete presets[name];
    localStorage.setWindowLevelPresets(presets);
}
