import { TrainingTask } from "./training-task";
import { User } from "../store/user";

export enum LockAction {
    Lock, Unlock
}

export type RTStructLock = {
    user_id: string,
    user_name: string,
    storageAccount: string,
    fileShare: string,
    patientId: string,
    forUid: string,
    seriesInstanceUid: string,
    sopInstanceUid: string,
}

export type RTStructLockDictionary = { [structureSetId: string]: RTStructLock | undefined };

// type of structureSet lock that the current client user is requesting (locking or unlocking)
export type StructureSetLockRequests = { [structureSetId: string]: LockAction }

/** Generates a lock request object for trainee structure set in a task */
export const generateRTStructLockForTask = (task: TrainingTask, user: User): RTStructLock => {
    return {
        user_id: user.userId,
        user_name: user.username,
        storageAccount: task.storageAccount,
        fileShare: task.fileShare,
        patientId: task.patientId,
        forUid: task.forUid,
        seriesInstanceUid: task.traineeStructureSet.seriesInstanceUid,
        sopInstanceUid: task.traineeStructureSet.sopInstanceUid,
    };
}

/** Returns true if given lockDictionary has a valid lock for the specified structure set for the given user, false otherwise. */
export function getUserCanEditStructureSet(user: User, structureSetId: string, lockDictionary: RTStructLockDictionary): boolean {
    return lockDictionary[structureSetId] ? user.hasRTStructLock(lockDictionary[structureSetId]!) : false;
}
