export const BOOLEAN_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec3 v_texcoord;
uniform mat4 orientation;

void main()
{
    v_texcoord = (orientation * vec4(in_texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const BOOLEAN_FS = `#version 300 es
precision mediump float;
precision mediump int;

precision mediump sampler3D;
uniform sampler3D textureData;

uniform float inverse; // 0 = false, 1 = true
in vec3 v_texcoord;
out vec4 color;

void main()
{
   vec4 col = texture(textureData, v_texcoord);
   if(inverse > 0.5) {
      color = vec4(1.0 - col.r, col.g, col.b, col.a);
   }
   else {
      color = col;
   }
}
`;