import { DatasetGradings } from "./roi-grading";
import { AzureFileClient } from "../web-apis/azure-file-client";
import { getDateTimeString } from "../util";
import { AzureFileInfo, AzureShareInfo } from "../web-apis/azure-files";

// Backup functionality for critical json files. (used just for grading sheets as of now,
// plenty of other critical files are being automatically backed up during azure file
// operations -- see azure-file-client.tsx -> saveBlob())

const BACKUP_STORAGE = "rtviewerbackup";
const GRADINGS_BACKUP_SHARE = "gradings";


export async function backupGradings(gradings: DatasetGradings, sourceShare: AzureShareInfo, user: string): Promise<void> {
    const storage = BACKUP_STORAGE;
    const shareName = GRADINGS_BACKUP_SHARE;
    const path = sourceShare.toString();
    const filename = getDateTimeString(new Date()) + "-" + user + ".json";
    const fileInfo = new AzureFileInfo(storage, shareName, path, filename);
    const content = JSON.stringify(gradings, null, 4);

    try {
        const client = new AzureFileClient(storage, { username: user, skipTemporaryFilesWhenSaving: true });
        await client.createDirIfNotExists(fileInfo.getPath());
        await client.saveToFile(fileInfo, content);
    }
    catch (err) {
        console.error(`An error occurred when trying to back up gradings file ${fileInfo.toString()}:`);
        console.log(err);
        throw err;
    }
}
