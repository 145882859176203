import { MouseToolBase } from "./mouse-tool-base";
import { View } from "../view";

export default class SelectTool extends MouseToolBase {

    handleMouseDown(view: View, mousePoint: number[]): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;

        // don't allow selection while doing visual comparisons
        if (!vs.isComparisonStructureSetSelected()) {
            vs.setSelectedRoi(vs.hoveredRoi);
            vs.setHoveredRoi(null);
        }
    }

    handleHover(view: View, mousePointMm: number[]): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;
        let roiOrNull = vs.findPointedRoi(mousePointMm);
        vs.setHoveredRoi(roiOrNull);
    }


    handleMouseLeave(view: View): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;
        
        vs.setHoveredRoi(null);
    }
}