import React, { PureComponent } from 'react'
import { FormControl } from 'react-bootstrap'
import _ from 'lodash'

type OwnProps = {
    roiNumber: number,
    roiName: string,
    dice?: number | null,
    sdice?: number | null,
    hd95?: number | null,
    onDiceChanged: (roiName: string, value: number | undefined) => void,
    onSDiceChanged: (roiName: string, value: number | undefined) => void,
    onHD95Changed: (roiName: string, value: number | undefined) => void,
}

export type StructureSelection = {
    roiNumber: number,
    roiName: string,
    dice?: number | null,
    sdice?: number | null,
    hd95?: number | null
}

type AllProps = OwnProps

export default class SimilarityMetricsTableRow extends PureComponent<AllProps> {

    getNumber = (value: string): number | undefined => {
        const nr = parseFloat(value);
        return _.isNaN(nr) ? undefined : nr;
    }

    handleDiceChanged = (event: any) => {
        let val = event.target.value;
        this.props.onDiceChanged(this.props.roiName, this.getNumber(val));
    }
    handleSDiceChanged = (event: any) => {
        let val = event.target.value;
        this.props.onSDiceChanged(this.props.roiName, this.getNumber(val));
    }
    handleHD95Changed = (event: any) => {
        let val = event.target.value;
        this.props.onHD95Changed(this.props.roiName, this.getNumber(val));
    }

    render() {
        return (
            <tr>
                <td>{this.props.roiNumber}</td>
                <td>{this.props.roiName}</td>
                <td><FormControl type='number' step={0.01}  defaultValue={this.props.dice ? this.props.dice.toString() : undefined} onChange={this.handleDiceChanged} /* disabled={this.props.dice ? true : false} */ /></td>
                <td><FormControl type='number' step={0.01} defaultValue={this.props.sdice ? this.props.sdice.toString() : undefined} onChange={this.handleSDiceChanged} /* disabled={this.props.sdice ? true : false} */ /></td>
                <td><FormControl type='number' step={0.01} defaultValue={this.props.hd95 ? this.props.hd95.toString() : undefined} onChange={this.handleHD95Changed} /* disabled={this.props.hd95 ? true : false} */ /></td>
            </tr>
        )
    }
}
