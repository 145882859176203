import React from 'react';
import { Axis, AxisNames } from '../../rtviewer-core/view';
import { num2str } from '../../util';

import './CoordinateOverlay.css';

type OwnProps = {
    depthAxis: Axis,
    depth: number,
    x: number,
    y: number,
    z: number,
    i: number,
    j: number,
    k: number,
    value: number,
    className?: string,
}

const PATIENT_POS_UNIT = 'mm';

export default class CoordinateOverlay extends React.Component<OwnProps> {

    renderDepthAndVoxelValue = (renderValue: boolean) => {
        return (<div className="coordinate-list">
            {renderValue && (<span className="coordinate-block">vxl: <span className="coordinate no-decimals">{num2str(Math.round(this.props.value), 0)}</span>, </span>)}
            <span className="coordinate-block">{AxisNames[this.props.depthAxis]}: <span className="coordinate no-decimals">{num2str(this.props.depth, 0)}</span></span>
        </div>);
    }

    renderImIndices = () => {
        return (
            <div className="coordinate-list">
                <span><span className="coordinate-section">S:</span>
                    (<span>
                        <span className="coordinate-block">I: <span className="coordinate no-decimals">{num2str(this.props.i, 0)}</span>,</span>
                        <span className="coordinate-block">J: <span className="coordinate no-decimals">{num2str(this.props.j, 0)}</span>,</span>
                        <span className="coordinate-block">K: <span className="coordinate no-decimals">{num2str(this.props.k, 0)}</span></span>
                    </span>)</span>
            </div>
        );
    }

    renderPatientPos = () => {
        return (
            <div className="coordinate-list">
                <span><span className="coordinate-section">P:</span>
                    (<span>
                        <span className="coordinate-block">X: <span className="coordinate decimals">{num2str(this.props.x, 1)}</span>,</span>
                        <span className="coordinate-block">Y: <span className="coordinate decimals">{num2str(this.props.y, 1)}</span>,</span>
                        <span className="coordinate-block">Z: <span className="coordinate decimals">{num2str(this.props.z, 1)}</span></span>
                    </span>)</span>
                <span className="coordinate unit">{PATIENT_POS_UNIT}
                </span>
            </div>
        );
    }

    render() {
        const { x, y, z, i, j, k, value } = this.props;

        let className = 'coordinate-overlay';
        if (this.props.className) { className += ` ${this.props.className}` };

        const areCoordinatesValid = [x, y, z, i, j, k, value].every(n => !isNaN(n));

        return (
            <div className={className}>
                {areCoordinatesValid && this.renderPatientPos()}
                {areCoordinatesValid && this.renderImIndices()}
                {this.renderDepthAndVoxelValue(areCoordinatesValid)}
            </div>
        );

    }
}
