import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { StoreState } from '../../../store/store';
import * as sagas from '../../../store/sagas';
import './SearchInput.css'



type DispatchProps = {
  searchTasks(searchText: string): void;
}

type AllProps = DispatchProps & StoreState;

class TasksTableSearchInput extends Component<AllProps> {

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const searchText = event.target.value;
    this.props.searchTasks(searchText);
  }

  render() {
    return (
      <Form.Group controlId="searchInput" className='mb-0'>
        <Form.Control
          type="text"
          placeholder="Search tasks"
          value={this.props.searchText}
          className='search-input'
          onChange={(e: any) => this.handleInputChange(e)}
        />
      </Form.Group>
    );
  }
}


export default connect(
  state => Object.assign({}, state),
  sagas.mapDispatchToProps
)(TasksTableSearchInput);

