import React from 'react';

import DatasetTableRow from './DatasetTableRow';
import { TriStateCheckboxState } from '../../common/TriStateCheckbox';
import { PageImage, PagePatient } from '../models/PagePatient';
import { Dataset } from '../../../datasets/dataset';
import { BatchJobOperation } from './DatasetPage';
import { DatasetImage } from '../../../datasets/dataset-image';

import './DatasetTable.css';
import { Table } from 'react-bootstrap';
import { LockAction } from '../../../datasets/locks';


type OwnProps = {
    dataset: Dataset;
    pagePatients: PagePatient[],
    areBatchColumnsVisible: boolean,
    showBatchControls?: boolean,
    batchSelections: { [checkboxId: string]: TriStateCheckboxState },
    batchSelectionOperations: { [checkboxId: string]: BatchJobOperation },
    getCheckboxId: (patientId: string, imageId?: string, structureSetId?: string) => string,
    handleTriStateCheckboxChange: (checkboxId: string, event: any) => void,
    handleLockClick: (datasetImage: DatasetImage, lockAction: LockAction) => void,
    handleLoadClick: (datasetImage: DatasetImage) => void,
    handleUnloadClick: (datasetImage: DatasetImage) => void,
    handleViewImageClick: (datasetImage: DatasetImage) => void,
    handleBatchOperationTypeChange: (value: { checkboxId: string, operation: BatchJobOperation }) => void,
    handleShowAllImagesClick: (image: PageImage) => void,
    handleShowAllStructureSetsClick: (image: PageImage) => void,
}

type AllProps = OwnProps;


export default class DatasetTable extends React.PureComponent<AllProps> {
    displayName = DatasetTable.name

    render() {
        const { pagePatients, dataset,
            areBatchColumnsVisible, batchSelections, getCheckboxId, handleTriStateCheckboxChange,
            handleLockClick,
            handleLoadClick, handleUnloadClick, handleViewImageClick } = this.props;
        
        return (
            <Table className="dataset-table"hover borderless size="md" responsive="sm">
                <thead>
                    <tr>
                        <th>Patient id</th>
                        <th>Series description</th>
                        <th>Modality</th>
                        <th>Structure sets</th>
                        <th>Locks</th>
                        <th>Load</th>
                        <th>View</th>
                        {areBatchColumnsVisible && (<th>Batch operation</th>)}
                    </tr>
                </thead>
                <tbody>
                    {pagePatients.filter(p => !p.isFilteredOut).map((pagePatient, patientIndex) => (
                        pagePatient.getImages().map((pageImage, imageIndex, allImages) => (
                            <DatasetTableRow
                                key={`${pageImage.seriesId}_${pageImage.image.seriesDescription}`}
                                dataset={dataset}
                                image={pageImage}
                                isLastImageOfList={allImages.length - 1 === imageIndex}
                                moreItemsAvailable={pagePatient.hasMoreImages()}
                                areBatchColumnsVisible={areBatchColumnsVisible}
                                patientIndex={patientIndex}
                                imageIndex={imageIndex}
                                batchSelections={batchSelections}
                                batchSelectionOperations={this.props.batchSelectionOperations}
                                getCheckboxId={getCheckboxId}
                                handleTriStateCheckboxChange={handleTriStateCheckboxChange}
                                handleLockClick={handleLockClick}
                                handleLoadClick={handleLoadClick}
                                handleUnloadClick={handleUnloadClick}
                                handleViewImageClick={handleViewImageClick}
                                showBatchControls={this.props.showBatchControls}
                                handleBatchOperationTypeChange={this.props.handleBatchOperationTypeChange}
                                handleShowAllImagesClick={this.props.handleShowAllImagesClick}
                                handleShowAllStructureSetsClick={this.props.handleShowAllStructureSetsClick}
                            />
                        ))))}
                </tbody>
            </Table>
        );
    }

}