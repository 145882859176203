// Toolbar above the dicom viewer grid

import React from 'react';
import { Button, ButtonGroup, ButtonToolbar, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { StoreState } from '../../../store/store';
import { connect } from 'react-redux';
import * as sagas from '../../../store/sagas';
import { WindowLevelToolbar } from './WindowLevelToolbar';
import ContouringToolbar  from './ContouringToolbar';
import { ViewerState } from '../../../rtviewer-core/viewer-state';

import './MainToolbar.css';
import { PreferenceToolbar } from './PreferenceToolbar';
import { InfoToolbar } from './InfoToolbar';
import { stopKeyboardPropagation } from '../../../react-util';
import DifferencesToolbar from './DifferencesToolbar';
import { StructureSet, ExpertContoursName } from '../../../dicom/structure-set';
import routePaths from '../../../routes';
import { Workspace } from '../../../store/work-state';
import TaskDetailsToolbar from './TaskDetailsToolbar';


type OwnProps = {
    viewerState: ViewerState,
    onSaveAll: () => Promise<void>,
    createNewStructureSet: (isComparison?: boolean) => void,
    structureSets: StructureSet[],
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    refreshSwitch?: any,
}

export enum MainMenu {
    Select, WindowLevel, Contouring, Preferences, Info, Differences, Details
}

class MainToolbar extends React.Component<AllProps, OwnState> {
    displayName = MainToolbar.name

    constructor(props: AllProps) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.viewerState.addListener(this.updateView);
    }

    componentWillUnmount() {
        this.props.viewerState.removeListener(this.updateView);
    }
    
    updateView = () => {
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    handleKeyDown = (e: React.KeyboardEvent) => {
        // prevent keystrokes from falling through any text fields in the toolbar
        stopKeyboardPropagation(e);
    }

    isContouringEnabled = () => {
        const { viewerState, currentTask, structureSetLocks, user } = this.props;
        const canEdit = viewerState.canEdit && viewerState.selectedStructureSet !== null;
        
        if (viewerState.comparisonStructureSet !== null && viewerState.selectedStructureSet === viewerState.comparisonStructureSet) {
            // never allow contouring of the comparison structure set
            return false;
        }

        // get current workspace
        const workspace = this.props.currentWorkState ? this.props.currentWorkState.workspace : Workspace.None;
        
        // special handling for the Guide app (it has two separate workspaces)
        if (workspace === Workspace.GuidelineTraining || workspace === Workspace.ReferenceLibrary) {

            const ss = viewerState.selectedStructureSet;
            if (!ss) { return false; }
            
            // 1. never allow modifications to expert contours
            if (ss.dataset.StructureSetLabel === ExpertContoursName) {
                return false;
            }

            // 2. never allow modifications without a lock
            if (!structureSetLocks || !user || structureSetLocks[ss.structureSetId] === undefined || structureSetLocks[ss.structureSetId]!.user_id !== user.userId) {
                return false;
            }

            // 3. only allow trainee to modify trainee task rois
            if (currentTask) {
                const traineeRois = currentTask.traineeStructureSet.rois.map(r => r.roiName);
                if (viewerState.selectedRoi && traineeRois.includes(viewerState.selectedRoi.name) && user.userId !== currentTask.trainee.user_id ) {
                    return false;
                }
            }
        }

        return canEdit;
    };


    render() {
        let vs = this.props.viewerState;
        let vm = vs.viewManager;
        let menu = vs.mainMenuSelection;

        function reset() {
            vs.resetWindowLevel();
            vm.resetViews();
        }
        const showNotForClinicalUseDisclaimer = !!this.props.currentWorkState && [Workspace.None, Workspace.Annotation].includes(this.props.currentWorkState.workspace);
        const isContouringDisabled = !this.isContouringEnabled();

        return (
            <div className="main-toolbar" onKeyDown={this.handleKeyDown}>
                <div className="toolbar">
                    <div className="toolbar-top">
                        <ButtonToolbar className="image-toolbar">
                            <ToggleButtonGroup className="mr-2" type="radio" name="options" value={menu} onChange={(value: MainMenu) => vs.setMainMenuSelection(value)}>
                                <ToggleButton variant="light" className="btn btn-sm pr-3 pl-3 toggle-btn" value={MainMenu.Select}>Pan/Select</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-sm pr-3 pl-3 toggle-btn" value={MainMenu.WindowLevel}>Windowing</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-sm pr-3 pl-3 toggle-btn" value={MainMenu.Contouring} disabled={isContouringDisabled}>Contouring</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-sm pr-3 pl-3 toggle-btn" value={MainMenu.Preferences}>Preferences</ToggleButton>
                                <ToggleButton variant="light" className="btn btn-sm pr-3 pl-3 toggle-btn" value={MainMenu.Info}>Measurements</ToggleButton>
                                {window.location.pathname.includes(routePaths.referenceLibrary) ? null : (this.props.trainingTasks) && <ToggleButton variant="light" className="btn btn-sm pr-3 pl-3 toggle-btn" value={MainMenu.Differences}>{this.props.currentTask ? 'Evaluation' : 'Comparison'}</ToggleButton>}
                                {window.location.pathname.includes(routePaths.referenceLibrary) || window.location.pathname.includes(routePaths.verify) ? null : (this.props.trainingTasks) && <ToggleButton variant="light" className="btn btn-sm pr-3 pl-3 toggle-btn" value={MainMenu.Details}>Task Details</ToggleButton>}
                                
                            </ToggleButtonGroup>
                            <ButtonGroup className="mr-2">
                                <Button variant="light" className="btn btn-default btn-sm" onClick={reset}>Reset</Button>
                            </ButtonGroup>
                            {showNotForClinicalUseDisclaimer && (<span className="disclaimer-not-for-clinical-use">Not for clinical use!</span>)}
                        </ButtonToolbar>

                        
                    </div>

                    <div className="tool-area">
                        {menu === MainMenu.WindowLevel && <WindowLevelToolbar viewerState={vs} />}
                        {menu === MainMenu.Preferences && <PreferenceToolbar viewerState={vs} />}
                        {menu === MainMenu.Contouring && <ContouringToolbar viewerState={vs} />}
                        {menu === MainMenu.Info && <InfoToolbar viewerState={vs} />}
                        {menu === MainMenu.Differences && <DifferencesToolbar viewerState={vs} onSaveAll={this.props.onSaveAll} structureSets={this.props.structureSets} createNewStructureSet={this.props.createNewStructureSet}  />}
                        {menu === MainMenu.Details && <TaskDetailsToolbar viewerState={vs} structureSets={this.props.structureSets} />}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(MainToolbar);