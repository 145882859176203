export abstract class Uniform {
    
    protected gl: WebGL2RenderingContext;
    protected name: string;
    protected location?: WebGLUniformLocation;

    constructor(gl: WebGL2RenderingContext, name: string){
        this.gl = gl;
        this.name = name;
    }

    public storeUniformLocation(programID: WebGLProgram): void {
        this.location = this.gl.getUniformLocation(programID, this.name) || undefined;
        if(!this.location){
            throw new Error("Attempted to load uniform location '" +this.name +"' that does not exist");
        }
    }
}