import { } from "immer";
import Immerable from "./immerable";
import _ from "lodash";

export enum TransferredFileType {
    ImageSlice, StructureSet
}

export class TransferredFile extends Immerable {

    ready: boolean;
    fileType?: TransferredFileType;
    scanId?: string;
    structureSetId?: string;

    constructor() {
        super();
        this.ready = false;
    }
}

class FileTransferTask extends Immerable {

    progressPercentage: number;
    files: { [filename: string]: TransferredFile };
    ready: boolean;
    failed: boolean;
    error: any;

    constructor() {
        super();
        this.progressPercentage = 0;
        this.files = {};
        this.ready = false;
        this.failed = false;
        this.error = null;
    }
}

export class UploadTask extends FileTransferTask {
}

export class DownloadTask extends FileTransferTask {
}

export type DownloadTaskCollection = { [downloadKey: string]: DownloadTask };

export type LocalFileProgress = {
    progress: number;
    total: number;
};

export const isLocalFileProgress = (obj: any): obj is LocalFileProgress => {
    return _.isNumber(obj['progress']) && _.isNumber(obj['total']);
}