import {View, Viewport, Axis, Plane} from "./view";
import { Image } from "../dicom/image";
import * as util from '../util';
import { ViewerState } from "./viewer-state";
import { BoundingBox } from "../math/bounding-box";
import { WEBGL_CANVAS_CLASS } from "../components/rtviewer/ViewGrid";
import { SdfOperations } from './webgl/sdf/boolean/sdf-operations';
import { SdfSlice } from './webgl/sdf/sdf-slice';
import { mouseTools } from "./mouse-tools/mouse-tools";

export enum ViewPosition {
    Left,
    TopRight,
    TopBottom
}

export enum ViewMode {
    SingleView,
    ThreeViews,
}

export class ViewManager {
    public viewerState: ViewerState;
    public image: Image;
    public viewMode: ViewMode;
    public mainPlane: Plane;
    public maxSlice: {[axis: number]: number};
    public views: View[];
    public zooming: number;
    public panning: {[axis: number]: number};
    private scrolling: {[axis: number]: number};

    // All dimensions are any type because they are not set definitely in the constructor method (though constructor sets them indirectly)
    private totalWidth: any;
    private totalHeight: any;
    public leftAreaWidth: any;
    private leftAreaWidthFromUI?: number; // Set by user by dragging. Try to maintain this size when window is resized
    public leftAreaMinWidth: any;
    public leftAreaMaxWidth: any;
    public rightTopAreaHeight: any;
    private rightTopAreaHeightFromUI?: number // Set by user by dragging. Try to maintain this size when window is resized
    public rightTopAreaMinHeight: any;
    public rightTopAreaMaxHeight: any;
    private listeners: (() => void)[];

    constructor(viewerState: ViewerState, totalWidth: number, totalHeight: number, img: Image) {
        this.viewerState = viewerState;
        this.image = img;
        this.viewMode = ViewMode.ThreeViews;
        this.mainPlane = Plane.Transversal;
        this.maxSlice = {
            [Axis.X]: img.iPixels - 1,
            [Axis.Y]: img.jPixels - 1,
            [Axis.Z]: img.kPixels - 1,
        };
        this.zooming = 1.0;
        this.panning = {
            [Axis.X]: 0,
            [Axis.Y]: 0,
            [Axis.Z]: 0
        }
        this.scrolling = {
            [Axis.X]: 0.5,
            [Axis.Y]: 0.5,
            [Axis.Z]: 0.5,
        };
        this.views = [];
        this.listeners = [];
        this.resize(totalWidth, totalHeight);
    }

    public getWebGlContext(): WebGL2RenderingContext {
        let canvas = document.getElementsByClassName(WEBGL_CANVAS_CLASS)[0] as HTMLCanvasElement;
        if (!canvas) { throw new Error('Could not retrieve webgl canvas'); }
        let gl = canvas.getContext( 'webgl2', { antialias: false } );
        if(!gl) {
            const error = 'WebGL 2 is not available';
            alert(error);
            throw new Error(error);
        }
        return (gl as WebGL2RenderingContext);
    }

    public resize(totalWidth: number, totalHeight: number) {
        this.totalWidth = Math.round(totalWidth);
        this.totalHeight = Math.round(totalHeight);
        this.leftAreaMinWidth = Math.round(totalWidth * 0.45);
        this.leftAreaMaxWidth = Math.round(totalWidth * 0.8);
        this.leftAreaWidth = Math.round( util.clamp( this.leftAreaWidthFromUI || totalWidth * 0.6, this.leftAreaMinWidth, this.leftAreaMaxWidth) );
        
        this.rightTopAreaMinHeight = Math.round(totalHeight / 2.7);
        this.rightTopAreaMaxHeight = Math.round(totalHeight - this.rightTopAreaMinHeight);
        this.rightTopAreaHeight = Math.round(util.clamp( this.rightTopAreaHeightFromUI || (totalHeight * 0.5), this.rightTopAreaMinHeight, this.rightTopAreaMaxHeight));
        
        this.createViews();
    }

    public setSingleView(mainPlane: Plane): void {
        this.mainPlane = mainPlane;
        this.viewMode = ViewMode.SingleView;
        this.createViews();
    }

    public setThreeViews(mainPlane: Plane): void {
        this.mainPlane = mainPlane;
        this.viewMode = ViewMode.ThreeViews;
        this.createViews();
    }

    public setLeftAreaWidth(w: number) {
        w = util.clamp(w, this.leftAreaMinWidth, this.leftAreaMaxWidth);
        w = Math.round(w);
        this.leftAreaWidthFromUI = w;
        this.leftAreaWidth = w;
        this.createViews();
    }

    public setRightTopAreaHeight(h: number) {
        h = util.clamp(h, this.rightTopAreaMinHeight, this.rightTopAreaMaxHeight);
        h = Math.round(h);
        this.rightTopAreaHeightFromUI = h;
        this.rightTopAreaHeight = h;
        this.createViews();
    }

    public addListener(f: () => void) {
        this.listeners.push(f);
    }

    public removeListener(f: () => void) {
        this.listeners = this.listeners.filter(ff => ff !== f);
    }

    public resetViews() {
        this.viewMode = ViewMode.ThreeViews;
        this.mainPlane = Plane.Transversal;
        this.zooming = 1.0;
        this.panning = {
            [Axis.X]: 0,
            [Axis.Y]: 0,
            [Axis.Z]: 0
        }
        this.scrolling = {
            [Axis.X]: 0.5,
            [Axis.Y]: 0.5,
            [Axis.Z]: 0.5,
        };
        this.leftAreaWidthFromUI = undefined;
        this.rightTopAreaHeightFromUI = undefined;
        this.resize(this.totalWidth, this.totalHeight);
    }

    public getScrolledSlice(axis: Axis) {
        return Math.floor(this.scrolling[axis] * (this.maxSlice[axis]+1));
    }

    public scrollToSlice(sliceId: string) {
        const i = this.image.sliceIds.findIndex(id => id === sliceId);
        if(i === -1) return;
            
        this.scrolling[Axis.Z] = (i+0.5) / ( this.maxSlice[Axis.Z] + 1 );
        this.createViews();
    }

    public scroll(axis: Axis, up: boolean) {
        let step = 1 / (this.maxSlice[axis] + 1);
        if(!up) step = -step;
        this.scrolling[axis]  = this.scrolling[axis] + step;
        this.createViews();
    }

    public scrollToPosition(axis: Axis, pos: number) {
        this.scrolling[axis] = pos;
        this.createViews();
    }

    public getScrollPositions() {
        return {
            [Axis.X]: (0.5 + this.getScrolledSlice(Axis.X)) / (this.maxSlice[Axis.X] + 1),
            [Axis.Y]: (0.5 + this.getScrolledSlice(Axis.Y)) / (this.maxSlice[Axis.Y] + 1),
            [Axis.Z]: (0.5 + this.getScrolledSlice(Axis.Z)) / (this.maxSlice[Axis.Z] + 1)
        };
    }

    public getScrollPositionsMm() {
        // returns image indices
        const img = this.image;
        
        const interpolate = (ratio: number, min: number, max: number) => {
            return min + (max - min) * ratio;
        }
        // console.log([this.getScrolledSlice(Axis.X), this.maxSlice[Axis.X], img.iMin, img.iMax, img.iMin + img.iSizeMM])
        // console.log([this.getScrolledSlice(Axis.Y), this.maxSlice[Axis.Y], img.jMin, img.jMax, img.jMin + img.jSizeMM])
        return {
            [Axis.X]: interpolate(this.getScrolledSlice(Axis.X) / (this.maxSlice[Axis.X]), img.iMin, img.iMax - 1),
            [Axis.Y]: interpolate(this.getScrolledSlice(Axis.Y) / (this.maxSlice[Axis.Y]), img.jMin, img.jMax - 1),
            [Axis.Z]: interpolate(this.getScrolledSlice(Axis.Z) / (this.maxSlice[Axis.Z]), img.kMin, img.kMax - 1),
        }
    }

    public zoom(up: boolean) {
        let zoomFactor = 1.15;
        let oldZoom = this.zooming;
        let minZoom = 1 / Math.pow(zoomFactor, 7);
        let maxZoom = Math.pow(zoomFactor, 14);
        this.zooming = up ? Math.min(maxZoom, oldZoom * zoomFactor) : Math.max(minZoom, oldZoom / zoomFactor);
        if(!up) { // scale down panning when zooming down
            let ratio = oldZoom / this.zooming;
            this.panning[Axis.X] /= ratio;
            this.panning[Axis.Y] /= ratio;
            this.panning[Axis.Z] /= ratio;
        }
        this.createViews();
    }

    // Google maps style of zooming where the focus point stays under mouse cursor after zooming
    // relative x and y coordinates, between [0,1]
    public zoomAndPanToPoint(up: boolean, view: View, x: number, y: number) {
        let vp = view.drawnViewport;
        let xIm = vp.width ? (x - vp.left) / vp.width : 0;
        let yIm = vp.height ? (y - vp.top) / vp.height : 0;

        this.zoom(up);

        view = (this.views.find(v => v.plane === view.plane) as View);
        vp = view.drawnViewport;
        let x2 = vp.left + xIm * vp.width;
        let y2 = vp.top + yIm * vp.height;

        this.pan(view, x-x2, y-y2);
        this.scrollToPosition(view.directions[0].axis, view.directions[0].inverted ? 1 - xIm : xIm);
        this.scrollToPosition(view.directions[1].axis, view.directions[1].inverted ? 1 - yIm : yIm);
    }

    public pan(view: View, px: number, py: number) {
        let dirX = view.directions[0];
        let dirY = view.directions[1];
        px *= (dirX.inverted ? -1 : 1);
        py *= (dirY.inverted ? -1 : 1);
        this.panning[dirX.axis] += px;
        this.panning[dirY.axis] += py;
        let scrollX = px  / (view.drawnViewport.width || 1 );
        let scrollY = py  / (view.drawnViewport.height || 1 );
        this.scrolling[dirX.axis]  -= scrollX;
        this.scrolling[dirY.axis]  -= scrollY;
        this.createViews();
    }

    // Scroll to the center of the roi bounding box
    public focusToRoi(bb: BoundingBox) {
        const img = this.image;
        const imgBb = img.getRealBoundingBox();
        const centerXmm = (bb.maxI + bb.minI) / 2;
        const centerYmm = (bb.maxJ + bb.minJ) / 2;
        const centerZmm = (bb.maxK + bb.minK) / 2;
        
        this.scrolling[Axis.X] = (centerXmm - imgBb.minI) / (imgBb.getXSize() || 1);
        this.scrolling[Axis.Y] = (centerYmm - imgBb.minJ) / (imgBb.getYSize() || 1);
        this.scrolling[Axis.Z] = (centerZmm - imgBb.minK) / (imgBb.getZSize() || 1);
        this.createViews();
    }

    private createViews() { 
        if(this.viewMode === ViewMode.SingleView) {
            let viewport = new Viewport(0, 0, this.totalWidth, this.totalHeight);
            let plane = this.mainPlane;
            this.views = [new View(this, viewport, plane)];
        }
        else if(this.viewMode === ViewMode.ThreeViews) {
            let rightAreaWidth = this.totalWidth - this.leftAreaWidth;
            let rightBottomAreaHeight = this.totalHeight - this.rightTopAreaHeight;
            let viewportArray = [
                new Viewport(0, 0, this.leftAreaWidth, this.totalHeight),
                new Viewport(this.leftAreaWidth, rightBottomAreaHeight, rightAreaWidth, this.rightTopAreaHeight),
                new Viewport(this.leftAreaWidth, 0, rightAreaWidth, rightBottomAreaHeight) ];

            let planes = [Plane.Transversal, Plane.Coronal, Plane.Sagittal];
            if(this.mainPlane === Plane.Coronal) {
                planes = [Plane.Coronal, Plane.Transversal, Plane.Sagittal];
            }
            else if (this.mainPlane === Plane.Sagittal) {
                planes = [Plane.Sagittal, Plane.Transversal, Plane.Coronal];
            }
            this.views = [
                new View(this, viewportArray[0], planes[0]),
                new View(this, viewportArray[1], planes[1]),
                new View(this, viewportArray[2], planes[2])
            ];
        }
        this.listeners.forEach(f => f());
    }

    /**
     * Method that will copy data to clipboard.<br>
     * Currently only contour data may be copied
     */
    public copyToClipboard(): void {
        if(this.viewerState.selectedStructureSet){
            const roi = this.viewerState.selectedRoi;
            if(roi && roi.sdf){
                const slice = this.getScrolledSlice(Axis.Z);
                const currentZ = slice * this.image.kSpacing + this.image.kMin;
                if(currentZ >= roi.sdf.boundingBox.minK && currentZ <= roi.sdf.boundingBox.maxK){
                    const roiSliceID = Math.floor((currentZ - roi.sdf.boundingBox.minK) / this.image.kSpacing);
                    let copy = new SdfSlice(this, roi.sdf.resolutionMm, roi.sdf.maxDistanceMm);
                    copy.createTexture(roi.sdf.size[0], roi.sdf.size[1]);
                    copy.setBoundingBox(roi.sdf.boundingBox.copy());
                    new SdfOperations(this).copySdfSlice(roi.sdf, roiSliceID, copy);
                    this.viewerState.clipboard.copy(copy);
                }
            }
        }
    }

    /**
     * Method that will handle paste command
     * @param data Data that is being pasted
     */
    public pasteFromClipboard(data: any): void {
        if(this.viewerState.canEdit && data && data instanceof SdfSlice){
            if(this.viewerState.selectedStructureSet && this.viewerState.selectedStructureSet.canEdit()){
                let roi = this.viewerState.selectedRoi;
                const slice = this.getScrolledSlice(Axis.Z);
                const currentZ = slice * this.image.kSpacing + this.image.kMin;
                if(this.viewerState.activeMouseTools.includes(mouseTools.brush) && mouseTools.brush.brushBuffer){
                    new SdfOperations(this).pasteSdfSlice(this.viewerState.clipboard.paste(), mouseTools.brush.brushBuffer.sdf, currentZ);
                }
                if(roi && roi.sdf && roi.canEdit()){
                    this.viewerState.undoStack.pushRoiStateBeforeEdit(roi);
                    roi.sdf = new SdfOperations(this).pasteSdfSlice(this.viewerState.clipboard.paste(), roi.sdf, currentZ, roi);
                    roi.setContoursChanged(null);
                    this.createViews();
                }
            }
        }
    }

    public updateBrushBuffer(): void {
        if(this.viewerState.activeMouseTools.includes(mouseTools.brush) && mouseTools.brush.brushBuffer){
            mouseTools.brush.updateSdf();
        }
    }
}

