import { MouseToolBase, MouseCursor } from "./mouse-tool-base";
import { View } from "../view";
import { mouseTools } from "./mouse-tools";
import { Roi } from "../../dicom/structure-set";
import { Sdf, getRoiSdfResolution } from "../webgl/sdf/sdf";
import { BoundingBox } from "../../math/bounding-box";
import { ViewManager } from "../view-manager";
import { SdfDrawing } from "../webgl/sdf/sdf-drawing";
import { SdfOperations } from "../webgl/sdf/boolean/sdf-operations";


export class BrushBuffer {
    sdf: Sdf;
    drawnBB: BoundingBox;

    constructor(sdf: Sdf, bb: BoundingBox) {
        this.sdf = sdf;
        this.drawnBB = bb.copy();
    }
}
export default class BrushTool extends MouseToolBase {

    private viewManager?: ViewManager | null;
    private mousePointMm: number[] | null;
    public roi: Roi | null;
    private view: View | null;
    public brushBuffer: BrushBuffer | null;
    public isDrawing: boolean;

    constructor() {
        super();
        this.mousePointMm = null;
        this.roi = null;
        this.view = null;
        this.brushBuffer = null;
        this.isDrawing = false;
    }

    createDrawBuffer() {
        const vm = this.viewManager;
        if(!vm || !this.roi) return;
        let vs = vm.viewerState;
        let img = vs.image;
        let ss = vs.selectedStructureSet;
        if(!ss) return;
        const resolution = getRoiSdfResolution(img, this.roi);
        let imgBB = new BoundingBox();
        imgBB.resetToImageDimensions(vm.image);
        
        if(this.roi.sdf) {
            const sdfOps = new SdfOperations(vm);
            let sdf = sdfOps.copy(this.roi.sdf, imgBB, false);
            this.brushBuffer = new BrushBuffer(sdf, this.roi.sdf.boundingBox.copy());
        }
        else {
            let sdf = new Sdf(vm, resolution );
            sdf.createTexture(imgBB, true, false);
            this.brushBuffer = new BrushBuffer(sdf, new BoundingBox());
        }
        vs.notifyListeners();
    }

    updateSdf(): void {
        if(this.viewManager && this.roi && this.roi.sdf){
            const sdfOps = new SdfOperations(this.viewManager);
            let boundingBox = new BoundingBox();
            boundingBox.resetToImageDimensions(this.viewManager.image);
            let sdf = sdfOps.copy(this.roi.sdf, boundingBox, false);
            this.brushBuffer = new BrushBuffer(sdf, this.roi.sdf.boundingBox.copy());
        }
    }

    handleActivate(vm: ViewManager) {
        this.viewManager = vm;
        this.roi = vm.viewerState.selectedRoi;
        this.createDrawBuffer();
    }

    handleDeactivate() {
        this.mousePointMm = null;
        this.roi = null;
        this.view = null;
        this.brushBuffer = null;
    }

    handleRoiSelected(roi: Roi | null) {
        if(!roi) {
            this.roi = null;
            this.mousePointMm = null;
            this.brushBuffer = null;
        }
        else if(roi !== this.roi) {
            this.roi = roi;
            this.createDrawBuffer();
        }
    }


    drawOnCanvas(canvas: HTMLCanvasElement, view: View) {
        let ctx = canvas.getContext('2d');
        let pt = this.mousePointMm;
        if(!ctx || !pt || !this.roi || !this.view || (this.view.plane !== view.plane)) {
            return;
        }
        let vm = view.viewManager;
        let vs = vm.viewerState;

        //Clipping rect
        ctx.save();
        let vp = view.availableViewport;
        ctx.beginPath();
        const top = (canvas.height - vp.top - vp.height );
        ctx.moveTo( vp.left, top ); // top left
        ctx.lineTo( vp.left + vp.width, top ); // top right
        ctx.lineTo( vp.left + vp.width, top + vp.height ); // bottom right
        ctx.lineTo( vp.left, top + vp.height ); // bottom left
        ctx.lineTo( vp.left, top ); // top left
        ctx.clip();

        // Brush circle
        pt = view.getPointInCanvasCoord(canvas, pt);
        ctx.fillStyle = this.roi.rgb();
        ctx.beginPath();
        ctx.arc(pt[0], pt[1], vs.brushWidthMm / 2 * view.pixelsPerMm, 0, 2 * Math.PI);
        ctx.fill();
        ctx.restore();
    }

    getMouseCursor(view: View) {
        const vs = view.viewManager.viewerState;
        return this.roi && this.roi.canEdit() ? (vs.erase ? MouseCursor.Eraser : MouseCursor.Pencil) : MouseCursor.Pointer;
    }

    handleMouseDown(view: View, mousePointMm: number[], mouseButton: number): void {
        if(mouseButton === 0){
            let vm = view.viewManager;
            let vs = vm.viewerState;
            let ss = vs.selectedStructureSet;
            let roi = vs.selectedRoi;
            if(!roi || !roi.canEdit()) {
                mouseTools.select.handleMouseDown(view, mousePointMm);
                return;
            }
            if(!ss || !this.brushBuffer) {
                return
            }
            this.isDrawing = true;
            const imageBb = vs.image.getRealBoundingBox();
            if( !imageBb.isPointInside(mousePointMm[0], mousePointMm[1], mousePointMm[2]) ){
                return;
            }
            this.mousePointMm = mousePointMm;
            this.view = view;

            const gl = vm.getWebGlContext() as any;
            const sdfDrawing = new SdfDrawing(gl);
            sdfDrawing.drawBrushStroke(this.brushBuffer, view, mousePointMm);
            vs.notifyListeners();
        }
    }

    handleMouseUp(view: View) {
        const vm = view.viewManager;
        const vs = vm.viewerState;
        const img = vs.image;
        const ss = vs.selectedStructureSet;
        const roi = vs.selectedRoi;

        if(!roi || !ss || !this.brushBuffer) {
            return;
        }
        if(!this.brushBuffer.drawnBB.isEmpty()) {
            vs.undoStack.pushRoiStateBeforeEdit(roi);
            const sdfOps = new SdfOperations(vm);
            let bb = this.brushBuffer.drawnBB.copy();
            bb.roundToFullPixels(img);
            roi.sdf = sdfOps.copy(this.brushBuffer.sdf, bb, false);
            const contoursChangedInSlices = img.getSliceIdsForArea(this.brushBuffer.drawnBB);
            roi.setContoursChanged(contoursChangedInSlices);
            ss.unsaved = true;
        }
        
        this.isDrawing = false;
    }

    handleHover(view: View, mousePointMm: number[]): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;
        this.roi = vs.selectedRoi;
        if(!this.roi || !this.roi.canEdit()) {
            mouseTools.select.handleHover(view, mousePointMm);
            return;
        }
        this.mousePointMm = mousePointMm;
        this.view = view;
        vs.notifyListeners();
    }

    handleDrag(view: View, mousePointMm: number[], diff: number[]): void {
        const vm = view.viewManager;
        if(this.isDrawing && this.brushBuffer && this.view){
            const vs = vm.viewerState;
            const gl = vm.getWebGlContext();
            const sdfDrawing = new SdfDrawing(gl);
            sdfDrawing.drawBrushStroke(this.brushBuffer, this.view, mousePointMm);
            vs.notifyListeners();
        } else {
            vm.pan(view, diff[0], diff[1]);
        }
    }

    handleMouseLeave(view: View): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;
        this.mousePointMm = null;
        vs.notifyListeners();
    }

    // Brush width
    handleScroll(view: View, up: boolean, ctrlKey: boolean, shiftKey: boolean): boolean { 
        let vm = view.viewManager;
        let vs = vm.viewerState;
        if(shiftKey) {
            let x = 1.33;
            let newVal = Math.round(up ? vs.brushWidthMm * x : vs.brushWidthMm / x);
            vs.setBrushWidth( newVal );
            return true;
        }
        return false; 
    } 

    handleEsc(view: View) {
        this.isDrawing = false;
        this.createDrawBuffer();
    }

    handleAlt(view: View) { 
        const vs = view.viewManager.viewerState;
        vs.setErase(!vs.erase);
    }
}