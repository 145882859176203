import Immerable from "./immerable";

export enum ContouringTaskState {
  NotStarted, UploadingFiles, PollingForResults, DownloadingFiles, Success, Failed, Error
}

export class ContouringTask extends Immerable {

  scanId: string;
  contouringState: ContouringTaskState;
  imagesUploaded: number;
  totalImagesToUpload: number;
  uploadStartTime: Date | null;
  uploadFinishTime: Date | null;
  taskFinishTime: Date | null;
  errorMessage: string;
  isDismissed: boolean;
  matchingStructureSetIds: string[] | null;

  constructor(scanId: string) {
    super();
    this.scanId = scanId;
    this.contouringState = ContouringTaskState.NotStarted;
    this.imagesUploaded = 0;
    this.totalImagesToUpload = 0;
    this.uploadStartTime = null;
    this.uploadFinishTime = null;
    this.taskFinishTime = null;
    this.errorMessage = '';
    this.isDismissed = false;
    this.matchingStructureSetIds = null;
  }
}