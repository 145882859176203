import { GradingWorkflowState } from "../../../datasets/roi-grading";

export enum FilterOperator { And, Or }

export default class DatasetFilter {
    public operator: FilterOperator;
    public caseSensitive: boolean;
    public useOriginalRoiNames: boolean;
    public filterGradingStates: GradingWorkflowState[];
    public values: string[];

    constructor(filterText: string, operator: FilterOperator, caseSensitive: boolean, useOriginalRoiNames: boolean, filterGradingStates: GradingWorkflowState[]) {
        this.operator = operator;
        this.caseSensitive = caseSensitive;
        this.useOriginalRoiNames = useOriginalRoiNames;
        this.filterGradingStates = filterGradingStates;
        this.values = filterText.split("\n").join(",").split(",").map(s => s.trim()).filter(s => s !== ""); // split by newline and comma
    }
}
