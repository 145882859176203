import { Vector3f } from '../../../../math/Vector3f';
import { Uniform } from './Uniform';

export class UniformVec3 extends Uniform {

    loadVec3(vector: Array<number> | {x: number, y: number, z: number} | Vector3f){
        if(this.location){
            if(vector instanceof Array) {
                this.gl.uniform3fv(this.location, vector);
            } else {
                this.gl.uniform3f(this.location, vector.x, vector.y, vector.z);
            }
        }
    }
}