import { AzureStorageAccountInfo } from "../web-apis/azure-files";
import Immerable from "./immerable";
import { DownloadKeyTrackable } from "./requests";

export class LoginError extends Immerable {
    arePopupsBlocked: boolean;
    errorMessage: string;

    constructor(arePopupsBlocked: boolean, errorMessage?: string) {
        super();

        this.arePopupsBlocked = arePopupsBlocked;
        this.errorMessage = errorMessage || '';
    }
}

export interface DownloadError extends DownloadKeyTrackable {
    error: any;
}

export class AzureDownloadError extends Immerable implements DownloadError {
    azureInfo: AzureStorageAccountInfo;
    downloadKey: string;
    error: any;

    constructor(azureInfo: AzureStorageAccountInfo, downloadKey: string, error: any) {
        super();

        this.azureInfo = azureInfo;
        this.downloadKey = downloadKey;
        this.error = error;
    }
}

export class ApiDownloadError extends Immerable implements DownloadError {
    path: string;
    downloadKey: string;
    error: any;

    constructor(path: string, downloadKey: string, error: any) {
        super();

        this.path = path;
        this.downloadKey = downloadKey;
        this.error = error;
    }
}
