import React, { Component } from 'react';
import Select, { OptionsType } from 'react-select';
import { connect } from 'react-redux';
import { StoreState } from '../../../store/store';
import * as sagas from '../../../store/sagas';
import { Filter, SortType } from '../../../datasets/training-task';
import { SortOption, FilterOption } from './TasksPage';
import _ from 'lodash';

type OwnProps = {
    options: OptionsType<any>;
    placeholder: string;
    isMulti?: boolean;
    filters?: Filter[];
    isClearable?: boolean;
}

type DispatchProps = {
    filterTasks?(filters: Filter[]): void;
    sortTasks(sortType: SortType): void;
}

type AllProps = StoreState & OwnProps & DispatchProps;
class TasksTableSortSelect extends Component<AllProps> {

    handleValue = (): OptionsType<any> | any => {
        if (this.props.isMulti && this.props.filters && this.props.filters.length > 0) {
            return this.props.options.filter(o => this.props.filters!.includes(o.value));
        } else if (!this.props.isMulti && this.props.sortType) {
            return this.props.options.find(o => o.value === this.props.sortType);
        }
        return null;
    };


    handleChange = (option: SortOption | FilterOption[]) => {
        if (this.props.filterTasks && this.props.isMulti && _.isArray(option)) {
            const filters = option.map((o) => o.value) as Filter[];
            this.props.filterTasks(filters);
        } else if (!this.props.isMulti && option && !_.isArray(option) && option.value) {
            this.props.sortTasks(option.value);
        } else if (!option) {
            // If no filter option is selected, clear the filters.
            this.props.filterTasks && this.props.filterTasks([]);
        }
    };

    render() {
        return (
            <Select
                options={this.props.options}
                width="100%"
                placeholder={this.props.placeholder}
                onChange={this.handleChange}
                value={this.handleValue()}
                isMulti={this.props.isMulti}
                isSearchable={true}
                isClearable={this.props.isClearable}
                styles={{
                    control: (provided) => ({
                        ...provided,
                        borderColor: '#f3f3f3',
                        boxShadow: '0 0 0 1px #f3f3f3',
                        backgroundColor: '#2c2c2c',
                        '&:hover': {
                            backgroundColor: '#2c2c2c',
                        },
                    }),
                    menu: (provided) => ({
                        ...provided,
                        backgroundColor: '#2c2c2c',
                    }),
                    option: (provided) => ({
                        ...provided,
                        backgroundColor: '#2c2c2c',
                        '&:hover': {
                            backgroundColor: '#444444',
                        },
                    }),
                    multiValue: (provided) => ({
                        ...provided,
                        color: '#2C2C2C',
                    }),
                }}
            />
        );

    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(TasksTableSortSelect);
