import _ from "lodash";

const QUERY_PARAM_STORAGE_ACCOUNT = 'sa';
const QUERY_PARAM_FILE_SHARE = 'fs';
const QUERY_PARAM_PATIENT_ID = 'p';
const QUERY_PARAM_FRAME_OF_REFERENCE = 'for';
const QUERY_PARAM_SERIES_INSTANCE = 'si';
const QUERY_PARAM_DEFAULT_STRUCTURE_SET = 'dss';
const QUERY_PARAM_TASK_ID = 't';

export enum UrlQueryType {
    AnnotationWorkQuery = 'AnnotationWorkQuery',
    AnnotationIndexQuery = 'AnnotationIndexQuery',
    ReferenceLibraryIndexQuery = 'ReferenceLibraryIndexQuery',
    TaskWorkQuery = 'TaskWorkQuery',
};

export function isUrlQueryType(val: any): val is UrlQueryType {
    return val in UrlQueryType;
}

/** a regex of all characters that are valid in query parameters */
const VALID_QUERY_CHARACTERS = /^[- a-zA-Z0-9._]*$/;

/** Models a direct link to a specific RTViewer view or work. */
export class UrlQuery {

    public storageAccount?: string;
    public fileShare?: string;
    /** this property is set, but is currently unused */
    public patientId?: string;
    /** this property is set, but is currently unused */
    public frameOfReferenceUid?: string;
    public imageSeriesInstanceUid?: string;
    public defaultStructureSetUid?: string;
    public taskId?: string;

    constructor(queryType: UrlQueryType.AnnotationWorkQuery, storageAccount: string, fileShare: string, patientId: string, frameOfReferenceUid: string, imageSeriesInstanceUid: string, defaultStructureSetUid?: string)
    constructor(queryType: UrlQueryType.AnnotationIndexQuery, storageAccount: string, fileShare?: string)
    constructor(queryType: UrlQueryType.ReferenceLibraryIndexQuery, storageAccount: string, fileShare?: string)
    constructor(queryType: UrlQueryType.TaskWorkQuery, taskId: string)
    constructor(queryParameters: URLSearchParams | string)
    constructor(queryParam: URLSearchParams | string | UrlQueryType, ...params: string[]) {
        if (isUrlQueryType(queryParam)) {
            switch (queryParam) {
                case UrlQueryType.AnnotationIndexQuery:
                case UrlQueryType.ReferenceLibraryIndexQuery:
                    this.storageAccount = params[0];
                    this.fileShare = params.length > 1 ? params[1] : undefined;
                    break;
                case UrlQueryType.TaskWorkQuery:
                    this.taskId = params[0];
                    break;
                case UrlQueryType.AnnotationWorkQuery:
                    this.storageAccount = params[0];
                    this.fileShare = params[1];
                    this.patientId = params[2];
                    this.frameOfReferenceUid = params[3];
                    this.imageSeriesInstanceUid = params[4];
                    this.defaultStructureSetUid = params.length > 5 ? params[5] : undefined;
                    break;
                default:
                    throw new Error(`Unsupported UrlQueryType (${queryParam})`);
            }
        } else {
            const qp: URLSearchParams = new URLSearchParams(queryParam);

            this.storageAccount = this.getSupportedQueryParameter(qp, QUERY_PARAM_STORAGE_ACCOUNT);
            this.fileShare = this.getSupportedQueryParameter(qp, QUERY_PARAM_FILE_SHARE);
            this.patientId = this.getSupportedQueryParameter(qp, QUERY_PARAM_PATIENT_ID);
            this.frameOfReferenceUid = this.getSupportedQueryParameter(qp, QUERY_PARAM_FRAME_OF_REFERENCE);
            this.imageSeriesInstanceUid = this.getSupportedQueryParameter(qp, QUERY_PARAM_SERIES_INSTANCE);
            this.defaultStructureSetUid = this.getSupportedQueryParameter(qp, QUERY_PARAM_DEFAULT_STRUCTURE_SET);
            this.taskId = this.getSupportedQueryParameter(qp, QUERY_PARAM_TASK_ID);
        }
    }

    /** Returns true if this UrlQuery is valid for requested url query type, false otherwise. */
    isValid(queryType: UrlQueryType) {
        switch (queryType) {
            case UrlQueryType.AnnotationIndexQuery:
            case UrlQueryType.ReferenceLibraryIndexQuery:
                return this.storageAccount !== undefined;
            case UrlQueryType.TaskWorkQuery:
                return this.taskId !== undefined;
            case UrlQueryType.AnnotationWorkQuery:
                return this.storageAccount !== undefined &&
                    this.fileShare !== undefined &&
                    this.patientId !== undefined &&
                    this.frameOfReferenceUid !== undefined &&
                    this.imageSeriesInstanceUid !== undefined;
            default:
                throw new Error(`Unsupported UrlQueryType (${queryType})`);
        }
    }

    /** Returns query parameters for requested query type, or an empty string if not valid for the requested type,
     * or the best possible query if no query type is given. */
    getQueryParameters(queryType?: UrlQueryType): string {

        if (!queryType) {
            // go through possible query types in order
            let result = '';
            for (const urlQueryType of [UrlQueryType.AnnotationWorkQuery, UrlQueryType.AnnotationIndexQuery, UrlQueryType.ReferenceLibraryIndexQuery, UrlQueryType.TaskWorkQuery]) {
                result = this.getQueryParameters(urlQueryType);
                if (result !== '') {
                    return result;
                }
            }

            return result;
        }

        if (queryType && !this.isValid(queryType)) {
            return '';
        }

        const qp = new URLSearchParams();

        switch (queryType) {
            case UrlQueryType.AnnotationIndexQuery:
            case UrlQueryType.ReferenceLibraryIndexQuery:
                qp.set(QUERY_PARAM_STORAGE_ACCOUNT, this.storageAccount!);
                if (!!this.fileShare) { qp.set(QUERY_PARAM_FILE_SHARE, this.fileShare); }
                break;
            case UrlQueryType.TaskWorkQuery:
                qp.set(QUERY_PARAM_TASK_ID, this.taskId!);
                break;
            case UrlQueryType.AnnotationWorkQuery:
                qp.set(QUERY_PARAM_STORAGE_ACCOUNT, this.storageAccount!);
                qp.set(QUERY_PARAM_FILE_SHARE, this.fileShare!);
                qp.set(QUERY_PARAM_PATIENT_ID, this.patientId!);
                qp.set(QUERY_PARAM_FRAME_OF_REFERENCE, this.frameOfReferenceUid!);
                qp.set(QUERY_PARAM_SERIES_INSTANCE, this.imageSeriesInstanceUid!);
                if (!!this.defaultStructureSetUid) { qp.set(QUERY_PARAM_DEFAULT_STRUCTURE_SET, this.defaultStructureSetUid); }
                break;
            default:
                throw new Error(`Unsupported UrlQueryType (${queryType})`);
        }

        return qp.toString();
    }

    private getSupportedQueryParameter(qp: URLSearchParams, queryParameter: string): string | undefined {
        const value = qp.get(queryParameter);
        if (value === null) { return undefined; }

        if (!VALID_QUERY_CHARACTERS.test(value)) {
            // throw new Error(`URL contains unsupported characters: ${queryParameter}=${value}`);
            return undefined;
        }

        return value;
    }
}
