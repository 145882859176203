import PanTool from './pan-tool';
import WindowLevelTool from './window-level-tool';
import SelectTool from './select-tool';
import LineDrawTool from './line-draw-tool';
import BrushTool from './brush-tool';
import InfoTool, { InfoToolNames } from "./info-tool";

export const mouseTools = { 
    select: new SelectTool(),
    pan: new PanTool(),
    windowLevel: new WindowLevelTool(),
    lineDraw: new LineDrawTool(),
    brush: new BrushTool(),
    measureLength: new InfoTool(InfoToolNames.MeasureLength),
    intensityProfile: new InfoTool(InfoToolNames.IntensityProfile),
};