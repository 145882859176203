import React from 'react';

import { DuplicateRoiGradingError, StructureSetGrading, GradingMeanings } from '../../../datasets/roi-grading';
import { StructureSet } from '../../../dicom/structure-set';
import { areStringsSameCi } from '../../../helpers/compare';

import './ConfirmGradingSaveDialog.css';


export default class ConfirmGradingSaveDialog extends React.PureComponent<{
    errors: DuplicateRoiGradingError[] | null,
    currentStructureSet: StructureSet,
    currentGrading: StructureSetGrading | undefined,
    roiNumberNeedingConfirmation: string | null,
}> {
    renderDuplicateGradeError(error: DuplicateRoiGradingError) {
        const { currentStructureSet } = this.props;
        return (
            <div key={error.roiName} className="duplicates-list-item">
                <div className="duplicate-roi-label">"{error.roiName}" has duplicate <span className="grading-meaning">"1: {GradingMeanings["1"]}"</span> gradings in these structure sets:</div>
                <div className="duplicate-structure-set-list">{Object.keys(error.duplicateStructureSetLabels).filter(k => k !== currentStructureSet.structureSetId)
                    .map(k => error.duplicateStructureSetLabels[k]).join(", ")}</div>
            </div>
        );
    }

    render() {
        const { currentGrading, errors, roiNumberNeedingConfirmation } = this.props;

        if (currentGrading === undefined || errors === null || roiNumberNeedingConfirmation === null || 
            !Object.keys(currentGrading.rois).includes(roiNumberNeedingConfirmation)) {
            // nothing to show
            return (<div />);
        }

        const roi = currentGrading.rois[roiNumberNeedingConfirmation];
        const duplicateRoiGradings = errors.find(e => areStringsSameCi(e.roiName, roi.roiName));
        const otherDuplicateRoiGradings = errors.filter(e => e !== duplicateRoiGradings);
        const goodGradeIndex = 1;
        const obsoleteGradeIndex = 6;
        const goodGrade = `"${goodGradeIndex}: ${GradingMeanings[goodGradeIndex]}"`;
        const obsoleteGrade = `"${obsoleteGradeIndex}: ${GradingMeanings[obsoleteGradeIndex]}"`;

        return (
            <div className="confirm-grading-save-dialog">
                {duplicateRoiGradings && (
                    <div className="duplicates-matching-current-roi">
                        You're trying to grade structure "{roi.roiName}" in this structure set as <span className="grading-meaning">{goodGrade}</span>, 
                        but this scan already has 
                        {Object.keys(duplicateRoiGradings.duplicateStructureSetLabels).length === 2 && (<span> another structure set where 
                            this exact same grading has been made. If you choose to proceed with saving, the following structure in its matching structure set will 
                            automatically be set to <span className="grading-meaning">{obsoleteGrade}</span>:
                        </span>)}
                        {Object.keys(duplicateRoiGradings.duplicateStructureSetLabels).length > 2 && (<span> several other structure sets where
                            this structure has been graded <span className="grading-meaning">{goodGrade}</span>. If you choose to proceed with saving, 
                            the following structures in their matching structure sets will automatically be set to 
                            <span className="grading-meaning">{obsoleteGrade}</span>:
                        </span>)}
                        <div className="duplicates-list">
                            {this.renderDuplicateGradeError(duplicateRoiGradings)}
                            <div className="duplicate-regrade-warning">
                                {Object.keys(duplicateRoiGradings.duplicateStructureSetLabels).length === 2
                                 ? (<span>The grading of this other structure</span>) : (<span>The gradings of these other structures</span>)} will be changed to <span className="grading-meaning">{obsoleteGrade}</span> if you confirm this grading change!
                            </div>
                        </div>
                    </div>)
                }
                {otherDuplicateRoiGradings.length > 0 && (
                    <div className="other-duplicates">
                        {duplicateRoiGradings ? (
                            <div>
                                In addition, the following other structures have been graded as <span className="grading-meaning">{goodGrade}</span>
                                in more than one structure set of this scan. You should fix these manually, or inform someone else who should fix them:
                            </div>
                        ) : (
                            <>
                                <div>
                                    Each specific structure in a scan is only allowed to be graded <span className="grading-meaning">{goodGrade}</span>
                                    in one structure set. The other ones should be set to <span className="grading-meaning">{obsoleteGrade}</span> (or something 
                                    other than <span className="grading-meaning">"1"</span>).
                                </div>
                                <div>
                                    The following structures have been graded as <span className="grading-meaning">{goodGrade}</span> in more than one
                                    structure set of this scan. You should fix these manually, or inform someone else who should fix them:
                                </div>
                            </>
                        )}
                        <div className="duplicates-list">
                            {otherDuplicateRoiGradings.map(d => this.renderDuplicateGradeError(d))}
                        </div>
                    </div>)
                }
            </div>
        );
    }
}
