import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';

import * as sagas from '../../store/sagas';
import DatasetBrowser, { DatasetBrowserType } from '../annotation-page/DatasetBrowser';
import RTViewer from '../rtviewer/RTViewer';
import { StoreState } from '../../store/store';
import { Dataset } from '../../datasets/dataset';
import WorkState, { Workspace } from '../../store/work-state';
import SplashScreen from '../splash-screen/SplashScreen';

import '../annotation-page/AnnotationPage.css';
import { SessionNotification } from '../common/models/SessionNotification';
import { getScanId } from '../../store/scans';
import { UrlQueryType } from '../../store/url-query';
import { getTaskDownloadKey, getTrainingTaskListId } from '../../datasets/training-task';
import { DownloadTask } from '../../store/file-transfer-task';
import { User } from '../../store/user';
import AccessForbidden from '../common/AccessForbidden';

const TASKS_RELOAD_INTERVAL_IN_MS = 5 * 60 * 1000; // 5 minute interval

type OwnProps = {}

type DispatchProps = {
    setCurrentWorkState(workState: WorkState | null): void,
    updateCurrentWorkState(updatedWorkStateProps: Partial<WorkState>): void,
    addNotification(notification: SessionNotification, delayInMilliseconds?: number): void,
    reloadDatasetGradings(dataset: Dataset): void,
    reloadTasks: () => void,
}

type AllProps = OwnProps & StoreState & DispatchProps & RouteComponentProps;

type OwnState = {
    user?: string,
    canEdit: boolean,
    reloadIntervalHandle: number | undefined,
}

class TasksHomePage extends React.Component<AllProps, OwnState> {
    displayName = TasksHomePage.name

    constructor(props: AllProps) {
        super(props);

        this.state = { canEdit: false, reloadIntervalHandle: undefined };
    }

    componentDidMount = () => {
        this.props.reloadTasks();
        const handle = window.setInterval(() => this.props.reloadTasks(), TASKS_RELOAD_INTERVAL_IN_MS);
        this.setState({ reloadIntervalHandle: handle });

        // make sure correct workspace is set
        if (this.props.currentWorkState && this.props.currentWorkState.workspace !== Workspace.GuidelineTraining) {
            this.props.updateCurrentWorkState({ workspace: Workspace.GuidelineTraining });
        }
    }

    componentDidUpdate = (prevProps: AllProps) => {
        if (prevProps.currentWorkState !== this.props.currentWorkState) {
            this.setUrl(this.props.currentWorkState);
        }
    }

    componentWillUnmount = () => {
        if (this.state.reloadIntervalHandle) {
            window.clearInterval(this.state.reloadIntervalHandle);
        }
    }

    /**
     * Set current work state into URL's query (search) parameters,
     * or remove any existing query parameters if there is no current
     * work.
     */
    setUrl = (workState: WorkState | undefined) => {
        // currently only set URL based on current work state, whether it's
        // a scan loaded into rtviewer or a dataset listing opened on the
        // annotation page (this component)
        if (!workState) {
            this.props.history.push({ search: undefined });
        } else {
            const query = workState.getTaskQuery();
            if (query && query.isValid(UrlQueryType.TaskWorkQuery)) {
                this.props.history.push({ search: query.getQueryParameters() });
                return;
            }
        }
    }

    /**
     * Returns current progress (0-100) of autoload download, or undefined
     * if not relevant.
     */
    getAutoLoadProgress = (): number | undefined => {
        const workState = this.props.currentWorkState;
        if (workState && workState.hasTaskWork()) {
            const downloadKey = getTaskDownloadKey(workState.task!);
            const download: DownloadTask = this.props.downloads![downloadKey];

            if (download) {
                return parseInt(download.progressPercentage.toString(), 10);
            }
        }

        return undefined;
    }

    handleBack = () => {
        this.setUrl(undefined);

        // attempt to scroll back to the tasks list element that was just closed in rtviewer
        if (this.props.currentWorkState && this.props.currentWorkState.hasTaskWork()) {
            const taskListElement = document.getElementById(getTrainingTaskListId(this.props.currentWorkState!.task!));
            setTimeout(() => {
                if (taskListElement) {
                    taskListElement.scrollIntoView({ block: "center" });
                }
            }, 50);
        }

        this.props.setCurrentWorkState(null);

    }

    render = () => {
        const { currentWorkState, isAutoloading, user }: { currentWorkState?: WorkState, isAutoloading?: boolean, user?: User } = this.props;

        if (!user || !user.permissions.tpAccess) {
            setTimeout(() => this.props.history.push('/'), 3500);
            return <AccessForbidden />;
        }

        const task = currentWorkState ? currentWorkState.task : null;
        const isTaskLoaded = task !== null;
        const hasErrored = currentWorkState ? currentWorkState.hasError() : false;

        // show splash screen if we're auto-loading a scan (and not a dataset page index)
        const showSplashScreen = !hasErrored && isAutoloading && currentWorkState;

        // show rtviewer if we're done with auto-loading and the splash screen (if applicable) 
        // and related scan dataset data is loaded in
        const showRTViewer = !showSplashScreen && !hasErrored && isTaskLoaded && !isAutoloading;

        // always default to showing the dataset browser if we've errored (or also in other fall-back cases)
        // TODO: will this cause problems if we set work state to error while already IN rtviewer?
        const showDatasetBrowser = !showSplashScreen && !showRTViewer;

        let canEdit = currentWorkState ? currentWorkState.canEdit : false;

        // TODO: don't allow rtstruct creation in Guide, only in Verify
        const canCreateRtstruct = currentWorkState ? currentWorkState.canCreateRtstruct : false;
        const scanId = isTaskLoaded ? getScanId(task!) : "";

        return (
            <>
                <div className="container-fluid annotation-container">

                    {showSplashScreen && (
                        <SplashScreen
                            isVisible={true}
                            showProgress={true}
                            progressNow={this.getAutoLoadProgress()}
                            splashText={`Loading task${currentWorkState && currentWorkState.getWorkName() ? ` ${currentWorkState.getWorkName()!.substring(0, 32)}` : ''}...`}
                            patientId={currentWorkState ? currentWorkState.getWorkName() : undefined}
                        />
                    )}

                    <div className={showDatasetBrowser ? 'visible' : 'invisible'} >
                        <Row>
                            <Col>
                                <Row style={{ marginLeft: '0.1rem' }}><img src={"/img/guide-title-new.svg"} alt="logo" className="guide-logo" /><h4 className="header-title">Training Tasks</h4></Row>

                                <DatasetBrowser
                                    type={DatasetBrowserType.TrainingTaskBrowser}
                                    isVisible={!isTaskLoaded} history={this.props.history} location={this.props.location} match={this.props.match}
                                />
                            </Col>
                        </Row>
                    </div>


                    {showRTViewer ?
                        <RTViewer scanId={scanId}
                            datasetImage={undefined} canEdit={canEdit} canCreateRtstruct={canCreateRtstruct} handleBack={this.handleBack} history={this.props.history} location={this.props.location} match={this.props.match} />
                        : null}
                </div>

                {!showRTViewer &&
                <div className="footer-apps">Guide is part of MVision AI GBS™</div>}

            </>
        );
    }
};

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(TasksHomePage);
