import Immerable from "../store/immerable";
import { RoiMapping } from "./dataset-files";

export class DatasetStructureSet extends Immerable {

    /** Internal ID of the scan that this structure set belongs to. */
    public scanId: string;

    public seriesId: string;
    public sopId: string;
    public frameOfReferenceUID: string;
    public approvalStatus: string;
    public label: string;
    public bestMatch: boolean;
    public roiMappings: RoiMapping[];
    public imageSeriesId: string;
    public datasetId: string;

    /** External scan ID used in the matching dataset CSV file -- this value is currently NOT USED in rtviewer but it needs to be placed back into
     * the CSV file during export/save. */
    public ssImageSeriesId: string;

    constructor(datasetId: string, imageSeriesId: string, scanId: string, seriesId: string, sopId: string, ssImageSeriesId: string, frameOfReferenceUID: string, approvalStatus: string, label: string, bestMatch: boolean, roiMappings: RoiMapping[]) {
        super();

        this.seriesId = seriesId;
        this.sopId = sopId;
        this.scanId = scanId;
        this.frameOfReferenceUID = frameOfReferenceUID;
        this.approvalStatus = approvalStatus;
        this.label = label;
        this.bestMatch = bestMatch;
        this.roiMappings = roiMappings;
        this.datasetId = datasetId;
        this.imageSeriesId = imageSeriesId;
        this.ssImageSeriesId = ssImageSeriesId;
    }
}
