import React from 'react';

import './glyphs.css';

import booleanOperationAndImage from '../images/boolean_operator_and.svg';
import booleanOperationOrImage from '../images/boolean_operator_or.svg';
import booleanOperationAndNotImage from '../images/boolean_operator_and_not.svg';
import booleanOperationXorImage from '../images/boolean_operator_xor.svg';

export const NewItemGlyph = () => {
  return (
    <span className="new-item-glyph">
      <svg height="12" width="12" fill="#ffffff">
        <rect x="5" y="1" width="2" height="10" rx="1" ry="1" />
        <rect x="1" y="5" width="10" height="2" rx="1" ry="1" />
      </svg>
    </span>
  );
}

export const BooleanOperationAndGlyph = () => {
  return (
    <span className="boolean-operation-and-glyph">
      <img src={booleanOperationAndImage} alt="And-operator" height="24" />
    </span>
  )
}

export const BooleanOperationOrGlyph = () => {
  return (
    <span className="boolean-operation-or-glyph">
      <img src={booleanOperationOrImage} alt="Or-operator" height="24" />
    </span>
  )
}

export const BooleanOperationAndNotGlyph = () => {
  return (
    <span className="boolean-operation-and-not-glyph">
      <img src={booleanOperationAndNotImage} alt="AndNot-operator" height="24" />
    </span>
  )
}

export const BooleanOperationXorGlyph = () => {
  return (
    <span className="boolean-operation-xor-glyph">
      <img src={booleanOperationXorImage} alt="Xor-operator" height="24" />
    </span>
  )
}
