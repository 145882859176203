import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

import { GradingWorkflowState, gradingWorkflowStatesInOrder } from '../../../datasets/roi-grading';
import { rtViewerApiClient } from '../../../web-apis/rtviewer-api-client';

import GradingState from './GradingState';

import './GradingStateDropdown.css';
import { isRutherford } from '../../../environments';

export default class GradingStateDropdown extends React.PureComponent<{
    gradingWorkflowState: GradingWorkflowState, onChange: (state: GradingWorkflowState) => void,
    disabled: boolean,
}> {
    gradingWorkflowStatesInOrder: GradingWorkflowState[];
    constructor(props: any) {
        super(props);

        // filter out "ready for training", "expert approved" and "obsolete" from the default list -- it will be added later if the user has a relevant user permission
        this.gradingWorkflowStatesInOrder = gradingWorkflowStatesInOrder.filter(gws =>
            gws !== GradingWorkflowState.ReadyForTraining &&
            gws !== GradingWorkflowState.ExpertApproved &&
            gws !== GradingWorkflowState.Obsolete);
    }

    handleChange = (state: GradingWorkflowState) => {
        this.props.onChange(state);
    }

    render() {
        const gradingWorkflowState = this.props.gradingWorkflowState;
        const disabled = this.props.disabled
        const workflowStates = [...this.gradingWorkflowStatesInOrder];
        if (!isRutherford() && rtViewerApiClient.permissions.canApproveRTStructsForTraining) {
            workflowStates.push(GradingWorkflowState.ReadyForTraining);
        }
        if (!isRutherford() && rtViewerApiClient.permissions.canSetRTStructsAsExpertApproved) {
            workflowStates.push(GradingWorkflowState.ExpertApproved);
        }
        if (!isRutherford() && rtViewerApiClient.permissions.canSetRTStructsAsObsolete) {
            workflowStates.push(GradingWorkflowState.Obsolete);
        }

        return (
            <DropdownButton title={(<GradingState gradingWorkflowState={gradingWorkflowState} />)} id="grading-workflow-state"
                disabled={disabled}
                variant="light"
                size="sm"
                className="grading-button grading-state-button">
                {
                    workflowStates.map(ws =>
                        (<Dropdown.Item as="button" key={ws}
                            onClick={() => this.handleChange(ws)}><GradingState gradingWorkflowState={ws} noColor={true} />
                        </Dropdown.Item>)
                    )}
            </DropdownButton>
        );
    }
}
