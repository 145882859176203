import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { connect } from 'react-redux';

import * as sagas from '../../../store/sagas';
import { ViewerState } from '../../../rtviewer-core/viewer-state';
import * as structureSet from '../../../dicom/structure-set';
import { StoreState } from '../../../store/store';
import './DifferencesToolbar.css';
import { TrainingTask, TrainingTaskState, handleTaskState, handleTaskType } from '../../../datasets/training-task';
import { MdComment } from 'react-icons/md';
import TaskDescriptionDialog from '../../common/dialogs/TaskDescriptionDialog';


type OwnProps = {
    viewerState: ViewerState,
    structureSets: structureSet.StructureSet[],
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    descriptionDialogVisible: boolean,
}

class TaskDetailsToolbar extends React.PureComponent<AllProps, OwnState> {
    displayName = TaskDetailsToolbar.name

    constructor(props: AllProps) {
        super(props);
        this.state = {
            descriptionDialogVisible: false,
        };
    }
    
    handleCloseTaskDescriptionDialog = () => {
        this.setState({ descriptionDialogVisible: false });
    }

    handleOpenTaskDescriptionDialog = () => {
        this.setState({ descriptionDialogVisible: true });
    }

    renderTaskDescription = (task: TrainingTask) => {
        if (task.description.length <= 10) {
            // Render the full description
            return task.description;
        }
        if (task.description.length > 15) {
            // Render the truncated description with a "Read More" button
            const truncatedDescription = task.description.substring(0, 15) + '...';
            return (
                <>
                    {truncatedDescription}
                    <Button className='expand-desc-button' size="sm" onClick={this.handleOpenTaskDescriptionDialog}><MdComment size={15} /></Button>
                </>
            )
        };
        if (task.description.length <= 0) {
            return null;
        }
    };

    render() {
        const { currentTask } = this.props;

        return (
            <Row className='task-details-tab'>
                <Col style={{ display: "flex" }}>
                    <h5>Task Details</h5>
                    {currentTask && (
                        <>
                        <Col>
                            <Col>Task name: {currentTask.name}</Col>
                            <Col style={{ wordBreak: "break-all" }}>Description: {this.renderTaskDescription(currentTask)}</Col>
                        </Col>
                        <Col>
                                <div>State: {handleTaskState(currentTask.state)}{(currentTask.state === TrainingTaskState.Graded || currentTask.state === TrainingTaskState.Archived) && `, ${currentTask.grade}`}</div>
                                <div>Type: {handleTaskType(currentTask.type)}</div>
                            </Col>
                        </>
                    )}
                </Col>
                {this.state.descriptionDialogVisible && this.props.currentTask &&
                    <TaskDescriptionDialog
                        description={this.props.currentTask.description}
                        isVisible={this.state.descriptionDialogVisible}
                        onClose={this.handleCloseTaskDescriptionDialog} />
                }
            </Row>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(TaskDetailsToolbar);
