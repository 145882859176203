/**
 * Dataset "table" inside the dataset/annotation page. This component mainly handles dataset's pagination and 
 * batch UI functionalities.
 */

import React from 'react';
import ReactPaginate from 'react-paginate';
import produce from 'immer';

import ReferenceLibraryTable from './ReferenceLibraryTable';
import { PageImage, PagePatient, PagePatientCollection, PageStructureSet } from '../annotation-page/models/PagePatient';
import { AzureShareInfo } from '../../web-apis/azure-files';
import { Dataset } from '../../datasets/dataset';
import { handleHierarchicalTriStateChange, TriStateCheckboxChecked, TriStateCheckboxState } from '../common/TriStateCheckbox';
import { DatasetImage } from '../../datasets/dataset-image';
import { DatasetStructureSet } from '../../datasets/dataset-structure-set';
import DatasetFilter from '../annotation-page/models/DatasetFilter';
import { getStructureSetFileInfo } from '../../datasets/dataset-files';
import BatchJobDialog, { BatchJobSelection } from '../annotation-page/BatchJobDialog';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import { BatchClient, BatchRequest, ImageRequest, RTStructRequest } from '../../web-apis/batch-client';
import * as sagas from '../../store/sagas';
import WorkState from '../../store/work-state';

import './ReferenceLibraryPage.css';
import { NotificationType, SessionNotification } from '../common/models/SessionNotification';
import { LockAction } from '../../datasets/locks';
import { User } from '../../store/user';
import { Button } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';

const perPage = 14;

export enum DataItemType {
    Patient, Image, StructureSet
}

export enum BatchJobOperation {
    None, Export, AutoContourAndExport
}

type OwnProps = {
    storageAccountName?: string,
    fileShare?: AzureShareInfo,
    dataset?: Dataset;
    error?: any,
    setPageError: (error: any) => void,
    setPatientCountHeader: (header: string) => void,
    getCurrentFilter: () => DatasetFilter | undefined,
    filterText?: string,
    initialWorkState: WorkState | null,
    onLockClick: (datasetImage: DatasetImage, lockAction: LockAction) => void,
    onLoadClick: (datasetImage: DatasetImage) => void,
    onUnloadClick: (datasetImage: DatasetImage) => void,
    onViewImageClick: (datasetImage: DatasetImage) => void,
    onOpenAddTaskDialogClick: () => void,
}

type DispatchProps = {
    startBatchJobRequest: () => void,
    finishBatchJobRequest: (wasSuccessful: boolean) => void,
    setPatientsPerPage: (PagePatients: PagePatient[]) => void,
    addNotification: (notification: SessionNotification, delayInMilliseconds?: number) => void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    isInitializing: boolean,
    initialWorkState: WorkState | null,
    paginationNeeded?: boolean,
    patients: PagePatientCollection, // All patients in selected fileshare
    pagePatients: PagePatient[], // Patients in the current pagination page
    pageIndex?: number,
    selectedPageDict: { [storage_share: string]: number }, // Which pagination page was last active for a fileshare
    showBatchControls?: boolean,
    // store all data items and their selection states (checked, unchecked, indeterminate) in a flat list by their "checkbox ID"
    batchSelections: { [checkboxId: string]: TriStateCheckboxState },
    // store all batch job operation states for each checkbox ID (= image checkbox ID)
    batchSelectionOperations: { [checkboxId: string]: BatchJobOperation },
    // store all checkbox IDs of each item type in current dataset into a helper structure
    batchSelectHelpers: {
        [DataItemType.Patient]: string[],
        [DataItemType.Image]: string[],
        [DataItemType.StructureSet]: string[],
    },
    // map all structure set checkbox IDs to their corresponding parent image checkbox IDs in a yet another helper structure
    batchSelectMapImagesToStructureSets: { [imageCheckboxId: string]: string[] },
    // map all checkbox item IDs back to the actual matching objects
    batchSelectMapCheckboxIdsToDatasetObjects: {
        [DataItemType.Image]: { [imageCheckboxId: string]: DatasetImage },
        [DataItemType.StructureSet]: { [imageCheckboxId: string]: DatasetStructureSet },
    },
    isBatchJobDialogVisible: boolean,
    batchJobDescription: string,
}

class ReferenceLibraryPage extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            isInitializing: false,
            initialWorkState: null,
            patients: new PagePatientCollection(),
            pagePatients: [],
            selectedPageDict: {},
            batchSelections: {},
            batchSelectionOperations: {},
            batchSelectHelpers: { [DataItemType.Patient]: [], [DataItemType.Image]: [], [DataItemType.StructureSet]: [] },
            batchSelectMapImagesToStructureSets: {},
            batchSelectMapCheckboxIdsToDatasetObjects: { [DataItemType.Image]: {}, [DataItemType.StructureSet]: {} },
            isBatchJobDialogVisible: false,
            batchJobDescription: '',
        };
    }

    componentDidUpdate = (prevProps: AllProps) => {
        // set some one-time-only-at-most initialization stuff so we get to calculate the correct initial page
        if (!this.state.isInitializing && this.props.initialWorkState !== null && this.props.initialWorkState.hasAnnotationWork()) {
            this.setState(produce((draft: OwnState) => {
                draft.initialWorkState = this.props.initialWorkState;
                // this is just a safeguard to ensure we don't come here again
                draft.isInitializing = true;
            }));
        }

        // generate new patient etc view models if needed
        if (prevProps.storageAccountName !== this.props.storageAccountName
            || prevProps.fileShare !== this.props.fileShare
            || prevProps.dataset !== this.props.dataset) {

            // re-set page data if props have changed
            this.setState(produce((draft: OwnState) => {
                draft.patients = new PagePatientCollection();
                draft.pagePatients = [];
                draft.showBatchControls = false;
                draft.batchJobDescription = this.props.filterText || '';
            }), async () => {
                // re-initialize page data if we have a valid dataset
                if (this.props.dataset !== undefined && this.props.fileShare !== undefined) {
                    try {
                        const { dataset, fileShare } = this.props;
                        const patientCollection = this.getPatientsFromImages(dataset.images, this.props.getCurrentFilter());
                        const pageDictIndex = fileShare.storageAccountName + "_" + fileShare.fileShareName;

                        // we need to calculate the initial page number if the page is initializing into
                        // a specific work state
                        const isInitialPageIndexNeeded = this.state.isInitializing
                            && this.state.initialWorkState!.dataset!.getDatasetId() === dataset.getDatasetId()
                            && this.state.selectedPageDict[pageDictIndex] === undefined;

                        const pageIndx = isInitialPageIndexNeeded ? this.calculatePageIndex(this.state.initialWorkState!, patientCollection) : this.state.selectedPageDict[pageDictIndex] || 0;
                        const checkboxStateItems = ReferenceLibraryPage.generateBatchSelectionCheckboxes(patientCollection);

                        this.props.setPageError(null);

                        // generate header from patient count statistics
                        const allPatients = patientCollection.getPatients();
                        const patientCount = allPatients.length;
                        const imageCount = allPatients.flatMap(p => p.getImages()).length;
                        const patientCountHeader = `${patientCount} patients, ${imageCount} images`;

                        this.props.setPatientCountHeader(patientCountHeader);

                        this.setState(produce((draft: OwnState) => {
                            draft.patients = patientCollection;
                            draft.pagePatients = [];
                            Object.assign(draft, checkboxStateItems);
                        }), async () => {
                            this.showPage(pageIndx);
                        });
                        // this.setState({ patients: patientCollection, pagePatients: [], header: header, error: null, ...checkboxStateItems },
                        //     () => this.showPage(pageIndx));
                    }
                    catch (error) {
                        console.error(error);
                        this.props.setPageError(error);
                    }
                }
            });
        }
    }

    getPatientsFromImages = (images: DatasetImage[], filter?: DatasetFilter): PagePatientCollection => {
        const patients = new PagePatientCollection();
        images.forEach(img => patients.addPatient(img));

        // do a natural sort for the patients
        patients.sort();

        // apply filter
        patients.filter(filter, this.props.dataset);

        return patients;
    }

    areAllOfTypeChecked = (typeToCheck: DataItemType): TriStateCheckboxChecked => {
        // if this type doesn't exist in the helpers => unchecked
        if (this.state.batchSelectHelpers[typeToCheck].length === 0) {
            return TriStateCheckboxChecked.Unchecked;
        }

        // everything unchecked => unchecked
        // even one checked or indeterminate => indeterminate
        // everything checked => checked
        if (this.state.batchSelectHelpers[typeToCheck].every((id: string) => this.state.batchSelections[id].state === TriStateCheckboxChecked.Checked)) {
            return TriStateCheckboxChecked.Checked;
        } else if (this.state.batchSelectHelpers[typeToCheck].every((id: string) => this.state.batchSelections[id].state === TriStateCheckboxChecked.Unchecked)) {
            return TriStateCheckboxChecked.Unchecked;
        } else {
            return TriStateCheckboxChecked.Indeterminate;
        }
    }

    getAllMassCheckedValues = () => {
        const areAllPatientsChecked = this.areAllOfTypeChecked(DataItemType.Patient);
        const areAllImagesChecked = this.areAllOfTypeChecked(DataItemType.Image);
        const areAllStructureSetsChecked = this.areAllOfTypeChecked(DataItemType.StructureSet);

        const anyImages = this.state.batchSelectHelpers[DataItemType.Image].length > 0;
        const anyStructureSets = this.state.batchSelectHelpers[DataItemType.StructureSet].length > 0;

        let isEverythingChecked: TriStateCheckboxChecked;
        if (areAllPatientsChecked === TriStateCheckboxChecked.Checked
            && (!anyImages || areAllImagesChecked === TriStateCheckboxChecked.Checked)
            && (!anyStructureSets || areAllStructureSetsChecked === TriStateCheckboxChecked.Checked)) {
            isEverythingChecked = TriStateCheckboxChecked.Checked;
        } else if (areAllPatientsChecked === TriStateCheckboxChecked.Unchecked
            && areAllImagesChecked === TriStateCheckboxChecked.Unchecked
            && areAllStructureSetsChecked === TriStateCheckboxChecked.Unchecked) {
            isEverythingChecked = TriStateCheckboxChecked.Unchecked;
        } else {
            isEverythingChecked = TriStateCheckboxChecked.Indeterminate;

        }

        return {
            [DataItemType.Patient]: areAllPatientsChecked,
            [DataItemType.Image]: areAllImagesChecked,
            [DataItemType.StructureSet]: areAllStructureSetsChecked,
            'everything': isEverythingChecked,
        };
    }

    static checkAppropriateTypeOfCheckbox(draft: any, checkboxId: string, nextChecked: TriStateCheckboxChecked, checkboxType: DataItemType) {
        if (checkboxType === DataItemType.StructureSet) {
            ReferenceLibraryPage.checkStructureSetCheckbox(draft, checkboxId, nextChecked);
        } else {
            ReferenceLibraryPage.checkPatientOrImageCheckbox(draft, checkboxId, nextChecked, checkboxType);
        }
    }

    static checkPatientOrImageCheckbox(draft: any, checkboxId: string, nextChecked: TriStateCheckboxChecked, checkboxType: DataItemType) {
        const { batchSelections } = draft;
        handleHierarchicalTriStateChange(checkboxId, nextChecked, batchSelections);
        // if we just unselected a patient or an image, also unselect related structure sets
        // we didn't actually put structure sets as children of images when generating checkboxes (to stop them from being in the
        // checkbox tri-state hierarchy) so we need to use helper structures to figure out which structure sets belong to which images
        if (nextChecked === TriStateCheckboxChecked.Unchecked) {
            const structureSetIds: string[] = [];
            const imageIds: string[] = [];
            if (checkboxType === DataItemType.Image) {
                imageIds.push(checkboxId);
            }
            else if (checkboxType === DataItemType.Patient) {
                imageIds.push(...(batchSelections[checkboxId].childIds));
            }
            imageIds.forEach((imageId: string) => {
                const structureSetIdsFromImage: string[] = draft.batchSelectMapImagesToStructureSets[imageId];
                structureSetIds.push(...structureSetIdsFromImage);
            });
            structureSetIds.forEach(structureSetId => batchSelections[structureSetId].state = nextChecked);
        }
    }

    static checkStructureSetCheckbox(draft: any, checkboxId: string, nextChecked: TriStateCheckboxChecked) {
        const { batchSelections } = draft;
        const checkbox: TriStateCheckboxState = batchSelections[checkboxId];
        checkbox.state = nextChecked;
        // if we just checked a structure set, also make sure its parents are checked. do nothing on uncheck
        if (nextChecked === TriStateCheckboxChecked.Checked && checkbox.parentId) {
            handleHierarchicalTriStateChange(checkbox.parentId, nextChecked, batchSelections);
        }
    }

    checkEverythingOfType = (setToChecked: TriStateCheckboxChecked, ...typesToCheck: DataItemType[]) => {
        this.setState(produce((draft: OwnState) => {
            typesToCheck.forEach(typeToCheck => {
                draft.batchSelectHelpers[typeToCheck].forEach((id: string) => { ReferenceLibraryPage.checkAppropriateTypeOfCheckbox(draft, id, setToChecked, typeToCheck); })
            });
        }));
    }

    handleCheckAllPatients = (setToChecked: TriStateCheckboxChecked) => {
        this.checkEverythingOfType(setToChecked, DataItemType.Patient);
    }

    handleCheckAllImages = (setToChecked: TriStateCheckboxChecked) => {
        this.checkEverythingOfType(setToChecked, DataItemType.Image);
    }

    handleCheckAllStructureSets = (setToChecked: TriStateCheckboxChecked) => {
        this.checkEverythingOfType(setToChecked, DataItemType.StructureSet);
    }

    handleCheckEverything = (setToChecked: TriStateCheckboxChecked) => {
        this.checkEverythingOfType(setToChecked, DataItemType.StructureSet, DataItemType.Patient);
    }

    handleCheckStructureSetsForSelectedImages = (setToChecked: TriStateCheckboxChecked) => {
        this.setState(produce((draft: OwnState) => {
            const checkedImageIds: string[] = draft.batchSelectHelpers[DataItemType.Image]
                .filter((imageId: string) => draft.batchSelections[imageId].state === TriStateCheckboxChecked.Checked);
            const structureSetIds: string[] = [];
            checkedImageIds.forEach(imageId => structureSetIds.push(...draft.batchSelectMapImagesToStructureSets[imageId]));
            structureSetIds.forEach(structureSetId => ReferenceLibraryPage.checkAppropriateTypeOfCheckbox(draft, structureSetId, setToChecked, DataItemType.StructureSet));
        }));
    }

    handleTriStateCheckboxChange = (checkboxId: string, event: any) => {
        const eventChecked = event.target.checked;
        const nextChecked = eventChecked ? TriStateCheckboxChecked.Checked : TriStateCheckboxChecked.Unchecked;

        const checkboxType = this.getCheckboxIdType(checkboxId);
        if (checkboxType !== null) {
            this.setState(produce((draft: OwnState) => ReferenceLibraryPage.checkAppropriateTypeOfCheckbox(draft, checkboxId, nextChecked, checkboxType)));
        }
    }

    handleSetAllBatchOperations = (setToOperation: BatchJobOperation) => {
        // set only currently visible (i.e. currently selected) items
        this.setState(produce((draft: OwnState) => {
            const checkedImageIds: string[] = draft.batchSelectHelpers[DataItemType.Image]
                .filter((imageId: string) => draft.batchSelections[imageId].state === TriStateCheckboxChecked.Checked);
            checkedImageIds.forEach(imageId => draft.batchSelectionOperations[imageId] = setToOperation);
        }));
    }

    static generateBatchSelectionCheckboxes = (patientCollection: PagePatientCollection): {} => {
        const batchSelections: { [id: string]: TriStateCheckboxState } = {};
        const batchSelectHelpers: { [DataItemType.Patient]: string[], [DataItemType.Image]: string[], [DataItemType.StructureSet]: string[] }
            = { [DataItemType.Patient]: [], [DataItemType.Image]: [], [DataItemType.StructureSet]: [] };
        const batchSelectMapImagesToStructureSets: { [imageId: string]: string[] } = {};
        const batchSelectMapImagesToDatasetImages: { [imageCheckboxId: string]: DatasetImage } = {};
        const batchSelectMapStructureSetsToDatasetStructureSets: { [imageCheckboxId: string]: DatasetStructureSet } = {};

        for (const pagePatient of patientCollection.getPatients()) {
            // generate checkbox data for patients

            const patientCheckboxId = ReferenceLibraryPage.getCheckboxId(pagePatient.id);
            const patientCheckboxState = new TriStateCheckboxState(patientCheckboxId);
            batchSelections[patientCheckboxId] = patientCheckboxState;
            batchSelectHelpers[DataItemType.Patient].push(patientCheckboxId);

            for (const pageImage of pagePatient.getImages()) {
                // generate checkbox data for a patient's images and connect them to the patient
                const image = pageImage.image;


                const seriesId: string = image.seriesId;

                const imageCheckboxId = ReferenceLibraryPage.getCheckboxId(pagePatient.id, seriesId);
                const imageCheckboxState = new TriStateCheckboxState(imageCheckboxId);
                imageCheckboxState.parentId = patientCheckboxId;
                patientCheckboxState.childIds.push(imageCheckboxId);
                batchSelections[imageCheckboxId] = imageCheckboxState;
                batchSelectHelpers[DataItemType.Image].push(imageCheckboxId);
                batchSelectMapImagesToStructureSets[imageCheckboxId] = [];

                // map the image checkbox id back to the actual dataset image object so we can refer back to it in later operations
                batchSelectMapImagesToDatasetImages[imageCheckboxId] = image;

                for (const pageStructureSet of pageImage.getStructureSets()) {
                    // generate checkbox data for an image's structure sets but only connect
                    // them by parent link, not by child link, to get them partially out of the
                    // hierarchy

                    const structureSetId: string = `${seriesId}${pageStructureSet.sopId}`;

                    const structureSetCheckboxId = ReferenceLibraryPage.getCheckboxId(pagePatient.id, seriesId, structureSetId);
                    const structureSetCheckboxState = new TriStateCheckboxState(structureSetCheckboxId);
                    structureSetCheckboxState.parentId = imageCheckboxId;
                    // imageCheckboxState.childIds.push(structureSetCheckboxId);
                    batchSelections[structureSetCheckboxId] = structureSetCheckboxState;
                    batchSelectHelpers[DataItemType.StructureSet].push(structureSetCheckboxId);

                    // use this to map which structure set belongs to which image while also bypassing the natural
                    // tri-state hierarchy functionality in tri-state checkboxes
                    batchSelectMapImagesToStructureSets[imageCheckboxId].push(structureSetCheckboxId);

                    // map the structure set checkbox id back to the actual dataset structure set object so we can refer back to it in later operations
                    batchSelectMapStructureSetsToDatasetStructureSets[structureSetCheckboxId] = pageStructureSet.structureSet;
                }
            }
        }

        return {
            batchSelections,
            batchSelectHelpers,
            batchSelectMapImagesToStructureSets,
            batchSelectMapCheckboxIdsToDatasetObjects: {
                [DataItemType.Image]: batchSelectMapImagesToDatasetImages,
                [DataItemType.StructureSet]: batchSelectMapStructureSetsToDatasetStructureSets,
            },
            batchSelectionOperations: {}
        };
    }

    // current set of batch selection checkboxes need to be updated if user clicks on any of the 'show all items' buttons for filtered items
    static updateBatchSelectionCheckboxes(draft: OwnState, newImages: PageImage[], newStructureSets: PageStructureSet[], dataset?: Dataset) {
        if (newImages.length > 0) {
            for (const newImage of newImages) {
                const patientCheckboxId = ReferenceLibraryPage.getCheckboxId(newImage.image.patientId);
                const patientCheckboxState = draft.batchSelections[patientCheckboxId];
                const imageCheckboxId = ReferenceLibraryPage.getCheckboxId(newImage.image.patientId, newImage.seriesId);
                const imageCheckboxState = new TriStateCheckboxState(imageCheckboxId);
                imageCheckboxState.parentId = patientCheckboxId;
                patientCheckboxState.childIds.push(imageCheckboxId);
                draft.batchSelections[imageCheckboxId] = imageCheckboxState;
                draft.batchSelectHelpers[DataItemType.Image].push(imageCheckboxId);
                draft.batchSelectMapImagesToStructureSets[imageCheckboxId] = [];
                draft.batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.Image][imageCheckboxId] = newImage.image;

                // update images' patient checkboxes to indeterminate if currently checked
                if (patientCheckboxState.state === TriStateCheckboxChecked.Checked) {
                    patientCheckboxState.state = TriStateCheckboxChecked.Indeterminate;
                }
            }
        }

        if (newStructureSets.length > 0 && dataset !== undefined) {
            for (const newStructureSet of newStructureSets) {
                const image = dataset.images.find(i => i.seriesId === newStructureSet.structureSet.imageSeriesId);
                if (image !== undefined) {
                    const seriesId = image.seriesId;
                    const imageCheckboxId = ReferenceLibraryPage.getCheckboxId(image.patientId, seriesId);
                    const structureSetId: string = `${seriesId}${newStructureSet.sopId}`;
                    const structureSetCheckboxId = ReferenceLibraryPage.getCheckboxId(image.patientId, seriesId, structureSetId);
                    const structureSetCheckboxState = new TriStateCheckboxState(structureSetCheckboxId);
                    structureSetCheckboxState.parentId = imageCheckboxId;
                    draft.batchSelections[structureSetCheckboxId] = structureSetCheckboxState;
                    draft.batchSelectHelpers[DataItemType.StructureSet].push(structureSetCheckboxId);
                    draft.batchSelectMapImagesToStructureSets[imageCheckboxId].push(structureSetCheckboxId);
                    draft.batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.StructureSet][structureSetCheckboxId] = newStructureSet.structureSet;
                }
            }
        }
    }

    clearAllBatchSelections = () => {
        this.setState(produce((draft: OwnState) => {
            const batchSelectionKeys = Object.keys(draft.batchSelections);
            batchSelectionKeys.forEach(key => draft.batchSelections[key].state = TriStateCheckboxChecked.Unchecked);
            draft.batchSelectionOperations = {};
        }));
    }

    countSelectionsOfType = (typeToCheck: DataItemType) => {
        // indeterminate boxes count as selected since we have to eventually export the indeterminate ones anyway
        return this.state.batchSelectHelpers[typeToCheck]
            .filter((id: string) => this.state.batchSelections[id].state !== TriStateCheckboxChecked.Unchecked).length;
    }

    static getCheckboxId = (patientId: string, imageId?: string, structureSetId?: string) => {
        return `${patientId}${imageId ? `_${imageId}` : ''}${structureSetId ? `_${structureSetId}` : ''}`;
    }

    getCheckboxIdType = (checkboxId: string): DataItemType | null => {
        if (this.state.batchSelectHelpers[DataItemType.StructureSet].includes(checkboxId)) {
            return DataItemType.StructureSet;
        } else if (this.state.batchSelectHelpers[DataItemType.Image].includes(checkboxId)) {
            return DataItemType.Image;
        } else if (this.state.batchSelectHelpers[DataItemType.Patient].includes(checkboxId)) {
            return DataItemType.Patient;
        }

        return null;
    }

    getAutoContourRequestCount = (): number => {
        const imageCheckboxIds = Object.keys(this.state.batchSelectionOperations);
        return imageCheckboxIds.filter(imageCheckboxId =>
            this.state.batchSelectionOperations[imageCheckboxId] === BatchJobOperation.AutoContourAndExport
            && this.state.batchSelections[imageCheckboxId].state !== TriStateCheckboxChecked.Unchecked).length;
    }

    getCurrentSelection = (): BatchJobSelection[] => {
        // gets the names and export operations of currently selected items
        const selections: BatchJobSelection[] = [];
        const batchKeys = Object.keys(this.state.batchSelections);
        const selectedKeys = batchKeys.filter(key => this.state.batchSelections[key].state !== TriStateCheckboxChecked.Unchecked);

        // ignore patient selections, let's take just images and structure sets
        const imageKeys = selectedKeys.filter(key => this.state.batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.Image].hasOwnProperty(key));
        const structKeys = selectedKeys.filter(key => this.state.batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.StructureSet].hasOwnProperty(key));

        imageKeys.forEach(imageKey => {
            const image = this.state.batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.Image][imageKey];
            const operation = this.state.batchSelectionOperations[imageKey];
            const structs: DatasetStructureSet[] = [];
            image.structureSets.forEach(ss => {
                const ssId = ReferenceLibraryPage.getCheckboxId(image.patientId, image.seriesId, `${image.seriesId}${ss.sopId}`);
                if (structKeys.includes(ssId)) { structs.push(ss); }
            });

            selections.push({ image, structs, operation });
        });

        return selections;
    }

    requestBatchOperation = async (contouringAction: string, description?: string): Promise<string> => {

        try {

            // immediately lock the button so user can't click on it multiple times
            this.props.startBatchJobRequest();

            const { storageAccountName, fileShare } = this.props;
            const { batchSelections, batchSelectHelpers, batchSelectMapCheckboxIdsToDatasetObjects, batchSelectionOperations } = this.state;
            if (fileShare === undefined || fileShare.storageAccountName !== storageAccountName) {
                throw new Error('File share and storage account selections are not complete or are out of sync -- cannot proceed');
            }

            // collect currently selected images
            // we're doing 'not unchecked' comparison here to to account for the possibility of the check state being 'indeterminate', which would still count in this case
            // (even though in the current implementation the images are ever only checked or unchecked, not indeterminate)
            const selectedImages = batchSelectHelpers[DataItemType.Image]
                .filter(imageId => batchSelections[imageId].state !== TriStateCheckboxChecked.Unchecked)
                .map(imageId => batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.Image][imageId]);
            const imageRequests = selectedImages
                .map(image => {
                    const imageCheckboxId = ReferenceLibraryPage.getCheckboxId(image.patientId, image.seriesId);
                    const autoContourActionForImage = batchSelectionOperations[imageCheckboxId] === BatchJobOperation.AutoContourAndExport ? contouringAction : null;
                    return new ImageRequest(image.patientId, image.seriesId, image.path, autoContourActionForImage);
                });

            // collect currently selected structure sets
            const selectedStructureSets = batchSelectHelpers[DataItemType.StructureSet]
                .filter(ssId => batchSelections[ssId].state === TriStateCheckboxChecked.Checked)
                .map(ssId => ({ checkboxId: ssId, model: batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.StructureSet][ssId] }));
            const rtStructRequests = selectedStructureSets
                .map(ss => {
                    const imageMap = this.state.batchSelectMapImagesToStructureSets;
                    const matchingImageCheckboxId = Object.keys(imageMap).find(imageCheckboxId => imageMap[imageCheckboxId].find(ssId => ss.checkboxId));
                    if (matchingImageCheckboxId) {
                        const matchingImage = batchSelectMapCheckboxIdsToDatasetObjects[DataItemType.Image][matchingImageCheckboxId];
                        return new RTStructRequest(matchingImage.patientId, ss.model.sopId,
                            getStructureSetFileInfo(fileShare, matchingImage.patientId, matchingImage.frameOfReferenceUid, matchingImage.seriesId, ss.model.sopId).path);
                    } else {
                        const errorMsg = `No matching parent image found for structure set ${ss}`;
                        console.error(errorMsg);
                        console.log(ss);
                        throw new Error(errorMsg);
                    }
                });


            const batchRequest = new BatchRequest(fileShare, imageRequests, rtStructRequests, description || undefined);

            const batchId = await BatchClient.createBatchJob(batchRequest);
            this.props.finishBatchJobRequest(true);
            this.props.addNotification(new SessionNotification(
                batchId,
                'Your batch job was queued. You can track the state of the batch job queue by opening the batch job queue panel from the top-right corner.',
                NotificationType.Success), 200);

            // clear all current selections on success (and success only):
            this.clearAllBatchSelections();

            return batchId;
        }
        catch (error) {
            const errorMessage = 'Something went wrong with your batch job. You can attempt again if you want. See console (F12) for error details.';
            this.props.finishBatchJobRequest(false);
            this.props.addNotification(new SessionNotification(
                new Date().toString(),
                errorMessage,
                NotificationType.Failure), 200);

            return errorMessage;
        }
    }

    calculatePageIndex = (workState: WorkState, patientCollection: PagePatientCollection): number => {
        const patients = patientCollection.getPatients();
        if (!patients || patients.length <= perPage) { return 0; };

        const patientIndex = patients.findIndex(p => p.id === workState.datasetImage!.patientId) + 1;
        if (patientIndex <= perPage) { return 0; };

        const remainder = patientIndex % perPage;
        const pageIndex = ((patientIndex - remainder) / perPage) + (remainder > 0 ? 1 : 0) - 1;
        return pageIndex;
    }

    showPage = (pageIndex: number) => {
        this.setState(produce((draft: OwnState) => {
            const patients = draft.patients.getPatients();
            if (!patients) { return };
            if (patients.length / perPage <= pageIndex) {
                pageIndex = 0;
            }

            const beginIndex = pageIndex * perPage;
            const endIndex = Math.min(beginIndex + perPage, patients.length); // End index will not be included in sliced array
            const pagePatients = patients.slice(beginIndex, endIndex);


            if (pagePatients.length > 0) {
                this.props.setPatientsPerPage(pagePatients);
            }

            draft.paginationNeeded = patients.length > perPage;
            draft.selectedPageDict[this.props.storageAccountName + "_" + this.props.fileShare!.fileShareName] = pageIndex;
            draft.pagePatients = pagePatients;
            draft.pageIndex = pageIndex;
        }));
    }

    handlePageClick = (data: any) => {
        this.showPage(data.selected);
    }

    handleBatchJobDialogOpen = () => {
        this.setState({ isBatchJobDialogVisible: true });
    }

    handleBatchJobDialogClose = () => {
        this.setState({ isBatchJobDialogVisible: false });
    }

    handleBatchOperationTypeChange = (value: { checkboxId: string, operation: BatchJobOperation }) => {
        this.setState(produce((draft: OwnState) => {
            const { checkboxId, operation } = value;
            draft.batchSelectionOperations[checkboxId] = operation;
        }));
    }

    handleShowAllImagesClick = (image: PageImage) => {
        this.setState(produce((draft: OwnState) => {
            const matchingPatient = draft.patients.patients.find(pp => pp.id === image.image.patientId);
            if (matchingPatient) {
                matchingPatient.showFilteredOutImages = true;
                matchingPatient.images.forEach(pi => pi.showFilteredOutStructureSets = true);

                // update checkboxes
                const newImages = matchingPatient.images.filter(pi => pi.isFilteredOut);
                const newStructureSets = newImages.flatMap(pi => pi.structureSets);
                ReferenceLibraryPage.updateBatchSelectionCheckboxes(draft, newImages, newStructureSets, this.props.dataset);
            }

        }), () => this.showPage(this.state.pageIndex || 0));
    }

    handleShowAllStructureSetsClick = (image: PageImage) => {
        this.setState(produce((draft: OwnState) => {
            const matchingPatient = draft.patients.patients.find(pp => pp.id === image.image.patientId);
            if (matchingPatient) {
                const matchingImage = matchingPatient.images.find(pi => pi.seriesId === image.seriesId);
                if (matchingImage) {
                    matchingImage.showFilteredOutStructureSets = true;

                    // update checkboxes
                    const newStructureSets = matchingImage.structureSets.filter(pss => pss.isFilteredOut);
                    ReferenceLibraryPage.updateBatchSelectionCheckboxes(draft, [], newStructureSets, this.props.dataset);
                }
            }
        }), () => this.showPage(this.state.pageIndex || 0));
    }

    onCreateTaskClick = () => {
        this.props.onOpenAddTaskDialogClick();
    }
    
    renderCreateTaskButton = () => (
        <Button variant='light' size="sm" title="Create a Task" className="mr-sm-2" onClick={this.onCreateTaskClick} data-cy="create-task-button"><span><FaPlus /> Create a Task</span></Button>
    )

    render = () => {
        const allPatients = this.state.patients.getPatients();
        const patientCount = allPatients.length;
        const paginationNeeded = this.state.paginationNeeded;

        let error = this.props.error;
        if (error) {
            if (typeof error === 'object') {
                console.log(error);
                error = "See exception details in console (F12)"
            }
            return (
                <div>
                    <p><b>Error: </b>{error}</p>
                </div>
            );
        }
        else if (!this.props.fileShare || !this.props.dataset) {
            // nothing to render if we don't have a dataset loaded
            return null;
        }
        else {
            const pagePatients = this.state.pagePatients;
            const pageCount = Math.ceil(patientCount / perPage);

            //const massCheckedValues = this.getAllMassCheckedValues();
            const areBatchColumnsVisible = this.state.showBatchControls || false;
            const selectedPatientsCount = this.countSelectionsOfType(DataItemType.Patient);
            const selectedImagesCount = this.countSelectionsOfType(DataItemType.Image);
            const selectedStructureSetsCount = this.countSelectionsOfType(DataItemType.StructureSet);
            const showCreateTaskButton = this.props.user && (this.props.user as User).permissions.isSupervisor;


            const paginationButtons = paginationNeeded ? (
                <div className="pagination-row">
                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={15}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        forcePage={this.state.pageIndex}
                    />
                </div>) : null;

            return (
                <div>   
                    {showCreateTaskButton && this.renderCreateTaskButton()}
                    
                    {paginationButtons}

                    <ReferenceLibraryTable
                        dataset={this.props.dataset}
                        pagePatients={pagePatients}
                        areBatchColumnsVisible={areBatchColumnsVisible}
                        batchSelections={this.state.batchSelections}
                        showBatchControls={this.state.showBatchControls}
                        batchSelectionOperations={this.state.batchSelectionOperations}
                        getCheckboxId={ReferenceLibraryPage.getCheckboxId}
                        handleTriStateCheckboxChange={this.handleTriStateCheckboxChange}
                        handleLockClick={this.props.onLockClick}
                        handleLoadClick={this.props.onLoadClick}
                        handleUnloadClick={this.props.onUnloadClick}
                        handleViewImageClick={this.props.onViewImageClick}
                        handleBatchOperationTypeChange={this.handleBatchOperationTypeChange}
                        handleShowAllImagesClick={this.handleShowAllImagesClick}
                        handleShowAllStructureSetsClick={this.handleShowAllStructureSetsClick}
                    />

                    {paginationButtons}


                    <BatchJobDialog
                        isVisible={this.state.isBatchJobDialogVisible}
                        onClose={this.handleBatchJobDialogClose}
                        selectedPatientsCount={selectedPatientsCount}
                        selectedImagesCount={selectedImagesCount}
                        selectedStructureSetsCount={selectedStructureSetsCount}
                        selectedAutoContours={this.getAutoContourRequestCount()}
                        requestBatchOperation={this.requestBatchOperation}
                        defaultDescription={this.state.batchJobDescription}
                        isBatchJobRequestInProgress={this.props.isBatchJobRequestLocked}
                        getCurrentSelection={this.getCurrentSelection}
                    />

                </div>
            );
        }
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(ReferenceLibraryPage);
