// Contains the main canvas that renders all the plane views defined by ViewManager

import React, { createRef } from 'react';
import { StoreState } from '../../store/store';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import SplitPane from 'react-split-pane';
import * as viewManager from '../../rtviewer-core/view-manager';
import PlaneView from './PlaneView';
import { ViewerState } from '../../rtviewer-core/viewer-state';
import { MainRenderer } from '../../rtviewer-core/webgl/main-renderer';

import './ViewGrid.css';

export const WEBGL_CANVAS_CLASS = "webgl-canvas";

type OwnProps = {
    viewerState: ViewerState,
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    refreshSwitch?: any,
    webGLRenderer?: MainRenderer,
}

class CanvasGrid extends React.Component<AllProps, OwnState> {
    displayName = CanvasGrid.name

    private rtViewerRef = createRef<HTMLDivElement>();

    constructor(props: AllProps) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        document.addEventListener('contextmenu', this.handleContextMenu);
        setTimeout(() => { 
            this.addCanvasWebGLContextLossEventListener();
        }, 2500);
        let vs = this.props.viewerState;
        let vm = vs.viewManager;

        let mainCanvas = document.getElementsByClassName(WEBGL_CANVAS_CLASS)[0] as HTMLCanvasElement;
        let secondaryCanvas = document.getElementsByClassName("secondary-canvas")[0] as HTMLCanvasElement;
        let canvasGrid = document.getElementsByClassName("canvas-grid")[0] as HTMLElement;
        let fResize = () => {
            this.resizeCanvases(100,100);
            let w = canvasGrid.offsetWidth;
            let h = canvasGrid.offsetHeight ;
            this.resizeCanvases(w, h);
            this.props.viewerState.viewManager.resize(w, h);
        };

        window.onresize = fResize;
        fResize();

        const glOrNull = mainCanvas.getContext( 'webgl2', { antialias: false, stencil: true } );
        if(!glOrNull) {
            alert('WebGL 2 is not available');
            return;
        }
        let gl = (glOrNull as WebGL2RenderingContext);

        let mainRenderer = new MainRenderer(vs, gl, secondaryCanvas);
        this.setState({webGLRenderer: mainRenderer});

        vm.addListener(this.updateViews);
        vs.addListener(this.updateViews);
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this.handleContextMenu);
        this.removeCanvasWebGLContextLossEventListener();
        this.props.viewerState.removeListener(this.updateViews);
        this.props.viewerState.viewManager.removeListener(this.updateViews);
        window.onresize = null;
    }
    addCanvasWebGLContextLossEventListener = () => {
        let mainCanvas: any = document.getElementsByClassName(WEBGL_CANVAS_CLASS)[0];
        if(mainCanvas !== undefined) {
            mainCanvas.addEventListener('webglcontextlost', (event: any) => {
                console.log('WebGL context has been lost')
                alert('WebGL has crashed. The page will be reloaded in order to restore webgl.')
                window.location.reload();
              });
        }
       
      }
    
    removeCanvasWebGLContextLossEventListener = () => {
        let mainCanvas: any = document.getElementsByClassName(WEBGL_CANVAS_CLASS)[0];
        if(mainCanvas !== undefined){
            mainCanvas.removeEventListener('webglcontextlost', (event: any) => {
                console.log('Remove context lost event listener')
            });
        }
      }

    
    handleContextMenu = (event: MouseEvent) => {
        if (this.rtViewerRef.current && this.rtViewerRef.current.contains(event.target as Node)) {
            event.preventDefault();
        }
    };
    
    resizeCanvases = (w: number, h: number) => {
        let mainCanvas: any = document.getElementsByClassName(WEBGL_CANVAS_CLASS)[0];
        mainCanvas.width = w;
        mainCanvas.height = h;
        mainCanvas.style.width = w;
        mainCanvas.style.height = h;
        let secondaryCanvas: any = document.getElementsByClassName("secondary-canvas")[0];
        secondaryCanvas.width = w;
        secondaryCanvas.height = h;
        secondaryCanvas.style.width = w;
        secondaryCanvas.style.height = h;
    }

    updateViews = () => {
        if(this.state.webGLRenderer) this.state.webGLRenderer.renderFrame();
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    handleLeftChange = (newSize: number) => {
        let vm = this.props.viewerState.viewManager;
        vm.setLeftAreaWidth(newSize);
    }

    handleRightTopChange = (newSize: number) => {
        let vm = this.props.viewerState.viewManager;
        vm.setRightTopAreaHeight(newSize);
    }

    render() {
        let vm = this.props.viewerState.viewManager;
        let isSingleView = vm.viewMode === viewManager.ViewMode.SingleView;
        return (
            <div className="canvas-grid" ref={this.rtViewerRef}>
                <div className="plane-views">
                    { isSingleView ?
                        <PlaneView view = {vm.views[0]}></PlaneView>
                    : <SplitPane className="canvas-grid-vertical-splitter" split="vertical" minSize={vm.leftAreaMinWidth} maxSize={vm.leftAreaMaxWidth} size={vm.leftAreaWidth}  onChange={this.handleLeftChange}>
                        <div className="canvas-grid-left-area">
                            <PlaneView view = {vm.views[0]}></PlaneView>
                        </div>
                        <SplitPane className="canvas-grid-right-area" split="horizontal" 
                                    minSize={vm.rightTopAreaMinHeight} 
                                    maxSize={vm.rightTopAreaMaxHeight}
                                    size={vm.rightTopAreaHeight} 
                                    onChange={this.handleRightTopChange}>
                            <div className="right-top-area">
                                <PlaneView view = {vm.views[1]}></PlaneView>
                            </div>
                            <div className="right-bottom-area">
                                <PlaneView view = {vm.views[2]}></PlaneView>
                            </div>
                        </SplitPane>
                    </SplitPane> }
                </div>
                
                <div className="canvas-wrapper">
                    <canvas className={WEBGL_CANVAS_CLASS} 
                        onContextMenu={() => false}
                        onMouseDown={() => false} 
                        ref="MainCanvas">
                    </canvas>
                    <canvas className="secondary-canvas" 
                        onContextMenu={() => false}
                        onMouseDown={() => false} 
                        ref="SecondaryCanvas">
                    </canvas>
                </div>
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(CanvasGrid);