import { Roi, RoiContours } from "../dicom/structure-set";
import { Sdf } from "./webgl/sdf/sdf";
import { SdfOperations } from "./webgl/sdf/boolean/sdf-operations";
import { ViewerState } from "./viewer-state";
import { deepCopy } from "../util";
import { mouseTools } from "./mouse-tools/mouse-tools";

const maxStackSize = 3;

class StoredRoiState {
    roi: Roi;
    sdf: Sdf;
    contours: RoiContours;
    contoursChangedInSlices: string[]; 
    contoursChangedInAllSlices: boolean; 
    roiUnsaved: boolean;
    constructor(roi: Roi, sdf: Sdf, contours: RoiContours, contoursChangedInSlices: string[], contoursChangedInAllSlices: boolean, roiUnsaved: boolean) {
        this.roi = roi;
        this.sdf = sdf;
        this.contours = contours;
        this.contoursChangedInSlices = contoursChangedInSlices;
        this.contoursChangedInAllSlices = contoursChangedInAllSlices;
        this.roiUnsaved = roiUnsaved;
    }
}

export class UndoStack {
    viewerState: ViewerState;
    undoStack: StoredRoiState[];
    redoStack: StoredRoiState[];
    constructor(vs: ViewerState) {
        this.viewerState = vs;
        this.undoStack = [];
        this.redoStack = [];
    }

    clear() {
        this.undoStack = [];
        this.redoStack = [];
    }

    canUndo(roi: Roi) {
        return this.undoStack.some(item => item.roi === roi);
    }

    canRedo(roi: Roi) {
        return this.redoStack.some(item => item.roi === roi);
    }

    redo(roi: Roi) {
        if(!this.canRedo(roi)) return;

        this.undoStack.push( this.createRoiState(roi) );

        const state = this.redoStack.filter(x => x.roi === roi).slice(-1)[0];
        this.redoStack = this.redoStack.filter(x => x !== state);
        this.applyRoiState(state);
    }

    undo( roi: Roi) {
        if(!this.canUndo(roi)) return;
        
        while(this.redoStack.length >= maxStackSize) {
            this.redoStack.shift(); // pop the first item
        }
        this.redoStack.push(this.createRoiState(roi));

        const state = this.undoStack.filter(x => x.roi === roi).slice(-1)[0];
        this.undoStack = this.undoStack.filter(x => x !== state);
        this.applyRoiState(state);
    }

    pushRoiStateBeforeEdit(roi: Roi) {
        if(!roi.sdf) return;
        
        // Adding to undo stack, need to clear redo stack for the roi
        this.redoStack = this.redoStack.filter(item => item.roi !== roi);

        while(this.undoStack.length >= maxStackSize) {
            this.undoStack.shift(); // pop the first item
        }
        this.undoStack.push( this.createRoiState(roi) );
    }

    private createRoiState(roi: Roi): StoredRoiState {
        if(!roi.sdf) throw new Error();
        const vs = this.viewerState;
        const ss = roi.structureSet;
        const sdfOps = new SdfOperations(vs.viewManager);
        const sdf = sdfOps.copy(roi.sdf, null, false);
        const contours = ss.contourData[roi.roiNumber];
        const contoursChangedInSlices = deepCopy (roi.contoursChangedInSlices );
        return new StoredRoiState(roi, sdf, contours, contoursChangedInSlices, roi.contoursChangedInAllSlices, roi.unsaved );
    }

    private applyRoiState(item: StoredRoiState): void {
        const vs = this.viewerState;
        const roi = item.roi;
        roi.sdf = item.sdf;
        roi.structureSet.contourData[roi.roiNumber] = item.contours;
        roi.contoursChangedInSlices = item.contoursChangedInSlices;
        roi.contoursChangedInAllSlices = item.contoursChangedInAllSlices;
        roi.unsaved = item.roiUnsaved;
        if(vs.activeMouseTools.includes(mouseTools.brush)) {
            mouseTools.brush.createDrawBuffer();
        }
        vs.notifyListeners();
    }
}

