import React from "react";
import { Button, Modal } from "react-bootstrap";
import { HotKeys } from "react-hotkeys";

import ModalDialog from "../../common/ModalDialog";
import { TrainingTask } from "../../../datasets/training-task";


type OwnProps = {
    isVisible: boolean,
    onClose: () => void,
    confirmType: ConfirmType,
    handleTaskAction: (task: TrainingTask) => void,
    currentTask: TrainingTask,
    onOperationSucceeded: () => void,
};

export enum ConfirmType {
    ARCHIVE = "archive",
    DELETE = "delete",
}

class ConfirmTaskOperationDialog extends React.PureComponent<OwnProps> {

    handleClose = () => {
        this.props.onClose();
    }

    handleConfirmDeletion = () => {
        this.props.handleTaskAction(this.props.currentTask);
        this.handleClose();
        this.props.onOperationSucceeded();
    }

    getMessage = (text: string) => {
        const label = this.props.currentTask.name;
        return `Are you sure you want to ${text} the task: ${label}?`;
    }

    keyMap = {
        CONFIRM_DIALOG: ["Enter"],
        CANCEL_DIALOG: ["esc"],
    };

    handlers = {
        CONFIRM_DIALOG: this.handleConfirmDeletion,
        CANCEL_DIALOG: this.handleClose,
    };

    render() {
        let text = "";
        if (this.props.confirmType === ConfirmType.DELETE) {
            text = "Delete";
        } else if (this.props.confirmType === ConfirmType.ARCHIVE) {
            text = "Archive";
        }

        return (
            <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
                <ModalDialog
                    show={this.props.isVisible}
                    onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Task {text}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {this.getMessage(text)}
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button data-cy="confirmation-btn" variant="danger" onClick={this.handleConfirmDeletion}>{text} Task</Button>
                        <Button variant="light" onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </ModalDialog>
            </HotKeys>
        );
    }
}


export default ConfirmTaskOperationDialog;
