declare let pako: any; // gzip Decompression from Pako

export function decompressGZip(arrayBuffer: ArrayBuffer) : ArrayBuffer{
    let array = new Uint8Array(arrayBuffer);
    array = pako.ungzip(array);
    return array.buffer;
}
export function compressGZip(arrayBuffer: ArrayBuffer): ArrayBuffer {
    let array = new Uint8Array(arrayBuffer);
    array = pako.gzip(array);
    return array.buffer;
}
