import React from 'react';
import { Modal, Form, Col, Button } from 'react-bootstrap';

import './NewStructureSetDialog.css';
import { PredictionModel } from '../../web-apis/contouring-options';
import { isRutherford } from '../../environments';
import ModalDialog from '../common/ModalDialog';

type OwnProps = {
    isVisible: boolean,
    isAutoContouringInProgress: boolean,
    onNewStructureCreation: (selectedNewStructureSetOption: NewStructureSetOption, contouringAction: string) => void,
    onClose: () => void,
}

type OwnState = {
    selectedNewStructureSetOption: NewStructureSetOption,
    selectedPredictionModel: PredictionModel | undefined,
    isModelOptionValid: boolean,
    isTextEntryOptionValid: boolean,
    isAutoContourOptionInProgressValid: boolean,
}

export enum NewStructureSetOption {
    NoSelection, FromScratch, AutoContour
}

class NewStructureSetDialog extends React.Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);
        const defaultNewStructureSetOption = isRutherford() ? NewStructureSetOption.FromScratch : NewStructureSetOption.AutoContour;
        this.state = {
            selectedNewStructureSetOption: defaultNewStructureSetOption,
            selectedPredictionModel: undefined,
            isModelOptionValid: true,
            isTextEntryOptionValid: true,
            isAutoContourOptionInProgressValid: true,
        };
    }

    resetValidation = () => {
        this.setState({
            isModelOptionValid: true,
            isTextEntryOptionValid: true,
            isAutoContourOptionInProgressValid: true,
        });
    }

    handleCreateNewStructureSetClick = () => {
        // validate current options:
        const model = this.state.selectedPredictionModel;

        // validate that if auto-contouring is set, a model must be selected
        if (this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour && !model) {
            this.setState({ isModelOptionValid: false });
            return;
        }

        // // validate that if auto-contouring is set to text entry, then there must actually be some text
        // if (this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour
        //     && model && model.label === customModelLabel
        //     && model.action.length < 3) {
        //     this.setState({ isTextEntryOptionValid: false })
        //     return;
        // }

        // validate that if auto-contouring is already in-progress, we can't start another one
        if (this.props.isAutoContouringInProgress && this.state.selectedNewStructureSetOption === NewStructureSetOption.AutoContour) {
            this.setState({ isAutoContourOptionInProgressValid: false });
            return;
        }



        this.resetValidation();

        // proceed if everything looks good
        // delay the the activation a bit so the dialog has time to close before possible auto-contouring is enabled. this will avoid
        // a case where any "auto-contouring is already in-progress" warnings quickly flash on the dialog that's already closing
        const action = model ? model.name : "";
        setTimeout(() => { this.props.onNewStructureCreation(this.state.selectedNewStructureSetOption, action); }, 100);
        this.handleClose();
    }

    handleClose = () => {
        this.props.onClose();
    }

    handleOptionChange = (selectedNewStructureSetOption: NewStructureSetOption) => {
        let removeModelValidation = {};
        if (selectedNewStructureSetOption !== NewStructureSetOption.AutoContour) {
            removeModelValidation = { isModelOptionValid: true, isTextEntryOptionValid: true, isAutoContourOptionInProgressValid: true };
        }
        this.setState({ selectedNewStructureSetOption, ...removeModelValidation });
    }

    handleModelChange = (model: PredictionModel | undefined) => {
        this.setState({ selectedPredictionModel: model, isModelOptionValid: true });
        this.selectAutoContouring();
    }

    selectAutoContouring = () => {
        this.setState({ selectedNewStructureSetOption: NewStructureSetOption.AutoContour });
    }

    render() {
        // let modelSelectValidation = {};
        // if (!this.state.isModelOptionValid) {
        //     modelSelectValidation = {
        //         control: (provided: any) => ({
        //             ...provided,
        //             borderColor: "#ee892c"
        //         }),
        //     }
        // }

        // let autoContouringInProgressWarningStyle = 'autocontour-in-progress';
        // if (!this.state.isAutoContourOptionInProgressValid) { autoContouringInProgressWarningStyle += ' autocontour-not-available' }

        return (
            <ModalDialog
                size="lg"
                show={this.props.isVisible}
                onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>New structure set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="new-structure-set-radio-options">
                            <Form.Row>
                                <Col>
                                    <Form.Check
                                        type="radio"
                                        label="Create an empty structure set"
                                        checked={this.state.selectedNewStructureSetOption === NewStructureSetOption.FromScratch}
                                        onChange={() => this.handleOptionChange(NewStructureSetOption.FromScratch)}
                                        id="radio-new-ss-from-scratch"
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleCreateNewStructureSetClick}>Create structure set</Button>
                    <Button variant="outline-secondary" onClick={this.handleClose}>Cancel</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default NewStructureSetDialog;
