import _ from "lodash";

export type LabelingInfo = {
    manufacturer: string;
    manufacturerModelName: string;
    systemVersion: string;
    gitHash: string;
    otherFields: LabelingKeyValuePair[];
}

export type LabelingKeyValuePair = [string, string];

export const generateLabelingInfo = (jsonObject: any): LabelingInfo => {

    const labeling: LabelingInfo = {
        manufacturer: '',
        manufacturerModelName: '',
        systemVersion: '',
        gitHash: '',
        otherFields: [],
    };

    for (const key of Object.keys(jsonObject)) {
        const value = jsonObject[key];
        if (_.isString(value)) {
            if (key === 'manufacturer') {
                labeling.manufacturer = value;
            } else if (key === 'manufacturer_model_name') {
                labeling.manufacturerModelName = value;
            } else if (key === 'system_version') {
                labeling.systemVersion = value;
            } else if (key === 'git_hash') {
                labeling.gitHash = value;
            } else {
                labeling.otherFields.push([key, value]);
            }
        }
    }

    return labeling;
}
