import React from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { TrainingTask } from "../../../datasets/training-task";
import { StoreState } from "../../../store/store";
import { connect } from "react-redux";
import * as sagas from '../../../store/sagas';
import TasksTable from "../../annotation-page/tasks-table/TasksTable";

import './ArchivedTasksDialog.css'


type OwnProps = {
    isVisible: boolean,
    handleClose(): void,
    handleViewTaskClick: (task: TrainingTask) => void,
}
type DispatchProps = {
    archiveTask: (task: TrainingTask) => void,
    unarchiveTask: (task: TrainingTask) => void,
}

type AllProps = OwnProps & DispatchProps & StoreState;

class ArchivedTasksDialog extends React.Component<AllProps> {

    render() {
        const { handleClose, isVisible } = this.props;

        return (
            <Modal show={isVisible} onHide={handleClose} className="archived">
                <Modal.Header closeButton>
                    <Modal.Title>Archived tasks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col >
                        <TasksTable showArchivedTasks={true} handleViewTaskClick={this.props.handleViewTaskClick}/>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button data-cy='archieved-tasks-close-btn' variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(ArchivedTasksDialog);