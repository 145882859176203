import { BoundingBox } from "../../../math/bounding-box";
import { Vector2f } from "../../../math/Vector2f";
import { Sdf } from "../sdf/sdf";
import { MarchingSquares } from "../utils/MarchingSquares";
import { ShaderProgram } from "./ShaderProgram";
import { VAO } from "../objects/VertexArrayObject";
import { Path, PathIterator } from "../utils/Path";
import { FrameBufferObject } from "../objects/FrameBufferObject";
import { ColorModificationAlgorithm, QuadRenderer } from "./QuadRenderer";
import { UniformVec2 } from "./uniforms/UniformVec2";
import { UniformVec3 } from "./uniforms/UniformVec3";
import { Vector3f } from "../../../math/Vector3f";
import { Plane } from "../../view";
import { createSDFTexture } from "../sdf/sdf-texture";

//render the edges of the contour
//render the stencil map, inside of the contour in red
//invert the colors in edge texture at the inside area of the stencil map

/**
 * Class that contains methods to modify SDF
 */
export class SDFRenderer {

    /**
     * Reference to WebGL2RenderingContext
     */
    private gl: WebGL2RenderingContext;

    /**
     * One of the FrameBufferObjects used in the rendering process
     */
    private distanceFBO?: FrameBufferObject;

    /**
     * Another one of the FrameBufferObjects used in the rendering process
     */
    private stencilFBO?: FrameBufferObject;

    /**
     * Shader that is used to render distance for straight lines
     */
    private edgeDistanceShader: EdgeDistanceShader;

    /**
     * Shader that is used to render distance for individual points (vertices)
     */
    private cornerDistanceShader: CornerDistanceShader;

    /**
     * Shader that is used to render distance for individual points (vertices) <br>
     * in the case of margin operation.
     */
    private marginCornerShader: MarginCornerShader;

    /**
     * Shader that is used to generate stencil map for the contours
     */
    private stencilShader: StencilShader;

    /**
     * Shader that may be used to render contours' outlines
     */
    private outlineShader: OutlineShader;

    /**
     * Default constructor that generates all the shaders.<br>
     * It is important to remember to delete SDFRenderer once it is no more needed,<br>
     * as it allocates quite some memory.
     * @param gl Reference to WebGL2RenderingContext
     */
    public constructor(gl: WebGL2RenderingContext){
        this.gl = gl;
        this.edgeDistanceShader = new EdgeDistanceShader(gl);
        this.cornerDistanceShader = new CornerDistanceShader(gl);
        this.marginCornerShader = new MarginCornerShader(gl);
        this.stencilShader = new StencilShader(gl);
        this.outlineShader = new OutlineShader(gl);
    }

    /**
     * Sets up distanceFBO and stencilFBO ready for use.
     * If FrameBuffers exist and have the required dimensions, the data contained within is just erased.
     * Otherwise old FrameBuffers are completely deleted and new FBOs created for upcoming operations.
     * @param width FrameBuffers' width in pixels.
     * @param height FrameBuffers' height in pixels.
     */
    private prepare(width: number, height: number){
        if(this.distanceFBO && this.distanceFBO.getWidth() === width && this.distanceFBO.getHeight() === height && this.stencilFBO){
            this.gl.stencilMask(0xFF);
            this.distanceFBO.bind();
            this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT | this.gl.STENCIL_BUFFER_BIT);
            this.stencilFBO.bind();
            this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT | this.gl.STENCIL_BUFFER_BIT);
            this.stencilFBO.unbind();
            this.gl.stencilMask(0x00);
        } else {
            this.deleteFrameBuffers();
            
            this.distanceFBO = new FrameBufferObject(this.gl, width, height);
            this.distanceFBO.createTextureAttachment(this.gl.COLOR_ATTACHMENT0, this.gl.R8, this.gl.RED, this.gl.UNSIGNED_BYTE);
            this.distanceFBO.createBufferAttachment(this.gl.DEPTH_STENCIL_ATTACHMENT, this.gl.DEPTH24_STENCIL8); //TODO: Could even use 32F?
            this.distanceFBO.checkStatus();
            this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
            this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT | this.gl.STENCIL_BUFFER_BIT);

            this.stencilFBO = new FrameBufferObject(this.gl, width, height);
            this.stencilFBO.createTextureAttachment(this.gl.COLOR_ATTACHMENT0, this.gl.R8, this.gl.RED, this.gl.UNSIGNED_BYTE);
            this.stencilFBO.createBufferAttachment(this.gl.DEPTH_STENCIL_ATTACHMENT, this.gl.DEPTH24_STENCIL8);
            this.stencilFBO.checkStatus();
            this.gl.clear(this.gl.COLOR_BUFFER_BIT | this.gl.DEPTH_BUFFER_BIT | this.gl.STENCIL_BUFFER_BIT);
            this.stencilFBO.unbind();
        }
    }

    /**
     * Method that will render a distance field texture for a single SDF slice.<br>
     * Distance field texture will be rendered on the distanceFBO.<br>
     * 
     * @param sdf SDF which the distance field texture is generated for
     * @param zCoordinate the z-coordinate of the slice which the SDF calculations are performed on
     * @param innerDistance maximum distance within the mesh
     * @param outerDistance maximum distance outside the mesh
     * @param resultBoundingBox BoundingBox of the output texture
     * @param resolution (Optional) Resolution of the operation. The higher the resolution, the more smooth the answer is. Default value of 2 is quite relatively good and fast.
     * @param renderOutlines? (Optional) Boolean controlling whether result should have visible edges. Mostly for debugging purposes. Defaults to false.
     */
    public renderSDF(sdf: Sdf, zCoordinate: number, innerDistance: number, outerDistance: number, resultBoundingBox: BoundingBox, resolution: number = 2.0, renderOutlines: boolean = false): boolean{
        if(innerDistance < 0 || outerDistance < 0){
            console.error("Inner and outer distance must be greater than or equal to 0.");
            return false;
        }

        const width = resolution * resultBoundingBox.getXSize()/sdf.resolutionMm;
        const height = resolution * resultBoundingBox.getYSize()/sdf.resolutionMm;
        this.prepare(width, height);
        if(!this.distanceFBO || !this.stencilFBO){
            return false;
        }

        const marchingSquares = new MarchingSquares(this.gl);
        const paths = marchingSquares.isoLines(sdf, Plane.Transversal, zCoordinate);
        marchingSquares.delete();

        if(!paths || paths.length <= 0){
            return false;
        }

        const center = new Vector2f((resultBoundingBox.minI + resultBoundingBox.maxI)*0.5,
        (resultBoundingBox.minJ + resultBoundingBox.maxJ) * 0.5);
        const scale = new Vector2f(width, height).scale(sdf.resolutionMm/(2.0*resolution));
        
        for(let path of paths){
            this.renderStencil(path, center, scale);
        }
        
        this.gl.enable(this.gl.BLEND);
        this.gl.blendEquation(this.gl.MAX);
        for(let path of paths){
            this.renderDistance(path, innerDistance, outerDistance, center, scale);
            this.renderCorners(path, innerDistance, outerDistance, center, scale);
        }

        this.renderFinalTexture();
        
        if(renderOutlines){
            this.renderOutlines(paths, new Vector3f(1.0, 0.0, 0.0), center, scale);
        }
        return true;
    }

    /**
     * Method that is used to create margins for an SDF.<br>
     * This is somewhat heavy process. To generate margin, the following steps are performed on every slice:<br>
     * 1. SourceSDF is turned into path(s).
     * 2. Distance texture with margins applied, is generated from the path.
     * 3. Path(s) are generated from the distance texture. So resulting path(s) have margins applied.
     * 4. Distance texture with inner- & outerdistance equal to maxDistanceMm is generated from the paths. (so the result format is the same as source format)
     * @param sourceSdf Sdf the margin operation is performed for.
     * @param innerMargin Boolean controlling whether the margin is inner(true) or outer(false).
     * @param leftMargin Left margin in mm.
     * @param rightMargin Right margin in mm.
     * @param posteriorMargin Posterior margin in mm.
     * @param anteriorMargin Anterior margin in mm.
     * @param cranialMargin Cranial margin in mm.
     * @param caudalMargin Caudal margin in mm.
     * @returns SDF containing the result of the margin tool operation.
     */
    //TODO: Should probably try to divide this into smaller steps at some point.. I'm leaving it as is to save time.
    public renderMargin(sourceSdf: Sdf, innerMargin: boolean, leftMargin: number, rightMargin: number, posteriorMargin: number, anteriorMargin: number, cranialMargin: number, caudalMargin: number) {
        const resolution = 2.0;

        //TODO: Most of the values used here or other methods make no damn sense.
        //But either way these values work, so we are happy - I guess?
        const resultBoundingBox = sourceSdf.boundingBox.copy();
        if(innerMargin){
            resultBoundingBox.minI += leftMargin;
            resultBoundingBox.maxI -= rightMargin;
            resultBoundingBox.minJ += anteriorMargin;
            resultBoundingBox.maxJ -= posteriorMargin;
            resultBoundingBox.minK += caudalMargin;
            resultBoundingBox.maxK -= cranialMargin;
        } else {
            resultBoundingBox.minI -= leftMargin;
            resultBoundingBox.maxI += rightMargin;
            resultBoundingBox.minJ -= anteriorMargin;
            resultBoundingBox.maxJ += posteriorMargin;
            resultBoundingBox.minK -= caudalMargin + cranialMargin;
            resultBoundingBox.maxK += caudalMargin + cranialMargin;
        }
        resultBoundingBox.roundToFullPixels(sourceSdf.viewManager.image);
        const resultSdf = new Sdf(sourceSdf.viewManager, sourceSdf.resolutionMm);
        resultSdf.boundingBox = resultBoundingBox;
        resultSdf.createTexture(resultBoundingBox, false, true);

        const width = resolution * resultSdf.size[0];
        const height = resolution * resultSdf.size[1];

        this.prepare(width, height);
        if(!this.distanceFBO || !this.stencilFBO){
            console.error("SDFRenderer couldn't initialize buffers");
            return;
        }

        const quadRenderer = new QuadRenderer(this.gl);
        const sliceThickness = sourceSdf.viewManager.image.kSpacing;
        const marchingSquares = new MarchingSquares(this.gl);
        //const sourceSize = sourceSdf.boundingBox.maxZ - sourceSdf.boundingBox.minZ; //deltaZ
        const sliceThicknessRelativeToSdf = sliceThickness / sourceSdf.boundingBox.getZSize();

        let resultFBO: FrameBufferObject;
        //TODO: Render SDF pitch black to avoid errors with narrow textures
        //Commented out code is left here as it might be used in the future.

        /*let black = createSDFTexture(this.gl, sourceSdf.size, true);
        resultFBO = new FrameBufferObject(this.gl, sourceSdf.size[0], sourceSdf.size[1]);
        for(let i = 0; i <= sourceSdf.size[2]; ++i){
            this.prepare(width, height);
            this.renderSDF(sourceSdf, (i+0.5)/sourceSdf.size[2], 0.0, 0.1, sourceSdf.boundingBox);
            resultFBO.bind();
            resultFBO.createTextureLayer(black, i);
            quadRenderer.render(this.distanceFBO, true);
            resultFBO.unbind();
        }
        resultFBO.delete();
        sourceSdf.data = black;*/
        //create margins in Z-direction first
        const cranialCaudal = cranialMargin > 0.0 || caudalMargin > 0.0;
        if(cranialCaudal){
            //To render Z-direction, we choose plane between coronal/sagittal with least slices
            let plane;
            let size: number[];
            let center: Vector2f;
            let sourceSize: number;
            let deltaMin: number;
            if(resultBoundingBox.getXSize() < resultBoundingBox.getYSize()){
                plane = Plane.Sagittal;
                size = [resultSdf.size[1], resultSdf.size[2], resultSdf.size[0]];
                center = new Vector2f((resultBoundingBox.minJ + resultBoundingBox.maxJ) * 0.5,
                (resultBoundingBox.minK + resultBoundingBox.maxK) * 0.5);
                sourceSize = sourceSdf.size[0] * sourceSdf.resolutionMm;
                deltaMin = resultBoundingBox.minI - sourceSdf.boundingBox.minI;
            } else {
                plane = Plane.Coronal;
                size = [resultSdf.size[0], resultSdf.size[2], resultSdf.size[1]];
                center = new Vector2f((resultBoundingBox.minI + resultBoundingBox.maxI) * 0.5,
                (resultBoundingBox.minK + resultBoundingBox.maxK) * 0.5);
                sourceSize = sourceSdf.size[1] * sourceSdf.resolutionMm;
                deltaMin = resultBoundingBox.minJ - sourceSdf.boundingBox.minJ;
            }

            const width = resolution * size[0];
            const height = resolution * size[1];
            const scale = new Vector2f(size[0] * resultSdf.resolutionMm, size[1] * sliceThickness).scale(0.5);    

            resultFBO = new FrameBufferObject(this.gl, size[0], size[1]);
            //then content is rotated in plane = Z and after that it needs to be rotated back to transversal = Z
            //create 3D-texture
            let rotated = createSDFTexture(this.gl, size, false);
            //for each slice
            for(let i = 0; i <= size[2]; ++i){
                let sourceZ = ((i+0.5)*sourceSdf.resolutionMm + deltaMin) / sourceSize;
                //let sourceZ = (i+0.5) * sourceSdf.resolutionMm
                //generate path from resultSdf

                let paths: Path[] | null;
                //const paths = marchingSquares.isoLines(sourceSdf, plane, i/size[2], true);
                //render path with margin to 3D-texture
                if((paths = marchingSquares.isoLines(sourceSdf, plane, sourceZ)) && paths.length > 0){
                    this.prepare(size[0], size[1]);

                    //first render stencil
                    for(let path of paths){
                        this.renderStencil(path, center, scale);
                    }
                    //then render distance & corners on stencilFBO
                    this.gl.enable(this.gl.BLEND);
                    this.gl.blendEquation(this.gl.MAX);
                    for(let path of paths){
                        if(innerMargin){
                            this.renderMarginEdges(path, innerMargin, 0, 0, caudalMargin, cranialMargin, center, scale);
                            this.renderMarginCorners(path, innerMargin, 0, 0, caudalMargin, cranialMargin, center, scale);    
                        } else {
                            this.renderMarginEdges(path, innerMargin, 0, 0, 2*caudalMargin, 2*cranialMargin, center, scale);
                            this.renderMarginCorners(path, innerMargin, 0, 0, 2*caudalMargin, 2*cranialMargin, center, scale);    
                        }
                    }

                    this.gl.disable(this.gl.BLEND);
                    
                    this.renderFinalTexture(quadRenderer);

                    resultFBO.bind();
                    resultFBO.createTextureAttachment(this.gl.COLOR_ATTACHMENT0, this.gl.R8, this.gl.RED, this.gl.UNSIGNED_BYTE);
                    quadRenderer.renderColorModification(innerMargin ? 0.5 : 2.0, ColorModificationAlgorithm.NONE, 0.0, this.distanceFBO);

                    //at this point scaling FBO contains post margin SDF.
                    resultFBO.unbind();
                    //renderToCanvas(this.gl, resultFBO);

                    const destPaths = plane === Plane.Coronal ? marchingSquares.isoLines(resultFBO, resultBoundingBox.minI, resultBoundingBox.minK, sourceSdf.resolutionMm) : marchingSquares.isoLines(resultFBO, resultBoundingBox.minJ, resultBoundingBox.minK, sourceSdf.resolutionMm);
                    if(destPaths && destPaths.length > 0){                    
                        this.prepare(width, height);
                        for(let path of destPaths){
                            this.renderStencil(path, center, scale);
                        }

                        this.gl.enable(this.gl.BLEND);
                        this.gl.blendEquation(this.gl.MAX);
                        for(let path of destPaths){
                            this.renderDistance(path, sourceSdf.maxDistanceMm, sourceSdf.maxDistanceMm, center, scale);
                            this.renderCorners(path, sourceSdf.maxDistanceMm, sourceSdf.maxDistanceMm, center, scale);
                        }

                        this.gl.disable(this.gl.BLEND);

                        this.renderFinalTexture(quadRenderer);

                        resultFBO.bind();
                        resultFBO.createTextureLayer(rotated, i);
                        quadRenderer.render(this.distanceFBO, true);
                        resultFBO.unbind();
                        continue;
                    }
                }
                resultFBO.bind();
                //TODO: IMO it's very weird that SDF value 1.0 is outside the contour
                //Changing that would be a lot of work though.
                this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
                resultFBO.createTextureLayer(rotated, i);
                this.gl.clear(this.gl.COLOR_BUFFER_BIT);
                resultFBO.unbind();
            }
            resultFBO.delete();
            //resultSdf.data = rotated;
            this.gl.bindTexture(this.gl.TEXTURE_3D, rotated);
            quadRenderer.renderOrientationModification(resultSdf, plane);
        } else if(innerMargin){
            const deltaY = posteriorMargin - anteriorMargin;
            resultBoundingBox.minJ += deltaY;
            resultBoundingBox.maxJ += deltaY;
        }
        resultFBO = new FrameBufferObject(this.gl, resultSdf.size[0], resultSdf.size[1]);
        //TODO: Could be optimized by choosing directions where size is smallest.
        //TODO: Also could ignore directions where margin is 0.
        let sourceSize = sourceSdf.size[2] * sourceSdf.viewManager.image.kSpacing;
        const center = new Vector2f((resultBoundingBox.minI + resultBoundingBox.maxI) * 0.5,
        (resultBoundingBox.minJ + resultBoundingBox.maxJ) * 0.5);
        const scale = new Vector2f(width, height).scale(resultSdf.resolutionMm/(2.0*resolution));
        for(let i = 0, maxIndex = resultBoundingBox.getZSize()/sliceThickness; i <= maxIndex; i++){
            let sourceZ: number;
            //TODO: Having these if-statements within this loop is not optimal
            //I tried to make brancless approach, but it didn't work.. to save time, let's keep it simple
            if(cranialCaudal){
                sourceZ = (i+0.5)/maxIndex;
            } else {
                sourceZ = ((i+0.5)*sliceThickness + resultBoundingBox.minK - sourceSdf.boundingBox.minK) / sourceSize;
                if(sourceZ < sliceThicknessRelativeToSdf){
                    continue;
                } else if(sourceZ > 1.0 - sliceThicknessRelativeToSdf){
                    break;
                }
            }
                        
            const paths = marchingSquares.isoLines(cranialCaudal ? resultSdf : sourceSdf, Plane.Transversal, sourceZ);

            if(!paths || paths.length <= 0){
                continue;
            }

            this.prepare(width, height);

            //first render stencil
            for(let path of paths){
                this.renderStencil(path, center, scale);
            }
            //then render distance & corners on stencilFBO
            this.gl.enable(this.gl.BLEND);
            this.gl.blendEquation(this.gl.MAX);
            for(let path of paths){
                if(innerMargin){
                    if(cranialCaudal){
                        this.renderMarginEdges(path, innerMargin, leftMargin, rightMargin, anteriorMargin, posteriorMargin, center, scale);
                        this.renderMarginCorners(path, innerMargin, leftMargin, rightMargin, anteriorMargin, posteriorMargin, center, scale);
                    } else {
                        this.renderMarginEdges(path, innerMargin, rightMargin, leftMargin, posteriorMargin, anteriorMargin, center, scale);
                        this.renderMarginCorners(path, innerMargin, rightMargin, leftMargin, posteriorMargin, anteriorMargin, center, scale);
                    }    
                } else {
                    if(cranialCaudal){
                        this.renderMarginEdges(path, innerMargin, 2*leftMargin, 2*rightMargin, 2*anteriorMargin, 2*posteriorMargin, center, scale);
                        this.renderMarginCorners(path, innerMargin, 2*leftMargin, 2*rightMargin, 2*anteriorMargin, 2*posteriorMargin, center, scale);    
                    } else {
                        this.renderMarginEdges(path, innerMargin, 2*leftMargin, 2*rightMargin, 2*posteriorMargin, 2*anteriorMargin, center, scale);
                        this.renderMarginCorners(path, innerMargin, 2*leftMargin, 2*rightMargin, 2*posteriorMargin, 2*anteriorMargin, center, scale);    
                    }
                }
            }

            this.gl.disable(this.gl.BLEND);
            
            this.renderFinalTexture(quadRenderer);

            resultFBO.bind();
            resultFBO.createTextureAttachment(this.gl.COLOR_ATTACHMENT0, this.gl.R8, this.gl.RED, this.gl.UNSIGNED_BYTE);
            quadRenderer.renderColorModification(innerMargin ? 0.5 : 2.0, ColorModificationAlgorithm.NONE, 0.0, this.distanceFBO);

            //at this point scaling FBO contains post margin SDF.
            resultFBO.unbind();

            const destPaths = marchingSquares.isoLines(resultFBO, resultBoundingBox.minI, resultBoundingBox.minJ, sourceSdf.resolutionMm);
            if(!destPaths || destPaths.length < 1){
                continue;
            }
            
            this.prepare(width, height);
            for(let path of destPaths){
                this.renderStencil(path, center, scale);
            }

            this.gl.enable(this.gl.BLEND);
            this.gl.blendEquation(this.gl.MAX);
            for(let path of destPaths){
                this.renderDistance(path, sourceSdf.maxDistanceMm, sourceSdf.maxDistanceMm, center, scale);
                this.renderCorners(path, sourceSdf.maxDistanceMm, sourceSdf.maxDistanceMm, center, scale);
            }

            this.gl.disable(this.gl.BLEND);

            this.renderFinalTexture(quadRenderer);

            resultFBO.bind();
            resultFBO.createTextureLayer(resultSdf.data, i);
            quadRenderer.render(this.distanceFBO, true);
            resultFBO.unbind();
        }
        resultFBO.delete();
        quadRenderer.delete();
        marchingSquares.delete();
        return resultSdf;
    }

    /**
     * A method to clear memory allocated by this SDFRenderer object.<br>
     * One should always remember to call this after SDFRenderer is no longer needed.
     */
    public delete(){
        this.edgeDistanceShader.delete();
        this.stencilShader.delete();
        this.cornerDistanceShader.delete();
        this.marginCornerShader.delete();
        this.outlineShader.delete();
        this.deleteFrameBuffers();
    }

    /**
     * A separate method to free memory allocated by FrameBufferObjects.<br>
     * This functionality is required in a couple of places within the class.<br>
     */
    private deleteFrameBuffers(): void {
        if(this.distanceFBO){
            this.distanceFBO.delete();
        }
        if(this.stencilFBO){
            this.stencilFBO.delete();
        }
    }

    /**
     * A method used to render path outlines on the distanceFBO.
     * @param paths Array of paths, each representing a loop of consecutive vertices.
     * @param color Outline color. Values clamped between 0 and 1.
     * @param center Texture's center coordinates in mm. Used to control coordinate transformations.
     * @param scale Texture's width and height in mm. Used to control coordinate transformations.
     * @returns true if outlines are rendered successfully, false otherwise.
     */
    public renderOutlines(paths: Path[], color: Vector3f, center: Vector2f, scale: Vector2f): boolean{
        if(!this.distanceFBO){
            return false;
        }
        this.distanceFBO.bind();
        if(paths && paths.length > 0){
            for(let path of paths){
                let vertices = new Array<Vector2f>(path.length);
                let index = 0;
                for(let vertex of path){
                    vertices[index++] = vertex;
                }
                let vao = new VAO(this.gl);
                vao.bind();
                vao.createAttribute(0, this.gl.FLOAT, toFloatArray(vertices, center, scale), 2);
                vao.bind(0);

                this.outlineShader.start();
                this.outlineShader.color.loadVec3(color);
                this.gl.drawArrays(this.gl.LINE_STRIP, 0, path.length);
                this.outlineShader.stop();

                vao.unbind(0);
                vao.delete();
            }
        }
        this.distanceFBO.unbind();
        return true;
    }

    /**
     * Method used to render margin distance to each edge.
     * @param path Path that the distances are rendered for.
     * @param innerMargin Boolean controlling whether the margin is inner(true) or outer(false).
     * @param leftMargin Left margin in mm in the projection space (may be different from the 3D space left margin).
     * @param rightMargin Right margin in mm in the projection space (may be different from the 3D space right margin).
     * @param topMargin Top margin in mm in the projection space.
     * @param bottomMargin Bottom margin in mm in the projection space.
     * @param center The center coordinates in mm in the projection space.
     * @param scale The scale in mm in the projection space.
     * @returns True if margin edges are rendered successfully, false otherwise
     */
    public renderMarginEdges(path: Path, innerMargin: boolean, leftMargin: number, rightMargin: number, topMargin: number, bottomMargin: number, center: Vector2f, scale: Vector2f): boolean {
        //render to distanceFBO's color attachment 0.
        this.gl.enable(this.gl.DEPTH_TEST);
        this.gl.enable(this.gl.STENCIL_TEST);

        if(!this.stencilFBO){
            return false;
        }
        this.stencilFBO.bind();
        
        let iterator = new PathIterator(path.fillOnRight ? path.front : path.back);
        let previous: Vector2f = iterator.next().value;
        let current: IteratorResult<Vector2f>;
        let index1 = 0;
        let index2 = 0;
        let currentEdge = 0;
        const numberOfEdges = path.length - 1;
        const numberOfVertices = numberOfEdges * 3;
        let vertices = new Array<Vector2f>(numberOfVertices);
        let indices = new Array<number>(numberOfEdges * 6);
        let distances = new Array<number>(numberOfVertices);

        if(innerMargin){
            //inner only 
            while(!(current = iterator.next()).done){
                const delta = new Vector2f(previous).subtract(current.value).normalize();
                const right = new Vector2f(delta.y, -delta.x);
                const dest = getDest(right, leftMargin, rightMargin, topMargin, bottomMargin);
                vertices[index1] = new Vector2f(previous);
                distances[index1++] = 0.5;
                vertices[index1] = new Vector2f(dest).add(previous);
                distances[index1++] = 0.0;
                vertices[index1] = new Vector2f(dest).add(current.value);
                distances[index1++] = 0.0;
                const min = currentEdge * 3;
                const max = (min+3)%numberOfVertices;
                indices[index2++] = max;
                indices[index2++] = min+2;
                indices[index2++] = min;
                indices[index2++] = min;
                indices[index2++] = min+2;
                indices[index2++] = min+1;
                currentEdge++;
                previous = current.value;
            }
        } else {
            //outer only
            while(!(current = iterator.next()).done){
                const delta = new Vector2f(previous).subtract(current.value).normalize();
                const left = new Vector2f(-delta.y, delta.x);
                const dest = getDest(left, leftMargin, rightMargin, topMargin, bottomMargin);
                vertices[index1] = new Vector2f(dest).add(previous);
                distances[index1++] = 0.0;
                vertices[index1] = new Vector2f(dest).add(current.value);
                distances[index1++] = 0.0;
                vertices[index1] = new Vector2f(previous);
                distances[index1++] = 0.5;
                const min = currentEdge * 3;
                const max = (min+5)%numberOfVertices;
                indices[index2++] = min+1;
                indices[index2++] = max;
                indices[index2++] = min;
                indices[index2++] = min;
                indices[index2++] = max;
                indices[index2++] = min+2;
                currentEdge++;
                previous = current.value;
            }
        }
        
        let vao = new VAO(this.gl);
        vao.bind();
        vao.createIndexBuffer(indices);
        vao.createAttribute(0, this.gl.FLOAT, toFloatArray(vertices, center, scale), 2);
        vao.createAttribute(1, this.gl.FLOAT, distances, 1);
        vao.bind(0, 1);
        this.edgeDistanceShader.start();
        this.gl.stencilFunc(this.gl.EQUAL, innerMargin ? 0xFF : 0, 0xFF);
        this.gl.drawElements(this.gl.TRIANGLES, vao.getIndexCount(), this.gl.UNSIGNED_INT, 0);
        this.edgeDistanceShader.stop();
        vao.unbind(0, 1);
        vao.delete();

        this.gl.disable(this.gl.DEPTH_TEST);
        this.gl.disable(this.gl.STENCIL_TEST);
        return true;
    }

    /**
     * Method to render SDF edge distances on the distance texture.<br>
     * This renders border as 0.5 and color values inwards and outwards from the edge going linearly down to 0.
     * @param path Path being a loop of consecutive vertices representing the edges being rendered
     * @param innerDistance distance at which inner color turns into 0. (1 after colors are inverted)
     * @param outerDistance distance at which outer color turns into 0.
     * @param center The center point in mm in projection space. (Currently always transversal)
     * @param scale The scale in mm in projection space. (Currently always transversal)
     * @returns True if distances are rendered successfully, false otherwise
     */
    public renderDistance(path: Path, innerDistance: number, outerDistance: number, center: Vector2f, scale: Vector2f): boolean {
        //render to distanceFBO's color attachment 0.
        this.gl.enable(this.gl.DEPTH_TEST);

        if(!this.stencilFBO){
            return false;
        }
        this.stencilFBO.bind();

        let vertices: Vector2f[];
        let indices: number[];
        let distances: number[];
        let iterator = new PathIterator(path.fillOnRight ? path.front : path.back);
        let previous: Vector2f = iterator.next().value;
        let current: IteratorResult<Vector2f>;
        let index1 = 0;
        let index2 = 0;
        let currentEdge = 0;
        const numberOfEdges = path.length - 1;
        //Looks a bit ugly, but performance should be somewhat good
        if(innerDistance <= 0.0){
            if(outerDistance > 0.0){
                //outer only
                const numberOfVertices = numberOfEdges * 3;
                vertices = new Array<Vector2f>(numberOfVertices);
                indices = new Array<number>(numberOfEdges * 6);
                distances = new Array<number>(numberOfVertices);
                while(!(current = iterator.next()).done){
                    const delta = new Vector2f(previous).subtract(current.value).normalize();
                    const left = new Vector2f(-delta.y, delta.x);
                    vertices[index1] = new Vector2f(left).scale(outerDistance).add(previous);
                    distances[index1++] = 0.0;
                    vertices[index1] = new Vector2f(left).scale(outerDistance).add(current.value);
                    distances[index1++] = 0.0;
                    vertices[index1] = new Vector2f(previous);
                    distances[index1++] = 0.5;
                    const min = currentEdge * 3;
                    const max = (min+5)%numberOfVertices;
                    indices[index2++] = min+1;
                    indices[index2++] = max;
                    indices[index2++] = min;
                    indices[index2++] = min;
                    indices[index2++] = max;
                    indices[index2++] = min+2;
                    currentEdge++;
                    previous = current.value;
                }
            } else {
                console.error("Performing SDF rendering with inner and outerdistance <= 0.0");
                return false;
            }
        } else if(outerDistance > 0.0){
            //render inner and outer
            const numberOfVertices = numberOfEdges * 5;
            vertices = new Array<Vector2f>(numberOfVertices);
            indices = new Array<number>(numberOfEdges * 12);
            distances = new Array<number>(numberOfVertices);
            while(!(current = iterator.next()).done){
                const delta = new Vector2f(previous).subtract(current.value).normalize();
                const left = new Vector2f(-delta.y, delta.x);
                vertices[index1] = new Vector2f(left).scale(outerDistance).add(previous);
                distances[index1++] = 0.0;
                vertices[index1] = new Vector2f(left).scale(outerDistance).add(current.value);
                distances[index1++] = 0.0;
                vertices[index1] = new Vector2f(previous);
                distances[index1++] = 0.5;
                vertices[index1] = new Vector2f(left).scale(-innerDistance).add(previous);
                distances[index1++] = 0.0;
                vertices[index1] = new Vector2f(left).scale(-innerDistance).add(current.value);
                distances[index1++] = 0.0;
                const min = currentEdge * 5;
                const max = (min+7)%numberOfVertices;
                indices[index2++] = min+1;
                indices[index2++] = max;
                indices[index2++] = min;
                indices[index2++] = min;
                indices[index2++] = max;
                indices[index2++] = min+2;
                indices[index2++] = max;
                indices[index2++] = min+4;
                indices[index2++] = min+2;
                indices[index2++] = min+2;
                indices[index2++] = min+4;
                indices[index2++] = min+3;
                currentEdge++;
                previous = current.value;
            }
        } else {
            //inner only
            const numberOfVertices = numberOfEdges * 3;
            vertices = new Array<Vector2f>(numberOfVertices);
            indices = new Array<number>(numberOfEdges * 6);
            distances = new Array<number>(numberOfVertices);
            while(!(current = iterator.next()).done){
                const delta = new Vector2f(previous).subtract(current.value).normalize();
                const right = new Vector2f(delta.y, -delta.x);
                vertices[index1] = new Vector2f(previous);
                distances[index1++] = 0.5;
                vertices[index1] = new Vector2f(right).scale(innerDistance).add(previous);
                distances[index1++] = 0.0;
                vertices[index1] = new Vector2f(right).scale(innerDistance).add(current.value);
                distances[index1++] = 0.0;
                const min = currentEdge * 3;
                const max = (min+3)%numberOfVertices;
                indices[index2++] = max;
                indices[index2++] = min+2;
                indices[index2++] = min;
                indices[index2++] = min;
                indices[index2++] = min+2;
                indices[index2++] = min+1;
                currentEdge++;
                previous = current.value;
            }
        }

        let vao = new VAO(this.gl);
        vao.bind();
        vao.createIndexBuffer(indices);
        vao.createAttribute(0, this.gl.FLOAT, toFloatArray(vertices, center, scale), 2);
        vao.createAttribute(1, this.gl.FLOAT, distances, 1);
        vao.bind(0, 1);
        this.edgeDistanceShader.start();
        this.gl.drawElements(this.gl.TRIANGLES, vao.getIndexCount(), this.gl.UNSIGNED_INT, 0);
        this.edgeDistanceShader.stop();
        vao.unbind(0, 1);
        vao.delete();

        this.gl.disable(this.gl.DEPTH_TEST);
        return true;
    }

    /**
     * Method to render SDF corner distances.<br>
     * This renders distance relative to each of the contour's corner points
     * @param path Path whose corner distances are being rendered.
     * @param innerDistance The maximum inner distance in mm, at which point color turns to 0.
     * @param outerDistance The maximum outer distance in mm, at which point color turns to 0.
     * @param center Center coordinate in mm in projection space. (Currently always transversal)
     * @param scale Scale in mm in projection space. (Currently always transversal)
     * @returns True if corners are rendered successfully, false otherwise
     */
    public renderCorners(path: Path, innerDistance: number, outerDistance: number, center: Vector2f, scale: Vector2f): boolean {
        this.gl.enable(this.gl.DEPTH_TEST);
        this.gl.enable(this.gl.STENCIL_TEST);

        if(!this.stencilFBO){
            return false;
        }
        this.stencilFBO.bind();

        let iterator = new PathIterator(path.fillOnRight ? path.front : path.back);
        iterator.next(); // skip the first vertex as it's mapped twice
        let current: IteratorResult<Vector2f>;
        let index1 = 0;
        let index2 = 0;
        let index3 = 0;
        let numberOfCorners = path.length - 1;
        let vertices = new Array<Vector2f>(numberOfCorners * 4);
        let directions = new Array<number>(numberOfCorners * 8);
        let indices = new Array<number>(numberOfCorners * 6);
        while(!(current = iterator.next()).done){
            indices[index2++] = index1;
            indices[index2++] = index1+1;
            indices[index2++] = index1+2;
            indices[index2++] = index1+2;
            indices[index2++] = index1+1;
            indices[index2++] = index1+3;
            const center = new Vector2f(current.value.x, current.value.y);
            vertices[index1++] = center;
            vertices[index1++] = center;
            vertices[index1++] = center;
            vertices[index1++] = center;
            directions[index3++] = -1.0;
            directions[index3++] = 1.0;
            directions[index3++] = -1.0;
            directions[index3++] = -1.0;
            directions[index3++] = 1.0;
            directions[index3++] = 1.0;
            directions[index3++] = 1.0;
            directions[index3++] = -1.0;
        }

        let vao = new VAO(this.gl);
        vao.bind();
        vao.createIndexBuffer(indices);
        vao.createAttribute(0, this.gl.FLOAT, toFloatArray(vertices, center, scale), 2);
        vao.createAttribute(1, this.gl.FLOAT, directions, 2);
        vao.bind(0, 1);
        this.cornerDistanceShader.start();
        if(innerDistance > 0.0){
            //render inner distance
            this.cornerDistanceShader.scale.loadVec2(new Vector2f(innerDistance/scale.x, innerDistance/scale.y));
            this.gl.stencilFunc(this.gl.EQUAL, 0xFF, 0xFF); //only render fragments where stencil value is 1
            this.gl.drawElements(this.gl.TRIANGLES, vao.getIndexCount(), this.gl.UNSIGNED_INT, 0);
        }
        if(outerDistance > 0.0){
            //render outer distance
            this.cornerDistanceShader.scale.loadVec2(new Vector2f(outerDistance/scale.x, outerDistance/scale.y));
            this.gl.stencilFunc(this.gl.EQUAL, 0, 0xFF); //only render fragments where stencil value is 0
            this.gl.drawElements(this.gl.TRIANGLES, vao.getIndexCount(), this.gl.UNSIGNED_INT, 0);
        }
        this.cornerDistanceShader.stop();

        vao.unbind(0, 1);
        vao.delete();

        this.gl.disable(this.gl.DEPTH_TEST);
        this.gl.disable(this.gl.STENCIL_TEST);
        return true;
    }

    /**
     * Method to render SDF margin corner distances.
     * @param path Path which corner distances are rendered for.
     * @param innerMargin Boolean controlling whether the margin is inner(true) or outer(false).
     * @param leftMargin Left margin in mm in the projection space (may be different from the 3D space left margin).
     * @param rightMargin Right margin in mm in the projection space (may be different from the 3D space right margin).
     * @param topMargin Top margin in mm in the projection space.
     * @param bottomMargin Bottom margin in mm in the projection space.
     * @param center The center coordinates in mm in the projection space.
     * @param scale The scale in mm in the projection space.
     * @returns True if margin corners are rendered successfully, false otherwise
     */
    public renderMarginCorners(path: Path, innerMargin: boolean, leftMargin: number, rightMargin: number, topMargin: number, bottomMargin: number, center: Vector2f, scale: Vector2f): void {
        this.gl.enable(this.gl.DEPTH_TEST);
        this.gl.enable(this.gl.STENCIL_TEST);

        if(!this.stencilFBO){
            return;
        }
        this.stencilFBO.bind();

        let topLeft = false;
        let topRight = false;
        let bottomLeft = false;
        let bottomRight = false;
        let count = 0;
        if(leftMargin > 0.0){
            if(topMargin > 0.0){
                topLeft = true;
                count++;
                if(rightMargin > 0.0){
                    topRight = true;
                    count++;
                    if(bottomMargin > 0.0){
                        bottomRight = true;
                        bottomLeft = true;
                        count += 2;
                    }
                } else if(bottomMargin > 0.0){
                    bottomLeft = true;
                    count++;
                }
            } else if(bottomMargin > 0.0){
                bottomLeft = true;
                count++;
                if(rightMargin > 0.0){
                    bottomRight = true;
                    count++;
                }
            }
        } else if(rightMargin > 0.0){
            if(topMargin > 0.0){
                topRight = true;
                count++;
            } if(bottomMargin > 0.0){
                bottomRight = true;
                count++;
            }
        }

        let iterator = new PathIterator(path.fillOnRight ? path.front : path.back);
        iterator.next(); // skip the first vertex as it's mapped twice
        let current: IteratorResult<Vector2f>;
        let index1 = 0;
        let index2 = 0;
        let numberOfCorners = path.length - 1;
        const vertexCount = numberOfCorners * count * 6;
        let vertices = new Array<Vector2f>(vertexCount);
        let directions = new Array<number>(vertexCount * 2);
        while(!(current = iterator.next()).done){
            if(topLeft){
                vertices[index1++] = new Vector2f(current.value.x - leftMargin, current.value.y + topMargin);
                vertices[index1++] = new Vector2f(current.value.x - leftMargin, current.value.y);
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value.x - leftMargin, current.value.y + topMargin);
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value.x, current.value.y + topMargin);
                directions[index2++] = -1.0;
                directions[index2++] = 1.0;
                directions[index2++] = -1.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = -1.0;
                directions[index2++] = 1.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 1.0;
            }
            if(topRight){
                vertices[index1++] = new Vector2f(current.value.x, current.value.y + topMargin);
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value.x + rightMargin, current.value.y + topMargin);
                vertices[index1++] = new Vector2f(current.value.x + rightMargin, current.value.y + topMargin);
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value.x + rightMargin, current.value.y);
                directions[index2++] = 0.0;
                directions[index2++] = 1.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 1.0;
                directions[index2++] = 1.0;
                directions[index2++] = 1.0;
                directions[index2++] = 1.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 1.0;
                directions[index2++] = 0.0;
            }
            if(bottomLeft){
                vertices[index1++] = new Vector2f(current.value.x - leftMargin, current.value.y);
                vertices[index1++] = new Vector2f(current.value.x - leftMargin, current.value.y - bottomMargin);
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value.x - leftMargin, current.value.y - bottomMargin);
                vertices[index1++] = new Vector2f(current.value.x, current.value.y - bottomMargin);
                directions[index2++] = -1.0;
                directions[index2++] = 0.0;
                directions[index2++] = -1.0;
                directions[index2++] = -1.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = -1.0;
                directions[index2++] = -1.0;
                directions[index2++] = 0.0;
                directions[index2++] = -1.0;
            }
            if(bottomRight){
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value.x, current.value.y - bottomMargin);
                vertices[index1++] = new Vector2f(current.value.x + rightMargin, current.value.y - bottomMargin);
                vertices[index1++] = new Vector2f(current.value);
                vertices[index1++] = new Vector2f(current.value.x + rightMargin, current.value.y - bottomMargin);
                vertices[index1++] = new Vector2f(current.value.x + rightMargin, current.value.y);
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = -1.0;
                directions[index2++] = 1.0;
                directions[index2++] = -1.0;
                directions[index2++] = 0.0;
                directions[index2++] = 0.0;
                directions[index2++] = 1.0;
                directions[index2++] = -1.0;
                directions[index2++] = 1.0;
                directions[index2++] = 0.0;
            }
        }

        let vao = new VAO(this.gl);
        vao.bind();
        vao.createAttribute(0, this.gl.FLOAT, toFloatArray(vertices, center, scale), 2);
        vao.createAttribute(1, this.gl.FLOAT, directions, 2);
        vao.bind(0, 1);
        this.marginCornerShader.start();
        this.gl.stencilFunc(this.gl.EQUAL, innerMargin ? 0xFF : 0, 0xFF);
        this.gl.drawArrays(this.gl.TRIANGLES, 0, vertexCount);
        this.marginCornerShader.stop();

        vao.unbind(0, 1);
        vao.delete();

        this.gl.disable(this.gl.DEPTH_TEST);
        this.gl.disable(this.gl.STENCIL_TEST);
    }

    /**
     * Method to render the stencil buffer.<br>
     * Pixels within the contour are given stencil value of 1.<br>
     * Pixels outside the contour are given stencil value of 0.<br>
     * @param path Path the stencil texture is drawn for.
     * @param center Center coordinates in the projection space in mm.
     * @param scale Scale in the projection space in mm.
     * @returns True if stencil map is successfully rendered, false otherwise.
     */
    public renderStencil(path: Path, center: Vector2f, scale: Vector2f): boolean {
        //render to stencil FBO's stencil attachment.
        this.gl.enable(this.gl.STENCIL_TEST);
        if(!this.stencilFBO || !this.distanceFBO){
            return false;
        }
        let index = 0;
        //TODO: Could use some array copy function
        const stencilVertices = new Array<Vector2f>(path.length + 1);
        stencilVertices[index] = path.getCentroid();
        for(let v of path){
            stencilVertices[++index] = v;
        }

        let vao = new VAO(this.gl);
        vao.bind();
        vao.createAttribute(0, this.gl.FLOAT, toFloatArray(stencilVertices, center, scale), 2);
        vao.bind(0);

        //update stencil buffer by setting fragments within the contour to one
        this.stencilShader.start();
        this.gl.depthMask(false);
        this.gl.colorMask(false, false, false, false);
        this.gl.stencilMask(0xFF);
        this.gl.stencilFunc(this.gl.ALWAYS, 1, 0x00); //every fragment passes the stencil test
        this.gl.stencilOp(this.gl.INVERT, this.gl.INVERT, this.gl.INVERT); //fragment's stencil value is always inverted

        this.stencilFBO.bind();
        this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, stencilVertices.length);
        this.distanceFBO.bind();
        this.gl.drawArrays(this.gl.TRIANGLE_FAN, 0, stencilVertices.length);
        this.distanceFBO.unbind();

        this.gl.stencilMask(0x00);
        this.gl.colorMask(true, true, true, true);
        this.gl.depthMask(true);
        this.stencilShader.stop();

        vao.unbind(0);
        vao.delete();
        this.gl.disable(this.gl.STENCIL_TEST);
        return true;
    }

    /**
     * Renders the color from stencil to distance <br>
     * so that the color of fragments within the contour are inverted.
     * @param renderer QuadRenderer used to perform the operation. May be undefined, if new renderer is to be created.
     */
    public renderFinalTexture(renderer?: QuadRenderer): void {
        //render on stencil FBO's color attachment 0.
        this.gl.enable(this.gl.STENCIL_TEST);
        if(!this.stencilFBO || !this.distanceFBO){
            return;
        }
        this.distanceFBO.bind();
        //inverse color
        this.gl.stencilFunc(this.gl.EQUAL, 0xFF, 0xFF); //perform for every fragment where stencil value == 0xFF

        if(renderer){
            renderer.render(this.stencilFBO, true);

            //keep color
            this.gl.stencilFunc(this.gl.EQUAL, 0, 0xFF); //perform for every fragment where stencil value == 0
            renderer.render(this.stencilFBO, false);
        } else {
            renderer = new QuadRenderer(this.gl);
            renderer.render(this.stencilFBO, true);

            this.gl.stencilFunc(this.gl.EQUAL, 0, 0xFF);
            renderer.render(this.stencilFBO, false);
            renderer.delete();
        }
        this.distanceFBO.unbind();
        this.gl.disable(this.gl.STENCIL_TEST);
    }
}

/**
 * A method to scale vector according to margins
 * @param direction Vector that is orthogonal to edge
 * @param leftMargin Length of the margin in left direction
 * @param rightMargin Length of the margin in right direction
 * @param topMargin Length of the margin in upwards direction
 * @param bottomMargin Length of the margin in downwards direction
 * @returns Direction vector scaled by the margins
 */
function getDest(direction: Vector2f, leftMargin: number, rightMargin: number, topMargin: number, bottomMargin: number): Vector2f {
    let dest: Vector2f = new Vector2f();
    let totalLength: number = 0.0;

    //Actual implementation: dotProduct(vec2(-left, 0), dir) > 0
    //simplified to this format
    if(direction.x < 0.0){
        const squared = leftMargin * leftMargin;
        dest.add(new Vector2f(-squared, 0.0)); //this has to be negative for left
        totalLength += squared;
    } else if(direction.x > 0.0){
        const squared = rightMargin * rightMargin;
        dest.add(new Vector2f(squared, 0.0));
        totalLength += squared;
    }
    if(direction.y > 0.0){
        const squared = topMargin * topMargin;
        dest.add(new Vector2f(0.0, squared));
        totalLength += squared;
    } else if(direction.y < 0.0){
        const squared = bottomMargin * bottomMargin;
        dest.add(new Vector2f(0.0, -squared)); //this has to also be negative for bottom
        totalLength += squared;
    }

    totalLength = Math.sqrt(totalLength);
    return dest.scale(1.0/totalLength);
}

/**
 * Function that will transform each vector component from mm-space
 * to boundingboxes unit space.
 * @param array Array of 2D-points that are to be processed
 * @param center boundingbox's center coordinates
 * @param halfSize boundingbox's size/2
 * @returns Array of floats, each float being a component of a 2D-point in boundingBox relative coordinates
 */
function toFloatArray(array: Vector2f[], center: Vector2f, scale: Vector2f): number[]{
    let result = new Array<number>(array.length * 2);
    let index = 0;
    for(let v of array){
        //coordinates are transformed to normalized coordinates in the boundingboxes coordinate-system
        result[index++] = (v.x - center.x) / scale.x;
        result[index++] = (v.y - center.y) / scale.y;
    }
    return result;
}

class EdgeDistanceShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es

    precision highp float;

    in vec2 position;
    in float distance;

    out float pass_distance;

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
        pass_distance = distance;
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es

    precision highp float;

    in float pass_distance;

    out vec4 color;

    void main(){
        gl_FragDepth = 1.0-2.0*pass_distance;
        color = vec4(pass_distance, pass_distance, pass_distance, 1.0);
    }
    `;

    constructor(gl: WebGL2RenderingContext){
        super(gl, EdgeDistanceShader.VERTEX_CONTENT, EdgeDistanceShader.FRAGMENT_CONTENT, "position", "distance");
    }
}

class CornerDistanceShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es

    precision highp float;

    in vec2 position;
    in vec2 direction;

    //scale relative to image size
    uniform vec2 scale;

    out vec2 pass_distance;

    void main() {
        gl_Position = vec4(position+direction*scale, 0.0, 1.0);
        pass_distance = direction;
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es

    precision highp float;

    in vec2 pass_distance;

    out vec4 color;

    void main() {
        float distance = 0.5-0.5*length(pass_distance);
        gl_FragDepth = 1.0-2.0*distance;
        color = vec4(distance, distance, distance, 1.0);
    }
    `;

    public scale: UniformVec2;

    constructor(gl: WebGL2RenderingContext){
        super(gl, CornerDistanceShader.VERTEX_CONTENT, CornerDistanceShader.FRAGMENT_CONTENT, "position", "direction");
        this.scale = new UniformVec2(gl, "scale");
        this.storeUniformLocations(this.scale);
    }
}

class MarginCornerShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es

    precision highp float;

    in vec2 position;
    in vec2 direction;

    out vec2 pass_distance;

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
        pass_distance = direction;
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es

    precision highp float;

    in vec2 pass_distance;

    out vec4 color;

    void main() {
        float distance = 0.5-0.5*length(pass_distance);
        gl_FragDepth = 1.0-2.0*distance;
        color = vec4(distance, distance, distance, 1.0);
    }
    `;

    constructor(gl: WebGL2RenderingContext){
        super(gl, MarginCornerShader.VERTEX_CONTENT, MarginCornerShader.FRAGMENT_CONTENT, "position", "direction");
    }
}
/**
 * Used for rendering the stencil map
 */
class StencilShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es

    precision highp float;

    in vec2 position;

    //out vec2 pass_textureCoordinates;

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
        //pass_textureCoordinates = vec2((position.x+1.0)/2.0, (position.y+1.0)/2.0);
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es

    precision highp float;

    //in vec2 pass_textureCoordinates;

    out vec4 color;

    //uniform sampler2D sampler;

    void main() {
        //vec4 original = texture(sampler, pass_textureCoordinates);
        //color = vec4(vec3(1.0, 1.0, 1.0) - original.rgb, original.a);
        color = vec4(1.0, 0.0, 0.0, 1.0);
    }
    `;

    constructor(gl: WebGL2RenderingContext){
        super(gl, StencilShader.VERTEX_CONTENT, StencilShader.FRAGMENT_CONTENT, "position");
    }
}

class OutlineShader extends ShaderProgram {

    private static VERTEX_CONTENT = `#version 300 es

    precision highp float;

    in vec2 position;

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
    }
    `;

    private static FRAGMENT_CONTENT = `#version 300 es
    
    precision highp float;

    out vec4 color;

    uniform vec3 lineColor;

    void main() {
        color = vec4(lineColor, 1.0);
    }
    `;

    public color: UniformVec3;

    constructor(gl: WebGL2RenderingContext){
        super(gl, OutlineShader.VERTEX_CONTENT, OutlineShader.FRAGMENT_CONTENT, "position");
        this.color = new UniformVec3(gl, "lineColor");
        this.storeUniformLocations(this.color);
    }
}