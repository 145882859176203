import React from 'react';
import Select, { OptionsType } from 'react-select';
import { PredictionModel, AvailableModels, MVSEG_PREDICTION_MODEL, getFreeTextEntryModel } from '../../web-apis/contouring-options';

import './ModelSelect.css';
import { MdAccessTime } from 'react-icons/md';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { StoreState } from '../../store/store';
import { ContouringClient } from '../../web-apis/contouring-client';
import { getBackendClient, backends } from '../../web-apis/auth';


type OwnProps = {
    onChange: (model: PredictionModel | undefined) => void,
    onMenuOpen?: () => void,
    currentPredictionModel: PredictionModel | undefined,
    styles?: {},
    isDisabled?: boolean,
}

type AllProps = OwnProps & StoreState;

type OwnState = {
    textEntry: string,
    availableModels: AvailableModels | undefined,
}

const freeTextEntryLabel = '(Custom, free text entry)';

const MVSEG_OPTION = { label: (<span>mvseg</span>), action: 'mvseg', value: 'mvseg' };
const FREE_TEXT_ENTRY_OPTION = { label: (<span>{freeTextEntryLabel}</span>), action: freeTextEntryLabel, value: freeTextEntryLabel };

class ModelSelect extends React.Component<AllProps, OwnState> {

    constructor(props: OwnProps) {
        super(props);
        this.state = {
            textEntry: "",
            availableModels: undefined,
        };
    }

    componentDidMount() {
        const getModels = async () => {
            let availableModels: AvailableModels | undefined = undefined;

            const backend = this.props.user!.currentBackend || backends.getDefaultBackend();
            if (backend) {
                const contouringClient = new ContouringClient(getBackendClient(backend));

                availableModels = await contouringClient.getAvailableModels();

                if (availableModels !== undefined) {
                    this.setState({ availableModels: availableModels });
                }
            }
        };

        getModels();
    }

    handleChange = (opt: any) => {
        const selectedPredictionModel = opt.value === FREE_TEXT_ENTRY_OPTION.value ? getFreeTextEntryModel(this.state.textEntry) : opt.action === MVSEG_OPTION.action ? MVSEG_PREDICTION_MODEL : this.state.availableModels ? this.state.availableModels.models.find(m => m.name === opt.action) : undefined;
        this.props.onChange(selectedPredictionModel);
    }

    handleTextEntryChange = (evt: any) => {
        const val = evt.target.value;
        this.setState({ textEntry: val });
        this.props.onChange(getFreeTextEntryModel(val));
    }

    handleMenuOpen = () => {
        if (this.props.onMenuOpen) {
            this.props.onMenuOpen();
        }
    }

    /** Prettify the list of model select options and add an 'mvseg' entry to it */
    getOptions = () => {
        const { availableModels } = this.state;

        // models have not yet loaded in
        if (availableModels === undefined) {
            return { options: [{ label: (<span>Loading available models</span>), action: '', value: '', isDisabled: true }] };
        }

        // show available models
        const extraOptions = [MVSEG_OPTION, FREE_TEXT_ENTRY_OPTION];
        const options: OptionsType<any> = availableModels.models
            .map(m => ({ label: (<span>{m.label} {!m.isOnline && <MdAccessTime />}</span>), action: m.name, value: m.name }))
            .concat(extraOptions);

        return { options };
    }

    render() {
        const { options }: { options: OptionsType<any> | undefined } = this.getOptions();
        const model = this.props.currentPredictionModel;

        return (
            <div>
                <Select
                    placeholder="Select prediction model"
                    options={options}
                    className="select-input"
                    onChange={this.handleChange}
                    onMenuOpen={this.handleMenuOpen}
                    value={options.find(opt => model && ((model.isFreeTextEntryModel && opt.value === freeTextEntryLabel) || opt.action === model.name))}
                    styles={this.props.styles !== undefined ? this.props.styles : {}}
                    isDisabled={this.props.isDisabled}
                />

                {model && model.isFreeTextEntryModel && (
                    <Form.Control
                        className="model-text-entry flash-on-reveal"
                        size="sm"
                        type="text"
                        placeholder="Enter model name"
                        value={this.state.textEntry}
                        onChange={this.handleTextEntryChange}
                        disabled={this.props.isDisabled}
                    />
                )}

                {this.props.currentPredictionModel && !this.props.currentPredictionModel.isOnline && (
                    <div>The worker offering this model is currently offline, so it will take a little longer for the segmentation results to get generated as the worker is being brought back online.</div>
                )}

            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    null
)(ModelSelect);
