export const SDF_POINT_VS = `
#version 300 es

precision highp float;

// Inverse of the size of the plane bounding box in mm. From DICOM attributes:
//
// 0: 1 / (PixelSpacing[1] * (Columns - 1))
// 1: 1 / (PixelSpacing[0] * (Rows - 1))
// 2: 1 / SliceThickness (or calculated plane separation)
//
// Note the inverted pixel spacing - this way it's consistent with orientation,
// unlike in DICOM.
uniform vec3 u_inverseSizeMM;
uniform vec3 u_boundingBoxMin;
uniform vec3 u_offByOneCorrection;

// Specifies the distance map range. Smaller = faster and better subpixel
// accuracy, but limits outline width and is worse for interpolation.
uniform float u_maxDistanceMM;


// Input in patient coordinates
in vec3 a_point;

// Outputs are in image plane coordinates in mm.
// FIXME: this requires high precision - calculate in javascript or with highp
flat out vec2 v_center;
out vec2 v_coord;

void main()
{

  // For fragment shader, we pass image plane coordinates in mm.
  // For WebGL, we pass clip space (normalized) coordinates.

  // Pass center point.
  vec3 planar = (a_point - u_boundingBoxMin);
  v_center = planar.xy;

  // Pass vertices of the bounding rectangle as TRIANGLES.
  const vec3 vert_offset[6] = vec3[6](
    vec3(-1.0, -1.0, 0.0),
    vec3( 1.0, -1.0, 0.0),
    vec3( 1.0,  1.0, 0.0),

    vec3( 1.0,  1.0, 0.0),
    vec3(-1.0,  1.0, 0.0),
    vec3(-1.0, -1.0, 0.0)
  );
  vec3 coord = planar + vert_offset[gl_VertexID % 6] * u_maxDistanceMM;
  v_coord = coord.xy;

  // Normalize vertex to clip space for rasterization.
  gl_Position = vec4(2.0 * (coord * u_inverseSizeMM - 0.5) * u_offByOneCorrection, 1.0);
}
`

export const SDF_POINT_FS = `
#version 300 es

precision mediump float;

// See the vertex shader.
uniform float u_inverseMaxDistanceMM;

// FIXME: this requires high precision - calculate in javascript or with highp
flat in vec2 v_center;
in vec2 v_coord;

// Alpha will be from 0 to 127 and should be blended with gl.MIN.
out vec4 outColor;

void main()
{
  float v = 0.5 + 0.5 * clamp(
    distance(v_coord, v_center) * u_inverseMaxDistanceMM,
    0.0,
    1.0
  );
  outColor = vec4(v, v, v, v);
}
`

export const SDF_EDGE_VS = `
#version 300 es

precision mediump float;

uniform vec3 u_inverseSizeMM;
uniform vec3 u_boundingBoxMin;
uniform vec3 u_offByOneCorrection;
uniform float u_maxDistanceMM;

// FIXME: this requires high precision - calculate in javascript or with highp
in vec3 a_p0;
in vec3 a_p1;

out float v_weight;

void main()
{
  vec3 p0 = (a_p0 - u_boundingBoxMin);
  vec3 p1 = (a_p1 - u_boundingBoxMin);

  vec3 d = (p1 - p0).yxz;
  d.x = -d.x;
  d *= u_maxDistanceMM / length(d.xy);

  // Vertices of the "tent" as TRIANGLES.
  // TODO: make constant?
  vec4 vert_data[12] = vec4[12](
    vec4(p0,     0.0),
    vec4(p0 + d, 1.0),
    vec4(p1 + d, 1.0),

    vec4(p1 + d, 1.0),
    vec4(p1,     0.0),
    vec4(p0,     0.0),

    vec4(p0,     0.0),
    vec4(p1,     0.0),
    vec4(p1 - d, 1.0),

    vec4(p1 - d, 1.0),
    vec4(p0 - d, 1.0),
    vec4(p0,     0.0)
  );

  vec4 v = vert_data[gl_VertexID % 12];
  v_weight = v.w;

  gl_Position = vec4(2.0 * (v.xyz * u_inverseSizeMM - 0.5) * u_offByOneCorrection, 1.0);
}
`

export const SDF_EDGE_FS = `
#version 300 es

precision mediump float;
in float v_weight;

// Alpha will be in [255, 127] and should be blended with gl.MIN.
out vec4 outColor;

void main()
{
  float v = 0.5 + 0.5 * v_weight;
  outColor = vec4(v, v, v, v);
}
`


export const SDF_TRIANGLE_VS = `
#version 300 es

precision mediump float;

// FIXME: this requires high precision - calculate in javascript or with highp

uniform vec3 u_inverseSizeMM;
uniform vec3 u_boundingBoxMin;
uniform vec3 u_offByOneCorrection;

// FIXME: this requires high precision - calculate in javascript or with highp
in vec3 a_point;

void main()
{
  // TODO: multiply from right = transpose
  vec3 planar = (a_point - u_boundingBoxMin);
  gl_Position = vec4(2.0 * (planar * u_inverseSizeMM - 0.5) * u_offByOneCorrection, 1.0);
}
`

export const SDF_TRIANGLE_FS = `
#version 300 es

precision mediump float;

// Value will be 127 and should be blended with gl.MIN.
out vec4 outColor;

void main()
{
  outColor = vec4(1.0, 1.0, 1.0, 1.0);
}
`