import React from 'react';

import TasksTableRow from './TasksTableRow';
import { StoreState } from '../../../store/store';

import './TasksTable.css';
import { Table } from 'react-bootstrap';
import { TrainingTask, TrainingTaskGroup } from '../../../datasets/training-task';
import { connect, ConnectedProps } from 'react-redux';
import { selectTasksGroupedByName } from '../../../store/selectors';


type OwnProps = {
    trainingTaskGroup?: TrainingTaskGroup,
    showArchivedTasks?: boolean,
    handleViewTaskClick: (task: TrainingTask) => void,
}

// combine redux & selector props
const mapStateToProps = (state: StoreState) => Object.assign({}, state,
    {
        taskGroups: selectTasksGroupedByName(state)
    });

const connector = connect(mapStateToProps);
type ReduxProps = ConnectedProps<typeof connector>;

type AllProps = OwnProps & StoreState & ReduxProps;


class TasksTable extends React.PureComponent<AllProps> {
    displayName = TasksTable.name

    render() {
        const { handleViewTaskClick, trainingTaskGroup } = this.props;

        const user = this.props.user;
        if (!user) {
            throw new Error('Not authorized');
        }

        const showSupervisorColumns = user.permissions.isSupervisor;
        const showUnarchiveColumn = this.props.showArchivedTasks && user.permissions.isSupervisor;
        let tasks: TrainingTask[] = [];
        if(trainingTaskGroup){
            tasks = trainingTaskGroup.trainingTasks;
        } else if (this.props.showArchivedTasks && this.props.archivedTrainingTasks) {
            tasks = this.props.archivedTrainingTasks;
        }


        return (
            <Table data-cy='tasks-table' className="tasks-table task-group-table" borderless size="md" responsive="sm">
                <thead>
                    <tr>
                        <th>Task Name</th>
                        <th>Type</th>
                        {showSupervisorColumns && <th>Trainee</th>}
                        <th>Supervisor</th>
                        <th>Patient</th>
                        <th>Description</th>
                        <th>Status</th>
                        {!this.props.showArchivedTasks && <th>Lock</th>}
                        <th>Load</th>
                        <th>View</th>
                        <th>Grade</th>
                        {showUnarchiveColumn ? <th>Unarchive</th> : showSupervisorColumns && <th>Edit</th>}
                    </tr>
                </thead>
                <tbody>
                    {tasks.map((task, index) => (
                        <TasksTableRow
                            key={task.id}
                            taskNumber={index}
                            task={task}
                            handleViewTaskClick={handleViewTaskClick}
                            showSupervisorColumns={showSupervisorColumns}
                        />
                    ))}
                </tbody>
            </Table>
        );
    }
}

export default connector(TasksTable);
