
import React, { Component } from 'react'
import { Col } from 'react-bootstrap'
import './HeadingTitle.css'


interface HeadingTitleProps {
    title?: string,
    subtitle?: string,
}
export default class HeadingTitle extends Component<HeadingTitleProps> {

    render() {
        return (
            <div className='heading'>
                <Col><img className='heading-img' src='/img/logo-mvision-w.svg' alt="MVision Logo" /></Col>
                <Col><h1>{this.props.title || 'Welcome to the RTViewer, Trainee'}</h1></Col>
                <Col><h4>{this.props.subtitle}</h4></Col>
            </div>
        )
    }
}
