import { Matrix4f } from "./Matrix4f";
import { Vector2f } from "./Vector2f";
import { Vector3f } from "./Vector3f";


export class Matrix3f {

    public m00 = 1; public m01 = 0; public m02 = 0;
    public m10 = 0; public m11 = 1; public m12 = 0;
    public m20 = 0; public m21 = 0; public m22 = 1;

    constructor(m00?: number | Matrix3f | Matrix4f | Array<number>, m01?: number, m02?: number,
        m10?: number, m11?: number, m12?: number,
        m20?: number, m21?: number, m22?: number){
            if(m00 !== undefined){
                this.set(m00, m01, m02,
                    m10, m11, m12,
                    m20, m21, m22);
            }
        }

    public set(m00: number | Matrix3f | Matrix4f | Array<number>, m01?: number, m02?: number,
        m10?: number, m11?: number, m12?: number,
        m20?: number, m21?: number, m22?: number): Matrix3f {
        if(typeof m00 === 'number'){
            if(m01 !== undefined && m02 !== undefined &&
                m10 !== undefined && m11 !== undefined && m12 !== undefined &&
                m20 !== undefined && m21 !== undefined && m22 !== undefined) {
                    this.m00 = m00;
                    this.m01 = m01;
                    this.m02 = m02;
                    this.m10 = m10;
                    this.m11 = m11;
                    this.m12 = m12;
                    this.m20 = m20;
                    this.m21 = m21;
                    this.m22 = m22;
                }
        } else if(m00 instanceof Matrix3f){
            this.m00 = m00.m00;
            this.m01 = m00.m01;
            this.m02 = m00.m02;
            this.m10 = m00.m10;
            this.m11 = m00.m11;
            this.m12 = m00.m12;
            this.m20 = m00.m20;
            this.m21 = m00.m21;
            this.m22 = m00.m22;
        } else if(m00 instanceof Matrix4f){
            this.m00 = m00.m00;
            this.m01 = m00.m01;
            this.m02 = m00.m02;
            this.m10 = m00.m10;
            this.m11 = m00.m11;
            this.m12 = m00.m12;
            this.m20 = m00.m20;
            this.m21 = m00.m21;
            this.m22 = m00.m22;
        } else if(m00 instanceof Array && m00.length === 9){
            this.m00 = m00[0];
            this.m01 = m00[1];
            this.m02 = m00[2];
            this.m10 = m00[3];
            this.m11 = m00[4];
            this.m12 = m00[5];
            this.m20 = m00[6];
            this.m21 = m00[7];
            this.m22 = m00[8];
        }
        return this;
    }

    setIdentity(): Matrix3f {
        this.m00 = this.m11 = this.m22 = 1;
        this.m01 = this.m02 = 0;
        this.m10 = this.m12 = 0;
        this.m20 = this.m21 = 0;
        return this;
    }

    getArray(): Array<number> {
        let array = new Array<number>(9);
        array[0] = this.m00;
        array[1] = this.m01;
        array[2] = this.m02;
        array[3] = this.m10;
        array[4] = this.m11;
        array[5] = this.m12;
        array[6] = this.m20;
        array[7] = this.m21;
        array[8] = this.m22;
        return array;
    }

    getFloat32Array(): Float32Array {
        let array = new Float32Array(9);
        array[0] = this.m00;
        array[1] = this.m01;
        array[2] = this.m02;
        array[3] = this.m10;
        array[4] = this.m11;
        array[5] = this.m12;
        array[6] = this.m20;
        array[7] = this.m21;
        array[8] = this.m22;
        return array;
    }

    transpose(): Matrix3f {
        let array = new Array<number>(9);
        array[0] = this.m00;
        array[1] = this.m10;
        array[2] = this.m20;
        array[3] = this.m01;
        array[4] = this.m11;
        array[5] = this.m21;
        array[6] = this.m02;
        array[7] = this.m12;
        array[8] = this.m22;
        return this.set(array);
    }

    public getAngles(): Vector3f {
        const theta = Math.acos((this.m00 + this.m11 + this.m22 - 1.0)*0.5);
        const denominator = 2.0 * Math.sin(theta);
        return new Vector3f((this.m21 - this.m12)/denominator, (this.m02 - this.m20)/denominator, (this.m10 - this.m01)/denominator);
    }

    public getDeterminant(): number {
        return this.m00 * (this.m11 * this.m22 - this.m12 * this.m21)
            + this.m01 * (this.m12 * this.m20 - this.m10 * this.m22)
            + this.m02 * (this.m10 * this.m21 - this.m11 * this.m20);
    }

    public invert(): Matrix3f {
        const determinant = this.getDeterminant();

        if (determinant === 0) {
            return this;
        }
        
        const determinant_inv = 1.0/determinant;

        // get the conjugate matrix
        const t00 = this.m11 * this.m22 - this.m12* this.m21;
        const t01 = - this.m10 * this.m22 + this.m12 * this.m20;
        const t02 = this.m10 * this.m21 - this.m11 * this.m20;
        const t10 = - this.m01 * this.m22 + this.m02 * this.m21;
        const t11 = this.m00 * this.m22 - this.m02 * this.m20;
        this.m21 = - this.m00 * this.m21 + this.m01 * this.m20;
        const t20 = this.m01 * this.m12 - this.m02 * this.m11;
        this.m12 = -this.m00 * this.m12 + this.m02 * this.m10;
        this.m22 = this.m00 * this.m11 - this.m01 * this.m10;

        this.m00 = t00*determinant_inv;
        this.m11 = t11*determinant_inv;
        this.m01 = t10*determinant_inv;
        this.m10 = t01*determinant_inv;
        this.m20 = t02*determinant_inv;
        this.m02 = t20*determinant_inv
        return this;
    }

    /**
     * Method to apply additional translation to this transformation matrix.<br>
     * The new translation is added on top of any previous transformation.
     * @param translation Translation to be applied
     * @returns This matrix with additional translation applied
     */
    translate(translation: Vector2f): Matrix3f;

    translate(x: number, y: number): Matrix3f;

    translate(translation: Vector2f | number, y?: number): Matrix3f {
        if(translation instanceof Vector2f){
            this.m20 += this.m00 * translation.x + this.m10 * translation.y;
            this.m21 += this.m01 * translation.x + this.m11 * translation.y;
        } else {
            this.m20 += this.m00 * translation + this.m10 * (y as number);
            this.m21 += this.m01 * translation + this.m11 * (y as number);
        }
        return this;
    }

    /**
     * A method to apply additional rotation to this transformation matrix.<br>
     * The new rotation is added on top of any previous rotations.
     * @param angle The rotation angle in radians
     * @param axis The axis around which the rotation is performed
     * @returns This matrix with additional rotation applied
     */
    rotate(angleRad: number, axis: Vector3f): Matrix3f {
        if(angleRad === 0.0){
            return this;
        } else{
            const c = Math.cos(angleRad);
            const s = Math.cos(angleRad);
            const oneminusc = 1.0 - c;
            const xy = axis.x * axis.y * oneminusc;
            const yz = axis.y * axis.z * oneminusc;
            const xz = axis.x * axis.z * oneminusc;
            const sinScaled = new Vector3f(axis).scale(s);

            const f00 = axis.x * axis.x * oneminusc + c;
            const f01 = xy + sinScaled.z;
            const f02 = xz - sinScaled.y;

            const f10 = xy - sinScaled.z;
            const f11 = axis.y * axis.y * oneminusc + c;
            const f12 = yz + sinScaled.x;

            const f20 = xz + sinScaled.y;
            const f21 = yz - sinScaled.x;
            const f22 = axis.z * axis.z * oneminusc + c;

            const t00 = this.m00 * f00 + this.m10 * f01 + this.m20 * f02;
            const t01 = this.m01 * f00 + this.m11 * f01 + this.m21 * f02;
            const t02 = this.m02 * f00 + this.m12 * f01 + this.m22 * f02;
            const t10 = this.m00 * f10 + this.m10 * f11 + this.m20 * f12;
            const t11 = this.m01 * f10 + this.m11 * f11 + this.m21 * f12;
            const t12 = this.m02 * f10 + this.m12 * f11 + this.m22 * f12;
            this.m20 = this.m00 * f20 + this.m10 * f21 + this.m20 * f22;
            this.m21 = this.m01 * f20 + this.m11 * f21 + this.m21 * f22;
            this.m22 = this.m02 * f20 + this.m12 * f21 + this.m22 * f22;
            this.m00 = t00;
            this.m01 = t01;
            this.m02 = t02;
            this.m10 = t10;
            this.m11 = t11;
            this.m12 = t12;
        }
        return this;
    }

    scale(scale: Vector2f): Matrix3f;

    scale(width: number, height: number): Matrix3f;

    scale(scale: Vector2f | number, height?: number): Matrix3f {
        if(scale instanceof Vector2f){
            this.m00 *= scale.x;
            this.m01 *= scale.x;
            this.m02 *= scale.x;
            this.m10 *= scale.y;
            this.m11 *= scale.y;
            this.m12 *= scale.y;
        } else {
            this.m00 *= scale;
            this.m01 *= scale;
            this.m02 *= scale;
            this.m10 *= height as number;
            this.m11 *= height as number;
            this.m12 *= height as number;
        }
        return this;
    }

    /**
         * A convenience method that will multiply this matrix by another matrix <br>
         * and store the result inside this one
         * @param right The factor this matrix will be multiplied with
         * @returns This matrix with the multiplication performed
         */
    multiply(right: Matrix3f): Matrix3f {
        return Matrix3f.multiply(this, right, this);
    }

    /**
     * A static method that will perform matrix multiplication.
     * Multiplication is performed by multiplying left with right and storing the result in dest.
     * If dest is not defined, a brand new destination matrix is created and returned.
     * @param left Matrix to be multiplied
     * @param right The factor the matrix is multiplied by
     * @param dest A destination matrix the result is stored to, or null if new matrix is to be created
     * @returns The destination matrix in which the multiplication result was stored
     */
    static multiply(left: Matrix3f, right: Matrix3f, dest?: Matrix3f): Matrix3f{
        if(!dest){
            dest = new Matrix3f();
        }
        let array = new Array<number>(9);
        array[0] = left.m00 * right.m00 + left.m10 * right.m01 + left.m20 * right.m02;
        array[1] = left.m01 * right.m00 + left.m11 * right.m01 + left.m21 * right.m02;
        array[2] = left.m02 * right.m00 + left.m12 * right.m01 + left.m22 * right.m02;
        array[3] = left.m00 * right.m10 + left.m10 * right.m11 + left.m20 * right.m12;
        array[4] = left.m01 * right.m10 + left.m11 * right.m11 + left.m21 * right.m12;
        array[5] = left.m02 * right.m10 + left.m12 * right.m11 + left.m22 * right.m12;
        array[6] = left.m00 * right.m20 + left.m10 * right.m21 + left.m20 * right.m22;
        array[7] = left.m01 * right.m20 + left.m11 * right.m21 + left.m21 * right.m22;
        array[8] = left.m02 * right.m20 + left.m12 * right.m21 + left.m22 * right.m22;
        return dest.set(array);
    }
}