/**
 * Dataset "table" inside the dataset/annotation page. This component mainly handles dataset's pagination and
 * batch UI functionalities.
 */

import React from 'react';
import { Button, Nav, Navbar, Row } from 'react-bootstrap';
import produce from 'immer';

import { connect } from 'react-redux';
import { StoreState } from '../../../store/store';
import * as sagas from '../../../store/sagas';
import WorkState from '../../../store/work-state';

import './TasksPage.css';
import { Filter, SortType, TrainingTask } from '../../../datasets/training-task';
import TaskGroupTable from './TaskGroupTable';
import TasksTableSearchInput from './TasksTableSearchInput';
import TasksTableSortSelect from './TasksTableSortSelect';
import ArchivedTasksDialog from '../../common/dialogs/ArchivedTasksDialog';

export enum DataItemType {
    Patient, Image, StructureSet
}

export type SortOption = {
    value: SortType;
    label: string;
};

export type FilterOption = {
    value: Filter;
    label: string;
};

type OwnProps = {
    error?: any,
    setPageError: (error: any) => void,
    initialWorkState: WorkState | null,
    handleViewTaskClick: (task: TrainingTask) => void,
}

type DispatchProps = {
    fetchStructureSetLocks: () => void,
    searchTasks(searchText: string): void;
    sortTasks(sortType: SortType | undefined): void;
    filterTasks(filters: Filter[]): void;
    setArchivedTasksDialogVisibility(value: boolean): void,
    loadArchivedTasks(): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

type OwnState = {
    isInitializing: boolean,
    initialWorkState: WorkState | null,
}

class TasksPage extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            isInitializing: false,
            initialWorkState: null,
    }
    }

    componentDidMount = () => {
        this.props.fetchStructureSetLocks();
    }

    componentDidUpdate = (prevProps: AllProps) => {
        // set some one-time-only-at-most initialization stuff so we get to calculate the correct initial page
        if (!this.state.isInitializing && this.props.initialWorkState !== null && this.props.initialWorkState.hasAnnotationWork()) {
            this.setState(produce((draft: OwnState) => {
                draft.initialWorkState = this.props.initialWorkState;
                // this is just a safeguard to ensure we don't come here again
                draft.isInitializing = true;
            }));
        }
    }

    handleReset = () => {
        this.props.sortTasks(undefined);
        this.props.searchTasks('');
        this.props.filterTasks([]);
    }
    toggleArchivedTasksDialog = () => {
        this.props.loadArchivedTasks();
        this.props.setArchivedTasksDialogVisibility(!this.props.isArchivedTasksDialogVisible)
    }

    render = () => {
        let error = this.props.error;
        if (error) {
            if (typeof error === 'object') {
                console.log(error);
                error = "See exception details in console (F12)"
            }
            return (
                <div>
                    <p><b>Error: </b>{error}</p>
                </div>
            );
        }
        else if (this.props.trainingTasks === undefined) {
            // nothing to render if we don't have a dataset loaded
            return null;
        }


        const sortOptions: SortOption[] = [
            { value: SortType.NameAsc, label: 'Task Name (A-Z)' },
            { value: SortType.NameDesc, label: 'Task Name (Z-A)' },
            { value: SortType.GradeAsc, label: 'Grade (Lowest)' },
            { value: SortType.GradeDesc, label: 'Grade (Highest)' },
        ];

        const filterOptions: FilterOption[] = [
            { value: Filter.FINISHED, label: 'Finished' },
            { value: Filter.STARTED, label: 'Started' },
            { value: Filter.GRADED, label: 'Graded' },
            { value: Filter.NOT_STARTED, label: 'Not started' },
        ];


        return (
            <div className='tasks-layout'>
                <Row>
                    <Navbar>
                        <Nav className="filters">
                            <Button data-cy="show-archived-btn" className='guideline-buttons' variant={"light"} onClick={() => this.toggleArchivedTasksDialog()}>Show archived tasks</Button>
                            <TasksTableSearchInput />
                            <TasksTableSortSelect isMulti={false} options={sortOptions} placeholder='Sort by' />
                            <TasksTableSortSelect isMulti={true} options={filterOptions} placeholder='Filters' filters={this.props.tasksFilters}/>
                            <div>
                            <Button onClick={this.handleReset} variant={"light"} >
                                Reset filters
                            </Button>
                            </div>
                        </Nav>
                    </Navbar>
                </Row>

                <TaskGroupTable
                    handleViewTaskClick={this.props.handleViewTaskClick}
                />
                <ArchivedTasksDialog handleClose={this.toggleArchivedTasksDialog} isVisible={this.props.isArchivedTasksDialogVisible} handleViewTaskClick={this.props.handleViewTaskClick} />
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(TasksPage);
