import React, { Component } from 'react';
import { ResponsivePie } from '@nivo/pie';

const customColors = [
    '#4DB9D4',  // Primary Color (Moonstone)
    '#CFEB86',  // Mindaro
    '#E9D482',  // Flax
    '#E48D75',  // Salmon
    '#E3A66C',  // Fawn
    '#02C7D9',  // Accent Color
    '#33d2e1'   // Lighter Shade of Accent Color
];

type OwnProps = {
    data: any[],
}

class DonutPlot extends Component<OwnProps> {
    render() {
        const { data } = this.props;
        return (
            <ResponsivePie
                data={data}
                margin={{ top: 0, right: 80, bottom: 100, left: 80 }}
                startAngle={0}
                endAngle={360}
                innerRadius={0.6}
                padAngle={2}
                cornerRadius={5}
                activeOuterRadiusOffset={5}
                colors={customColors}
                borderWidth={3}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                arcLinkLabelsSkipAngle={10}
                arcLinkLabelsTextColor='#f3f3f3'
                arcLinkLabelsThickness={3}
                arcLinkLabelsStraightLength={5}
                arcLinkLabelsColor={{ from: 'color' }}
                arcLabelsSkipAngle={10}
                arcLabelsTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            2
                        ]
                    ]
                }}
                tooltip={({ datum: { id, value, color } }) => (
                    <div
                        style={{
                            padding: 12,
                            color,
                            borderRadius: 4,
                            background: '#222222',
                        }}
                    >
                        <strong>
                            {id}: {value}
                        </strong>
                    </div>
                )}
                legends={data.length > 10 ? [] : [
                    {
                        anchor: 'bottom',
                        direction: 'column',
                        justify: false,
                        translateX: 0,
                        translateY: 80,
                        itemsSpacing: 0,
                        itemWidth: 80,
                        itemHeight: 15,
                        itemTextColor: '#f3f3f3',
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 10,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#f3f3f3'
                                }
                            }
                        ]
                    }
                ] }
            />
        );
    }
}

export default DonutPlot;
