import { MouseToolBase } from "./mouse-tool-base";
import { View } from "../view";
import { deepCopy } from '../../util';

export default class WindowLevelTool extends MouseToolBase {

    handleDrag(view: View, mousePointMm: number[], diff: number[]): void {
        let vm = view.viewManager;
        let vs = vm.viewerState;
        let wwDiff = Math.round( diff[0] * 2000 );
        let wcDiff = Math.round( diff[1] * 2000 );
        let windowLevel = deepCopy(vs.windowLevel);
        windowLevel.ww += wwDiff;
        windowLevel.wc += wcDiff;
        if(windowLevel.ww < 1) windowLevel.ww = 1;
        vs.setWindowLevel(windowLevel);
    }
}