import React from 'react';
import { connect } from 'react-redux';

import * as sagas from '../../store/sagas';
import { StoreState } from '../../store/store';
import { differenceInHours } from 'date-fns';
import { Alert } from 'react-bootstrap';

import './NewVersionAlert.css';
import LinkButton from './LinkButton';
import { getAppName } from '../../environments';


type DispatchProps = {
    snoozeNewVersionAlert: () => void,
}

type AllProps = StoreState & DispatchProps;

const ALERT_SNOOZE_TIME_HOURS = 4;

class NewVersionAlert extends React.PureComponent<AllProps>{
    handleSnoozeClick = () => {
        this.props.snoozeNewVersionAlert();
    }

    render() {
        // TODO: current implementation pushes the page down a bit, hiding the bottom of the page under the fold, requiring vertical scrolling.
        // Not optimal, but currently preferable to popups or modals that user has to dismiss to get them out of the way to save their work.
        // Best case solution would be a notification center type thing.
        if (this.props.isNewAppVersionAvailable
            && (!this.props.newVersionAlertSnoozedSince
                || differenceInHours(new Date(), this.props.newVersionAlertSnoozedSince as Date) > ALERT_SNOOZE_TIME_HOURS)) {
            return (
                <Alert variant="info" style={{ zIndex: 10 }}>
                    <span>A new version of {getAppName()} is available. Please save your work and refresh this page to change to the latest version as soon as possible.</span>
                    <span><LinkButton onClick={this.handleSnoozeClick}>Snooze for 4 hours.</LinkButton></span>
                </Alert>
            );
        }
        else {
            return null;
        }
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(NewVersionAlert);
