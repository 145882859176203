import { VBO } from './VertexBufferObject';

export class AttributeVBO extends VBO {

    private attrSize: number;
    private dataType: number;

    constructor(gl: WebGL2RenderingContext, attrSize: number, dataType: number){
        super(gl, gl.ARRAY_BUFFER);
        this.attrSize = attrSize;
        this.dataType = dataType;
    }

    setPointer(attribute: number): void {
        this.gl.vertexAttribPointer(attribute, this.attrSize, this.dataType, false, 0, 0);
    }

}