import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as sagas from '../../../store/sagas';
import { Roi } from '../../../dicom/structure-set';

interface Props {
    roi: Roi | null;
    show: boolean;
    handleClose: () => void;
    handleInterpolation: () => void;
}

type AllProps = Props;

class RunInterpolationModal extends React.Component<AllProps> {

    render() {
        if (!this.props.roi) {
            return null;
        }

        return (
            <Modal show={this.props.show} onHide={this.props.handleClose} restoreFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to interpolate the following structure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="task-grade">
                            <Form.Label>Selected Structure: {this.props.roi.name}</Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.handleInterpolation}>
                        Interpolate
                    </Button>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(RunInterpolationModal);
