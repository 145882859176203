export const VECTOR_TO_SDF_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec3 v_texcoord;
uniform mat4 orientation;

void main()
{
    v_texcoord = (orientation * vec4(in_texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const VECTOR_TO_SDF_FS = `#version 300 es
precision mediump float;
precision mediump int;

precision mediump sampler3D;
uniform sampler3D vectorTexture;
uniform sampler3D sdfTexture;
uniform float maxDistancePixels;

in vec3 v_texcoord;
out vec4 color;

float getDist(vec4 point) {
    float x = point.r;
    float y = point.g;
    return sqrt( x*x + y*y ) + point.b;
}

void main()
{
   vec4 vectorSample = texture(vectorTexture, v_texcoord);
   vec4 sdfSample = texture(sdfTexture, v_texcoord);

   if(vectorSample.r < 1.0 && vectorSample.g < 1.0) {
       float dist = getDist(vectorSample);
       if(sdfSample.r <= 0.5) {
           dist = 0.5 - dist / 2.0;
       }
       else {
           dist = 0.5 + dist / 2.0;
       }
       color = vec4(dist, dist, dist, 1.0);
   }
   else {
        if(sdfSample.r <= 0.5) {
          float r = 0.0;
          color = vec4(r, r, r, 1.0);
        }
        else {
          float r = 1.0;
          color = vec4(r, r, r, 1.0);
        }
   }
}
`;