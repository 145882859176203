import React from 'react';
import { Button, ProgressBar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { StoreState } from '../../store/store';
import { User } from '../../store/user';

import './SplashScreen.css';
import { IconContext } from 'react-icons';
import * as sagas from '../../store/sagas';

type OwnProps = {
    isVisible: boolean,
    showProgress: boolean,
    progressMin?: number,
    progressMax?: number,
    progressNow?: number,
    patientId?: string,
    splashText?: string,
}

type DispatchProps = {
    setUserSettingPatientInfoVisibility(showPatientInfo: boolean): void,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class SplashScreen extends React.Component<AllProps> {

    handleShowIdButtonClick = () => {
        const user = this.props.user;
        if (user && user instanceof User) {
            const currentValue = user.showPatientInfo;
            this.props.setUserSettingPatientInfoVisibility(!currentValue);
        }
    }

    render = () => {
        const { splashText } = this.props;

        if (!this.props.isVisible) {
            return null;
        }

        const user = this.props.user;
        const showLoadingText = user && user instanceof User && user.showPatientInfo;
        const showShowLoadingTextButton = !splashText && this.props.patientId && user && user instanceof User;

        const progressState: any = {};
        if (this.props.progressMin) { progressState.min = this.props.progressMin; }
        if (this.props.progressMax) { progressState.max = this.props.progressMax; }
        if (this.props.progressNow) { progressState.now = this.props.progressNow; }

        const loadingText = splashText ? splashText : this.props.patientId && showLoadingText ? `Loading patient ${this.props.patientId}...` : 'Loading patient...';
        const showButtonTooltip = showLoadingText ? 'Hide patient ID' : 'Show patient ID';

        return (
            <div className="splash">
                <div className="logobox">
                    <img src="/img/logo-mvision-w.svg" alt="MVision Logo" width={350} />
                </div>
                <IconContext.Provider value={{ size: '1.5em' }}>
                    <div className="loading-text-row">
                        <span className="loading-text">{loadingText}</span>
                        <span className="show-id-button">{showShowLoadingTextButton && (<Button onClick={this.handleShowIdButtonClick} title={showButtonTooltip} size="sm">{showLoadingText ? (<FaEyeSlash />) : (<FaEye />)}</Button>)}</span>
                    </div>
                </IconContext.Provider>
                {this.props.showProgress && (
                    <div><ProgressBar animated variant="mvision-teal" {...progressState}></ProgressBar></div>
                )}
            </div>
        );
    }
};

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(SplashScreen);
