/*

LOGIN/AUTHENTICATION IN RTVIEWER

RTViewer uses MSAL to authenticate Azure AD users against Azure app registrations. Some auth operations
are required for RTViewer to work in the first place, some are only needed if user needs to access
content related to a specific app registration (e.g. livereview for a clinical/production server).

See following documentation for basics of MSAL:
https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-overview
https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-browser#microsoft-authentication-library-for-javascript-msaljs-20-for-browser-based-single-page-applications


RTViewer must handle multi-authentication for use cases where a user wants to access content
in a supported app registration. In this case the user must first authenticate into any required
app registrations (there will probably only ever be one of these) and then log into any supported,
optional ones (as of writing this there's also only one of these, but there could be more in the future).


environments.tsx is used to track the overall supported backends with the exported 'backends' object.
Each backend links to one AppAuth object. AppAuths model logging into azure app registrations, and under
the hood each contain one separate instance of an MSAL PublicClientApplication, plus some metadata that
RTViewer needs to keep track of.


Redux store also has a separate AppAuthStatesCollection object that is used to track AppAuthState objects.
These are used to track auth login user flow -- but only the user flow. The actual login and authentication
tokens etc are stored in the aforementioned AppAuth objects which are NOT in the redux store.


In other words: the most straightforward distinction between these two classes (AppAuth and AppAuthState) is 
that AppAuth corresponds to the actual MSAL object, whereas AppAuthState is more concerned about the
abstract user flow of logging in, and keeping track of which logins are required and which ones are not
needed.


APPLICATION AUTHENTICATION FLOW 

When the app (RTViewer) is started, AppAuthStatesCollection is initialized. By default only the default
app auth is marked as required in the redux store AppAuthStatesCollection object. Any other app auths
are only marked as required if the app realises they are needed (e.g. because we're opening a livereview
link to such a server, or because the user switched to a backend that needs a new app authorization to
work). 

After this the asynchronous code in sagas.tsx will perform logins via popups. These will require user
interaction if the user has not previously logged in and set single sign-on settings on, but otherwise
they will appear and disappear automatically. These auth operations are done one by one (MSAL does not support
simultaneous login processes).

Note that the actual RTViewer program is generally not displayed until all required auths have been completed.

Afterwards RTViewer uses the now logged-in AppAuth objects to generate access tokens when needed
(i.e. for backend API calls). These are generally done silently, but will also require user interaction
via popups if the silent token procedurement fails.


(An aside: the requirement for RTViewer to support multiauthentication stems from the need for RTViewer
to support multiple backends. Most backends use the default app registration auth, but some need to
authenticate with one of the optional, supported app registrations. Use cases for this are e.g. opening
a livereview link from a specific server that uses a different app registration, or using that server
for autocontouring in RTViewer.

Each backend uses one of the supported app registrations for authentication. This is a bit of a separate, 
but connected topic. All of the supported backends point to one of these supported auths, so this auth 
flow is used to abstract the link between backends and azure authentication away.)



OVERVIEW OF RELATED CLASSES

AppAuth (app-auth.tsx): a concrete instance of an MSAL authentication of an azure app registration.
  This is required to authenticate rt viewer users through azure AD.
AppAuthState (auth-state.tsx): tracks user interaction flow of AppAuth registration
  for react components.
AppAuthStatesCollection (auth-state.tsx): a collection of AppAuthStates. This is kept in
  the redux store.
Backend (backends.tsx): Models a single backend. Each backend has a BackendTier.
Backends (backends.tsx): A collection object of individual backend objects.
BackendTier (backends.tsx): Models a backend tier. These correspond to specific AppAuths.
BackendClient (backend-client.tsx): Models a client instance of a backend. You should
  create a new one each time you need one. This class concretely links a backend with a
  specific AppAuth, and performs API calls through an abstraction layer.
Environment globals (environments.tsx): THe environments file contains hardcoded global values
  such as: all different backends rt viewer supports, all backends users are allowed to use,
  AppAuth/app registration settings and client IDs, linking of backend tiers to AppAuth
  instances, the default AppAuth.


This auth class contains helper functions for interacting between the environment-specific backends
and their AppAuth objects.

*/


import _ from 'lodash';

import { Backend, Backends, BackendTier } from './backends';
import { defaultAuths, mvapiClientId, MVAPI_AUTH, mvdapiprodClientId, MVDAPIPROD_AUTH, getRTViewerBackend, } from '../environments';
import BackendClient from "./backend-client";
import AppAuth from "./app-auth";
import { LiveReviewQueryV2 } from '../store/live-review-query';


// GLOBALS

// // set default backend
// const DEFAULT_BACKEND = isRutherford() ? ukNonclinicalBackend.name : demoBackend.name;

// // This is the list of all backends rtviewer can connect to. This list doesn't have to be
// // the same as the list of supported backends users can change to. A backend has to be at least
// // in this list in order for it to work in livereview.
export const backends: Backends = new Backends({
//   [demoBackend.name]: new Backend(demoBackend, BackendTier.Nonclinical),
//   [devBackend.name]: new Backend(devBackend, BackendTier.Nonclinical),
//   [euClinicalBackend.name]: new Backend(euClinicalBackend, BackendTier.Clinical),
//   [euNonclinicalBackend.name]: new Backend(euNonclinicalBackend, BackendTier.Nonclinical),
//   [ukClinicalBackend.name]: new Backend(ukClinicalBackend, BackendTier.Clinical),
//   [ukNonclinicalBackend.name]: new Backend(ukNonclinicalBackend, BackendTier.Nonclinical),
//   [previewWestEuropeBackend.name]: new Backend(previewWestEuropeBackend, BackendTier.Nonclinical),
//   [westEuropeBackend.name]: new Backend(westEuropeBackend, BackendTier.Clinical),
  // [rtViewerBackend.name]: new Backend(rtViewerBackend, BackendTier.Nonclinical),
//   [slowClinicalBackend.name]: new Backend(slowClinicalBackend, BackendTier.Clinical),
//   [slowNonclinicalBackend.name]: new Backend(slowNonclinicalBackend, BackendTier.Nonclinical),
//   [usClinicalBackend.name]: new Backend(usClinicalBackend, BackendTier.Clinical),
//   [usNonclinicalBackend.name]: new Backend(usNonclinicalBackend, BackendTier.Nonclinical),
//   [chClinicalBackend.name]: new Backend(chClinicalBackend, BackendTier.Clinical),
//   [chNonclinicalBackend.name]: new Backend(chNonclinicalBackend, BackendTier.Nonclinical),
// }, DEFAULT_BACKEND);
}, undefined);


// map supported backend tiers to azure app registrations
// we'll use this information to connect each backend into appropriate app auth
export const backendTierAppAuths: Record<BackendTier, AppAuth> = {
  Nonclinical: new AppAuth(MVAPI_AUTH, mvapiClientId),
  Clinical: new AppAuth(MVDAPIPROD_AUTH, mvdapiprodClientId),
};

// returns a list of all supported auths that are not the default ones
export const getOptionalAuths = (): string[] => {
  return _.map(backendTierAppAuths, a => a.appName).filter(a => !defaultAuths.includes(a));
}

export const getAppAuthByName = (appAuthName: string): AppAuth => {
  const match = _.find(backendTierAppAuths, a => a.appName === appAuthName);
  if (match) { return match; }
  else {
      throw new Error(`Could not find app auth ${appAuthName} among supported app auths.`);
  }
}


// returns an app registration authentication object matching a specific backend tier
export function getAppAuthByTier(backendTier: BackendTier): AppAuth {
  if (_.has(backendTierAppAuths, backendTier)) {
    return backendTierAppAuths[backendTier];
  }
  else {
    throw new Error(`Unsupported backend tier (${backendTier})`);
  }
}

// returns a new backend client for a specific backend
export function getBackendClient(backend: Backend): BackendClient {
  const appAuth = getAppAuthByTier(backend.tier);
  return new BackendClient(backend, appAuth);
}

// special case: returns a backend matching the rtviewer API backend
export function getRtViewerBackend(): Backend {
  return getRTViewerBackend();
}

// special case: returns a backend client for the rtviewer API backend
export function getRtViewerBackendClient(): BackendClient {
  const backend = getRtViewerBackend();
  return getBackendClient(backend);
}

// returns a backend matching the supplied livereview query
export function getRequiredLiveReviewBackend(query: LiveReviewQueryV2): Backend {
  const apiUrl = query.getApiUrl();
  return backends.getBackendByUrl(apiUrl);
}

// returns a backend client matching the supplied livereview query
export function getRequiredLiveReviewBackendClient(query: LiveReviewQueryV2): BackendClient {
  const backend = getRequiredLiveReviewBackend(query);
  return getBackendClient(backend);
}