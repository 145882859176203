import React from 'react';
import { ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

import { TriStateCheckboxChecked } from '../../common/TriStateCheckbox';
import { PageImage } from '../models/PagePatient';
import { BatchJobOperation } from './DatasetPage';


type OwnProps = {
    image: PageImage,
    imageCheckboxId: string,
    imageChecked: TriStateCheckboxChecked,
    currentBatchOperation?: BatchJobOperation,
    handleBatchOperationTypeChange: (value: {checkboxId: string, operation: BatchJobOperation}) => void,
}

export default class DatasetBatchJobCell extends React.PureComponent<OwnProps> {
    render() {
        const { image, imageCheckboxId, imageChecked, currentBatchOperation, 
                handleBatchOperationTypeChange } = this.props;

        const checkboxId = imageCheckboxId;
        const isChecked = imageChecked === TriStateCheckboxChecked.Checked;

        if (!isChecked) {
            return null;
        }

        const batchOp = currentBatchOperation ? currentBatchOperation : BatchJobOperation.Export;

        const toggleButtonValues = {
            [BatchJobOperation.Export]: { checkboxId, operation: BatchJobOperation.Export },
            [BatchJobOperation.AutoContourAndExport]: { checkboxId, operation: BatchJobOperation.AutoContourAndExport },
            [BatchJobOperation.None]: { checkboxId, operation: BatchJobOperation.None },
        };

        return (
            <td>
                <ButtonToolbar>
                    <ToggleButtonGroup
                        type="radio"
                        size="sm"
                        onChange={handleBatchOperationTypeChange}
                        name={`batch-job-operation-for-${image.seriesId}`}
                        value={toggleButtonValues[batchOp]}
                    >
                        <ToggleButton variant="outline-secondary" value={toggleButtonValues[BatchJobOperation.Export]}>Export only</ToggleButton >
                        <ToggleButton variant="outline-primary" value={toggleButtonValues[BatchJobOperation.AutoContourAndExport]}>Auto-contour and export</ToggleButton >
                    </ToggleButtonGroup>
                </ButtonToolbar>
            </td>
        );
    }
}
