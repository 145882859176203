export class VBO {
    
    protected gl: WebGL2RenderingContext;
    protected type: any;
    protected id?: WebGLBuffer;

    constructor(gl: WebGL2RenderingContext, type: any){
        this.gl = gl;
        this.id = gl.createBuffer() || undefined;
        if(!this.id){
            throw new Error("VertexBufferObject creation failed");
        }
        this.type = type;
    }

    public bind(): void {
        if(this.id) {
            this.gl.bindBuffer(this.type, this.id);
        }
    }

    public unbind(): void {
        this.gl.bindBuffer(this.type, null);
    }

    public storeData(data: DataView | ArrayBuffer): void {
        this.gl.bufferData(this.type, data, this.gl.STATIC_DRAW);
    }

    public delete(): void {
        if(this.id) {
            this.gl.deleteBuffer(this.id);
        }
    }

}