import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Location } from 'history';

import { StoreState } from '../store/store';
import { AppAuthStatesCollection } from '../store/auth-state';
import { User } from '../store/user';
import { getAppName } from '../environments';
import * as sagas from '../store/sagas';


type OwnProps = {}

type DispatchProps = {
    initializeApp: (routeLocation: Location) => void,
}


type AllProps = OwnProps & DispatchProps & StoreState & RouteComponentProps;

// Wrapper page for all page components
class AuthenticatedPage extends React.Component<PropsWithChildren<AllProps>> {
    displayName = AuthenticatedPage.name

    componentDidMount = () => {
        document.title = getAppName();

        // initialize the application
        const routeLocation = this.props.location;
        this.props.initializeApp(routeLocation);
    }

    render() {
        const appAuthStatesCollection = this.props.appAuthStatesCollection as AppAuthStatesCollection;
        const isWaitingForLogin = appAuthStatesCollection.areAppStartLoginsPending();
        const hasUserAuthenticated = !isWaitingForLogin && this.props.user && this.props.user as User && (this.props.user as User).isAuthenticated;

        return (
            <>
                {isWaitingForLogin && (<div>Waiting for login...</div>)}
                {hasUserAuthenticated && this.props.children}
            </>
        );
    }
}


export default withRouter(connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(AuthenticatedPage));
