import Immerable from "./immerable";

export enum LogInProcessState {
    NotLoggedIn,
    LoggingInProgress,
    LoggedIn,
    Error,
}

// this class keeps track of what azure app registrations we're logged into
// see also: web-apis/backend-client
export class AppAuthState extends Immerable {
    appAuthName: string;
    isRequired: boolean;
    isRequiredAtAppStart: boolean;
    logInProcessState: LogInProcessState;

    // NOTE: this is set to TRUE at the start of the LOGOUT process, but the value is currently not used in UI
    // but could be useful e.g. when displaying error messages
    isLogoutInProgress: boolean;

    constructor(appAuthName: string, isRequired: boolean) {
        super();
        this.appAuthName = appAuthName;
        this.isRequired = isRequired;
        this.logInProcessState = LogInProcessState.NotLoggedIn;
        this.isLogoutInProgress = false;

        // assumption -- any app auth that is initialized as required is also required immediately at app start
        this.isRequiredAtAppStart = isRequired;
    }
}

export class AppAuthStatesCollection extends Immerable {
    _appAuthStates: AppAuthState[];

    constructor() {
        super();
        this._appAuthStates = [];
    }
    
    addAuthState(authState: AppAuthState): boolean {
        const existingIndex = this._appAuthStates.findIndex(a => a.appAuthName === authState.appAuthName);
        if (existingIndex === -1) {
            this._appAuthStates.push(authState);
            return true;
        } else {
            // authstate is already in the collection
            return false;
        }
    }

    setAuthStateAsRequired(appAuthName: string): boolean {
        const authState = this.getAppAuthState(appAuthName);
        if (authState) {
            authState.isRequired = true;
            return true;
        } else {
            return false;
        }
    }

    setAuthStateAsLoggedIn(appAuthName: string): boolean {
        const authState = this.getAppAuthState(appAuthName);
        if (authState) {
            authState.logInProcessState = LogInProcessState.LoggedIn;
            return true;
        } else {        
            return false;
        }
    }

    getAppAuthState(appAuthName: string): AppAuthState | undefined {
        return this._appAuthStates.find(a => a.appAuthName === appAuthName);
    }

    // returns a list of app auth states that are required but for which auth login process is not yet done or started
    getAppsThatNeedLogin(): AppAuthState[] {
        return this._appAuthStates.filter(a => a.isRequired && a.logInProcessState === LogInProcessState.NotLoggedIn);
    }

    // returns true if there are any app auths that are required at rtviewer start that are not yet fully logged in
    areAppStartLoginsPending(): boolean {
        return this._appAuthStates.some(a => a.isRequiredAtAppStart && a.logInProcessState !== LogInProcessState.LoggedIn);
    }

    // returns true if there are any app auth states that are required but aren't yet logged in
    isWaitingForLogins(): boolean {
        return this._appAuthStates.some(a => a.isRequired && a.logInProcessState !== LogInProcessState.LoggedIn);
    }
}