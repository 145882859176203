import React from 'react';
import { fromUnixTime, formatISO9075 } from 'date-fns';
import { Row, Col, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoMdCheckmark, IoMdWarning } from 'react-icons/io';

import { BatchJobStatus } from '../../web-apis/batch-client';

import './BatchJobItem.css';
import { IconContext } from 'react-icons';

export default class BatchJobItem extends React.PureComponent<{
    batchJob: BatchJobStatus
}> {

    getBatchJobTitle = (): any => {
        const { batchJob } = this.props;
        return (<span><span className="source-storage">{batchJob.sourceStorageAccountName}</span>/<span className="source-file-share">{batchJob.sourceFileShareName}</span></span>);
    }

    getTargetPath = (): string => {
        const { batchJob } = this.props;
        return `storage account name: ${batchJob.targetStorageAccountName}, fileshare name: ${batchJob.targetFileShareName}, target path: ${batchJob.targetPath}`;
    }

    getProgressBarSettings = (done: number, total: number, isFinished: boolean, itemTitle: string) => {
        const settings: any = { variant: 'info' };

        if (total === 0) {
            settings.now = 1;
            settings.max = 1;
        } else {
            settings.now = done;
            settings.max = total;
        }

        if (!isFinished && done < total) {
            settings.animated = true;
            settings.striped = true;
        }

        const title = `${done}/${total}`;
        settings.status = title;
        settings.title = `${itemTitle}: ${title}`;

        return settings;
    }

    handlePrintErrorsClick = () => {
        const { batchJob } = this.props;
        console.log(`Errors for batch job ${batchJob.sourceStorageAccountName}/${batchJob.sourceFileShareName}:`);
        batchJob.errors.forEach(error => console.log(error));
    }

    render() {
        const { batchJob } = this.props;

        const contourStatus = this.getProgressBarSettings(batchJob.contouringsDone, batchJob.contouringsTotal, batchJob.finished, 'Auto-contours');
        const imagesStatus = this.getProgressBarSettings(batchJob.imageExportsDone, batchJob.imageExportsTotal, batchJob.finished, 'Images');
        const structsStatus = this.getProgressBarSettings(batchJob.rtStructExportsDone, batchJob.rtStructExportsTotal, batchJob.finished, 'Structure sets');

        return (
            <div className="batch-job-item">

                <div className="item-status">
                    <IconContext.Provider value={{ size: "2em" }}>
                        {/* {!batchJob.finished && (<div className="in-progress" title="This batch job is still on-going."><FaRegClock /></div>)} */}
                        {batchJob.finished && (<div className="finished" title="This batch job has finished."><IoMdCheckmark /></div>)}
                        {batchJob.errors.length > 0 && (
                            <div className="errors">
                                <OverlayTrigger
                                    placement="left"
                                    overlay={(<Tooltip id={`${batchJob.batchId}_batch_job_errors`}>This batch job has {batchJob.errors.length} error(s). Click to print them to console (F12).</Tooltip>)} >
                                    <IoMdWarning onClick={this.handlePrintErrorsClick} />
                                </OverlayTrigger>
                            </div>
                        )}
                    </IconContext.Provider>
                </div>

                <div className="title-row">Batch job <span className="title">{this.getBatchJobTitle()}</span> by <span className="user">{batchJob.user}</span></div>
                <div>
                    <Row>
                        <Col lg={3}>Started on</Col>
                        <Col><span className="date">{formatISO9075(fromUnixTime(batchJob.timeStampStarted / 1000))}</span></Col>
                    </Row>
                    {batchJob.finished && (
                        <Row>
                            <Col lg={3}>Finished on</Col>
                            <Col><span className="date">{formatISO9075(fromUnixTime(batchJob.timeStampFinished / 1000))}</span></Col>
                        </Row>
                    )}
                    {batchJob.batchDescription && (
                        <Row>
                            <Col lg={3}>Description</Col>
                            <Col><span className="description">{batchJob.batchDescription}</span></Col>
                        </Row>
                    )}
                </div>
                <div>
                    {batchJob.contouringsTotal > 0 && (
                        <Row>
                            <Col lg={3}>Auto-contours:</Col>
                            <Col lg={5} className="progress-bar-container"><ProgressBar {...contourStatus} /></Col>
                            <Col><div className="progress-numbers">{contourStatus.status}</div></Col>
                        </Row>)}
                    <Row>
                        <Col lg={3}>Images:</Col>
                        <Col lg={5} className="progress-bar-container"><ProgressBar {...imagesStatus} /></Col>
                        <Col><div className="progress-numbers">{imagesStatus.status}</div></Col>

                    </Row>
                    {batchJob.rtStructExportsTotal > 0 && (
                        <Row>
                            <Col lg={3}>Structure sets:</Col>
                            <Col lg={5} className="progress-bar-container"><ProgressBar {...structsStatus} /></Col>
                            <Col><div className="progress-numbers">{structsStatus.status}</div></Col>
                        </Row>)}
                </div>
                <div>
                    <Row>
                        <Col lg={3}>Export folder:</Col>
                        <Col><span className="export-folder">/{batchJob.targetPath}</span></Col>
                    </Row>
                    <Row>
                        <Col lg={3}></Col>
                        <Col className="storage-info">Storage account: <span className="storage-account">{batchJob.targetStorageAccountName}</span>, file share: <span className="file-share">{batchJob.targetFileShareName}</span></Col>
                    </Row>
                </div>

            </div>
        );
    }
}