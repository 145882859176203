import _ from "lodash";
import AppAuth, { BackendFetchOptions } from "./app-auth";
import { Backend } from "./backends";



export default class BackendClient {

    backend: Backend;
    appAuth: AppAuth;
    name: string;
    url: string;

    constructor(backend: Backend, appAuth: AppAuth) {
        this.backend = backend;
        this.name = backend.name;
        this.url = backend.url;
        this.appAuth = appAuth;
    }

    async fetch(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const fullUrl = this.backend.url + partialUrl;
        return await this.appAuth.fetch(fullUrl, httpRequestOptions, backendFetchOptions);
    }

    async get(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithGetMethod = _.set(httpRequestOptions || {}, 'method', 'get');
        return await this.fetch(partialUrl, optionsWithGetMethod, backendFetchOptions);
    }

    async delete(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithDeleteMethod = _.set(httpRequestOptions || {}, 'method', 'delete');
        return await this.fetch(partialUrl, optionsWithDeleteMethod, backendFetchOptions);
    }

    async post(partialUrl: string, httpRequestOptions: any = undefined, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<Response> {
        const optionsWithPostMethod = _.set(httpRequestOptions || {}, 'method', 'post');
        return await this.fetch(partialUrl, optionsWithPostMethod, backendFetchOptions);
    }

    async quickPost(partialUrl: string, httpRequestOptions: any): Promise<any> {
        const optionsWithPostMethod = _.set(httpRequestOptions || {}, 'method', 'post');
        const response = await this.appAuth.fetch(partialUrl, optionsWithPostMethod);
        return await response.json(); 
    }

    async quickGet(partialUrl: string, httpRequestOptions: any, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<any> {
        const optionsWithGetMethod = _.set(httpRequestOptions || {}, 'method', 'get');
        const response = await this.appAuth.fetch(partialUrl, optionsWithGetMethod, backendFetchOptions);
        return await response.json();
    }

    async quickFetch(partialUrl: string, httpRequestOptions: any, backendFetchOptions: Partial<BackendFetchOptions> = {}): Promise<any> {
        return this.fetch(partialUrl, httpRequestOptions, backendFetchOptions)
            .then(async response => backendFetchOptions.asText ? await response.text() : await response.json())
            .catch(error => {
                const errorMessage = `Error calling quickFetch on ${partialUrl} (${this.name}): ${_.get(error, 'message', '')}`;
                throw new Error(errorMessage);
            });
    }
}