import { MouseToolBase } from "./mouse-tool-base";
import { View, Plane } from "../view";
import * as polygonMath3D from '../../math/polygon-math-3d';
import {getDistance, range2} from "./utils";

export enum InfoToolNames {
    MeasureLength, IntensityProfile
}

class Line {
    public plane: Plane;
    public slice: number;
    public pointsMm: number[][];
    public pointsPatientMm: number[][];
    public pointsIndices: number[][];
    public hoverPointMm: number[] | null;
    public hoverPointPatientMm: number[] | null;
    public hoverPoint: number[] | null;  // indices in image/scan coordinates
    private epsilonMm = 2; // distance in Mm to the starting point that will close the line

    constructor(view: View, ptMm: number[], ptPatientMm: number[], pt: number[]) {
        this.plane = view.plane;
        this.slice = view.slice;
        this.pointsMm = [ptMm];
        this.pointsPatientMm = [ptPatientMm];
        this.pointsIndices = [pt];
        this.hoverPointMm = null;
        this.hoverPointPatientMm = null;
        this.hoverPoint = null;
    }

    // Can't add a point that would cross current lines
    isOkToAdd(): boolean {
        return true;
    }

    isClosing(): boolean {
        if(!this.hoverPointMm) return false;
        if(this.pointsMm.length < 3) return false;
        if(!this.pointsMm.some(pt => !polygonMath3D.arePointsClose(this.plane, pt, this.pointsMm[0], this.epsilonMm))) return false;
        return polygonMath3D.arePointsClose(this.plane, this.hoverPointMm, this.pointsMm[0], this.epsilonMm);
    }

    addPoint(): boolean {
        if(!this.hoverPointMm ) return false;
        if(!this.hoverPoint ) return false;
        if(!this.hoverPointPatientMm ) return false;

        let lineReady = this.isClosing();
        let pointMm = lineReady ? this.pointsMm[0] : this.hoverPointMm;
        this.pointsMm.push(pointMm);

        let pointPatientMm = lineReady ? this.pointsPatientMm[0] : this.hoverPointPatientMm;
        this.pointsPatientMm.push(pointPatientMm);

        let pointIndices = lineReady ? this.pointsIndices[0] : this.hoverPoint;
        this.pointsIndices.push(pointIndices);

        this.hoverPointMm = null;
        this.hoverPointPatientMm = null;
        this.hoverPoint = null
        return lineReady;
    }
}

export default class InfoTool extends MouseToolBase {

    task: number;  // profile OR measure
    public intensityProfile: number[];  // task: profile -> voxel values along the drawn line
    public distanceMM: number;  // task: profile -> length of the drawn line
    private line: Line | null;  // obj containing line points in image/patient coordinates
    private isMeasuring: boolean;

    constructor(task: InfoToolNames) {
        super();
        this.task = task;
        this.line = null;
        this.intensityProfile = [];
        this.distanceMM = 0.0;
        this.isMeasuring = false;
    }

    resetData() {
        this.line = null;
        this.intensityProfile = [];
        this.distanceMM = 0.0;
    }

    handleActivate() {
        this.resetData();
    }

    handleDeactivate() {
        this.resetData();
    }

    drawOnCanvas(canvas: HTMLCanvasElement, view: View) {
        // let vm = view.viewManager;
        // let vs = vm.viewerState;
        // vs.setErase(!vs.erase);
        // view.viewManager.viewerState.notifyListeners();

        const line = this.line;
        let ctx = canvas.getContext("2d");
        if(!line && ctx) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        if(!line) return;
        if(line.plane !== view.plane) return;
        if (this.task === InfoToolNames.IntensityProfile && line.slice !== view.slice) {
            this.resetData();
            return;
        } else {
            // if(line.slice !== view.slice) return;  // uncomment it IF LINE MUST BE SHOWN ONLY ON THE DRAWN SLICE
        }

        if(!ctx) return;

        ctx.lineWidth = line.isClosing() ? 2 : 1;
        ctx.strokeStyle="rgb(255,0,0)"; // line.roi.rgb();
        let polyMm = line.pointsMm;
        let polyPatientMm = line.pointsPatientMm;
        let poly = line.pointsIndices;
        let first = view.getPointInCanvasCoord(canvas, polyMm[0]);
        ctx.beginPath();
        ctx.arc(first[0], first[1], 3*view.viewManager.zooming, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.beginPath();
        let beginPt = view.getPointInCanvasCoord(canvas, polyMm[0]);
        ctx.moveTo(beginPt[0], beginPt[1]);

        let totalLength = 0.;
        for(let iPt = 0; iPt < polyMm.length; ++iPt)
        {
            let p = view.getPointInCanvasCoord(canvas, polyMm[iPt]);
            ctx.arc(p[0], p[1], 3*view.viewManager.zooming, 0, 2 * Math.PI);
            ctx.lineTo( p[0], p[1] );
            if (this.task === InfoToolNames.MeasureLength) {
                if (iPt > 0) {
                    let length = 0.0;
                    for (let i=0; i < 3; i++){
                        length += (polyPatientMm[iPt][i] - polyPatientMm[iPt - 1][i]) * (polyPatientMm[iPt][i] - polyPatientMm[iPt - 1][i]);
                    }
                    totalLength += Math.sqrt(length);
                    ctx.font = "25px Arial";
                    ctx.fillStyle = "red";
                    let ptc = view.getPointInCanvasCoord(canvas, polyMm[iPt]);
                    ctx.fillText(Math.round(totalLength).toString(), ptc[0] - 10, ptc[1] - 10);
                }
            }
        }

        if (this.task === InfoToolNames.IntensityProfile) {
            if (poly.length >= 2 && this.intensityProfile.length === 0) {
                // console.log(poly[0])
                // console.log(poly[1])
                // console.log(poly.length)
                this.intensityProfile = this.getVoxelValues(poly, view);
                this.distanceMM = getDistance(polyPatientMm[0], polyPatientMm[1]);
                // console.log(this.intensityProfile)
                // console.log("sample image profile")
            }
        }

        ctx.stroke();
        // console.log(totalLength)
    }

    getVoxelValue(pt: number[][], view: View): number[] {
        let vm = view.viewManager;
        let vals: number[] = []
        for (let i=0; i<pt.length; i++) {
            vals[i] = vm.image.getValue(pt[i][0], pt[i][1], pt[i][2]);
            // console.log(i + '-th: ' + pt[i] + ' : ' + vals[i])
        }
        // console.log('first: ' + pt[0] + ' : ' + vals[0])
        // console.log('last: ' + pt[pt.length - 1] + ' : ' + vals[pt.length - 1])
        return vals
    }

    getVoxelValues(pts: number[][], view: View): number[] {
        const dist = getDistance(pts[0], pts[1]);
        const n = Math.round(dist/2);
        // console.log([dist, n, pts[0][0], pts[0][1], pts[0][2], pts[1][0], pts[1][1], pts[1][2]])
        let ptsX = range2(pts[0][0], pts[1][0], n);
        let ptsY = range2(pts[0][1], pts[1][1], n);
        let ptsZ = range2(pts[0][2], pts[1][2], n);
        let ptsXYZ = [];
        for (let i=0; i<ptsX.length; i++) {
            ptsXYZ[i] = [Math.round(ptsX[i]), Math.round(ptsY[i]), Math.round(ptsZ[i])];
        }
        return this.getVoxelValue(ptsXYZ, view);
    }

    handleMouseDown(view: View, mousePointMm: number[], mouseButton: number): void {
        if (mouseButton === 0) {
            this.isMeasuring = true;
            let vm = view.viewManager;
            let vs = vm.viewerState;
            const mousePointImScaled = [mousePointMm[0], mousePointMm[1], mousePointMm[2]];
            const mousePointPatient = [mousePointMm[6], mousePointMm[7], mousePointMm[8]];
            const ptImIndices = [mousePointMm[9], mousePointMm[10], mousePointMm[11]];  //this.pt2ind([mousePointMm[3], mousePointMm[4]], view)
            // console.log(ptImIndices)
            if(this.line && this.line.plane !== view.plane) {
                this.resetData();
            }
            if(this.line && this.line.slice !== view.slice) {
                this.resetData();
            }

            if(this.line) {
                if(this.line.isOkToAdd() && vs.image.isPointIn(mousePointImScaled)) {
                    if (this.task === InfoToolNames.IntensityProfile && this.line.pointsMm.length >= 2) {
                        return  // ignore additional clicks
                    } else {
                        this.line.hoverPointMm = mousePointImScaled;
                        this.line.hoverPointPatientMm = mousePointPatient;
                        this.line.hoverPoint = ptImIndices;
                        const lineReady = this.line.addPoint();
                        if (lineReady) {
                            this.resetData();
                        }
                    }
                }
            }
            else if(vs.image.isPointIn(mousePointImScaled)) {
                this.line = new Line(view, mousePointImScaled, mousePointPatient, ptImIndices);
            }
            vs.notifyListeners();
        }
    }

    handleMouseUp(view: View) {
        this.isMeasuring = false;
    }

    handleEsc(view: View) {
        if(this.line) {
            this.resetData()
            view.viewManager.viewerState.notifyListeners();
        }
    }

    handleDrag(view: View, mousePointMm: number[], diff: number[]): void {
        const vm = view.viewManager;
        if (!this.isMeasuring) {
            vm.pan(view, diff[0], diff[1]);
        }
    }
}
