import _ from "lodash";
import React from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Select from 'react-select';
import { defaultAuths, getAppName, isDemo, RTViewerDisplayVersion, userAllowedBackends } from "../../../environments";
import { getAreConcurrentUploadsEnabled, setAreConcurrentUploadsEnabled } from "../../../local-storage";

import * as sagas from '../../../store/sagas';
import { StoreState } from "../../../store/store";
import { User } from "../../../store/user";
import AppAuth from "../../../web-apis/app-auth";
import { backends, backendTierAppAuths } from "../../../web-apis/auth";
import { Backend } from "../../../web-apis/backends";
import { Checkbox } from "../../misc-components";
import ModalDialog from "../ModalDialog";


import './UserSettingsDialog.css';
import { AppVersionInfo } from "../../../store/app-version-info";

type OwnProps = {}

type DispatchProps = {
    setUserSettingsDialogVisibility(value: boolean): void,
    setUserSettingBackend(backend: Backend | null): void,
    logIntoAppAuth: (appAuth: AppAuth) => void,
    requestLogOut: () => void,
    setUserSettingPatientInfoVisibility(showPatientInfo: boolean): void,
}

type OwnState = {
    /** This state prop is only used to get the settings react component to self-update. 
     * The actual value is read from and written to localStorage. */
    areConcurrentUploadsEnabledHack: boolean,
}

type AllProps = OwnProps & StoreState & DispatchProps;

class UserSettingsDialog extends React.Component<AllProps, OwnState> {

    constructor(props: AllProps) {
        super(props);

        this.state = {
            areConcurrentUploadsEnabledHack: getAreConcurrentUploadsEnabled(),
        };
    }

    isDefaultAppAuth = (appAuth: AppAuth) => {
        return defaultAuths.includes(appAuth.appName);
    }

    handleClose = () => {
        this.props.setUserSettingsDialogVisibility(false);
    }

    handleChange = (option: any) => {
        this.props.setUserSettingBackend(backends.getBackend(option.value));
    }

    handleLogOutClick = () => {
        this.props.requestLogOut();
    }

    handleLogInClick = (appAuth: AppAuth) => {
        this.props.logIntoAppAuth(appAuth);
    }

    handleUploadConcurrencyChange = () => {
        const newValue = !getAreConcurrentUploadsEnabled();
        setAreConcurrentUploadsEnabled(newValue);
        this.setState({ areConcurrentUploadsEnabledHack: newValue });
    }
    
    handleShowPatientInfoChange = (newValue: boolean) => {
        this.props.setUserSettingPatientInfoVisibility(newValue);
    }

    render() {
        const isVisible = this.props.isUserSettingsDialogVisible;

        const options = userAllowedBackends.map(b => ({ label: b.name, value: backends.getBackend(b.name).name }));
        const user = this.props.user as User;
        const permissions = user.permissions;
        const currentBackend = user.currentBackend !== null ? user.currentBackend.name : backends.getDefaultBackend() !== undefined ? backends.getDefaultBackend()!.name : undefined;
        const version: AppVersionInfo | undefined = this.props.appVersion;

        const canChangeBackend = !isDemo() && permissions.canChangeBackend;
        const showAppRegistrations = !isDemo() && permissions.allowDebugMode;

        const areConcurrentUploadsEnabled = getAreConcurrentUploadsEnabled();

        const labeling = this.props.labelingInfo;

        return (
            <ModalDialog
                show={isVisible}
                onHide={this.handleClose}
                size="lg">

                <Modal.Header closeButton>
                    <Modal.Title>User Session Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body className="user-settings">
                    <div className="notice">Note that these settings are only active in this session/tab.</div>
                    <Form>
                        <Form.Group>
                            <Form.Row>
                                <Col lg={6}>
                                    <div>Show identifying information (patient ID, dataset name) on main view and splash screen</div>
                                </Col>
                                <Col className="value-row-shift-checkbox">
                                    <Checkbox
                                        isSelected={user.showPatientInfo}
                                        onCheckboxChange={() => this.handleShowPatientInfoChange(!user.showPatientInfo)}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        {canChangeBackend && (
                            <Form.Group>
                                <Form.Row>
                                    <Col lg={6}>
                                        <div>Current backend</div>
                                    </Col>
                                    <Col className="value-row-shift-dropdown">
                                        <Select
                                            className="select-input"
                                            options={options}
                                            onChange={this.handleChange}
                                            value={options.find(opt => currentBackend && opt.value === currentBackend)}>
                                        </Select>
                                    </Col>
                                </Form.Row>
                            </Form.Group>
                        )}
                        {showAppRegistrations && (
                            <Form.Group>
                                <Form.Label>Backend/API auth debug information:</Form.Label>
                                {_.map(backendTierAppAuths, (appAuth, tier) => (
                                    <Form.Row className="app-auth-row" key={tier}>
                                        <Col lg={3}>
                                            <div>Tier: {tier}</div>
                                        </Col>
                                        <Col lg={3}>
                                            API: {appAuth.appName}
                                        </Col>
                                        <Col lg={3}>
                                            {appAuth.isLoggedIn ? 'Logged in' : 'Not logged in'}
                                        </Col>
                                        <Col lg={3}>
                                            {!appAuth.isLoggedIn && (<Button size="sm" variant={this.isDefaultAppAuth(appAuth) ? 'primary' : 'secondary'} onClick={() => this.handleLogInClick(appAuth)}>Log in</Button>)}
                                        </Col>
                                    </Form.Row>
                                ))}
                            </Form.Group>
                        )}

                        <hr />

                        <Form.Group>
                            <h3>Local settings</h3>

                            <div className="notice">
                                These settings apply to every session/tab of {getAppName()} in this browser.
                                <br />
                                Note that these settings won't be applied until after you refresh this tab.
                            </div> 

                            <Form.Row>
                                <Col lg={6}>
                                    <div title="Disabling this option will limit file uploads to one at a time.">Enable concurrent uploads</div>
                                </Col>
                                <Col className="value-row-shift-checkbox">
                                    <Checkbox
                                        label=""
                                        isSelected={areConcurrentUploadsEnabled}
                                        onCheckboxChange={this.handleUploadConcurrencyChange}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>

                        {labeling !== undefined && (
                            <>
                                <hr />

                                <Form.Group>
                                    <h3>Labeling</h3>

                                    {/* <div className="notice"><span className="local-page-not-for-clinical">{NOT_FOR_CLINICAL_USE_LABELING}.</span></div> */}

                                    <Form.Row>
                                        <Col lg={6}><b>System version</b></Col><Col>{labeling.systemVersion}</Col>
                                        <Col lg={6}><b>Manufacturer</b></Col><Col>{labeling.manufacturer}</Col>
                                        <Col lg={6}><b>Manufacturer model name</b></Col><Col>{labeling.manufacturerModelName}</Col>
                                        <Col lg={6}><b>Version</b></Col><Col>{labeling.gitHash}</Col>
                                        {labeling.otherFields.map(f => (<React.Fragment key={f[0]}><Col lg={6}><b>{f[0]}</b></Col><Col>{f[1]}</Col></React.Fragment>))}
                                    </Form.Row>

                                </Form.Group>
                            </>
                        )}

                        <Form.Group>
                            <Form.Row className="logout-button">
                                <Col lg={6}>
                                    <div>Logged in as <b><span title={user.email}>{user.username}</span></b></div>
                                </Col>
                                <Col className="value-row-shift-checkbox">
                                    <Button variant="danger" onClick={() => this.handleLogOutClick()}>Log out of {getAppName()}</Button>
                                </Col>
                                <Col lg={3}>
                                    Version: {RTViewerDisplayVersion}{!!version && (<span className="version-commit-id">({version.commit})</span>)}
                                </Col>
                            </Form.Row>
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={this.handleClose}>Ok</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps,
)(UserSettingsDialog);
