import { Backend } from "../web-apis/backends";
import { UserPermissions } from "../web-apis/user-permissions";
import Immerable from "./immerable";
import { LoggedInMsalUser } from "../web-apis/app-auth";
import { TrainingTask } from "../datasets/training-task";
import { RTStructLock } from "../datasets/locks";

/*
    User class
    User is currently being stored in two different places: here and in rtviewer-api-client.tsx
    (the RTViewerAPIClient class, exported as a singleton rtViewerApiClient). These classes
    should eventually be refactored so that RTViewerAPIClient is only concerned with calling
    the RTViewer API, not with storing user information.

    The current workaround for this is that this class is considered the single source of
    truth. Updating this class in the redux store will cause redux sagas functions to
    automatically update rtViewerApiClient with matching fields (using 
    RTViewerAPIClient.updateUser).
*/

/** Use this user ID if user ID has not yet been set. */
export const INVALID_USER_ID = 'N/A---0000000000000000000000000000000000000000000---N/A';

export class User extends Immerable {
    public isAuthenticated: boolean;
    public username: string;
    public email: string;
    public permissions: UserPermissions;
    public currentBackend: Backend | null;
    public userId: string;

    /** 
     * Show (or not) information that may identify patient and/or originating clinic in
     * places like the splash screen or RTViewer main view. This should generally be set 
     * to false when e.g. screen-sharing your session of RTViewer.
     */
    public showPatientInfo: boolean;

    constructor() {
        super();
        this.isAuthenticated = false;
        this.username = '';
        this.email = '';
        this.permissions = new UserPermissions();

        // null -> use default
        this.currentBackend = null;

        // TODO: is this a good default?
        this.showPatientInfo = true;

        this.userId = INVALID_USER_ID;
    }

    setBasicDetails(username: string, email: string) {
        this.username = username;
        this.email = email;
        this.isAuthenticated = true;
    }

    setPermissions(permissions: UserPermissions) {
        this.permissions = permissions;
    }

    setLoggedInDetails(details: LoggedInMsalUser) {
        this.userId = details.userId;
        if (details.name) { this.username = details.name; }
        if (details.email) { this.email = details.email; }
    }

    allowConfiguration() {
        // currently, as the log out functionality is embedded into the user settings dialog, we allow everyone to access it
        return true;
    }

    isTaskTrainee(task: TrainingTask): boolean {
        return task.trainee.user_id === this.userId;
    }

    hasRTStructLock(lock: RTStructLock): boolean {
        return lock.user_id === this.userId;
    }
}