import React from 'react';
import {Container, Row } from 'react-bootstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import BatchJobQueuePage from './batch-job-queue-page/BatchJobQueuePage';
import HelpDialog from './common/dialogs/HelpDialog';
import UserSettingsDialog from './common/dialogs/UserSettingsDialog';
import NewVersionAlert from './common/NewVersionAlert';
import NotificationsList from './common/Notifications';
import ServiceAgreementModal from './common/ServiceAgreement';
import UtilityToolsToolbar from './common/UtilityToolsToolbar';
import { connect } from 'react-redux';
import { StoreState } from '../store/store';
import SidebarMenu from './welcome-page/sidebar-menu/SidebarMenu';
import GlobalToolbar from './common/GlobalToolbar';

interface IProps extends RouteComponentProps<any> { }

type OwnProps = {}

type AllProps = OwnProps & StoreState & IProps;

class Layout extends React.Component<AllProps> {
  render() {
    const isToolsPage = this.props.location.pathname.includes('/tools/');

    const loginFailed = this.props.loginError !== null;
    if (loginFailed) { return null; }

    // TODO: Check if the main Container component should be reverted back to just:
    // <Container fluid>
    return (
      <Container fluid className="home-page">
        {/* <Navbar bg="light" variant="light" fluid="true" collapseOnSelect>
          <Nav className="">
            <Nav.Link href="/local">Local files</Nav.Link>
            <Nav.Link href="/annotation">Azure files</Nav.Link>
          </Nav>
      </Navbar>  */}

        <SidebarMenu history={this.props.history} location={this.props.location} match={this.props.match} />
        <ServiceAgreementModal />
        <GlobalToolbar isEmbedded={false} />


        <NewVersionAlert />

        {isToolsPage && <UtilityToolsToolbar />}

        <NotificationsList />

        <Row>
          {this.props.children}
        </Row>
        <BatchJobQueuePage />                                                                                           
        <UserSettingsDialog />
        <HelpDialog />
      </Container>);
  }
}

export default withRouter<IProps, any>(connect(
  state => Object.assign({}, state),
  null
)(Layout));

