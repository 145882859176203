// React component placed on top of canvas placed on one plane (e.g. transversal). Contains UI interaction with that image plane, 
// such as mouse interactions.

import React from 'react';
import { StoreState } from '../../store/store';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import * as viewManager from '../../rtviewer-core/view-manager';
import { View } from "../../rtviewer-core/view";
import './PlaneView.css';
import { PositionDragger } from './PositionDragger';
import { isDemo } from '../../environments';
import { MouseCursor } from '../../rtviewer-core/mouse-tools/mouse-tool-base';
import {Line} from "react-chartjs-2";
import InfoTool from "../../rtviewer-core/mouse-tools/info-tool";
import CoordinateOverlay from './CoordinateOverlay';
import { User } from '../../store/user';
import { Row } from 'react-bootstrap';

type OwnProps = {
    view: View,
}

type DispatchProps = {
}

type AllProps = OwnProps & StoreState & DispatchProps;

const intensityProfileState: {
    labels: number[],
    datasets: {
        label: string,
        fill: boolean,
        lineTension: number,
        backgroundColor: string,
        borderColor: string,
        borderWidth: number,
        data: number[],
    }[]
} = {
    labels: [],
    datasets: [{
        label: 'Voxel Value',
        fill: false,  // fills the volume below the line
        lineTension: 0.0,
        backgroundColor: 'rgba(255,128,128,1)',  // color of markers
        borderColor: 'rgba(255,0,0,1)',  // color of line
        borderWidth: 2,
        data: []  // data -> [65, 59, 80, 81, 56] OR data -> [{x: 0, y: 0}, {x: 2, y: 2}, {x: 3, y: 3}, {x: 4, y: 4}, {x: 5, y: 5}]
    }
    ]
}

type OwnState = {
    refreshSwitch: boolean,
    activePlane: boolean, // Is this the plane where mouse pointer currently is
    lastMouseMoveEvent?: any,
}

class PlaneView extends React.Component<AllProps, OwnState> {
    intensityProfileApp: any = null;
    intensityProfile: number[] = [];
    intensityProfileState = intensityProfileState;
    redoIntensityProfile: boolean = false;
    displayName = PlaneView.name;
    activeVoxelPatientPos: number[] = [NaN, NaN, NaN];  // position ([X, Y, Z]) in patient coordinate system
    activeVoxelImIndices: number[] = [NaN, NaN, NaN];
    activeVoxelImIndicesMm: number[] = [NaN, NaN, NaN];
    activeVoxelValue: number = NaN;  // the voxel value (intensity) at the mouse location

    mousePos: {x: number, y: number} | undefined = undefined;

    constructor(props: AllProps) {
        super(props);
        this.state = {
            activePlane: false,
            refreshSwitch: false,
        };
    }

    updateProfile(distanceMM: number) {
        let data: number[] = [];
        let labels: number[] = [];
        for (let d=0; d<this.intensityProfile.length; d++) {
            data.push(this.intensityProfile[d]);
            labels.push(Math.round(distanceMM * (d / (this.intensityProfile.length - 1))));
        }
        this.intensityProfileState.labels = labels;
        this.intensityProfileState.datasets[0].data = data;

        let element0 = (<div />);
        if (this.intensityProfile.length) {
            this.redoIntensityProfile = false;
            element0 = (
                <div className='popup'>
                    <Line
                        data={this.intensityProfileState}
                        options={{scales: {yAxes: [{scaleLabel: {display: true,labelString: 'Intensity'}}], xAxes: [{scaleLabel: {display: true,labelString: 'mm'}}]}}}
                    />
                </div>
            );
        }

        class IntensityProfileApp extends React.Component {
            render() {
                return element0}
        }

        this.intensityProfileApp = IntensityProfileApp;
    }

    componentDidMount() {
        let vm = this.props.view.viewManager;
        let vs = vm.viewerState;
        vm.addListener(this.updateView);
        vs.addListener(this.updateView);
        this.updateView();

        // In React, child component (Expand button) events need to be bound before parent (planeview) events so that e.stopPropagation works
        let expandButton = (this.refs.ExpandButton as HTMLElement);
        expandButton.addEventListener('click', this.handleExpandPlaneClick, false);
        expandButton.addEventListener('mousedown', (evt)=>evt.stopPropagation(), false);

        let planeView = (this.refs.PlaneView as HTMLElement);
        planeView.onwheel = this.handleMouseWheelEvent;
        planeView.addEventListener('click', this.handleClick, false);
        planeView.addEventListener('mousedown', this.handleMouseDown, false);
        planeView.addEventListener('mouseup', this.handleMouseUp, false);
        planeView.addEventListener('mousemove', this.handleMouseMove, false);
        planeView.addEventListener('mouseleave', this.handleMouseLeave, false);
    }

    componentWillUnmount() {
        let vm = this.props.view.viewManager;
        let vs = vm.viewerState;
        vm.removeListener(this.updateView);
        vs.removeListener(this.updateView);
    }
    
    updateView = () => {
        this.setState({refreshSwitch: !this.state.refreshSwitch});
    }

    findElementAbsolutePos = (obj: any): any => {
        let curLeft = 0;
        let curTop = 0;
        if (obj.offsetParent) {
            do {
                curLeft += obj.offsetLeft;
                curTop += obj.offsetTop;
                obj = obj.offsetParent;
            } while (obj);
            return [curLeft,curTop];
        }
    }

    // Returns relative x and y coordinates, between [0,1]
    getMousePoint = (evt?: any): number[] => {
        let planeView = (this.refs.PlaneView as HTMLElement);
        let planePos = this.findElementAbsolutePos(planeView);
        let i: number = 0;
        let j: number = 0;
        if(evt){
            i = evt.clientX - planePos[0];
            j = evt.clientY - planePos[1];
        } else if(this.mousePos) {
            i = this.mousePos.x - planePos[0];
            j = this.mousePos.y - planePos[1];
        }

        // Make roi tooltip follow the mouse cursor
        let tip: any = this.refs.RoiTooltip;
        tip.style.left = i - 20 + "px";
        tip.style.top = j - 30 + "px";

        i /= planeView.clientWidth;
        j /= planeView.clientHeight;
        return [i, j];
    }

    handleClick = (evt: any) => {
        evt = evt || window.event;
        evt.preventDefault();
        let view = this.props.view;
        let vs = view.viewManager.viewerState;
        let pt = this.getMousePoint(evt);
        let ptMm = view.getPointInMm(pt);
        vs.activeMouseTools.forEach(tool => tool.handleClick(view, ptMm));
    }

    handleExpandPlaneClick = (evt: any) => {
        evt.stopPropagation();
        let view = this.props.view;
        let vm = view.viewManager;
        if(vm.viewMode === viewManager.ViewMode.SingleView || view.plane !== vm.mainPlane) {
            vm.setThreeViews(view.plane);
        }
        else {
            vm.setSingleView(view.plane);
        }
    }

    scroll = (up: boolean) => {
        this.props.view.viewManager.scroll(this.props.view.depthAxis, up);

        let pt = this.getMousePoint();
        let ptMm = this.props.view.getPointInMm(pt);
        this.updateVoxelValue(ptMm);
        this.updateView();
    }

    zoom = (mousePoint: number[] | null, up: boolean) => {
        let view =  this.props.view;
        let vm = view.viewManager;
        let vs = vm.viewerState;

        if(mousePoint && vs.focusWhenMouseZooming) {
            vm.zoomAndPanToPoint(up, view, mousePoint[0], mousePoint[1]);
        }
        else {
            vm.zoom(up);
            if(this.state.lastMouseMoveEvent) this.handleMouseMove(this.state.lastMouseMoveEvent); // otherwise the "brush cursor" will move away
        }
    }


    handleMouseDown = (evt: any) => {
        let view = this.props.view;
        let vm = view.viewManager;
        let vs = vm.viewerState;
        let planeView = (this.refs.PlaneView as HTMLElement);

        let pt = this.getMousePoint(evt);
        let ptMm = view.getPointInMm(pt);
        let ptPatient = view.getPointInPatientMM(ptMm);
        let ptImageIndices = view.getPointInImageIndices(ptMm);

        const isInfoTool = vs.activeMouseTools[0] instanceof InfoTool;
        let pts: number[];
        if (isInfoTool) {
            pts = [ptMm[0], ptMm[1], ptMm[2], pt[0], pt[1], pt[2], ptPatient[0], ptPatient[1], ptPatient[2], ptImageIndices[0], ptImageIndices[1], ptImageIndices[2]];
        } else {
            pts = ptMm;
        }
        vs.activeMouseTools.forEach(tool => tool.handleMouseDown(view, pts, evt.button));
        if (isInfoTool) {
            this.redoIntensityProfile = this.intensityProfile.length !== vs.activeMouseTools[0].intensityProfile.length;
        } else {
            this.intensityProfileApp = null;
            this.intensityProfile = [];
        }
        if (this.redoIntensityProfile) {
            this.intensityProfile = vs.activeMouseTools[0].intensityProfile;
            this.updateProfile(vs.activeMouseTools[0].distanceMM);
            this.setState({activePlane: true, lastMouseMoveEvent: evt});
        }
        let startX = evt.clientX;
        let startY = evt.clientY;
        document.onmouseup = closeDragElement;
        document.onmousemove = elementDrag;
        let t = this;
        function elementDrag(e: any) {
            e = e || window.event;
            e.preventDefault();
            let diffX = e.clientX - startX;
            let diffY = e.clientY - startY;
            startX = e.clientX;
            startY = e.clientY;
            let diff = [ diffX / planeView.clientWidth,
                         diffY / planeView.clientHeight];
            let pt = t.getMousePoint(e);
            let ptMm = view.getPointInMm(pt);
            vs.activeMouseTools.forEach(tool => tool.handleDrag(view, ptMm, diff));
        }
        function closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
            vs.activeMouseTools.forEach(tool => tool.handleDragRelease(view));
        }
    }

    handleMouseUp = (evt: any) => {
        let view = this.props.view;
        let vm = view.viewManager;
        let vs = vm.viewerState;
        vs.activeMouseTools.forEach(tool => tool.handleMouseUp(view));
    }

    handleMouseMove = (evt: any) => {
        let view = this.props.view;
        let vm = view.viewManager;
        let vs = vm.viewerState;

        this.mousePos = {x: evt.clientX, y: evt.clientY};

        // This plane is now active so that it can be scrolled with up/down keys etc.
        window.onkeydown = this.handleKey;
        this.setState({activePlane: true, lastMouseMoveEvent: evt});

        let pt = this.getMousePoint(evt);
        let ptMm = view.getPointInMm(pt);
        this.updateVoxelValue(ptMm);

        vs.activeMouseTools.forEach(tool => tool.handleHover(view, ptMm));
    }

    handleMouseLeave = (evt: any) => {
        let view = this.props.view;
        let vm = view.viewManager;
        let vs = vm.viewerState;

        this.activeVoxelValue = NaN;
        this.setState({activePlane: false});
        vs.activeMouseTools.forEach(tool => tool.handleMouseLeave(view));
    }

    handleMouseWheelEvent = (e: any) =>
    {
        let vs = this.props.view.viewManager.viewerState;
        let up = e.deltaY < 0;

        let pt = this.getMousePoint(e);
        let ptMm = this.props.view.getPointInMm(pt);
        this.updateVoxelValue(ptMm);

        // First check if any currently active mouse tool is willing to handle the wheel event
        // (e.g. brush tool to change the brush width)
        let eventHandled = false;
        vs.activeMouseTools.forEach(tool => {
            let result = tool.handleScroll(this.props.view, up, e.ctrlKey, e.shiftKey);
            eventHandled = eventHandled || result;
        })
        if(eventHandled) {
            return false;
        }

        if (e.ctrlKey)
        {
            this.zoom(this.getMousePoint(e), up);
            return false;
        }

        this.scroll(up);

        // get the latest scroll position
        pt = this.getMousePoint(e);
        ptMm = this.props.view.getPointInMm(pt);
        this.updateVoxelValue(ptMm);
        this.updateView()

        return false;
    }

    handleKey = (e: any) => {
        // todo: update the voxel position/value printed when a keyboard is used to change slices
        let vs = this.props.view.viewManager.viewerState;
        let roi = vs.selectedRoi;

        switch(e.keyCode){
            case 68: // d for debug
                if(process.env.NODE_ENV === 'development') {
                    vs.setDebugMode(!vs.debugMode);
                }
                break;
            case 18: // Alt
                vs.activeMouseTools.forEach(tool => { tool.handleAlt(this.props.view)});
                return false;
            case 38:
            case 40:
                let up = e.keyCode === 38;
                let eventHandled = false;
                vs.activeMouseTools.forEach(tool => {
                    let result = tool.handleScroll(this.props.view, up, e.ctrlKey, e.shiftKey);
                    eventHandled = eventHandled || result;
                })
                if(eventHandled){
                    return false;
                }
        }

        e = e || window.event;

        switch(e.keyCode){
            case 27: // esc
                vs.activeMouseTools.forEach(tool => {
                    tool.handleEsc(this.props.view);
                });
                return false;
            case 38: // up arrow
                if(e.ctrlKey) {
                    this.zoom(null, true);
                } else {
                    this.scroll(true);
                }
                return false;
            case 40: // down arrow
                if(e.ctrlKey) {
                    this.zoom(null, false);
                } else {
                    this.scroll(false);
                }
                return false;
            case 89: // y
                if(e.ctrlKey) { // Ctrl+Y for redo
                    if(roi && vs.undoStack.canRedo(roi)) {
                        vs.undoStack.redo(roi);
                    }
                }
                return true;
            case 90: // z
                if(e.ctrlKey) { // Ctrl+Z for undo
                    if(roi && vs.undoStack.canUndo(roi)) {
                        vs.undoStack.undo(roi);
                    }
                }
                return true;
            case 67: // c
                if(e.ctrlKey) { // Ctrl+C for copy
                    console.log("Attempted to copy.");
                    vs.viewManager.copyToClipboard();
                    //if contour
                    //vs.clipboard.copy(contour);
                }
                return true;
            case 86: // v
                if(e.ctrlKey) { // Ctrl+V for paste
                    console.log("Attempted to paste.");
                    vs.viewManager.pasteFromClipboard(vs.clipboard.paste());
                }
                return true;
        }
        return true;
    }

    updateVoxelValue(ptMm: number[]) {
        let view = this.props.view;
        let vm = view.viewManager;
        for (let i=0; i<3; i++) {
            if (isNaN(ptMm[i])) {
                if (isNaN(this.activeVoxelImIndicesMm[i])) {
                    this.activeVoxelValue = NaN;
                    return;
                }
                ptMm[i] = this.activeVoxelImIndicesMm[i]
            }
        }
        this.activeVoxelImIndicesMm = ptMm
        let ptPatient = this.props.view.getPointInPatientMM(ptMm);
        let ptImIndices = this.props.view.getPointInImageIndices(ptMm);

        // for getting the voxel value at cross-hair
        // let scrollPos = vm.getScrollPositions()
        // this.activeVoxelImIndices[0] = Math.round(scrollPos[0] * vm.image.iPixels)
        // this.activeVoxelImIndices[1] = Math.round(scrollPos[1] * vm.image.jPixels)
        // this.activeVoxelImIndices[2] = Math.round(scrollPos[2] * vm.image.kPixels)

        // for getting the voxel value at mouse position
        this.activeVoxelPatientPos = [Math.round(10 * ptPatient[0])/10, Math.round(10 * ptPatient[1])/10, Math.round(10 * ptPatient[2])/10]
        this.activeVoxelImIndices = ptImIndices
        if (this.activeVoxelImIndices[0] < 0 || this.activeVoxelImIndices[1] < 0 || this.activeVoxelImIndices[2] < 0 ||
            this.activeVoxelImIndices[2] >= vm.image.kPixels || this.activeVoxelImIndices[0] >= vm.image.iPixels || this.activeVoxelImIndices[1] >= vm.image.jPixels) {
            this.activeVoxelValue = NaN;
        } else {
            this.activeVoxelValue = vm.image.getValue(this.activeVoxelImIndices[0], this.activeVoxelImIndices[1], this.activeVoxelImIndices[2]);
            // console.log([this.activeVoxelImIndices[0], this.activeVoxelImIndices[1], this.activeVoxelImIndices[2], this.activeVoxelValue])
        }
        // console.log([view.depthAxis, view.slice, this.activeVoxelImIndices[2], this.activeVoxelImIndices[0], this.activeVoxelImIndices[1], this.activeVoxelValue])
    }

    render() {
        const view = this.props.view;
        const vm = view.viewManager;
        const vs = vm.viewerState;
        const img = vs.image;
        const isMainPlane = view.plane === vm.mainPlane;
        const windowLevelText = "WW/WC: " + vs.windowLevel.ww + "/" + vs.windowLevel.wc;
        const zoomText = "Zoom: " + Math.round(vm.zooming * 100) + '%';

        const isInfoTool = vs.activeMouseTools[0] instanceof InfoTool;
        if (!isInfoTool || vs.activeMouseTools[0].intensityProfile.length === 0) {
            // this.intensityProfileApp = null
            this.intensityProfile = [];
        }

        const directionLetters = img.getDirectionLetters()[view.plane];
        const roiTooltip = vs.hoveredRoi && this.state.activePlane ? `${vs.hoveredRoi.name} (${vs.hoveredRoi.structureSet.getLabel()})` : "";
        const patientName = img.dicomTags.PatientName;
        const seriesInstanceUID = img.dicomTags.SeriesInstanceUID;
        // const forUID = img.dicomTags.FrameOfReferenceUID;
        const seriesDescription = img.dicomTags.SeriesDescription;
        const manufacturer = img.dicomTags.Manufacturer;
        const manufacturerModelName = img.dicomTags.ManufacturerModelName;
        const datasetName = vs.dataset ? vs.dataset.datasetFile.fileShareName : null;

        // patient & dataset info is displayed if 1) user has set appropriate setting on, or 2) we CAN'T resolve the user
        const displayPatientInfo = !this.props.user || !(this.props.user instanceof User) || this.props.user.showPatientInfo;
        
        let mouseCursorClass = "";
        vs.activeMouseTools.forEach(mouseTool => {
            const cursor = mouseTool.getMouseCursor(view);
            if (cursor === MouseCursor.Pointer)  mouseCursorClass = " pointer-cursor ";
            if (cursor === MouseCursor.Crosshair) mouseCursorClass = " crosshair-cursor ";
            if (cursor === MouseCursor.Pencil) mouseCursorClass = " pencil-cursor ";
            if (cursor === MouseCursor.Eraser) mouseCursorClass = " eraser-cursor ";
        });

        return (
            <div className={"plane-view " + mouseCursorClass} ref="PlaneView">
                
                <PositionDragger horizontal={false} view={view} refreshSwitch={this.state.refreshSwitch}></PositionDragger>
                <PositionDragger horizontal={true} view={view} refreshSwitch={this.state.refreshSwitch}></PositionDragger>
                
                <svg className="expand-plane-svg" ref="ExpandButton">
                    <rect rx="4" ry="4" className="expand-plane-button"/>
                    <rect x="20%" y="27%" className="expand-plane-rectangle"/>
                </svg>

                <div className="overlay overlay-disclaimer">
                </div>
                {this.intensityProfile.length && this.intensityProfileApp != null ? <this.intensityProfileApp /> : null}
                <div className="overlay overlay-top-left">
                    {isMainPlane && !isDemo() ? <>
                        {displayPatientInfo && datasetName && (<div>{"Dataset: " + datasetName}<br/></div>)}
                        {displayPatientInfo && (<>{"Patient: " + patientName}<br/></>)}
                        {"SeriesUID: " + seriesInstanceUID}<br/>
                        {/*{"FORUID: " + forUID}<br/>*/}
                        {"Series: " + seriesDescription}<br/>
                        { manufacturerModelName ? manufacturer + "/" + manufacturerModelName : manufacturer}
                    </>
                    : null}
                </div>

                {(vs.comparisonStructureSet && vs.comparisonStructureSet === vs.selectedStructureSet) &&
                    <div className='overlay overlay-top-right'>
                        {isMainPlane && <>
                            {vs.comparisonStructureSet.getRois().map((r, i) => {
                                if (i < 3) {
                                    return <Row key={i}>
                                        <div className="color-square" style={{ backgroundColor: r.fillColorRgb() === 'rgb(0, 0, 0)' ? r.rgb() : r.fillColorRgb() }}
                                        />{r.name}<br />
                                    </Row>
                                }
                                return <Row key={i}>
                                    <div className="color-line" style={{ backgroundColor: r.fillColorRgb() === 'rgb(0, 0, 0)' ? r.rgb() : r.fillColorRgb() }}
                                    />{i === 3 ? 'Test Structure' : i === 4 ? 'Reference Structure' : r ? r.name : ''}<br />
                                </Row>
                            }

                            )}</>}
                    </div>
                }


                <div className="overlay overlay-bottom-left">
                    {isMainPlane ? 
                        <div>
                            {zoomText}<br/>
                            {windowLevelText}
                        </div>: null}
                </div>

                <CoordinateOverlay
                    className="overlay" 
                    depthAxis={view.depthAxis} 
                    depth={Math.round(100 * vm.getScrollPositionsMm()[view.depthAxis]) / 100}
                    x={this.activeVoxelPatientPos[0]}
                    y={this.activeVoxelPatientPos[1]}
                    z={this.activeVoxelPatientPos[2]}
                    i={this.activeVoxelImIndices[0]}
                    j={this.activeVoxelImIndices[1]}
                    k={this.activeVoxelImIndices[2]}
                    value={this.activeVoxelValue}
                />

                <div className="overlay overlay-top"> {directionLetters[0]} </div>
                <div className="overlay overlay-right"> {directionLetters[1]}</div>
                <div className="overlay overlay-bottom">{directionLetters[2]}</div>
                <div className="overlay overlay-left">{directionLetters[3]}</div>

                <div ref="RoiTooltip" className={roiTooltip !== "" ? "overlay overlay-on-hover" : "overlay"}>
                    {roiTooltip}
                </div>
            </div>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(PlaneView);