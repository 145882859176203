// utility functions for React components

/** Prevents keyboard events from propagating past this component. Useful for modal dialogs
 * to prevent keyboard actions from triggering operations under the dialogs.
 * 
 * Usage:
 * <Component onKeyDown={stopKeyboardPropagation} />
 * 
 * (Or just call it directly from a method.)
 */
export const stopKeyboardPropagation = (e: React.KeyboardEvent) => {
    e.stopPropagation();
}
