import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { TrainingTask, TrainingTaskState } from '../../datasets/training-task';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import GradeButtons from './GradeButtons';

interface Props {
    task: TrainingTask;
    show: boolean;
    handleClose: () => void;
}


export interface Grade {
    selectedGrade: number | null;
}


interface DispatchProps {
    updateTask(task: TrainingTask, update: Partial<TrainingTask>): void;
}

type AllProps = Props & DispatchProps;

interface State {
    grade: string;
    comments: string;
    isTaskFinished: boolean;
}

class TaskGradeModal extends React.Component<AllProps, State> {
    constructor(props: AllProps) {
        super(props);
        this.state = {
            grade: this.props.task.grade || '0',
            comments: this.props.task.comments || '',
            isTaskFinished: this.props.task.state === TrainingTaskState.Finished,
        };
    }

    componentDidUpdate(prevProps: AllProps) {
        if (prevProps.task !== this.props.task) {
            this.setState({
                isTaskFinished: this.props.task.state === TrainingTaskState.Finished,
        });
    }}   

    handleGradeUpdate = () => {
        const { grade, comments } = this.state;
        if (grade) {
            this.props.updateTask(this.props.task, { grade: grade, state: TrainingTaskState.Graded, comments: comments });
            this.props.handleClose();
        }
    };

    handleGradeChange = (grade: number) => {
        this.setState({ grade: grade.toString() });
    };

    handleCommentsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value } = event.target;
        this.setState({ comments: value });
    };

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Task Grading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="task-grade">
                            <Form.Label>Grade</Form.Label>
                            <GradeButtons onGradeSelect={this.handleGradeChange} />
                        </Form.Group>

                        <Form.Group controlId="task-comments">
                            <Form.Label>Overall comments</Form.Label>
                            <Form.Control as="textarea" rows={3} value={this.state.comments} onChange={this.handleCommentsChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleGradeUpdate} className={this.state.isTaskFinished ? "grade-ready" : ""}>
                        Grade Task
                    </Button>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(TaskGradeModal);
