import { StructureSet } from "../dicom/structure-set";
import { areStringsSameCi } from "../helpers/compare";
import Immerable from "../store/immerable";
import { isRutherford } from "../environments";

// rutherford-specific ROI gradings entries
const rutherfordRoiGradings: { [val: string]: string } = {
    "0": "Not set",
    "A": "Approved",
    "U": "Unapproved",
};

// Adding a new ROI grading:
// 1. Add a new entry here. You're done!
export const GradingMeanings: { [val: string]: string } = isRutherford() ? rutherfordRoiGradings : {
    "0": "Not selected",
    "1": "Good",
    "2": "OK, small fixes",
    "3": "OK, large fixes",
    "4": "Bad, no use",
    "5": "No organ/tissue present",
    "6": "Good, but obsolete",
    "7": "Good, but requires verification",
    "8": "Not in scan",
}

/** Returns matching variant string for boostrap component use */
export const getGradingVariant = (val: string): 'light' | 'danger' | 'success' => {
    switch (val) {
        case "0":
            return 'light';
        case "4":
        case "U":
            return 'danger';
        default:
            return 'success';
    }
}

// Adding a new grading workflow state:
// 1. Add a new entry into the enum below
// 2. Add the new entry to gradingWorkflowStatesInOrder and convertWorkflowStateToText in this file
// 3. In grading/GradingState.tsx add the new entry to convertWorkflowStateToIcon and getColorStyle
// 4. Add the new style class from getColorStyle to grading/GradingState.css (even if it uses same colors as some other state)
export enum GradingWorkflowState {
    Undefined = "UNDEFINED",                // "not set" value, default
    NotStarted = "NOTSTARTED",              // work has not yet started
    InProgress = "INPROGRESS",              // work is on-going
    ReadyForNextPhase = "READYFORNEXTPHASE",      // work is ready to move on to the next phase
    WaitingForPeerReview = "WAITINGFORPEERREVIEW",  // work is ready to be reviewed by a peer
    WaitingForReview = "WAITINGFORREVIEW",  // work is ready to be reviewed
    PeerFixesNeeded = 'PEERFIXESNEEDED',    // work has been reviewed by a peer, needs improvement 
    FixesNeeded = "FIXESNEEDED",            // work has been reviewed, needs improvements
    PeerReviewed = "PEERREVIEWED",          // work has been peer reviewed, but the review needs final verification
    Reviewed = "REVIEWED",                  // work has been reviewed as ok
    ReadyForTraining = "READYFORTRAINING",  // work has been approved for model training
    ExpertApproved = "EXPERTAPPROVED",      // work has been approved by experts for use in testing
    Obsolete = "OBSOLETE",                  // the work done is obsolete and has been superceded by newer work
    RutherfordUndefined = "RUTHERFORD_UNDEFINED",
    RutherfordApproved = "RUTHERFORD_APPROVED",
    RutherfordUnapproved = "RUTHERFORD_UNAPPROVED",
}

// rutherford-specific workflow entries
const rutherfordWorkflowStatesInOrder: GradingWorkflowState[] = [
    GradingWorkflowState.RutherfordUndefined,
    GradingWorkflowState.RutherfordApproved,
    GradingWorkflowState.RutherfordUnapproved,
];

// The order in which workflow states should generally appear to user.
// You need to manually filter out values such as 'undefined' and 'ready for training' if they are not needed.
export const gradingWorkflowStatesInOrder: GradingWorkflowState[] = isRutherford() ? rutherfordWorkflowStatesInOrder : [
    GradingWorkflowState.Undefined,
    GradingWorkflowState.NotStarted,
    GradingWorkflowState.InProgress,
    GradingWorkflowState.ReadyForNextPhase,
    GradingWorkflowState.WaitingForPeerReview,
    GradingWorkflowState.WaitingForReview,
    GradingWorkflowState.PeerFixesNeeded,
    GradingWorkflowState.FixesNeeded,
    GradingWorkflowState.PeerReviewed,
    GradingWorkflowState.Reviewed,
    GradingWorkflowState.ReadyForTraining,
    GradingWorkflowState.ExpertApproved,
    GradingWorkflowState.Obsolete,
]

export const convertWorkflowStateToText = (state: GradingWorkflowState): string => {
    switch (state) {
        case GradingWorkflowState.Undefined:
            return ' ';
        case GradingWorkflowState.NotStarted:
            return 'Work not started';
        case GradingWorkflowState.InProgress:
            return 'Work in progress';
        case GradingWorkflowState.ReadyForNextPhase:
            return 'Ready for next phase';
        case GradingWorkflowState.WaitingForPeerReview:
            return 'Waiting for peer review';
        case GradingWorkflowState.WaitingForReview:
            return 'Waiting for review';
        case GradingWorkflowState.PeerFixesNeeded:
            return 'Peer fixes needed';
        case GradingWorkflowState.FixesNeeded:
            return 'Fixes needed';
        case GradingWorkflowState.PeerReviewed:
            return 'Peer reviewed';
        case GradingWorkflowState.Reviewed:
            return 'Reviewed';
        case GradingWorkflowState.ReadyForTraining:
            return 'Ready for training';
        case GradingWorkflowState.ExpertApproved:
            return 'Expert approved';
        case GradingWorkflowState.Obsolete:
            return 'Obsolete';
        case GradingWorkflowState.RutherfordUndefined:
            return '';
        case GradingWorkflowState.RutherfordApproved:
            return 'Approved';
        case GradingWorkflowState.RutherfordUnapproved:
            return 'Unapproved';
        default:
            return '';
    }
}

// Following classes will be deserialized from JSON. Don't add methods to the classes

export class RoiGrading extends Immerable {
    public value: string;
    public valueMeaning: string;
    public unsure: boolean;
    public roiName: string;
    public comment?: string;

    constructor(roiName: string) {
        super();

        this.value = "0";
        this.valueMeaning = GradingMeanings[this.value];
        this.unsure = false;
        this.roiName = roiName;
    }
}

export class StructureSetGrading extends Immerable {
    public rois: { [roiNumber: string]: RoiGrading };
    public comment?: string;
    public workflowState: GradingWorkflowState;

    constructor() {
        super();

        this.rois = {}; // Roi gradings are only created once user grades/comments that roi
        this.workflowState = GradingWorkflowState.Undefined;
    }
}

export class DatasetGradings extends Immerable {
    public structureSets: { [ssId: string]: StructureSetGrading }

    constructor() {
        super();

        this.structureSets = {};
    }
}

export class DuplicateRoiGradingError {
    public roiName: string;
    public duplicateStructureSetLabels: { [structureSetId: string]: string };

    constructor(roiName: string, duplicateStructureSetLabels: { [structureSetId: string]: string }) {
        this.roiName = roiName;
        this.duplicateStructureSetLabels = duplicateStructureSetLabels;
    }
}

/** A ROI grading parsed directly from JSON. This is a plain Javascript object and must be converted
 *  into an actual RoiGrading object to get its functionality (methods).
 */
export type ParsedRoiGrading = {
    value: string;
    valueMeaning: string;
    unsure: boolean;
    roiName: string;
    comment?: string;
}

/** A structure set grading parsed directly from JSON. This is a plain Javascript object and must be converted
 *  into an actual StructureSetGrading object to get its functionality (methods).
 */
export type ParsedStructureSetGrading = {
    rois: { [roiNumber: string]: ParsedRoiGrading };
    comment?: string;
    workflowState: GradingWorkflowState;
}

/** Dataset gradings parsed directly from JSON. This is a plain Javascript object and must be converted
 *  into an actual DatasetGradings object to get its functionality (methods).
 */
export type ParsedDatasetGradings = {
    structureSets: { [ssId: string]: ParsedStructureSetGrading };
}

/**
 * Converts parsed dataset gradings objects recursively into a proper, hydrated DatasetGradings
 * object and it's children that we can use in the application.
 * @param parsedGradings 
 */
export function importParsedGradings(parsedGradings: ParsedDatasetGradings): DatasetGradings {
    const datasetGradings = new DatasetGradings();

    for (const ssId of Object.keys(parsedGradings.structureSets)) {
        const parsedStructureSetGrading = parsedGradings.structureSets[ssId];

        const structureSetGrading = new StructureSetGrading();
        structureSetGrading.comment = parsedStructureSetGrading.comment;
        structureSetGrading.workflowState = parsedStructureSetGrading.workflowState;

        for (const roiNumber of Object.keys(parsedStructureSetGrading.rois)) {
            const parsedRoiGrading = parsedStructureSetGrading.rois[roiNumber];
            const roiGrading = new RoiGrading(parsedRoiGrading.roiName);
            roiGrading.value = parsedRoiGrading.value;
            roiGrading.valueMeaning = parsedRoiGrading.valueMeaning;
            roiGrading.unsure = parsedRoiGrading.unsure;
            roiGrading.comment = parsedRoiGrading.comment;

            structureSetGrading.rois[roiNumber] = roiGrading;
        }

        datasetGradings.structureSets[ssId] = structureSetGrading;
    }

    return datasetGradings;
}

// Checks if there are multiple ROIs in this scan with the same name that have the same grade (usually "1 - Good"). 
// Returns an array of error objects if that is the case, or null if it's ok to continue saving.
export function checkForMultipleSameGradeRois(
    currentStructureSet: StructureSet,
    currentGrading: StructureSetGrading,
    datasetGradings: DatasetGradings,
    scanStructureSets: StructureSet[],
    gradeValueToCheck: string,
): DuplicateRoiGradingError[] | null {

    const datasetStructureSetGradings = datasetGradings.structureSets;

    // STEP 1: collect all gradings from this same scan into one map

    // map: <structure set ID, grading>
    const gradingsInSameScan: Map<string, StructureSetGrading> = new Map();
    for (const [gradingStructureSetId, grading] of Object.entries(datasetStructureSetGradings)) {
        // ignore current structure set since that's the one were saving
        if (gradingStructureSetId !== currentStructureSet.structureSetId
            // but add in any gradings objects that match any of the structure sets in current scan
            && scanStructureSets.some(ss => ss.structureSetId === gradingStructureSetId)) {
            gradingsInSameScan.set(gradingStructureSetId, grading);
        }
    }

    // STEP 2: do an integrity check for the gradings in this same scan - there should only be one grade of the checked value
    // for each unique ROI name, including the new grading we're about to save.
    // this check could be done in the previous loop if required for performance, but it's in it's own loop now for clarity.

    // map: <roi name, structure set IDs the roi is in []>
    const roisMatchingCheckedGradeValue: Map<string, string[]> = new Map();

    // start by marking down any matching rois in current structure set
    for (const [, currentRoiGrading] of Object.entries(currentGrading.rois)) {
        if (currentRoiGrading.value === gradeValueToCheck) {
            roisMatchingCheckedGradeValue.set(currentRoiGrading.roiName, [currentStructureSet.structureSetId]);
        }
    }

    // perform the actual check -- note that the integrity check is performed for all structure sets in this scan,
    // comparing them against each other, not just comparing them to the current structure set. this way we can also
    // fix gradings for structure sets that were graded prior to this implementation
    for (const [gradingStructureSetId, grading] of gradingsInSameScan.entries()) {
        for (const [, roiGrading] of Object.entries(grading.rois)) {
            // keep track of the requested checked value roi gradings,
            // mark them down for future error messages if there's more than one
            if (roiGrading.value === gradeValueToCheck) {
                const roiName = roiGrading.roiName;

                // check if this roi has already been marked as duplicate
                // note that ROI name check is case insensitive
                const matchingRoiName = [...roisMatchingCheckedGradeValue.keys()].find(r => areStringsSameCi(r, roiName));
                if (matchingRoiName === undefined) {
                    roisMatchingCheckedGradeValue.set(roiName, [gradingStructureSetId]);
                } else {
                    roisMatchingCheckedGradeValue.set(matchingRoiName, roisMatchingCheckedGradeValue.get(matchingRoiName)!.concat(gradingStructureSetId));
                }
            }
        }
    }

    // STEP 3: collect duplicate errors
    const duplicateRoiGradingErrors: DuplicateRoiGradingError[] = [];
    for (const [roiName, structureSetIds] of roisMatchingCheckedGradeValue.entries()) {
        if (structureSetIds.length < 2) {
            // nothing to worry about if there's only one entry -- there's no duplicates
            continue;
        }

        // get duplicate roi structure sets' IDs & labels
        const duplicateStructureSets: { [structureSetId: string]: string } = {};
        roisMatchingCheckedGradeValue.get(roiName)!
            .forEach(structureSetId => duplicateStructureSets[structureSetId] = scanStructureSets.find(ss => ss.structureSetId === structureSetId)!.dataset.StructureSetLabel);
        duplicateRoiGradingErrors.push(new DuplicateRoiGradingError(roiName, duplicateStructureSets));
    }

    return duplicateRoiGradingErrors.length > 0 ? duplicateRoiGradingErrors : null;
}
