import { Vector2f } from '../../../../math/Vector2f';
import { Uniform } from './Uniform';

export class UniformVec2 extends Uniform {

    loadVec2(vector: Array<number> | {x: number, y: number} | Vector2f) {
        if(this.location){
            if(vector instanceof Array){
                this.gl.uniform2fv(this.location, vector);
            } else {
                this.gl.uniform2f(this.location, vector.x, vector.y);
            }
        }
    }
}