import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as sagas from '../../../store/sagas';

interface Props {
    show: boolean;
    handleClose: () => void;
    handleCalculateMetrics: () => void;
}


type AllProps = Props;

interface State {
    grade: string;
    comments: string;
    isTaskFinished: boolean;
}

class RunCalculationModal extends React.Component<AllProps, State> {

    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Do you want to calculate metrics?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="task-grade">
                            <Form.Label>Note that this operation may take several minutes.</Form.Label>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.props.handleCalculateMetrics}>
                        Calculate Metrics
                    </Button>
                    <Button variant="secondary" onClick={this.props.handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(RunCalculationModal);
