import React from 'react';
import { Alert } from 'react-bootstrap';

class AccessForbidden extends React.PureComponent {
    render() {
        return (
            <div className="container">
                <Alert variant="danger" style={{ marginTop: '10px' }}>
                    <div><b>Access Forbidden:</b> cannot access this page, redirecting now...</div>
                </Alert>
            </div>);
    }
}

export default AccessForbidden;
