import React from 'react';

import './BatchJobSelectionCounts.css';

export default class BatchJobSelectionCounts extends React.PureComponent<{
    selectedPatientsCount: number,
    selectedImagesCount: number,
    selectedStructureSetsCount: number,
    selectedAutoContourOperations?: number,
}> {
    render() {
        const {selectedPatientsCount,selectedImagesCount,selectedStructureSetsCount,selectedAutoContourOperations} = this.props;
        return (<div className="batch-job-selection-counts">
            <span>
                {`Selected items: ${selectedPatientsCount} patients, ${selectedImagesCount} images, ${selectedStructureSetsCount} structure sets`}
                {selectedAutoContourOperations !== undefined && selectedAutoContourOperations > 0 && `, ${selectedAutoContourOperations} auto-contouring operations`}
                </span>
        </div>);
    }

}