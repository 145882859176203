import { Sdf } from "../sdf";
import * as twgl from 'twgl.js';
import * as booleanShaders from './boolean-shaders';
import { createVertexBuffers } from '../../create-vertex-buffers';
import * as m4 from '../../../../math/m4';
import { BoundingBox } from "../../../../math/bounding-box";
import { ViewManager } from "../../../view-manager";
import { SdfSlice } from "../sdf-slice";

import { FrameBufferObject } from '../../objects/FrameBufferObject';
import { SliceToSdfRenderer } from '../../rendering/SliceToSdfRenderer';
import { Propagation } from "../propagation/sdf-propagation";
import { Roi } from "../../../../dicom/structure-set";
import { Vector3f } from "../../../../math/Vector3f";

export enum BooleanOperator {
    OR, AND, AND_NOT, XOR
}

export class SdfOperations {
    private viewManager: ViewManager;
    private booleanProgram: WebGLProgram;
    private booleanUniformLoc: any;

    constructor(viewManager: ViewManager) {
        this.viewManager = viewManager;
        let gl = viewManager.getWebGlContext();
        this.booleanProgram = twgl.createProgramInfo((gl as any), [booleanShaders.BOOLEAN_VS, booleanShaders.BOOLEAN_FS]).program;
        this.booleanUniformLoc = {
            textureMatrix: gl.getUniformLocation(this.booleanProgram, 'orientation'),
            textureData: gl.getUniformLocation(this.booleanProgram, 'textureData'),
            inverse: gl.getUniformLocation(this.booleanProgram, 'inverse'),
        }
    }

    public copy( sourceSdf: Sdf, bb: BoundingBox | null, isRGBA: boolean ): Sdf {
        let vm = this.viewManager;
        const destBB = bb ? bb.copy() : sourceSdf.boundingBox.copy();
        const destSdf = new Sdf(vm, sourceSdf.resolutionMm);
        destSdf.createTexture(destBB, false, isRGBA);
        this.applyBooleanInternal(BooleanOperator.OR, sourceSdf, destSdf);
        destSdf.propagationPending = sourceSdf.propagationPending;
        return destSdf;
    }


    public applyBoolean( sdf1: Sdf, sdf2: Sdf, operator: BooleanOperator, resultToSdf1IfFits: boolean) : Sdf {

        if(operator === BooleanOperator.XOR) {
            let xor1 = this.applyBoolean( sdf1, sdf2, BooleanOperator.AND_NOT, false);
            let xor2 = this.applyBoolean( sdf2, sdf1, BooleanOperator.AND_NOT, false);
            return this.applyBoolean( xor1, xor2, BooleanOperator.OR, false);
        }
        
        // 1. Create a new SDF
        let destSdf = sdf1;
        let destBB = sdf1.boundingBox.copy();
        if(operator === BooleanOperator.OR) {
            destBB = sdf1.boundingBox.union(sdf2.boundingBox);
        }
        else if(operator === BooleanOperator.AND) {
            destBB = sdf1.boundingBox.intersection(sdf2.boundingBox);
        }
        else if(operator === BooleanOperator.AND_NOT) {
            destBB = sdf1.boundingBox.copy();
        }

        destSdf = (resultToSdf1IfFits && sdf1.boundingBox.contains(destBB)) ? sdf1 : this.copy(sdf1, destBB, false);
        
        // 2. Apply the boolean operator
        this.applyBooleanInternal(operator, sdf2, destSdf);

        destSdf.propagationPending = true;
        return destSdf;
    }

    public getMeanRoi(firstRoi: Roi, secondRoi: Roi): Roi | null {
        if(firstRoi === null) {
            return secondRoi;
        }
        if(secondRoi === null) {
            return firstRoi;
        }
        const ss = firstRoi.structureSet;
        const roiNumber = 50;
        const name = `Mean ROI of ${firstRoi.name}`;
        // color is opposite of the first roi color
        const color = firstRoi.lineColor.x === 255 ? new Vector3f(0, 0, 0) : new Vector3f(255, 255, 255);
        return new Roi(ss, roiNumber, name, firstRoi.interpretedType, color);
    }


    public copySdfSlice( sourceSdf: Sdf, sliceIndex: number, resultSlice: SdfSlice) {
        const vm = this.viewManager;
        const gl = vm.getWebGlContext();
        gl.useProgram(this.booleanProgram);

        const fb = gl.createFramebuffer();
        gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, resultSlice.data, 0);
        
        gl.disable(gl.BLEND);
        gl.uniform1f(this.booleanUniformLoc.inverse, 0);


        gl.uniform1i(this.booleanUniformLoc.textureData, 0);
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_3D, sourceSdf.data);

        gl.viewport(0, 0, resultSlice.size[0], resultSlice.size[1]);
        
        let imageVertexPositions = [
            -1, -1,
            1, -1,
            1, 1,
            1, 1,
            -1, 1,
            -1, -1
        ];
        let imageTextureCoords = [
            0.0, 1.0,
            1.0, 1.0,
            1.0, 0.0,
            1.0, 0.0,
            0.0, 0.0,
            0.0, 1.0
        ];
        //TODO: Even these VertexBufferObjects are left alive!
        //These memoryleaks are out of control!
        gl.bindVertexArray(createVertexBuffers(gl, imageVertexPositions, imageTextureCoords));

        let step = 1 / (sourceSdf.size[2]);
        let scroll = (0.5 + sliceIndex) * step;
        let m = m4.translation(0, 1, scroll );
        m = m4.xRotate(m, Math.PI);
        gl.uniformMatrix4fv(this.booleanUniformLoc.textureMatrix, false, m);
        //Triangle_strip would be preferred. And texture coords could be generated by
        //the vertex shader
        gl.drawArrays(gl.TRIANGLES, 0, 6);
        /*let buffer = new Uint8Array(resultSlice.size[0] * resultSlice.size[1] * 4);
        gl.readPixels(0, 0, resultSlice.size[0], resultSlice.size[1], gl.RGBA, gl.UNSIGNED_BYTE, buffer);
        buffer.forEach(f => {
            console.log(f);
        });*/
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.deleteFramebuffer(fb);
    }

    /**
     * A method to add SdfSlice to Sdf at the specified Z-coordinates <br>
     * If slice is outside of SDF's boundingbox, SDF's texture is first expanded
     * and copied
     * @param slice SdfSlice representing the data that is being added
     * @param destSdf SDF where the slice is added
     * @param z Z-coordinate to add the slice to
     */
    public pasteSdfSlice(slice: SdfSlice, destSdf: Sdf, z: number, destRoi?: Roi) : Sdf {
        const vm = this.viewManager;
        const img = vm.image;
        const gl = this.viewManager.getWebGlContext();
        
        if(slice.boundingBox){
            const sliceBoundingBox = new BoundingBox();
            sliceBoundingBox.expandWithPoint(slice.boundingBox.minI, slice.boundingBox.minJ, z - img.kSpacing*1.5);
            sliceBoundingBox.expandWithPoint(slice.boundingBox.maxI, slice.boundingBox.maxJ, z + img.kSpacing*1.5);
            if(!destSdf.boundingBox.contains(sliceBoundingBox)){
                let boundingBox = destSdf.boundingBox.copy();
                boundingBox.expandWithPoint(sliceBoundingBox.minI, slice.boundingBox.minJ, z - img.kSpacing*1.5);
                boundingBox.expandWithPoint(sliceBoundingBox.maxI, slice.boundingBox.maxJ, z + img.kSpacing*1.5);
                destSdf = this.copy(destSdf, boundingBox, false);
            }
        }
        let sliceIndex = Math.floor((z - destSdf.boundingBox.minK) / img.kSpacing);
        
        //TODO: Important! SliceToSdfRenderer's texture coordinates along Y-axis were inverted!! (Cuz they should be)
        const renderer = new SliceToSdfRenderer(gl);
        let fbo = new FrameBufferObject(gl, destSdf.size[0], destSdf.size[1]);
        fbo.createTextureLayer(destSdf.data, sliceIndex);
        fbo.checkStatus();
        renderer.render(destSdf, slice);
        fbo.unbind();
        fbo.delete();
        renderer.delete();

        if(destRoi){
            new Propagation(vm).propagate(destRoi);
        } else {
            destSdf.propagationPending = true;
        }

        return destSdf;
    }


    private applyBooleanInternal(op: BooleanOperator, sourceSdf: Sdf, destSdf: Sdf) {

        const vm = this.viewManager;
        const img = sourceSdf.viewManager.image;
        const gl = vm.getWebGlContext();
        gl.useProgram(this.booleanProgram);
        for(let sourceZ = 0; sourceZ < sourceSdf.size[2]; ++sourceZ) {
            let destZ = sourceZ + Math.round( (sourceSdf.boundingBox.minK - destSdf.boundingBox.minK) / img.kSpacing );
            if(destZ < 0 || destZ >= destSdf.size[2]){
                continue;
            }
            const fb = gl.createFramebuffer();
            gl.bindFramebuffer(gl.FRAMEBUFFER, fb);
            gl.framebufferTextureLayer(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, destSdf.data, 0, destZ);
            
            if(op === BooleanOperator.OR) {
                gl.enable(gl.BLEND);
                gl.blendEquation(gl.MIN);
                gl.blendFunc(gl.ONE, gl.ONE);
                gl.uniform1f(this.booleanUniformLoc.inverse, 0);
            }
            else if(op === BooleanOperator.AND) {
                gl.enable(gl.BLEND);
                gl.blendEquation(gl.MAX);
                gl.blendFunc(gl.ONE, gl.ONE);
                gl.uniform1f(this.booleanUniformLoc.inverse, 0);
            }
            else if(op === BooleanOperator.AND_NOT) {
                gl.enable(gl.BLEND);
                gl.blendEquation(gl.MAX);
                gl.blendFunc(gl.ONE, gl.ONE);
                gl.uniform1f(this.booleanUniformLoc.inverse, 1);
            }
    
            gl.uniform1i(this.booleanUniformLoc.textureData, 0);
            gl.activeTexture(gl.TEXTURE0);
            gl.bindTexture(gl.TEXTURE_3D, sourceSdf.data);
    
            let left = Math.round( (sourceSdf.boundingBox.minI - destSdf.boundingBox.minI) / destSdf.resolutionMm  );
            let top = Math.round( (sourceSdf.boundingBox.minJ - destSdf.boundingBox.minJ) / destSdf.resolutionMm  );
            let width = Math.round(sourceSdf.size[0] * sourceSdf.resolutionMm / destSdf.resolutionMm);
            let height = Math.round(sourceSdf.size[1] * sourceSdf.resolutionMm / destSdf.resolutionMm);
            gl.viewport(left, top, width, height);
            
            let imageVertexPositions = [
                -1, -1,
                1, -1,
                1, 1,
                1, 1,
                -1, 1,
                -1, -1
            ];
            let imageTextureCoords = [
                0.0, 1.0,
                1.0, 1.0,
                1.0, 0.0,
                1.0, 0.0,
                0.0, 0.0,
                0.0, 1.0
            ];
            gl.bindVertexArray(createVertexBuffers(gl, imageVertexPositions, imageTextureCoords));
    
            let step = 1 / (sourceSdf.size[2]);
            let scroll = (0.5 + sourceZ) * step;
            let m = m4.translation(0, 1, scroll );
            m = m4.xRotate(m, Math.PI);
            gl.uniformMatrix4fv(this.booleanUniformLoc.textureMatrix, false, m);
            gl.drawArraysInstanced(gl.TRIANGLES, 0, 6, 1);
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            gl.deleteFramebuffer(fb);
            gl.disable(gl.BLEND);
        }
    }
}



