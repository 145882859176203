import { BoundingBox } from '../../../math/bounding-box';
import { Vector2f } from '../../../math/Vector2f';
import { Point, EdgeType } from './MarchingSquares';

class Element<Type> {

    protected value: Type;
    private child?: Element<Type>;
    private parent?: Element<Type>;

    constructor(value: Type) {
        this.value = value;
    }

    public setValue(value: Type): Element<Type>{
        this.value = value;
        return this;
    }

    public getValue(): Type {
        return this.value;
    }

    public setChild(child: Element<Type> | undefined, updateChild?: boolean): Element<Type> {
        if(child){
            if(updateChild){
                child.setParent(this, false);
            }
            return this.child = child;
        }
        else {
            this.child = undefined;
            return this;
        }
    }

    public getChild(): Element<Type> | undefined {
        return this.child;
    }

    public setParent(parent: Element<Type> | undefined, updateParent?: boolean): Element<Type> {
        if(parent){
            if(updateParent){
                parent.setChild(this, false);
            }
            return this.parent = parent;
        } else {
            this.parent = undefined;
            return this;
        }
    }

    public getParent(): Element<Type> | undefined {
        return this.parent;
    }
}

/**
 * PathIterator has to return Edge2's because we need the fillOnRightSide
 */
export class PathIterator implements Iterator<Vector2f> {
    
    private currentValue?: Element<Vector2f>;
    private reverse: boolean;

    constructor(firstElement: Element<Vector2f> | PathEnd, reverse?: boolean){
        if(firstElement instanceof Element){
            this.currentValue = firstElement;
            this.reverse = reverse || false;
        } else {
            this.currentValue = firstElement.vertex;
            this.reverse = firstElement.isBack;
        }
    }

    public next(): IteratorResult<Vector2f> {
        if(this.currentValue){
            const value = this.currentValue.getValue();
            this.currentValue = this.reverse ? this.currentValue.getParent() : this.currentValue.getChild();
            return {
                done: false,
                value: value
            };
        }
        return {
            done: true,
            value: null
        };
    }
}

/**
 * Edge path represents a path consisting of sequential points in 2D-space.
 * Each point is connected to previous one to form an edge.
 * It's constructed as a double linked list
 */
export class Path implements Iterable<Vector2f> {

    private static runningIndex = 0;
    public front: PathEnd;
    public back: PathEnd;
    public length: number;
    private closed: boolean;
    public index: number;
    public readonly fillOnRight: boolean;

    constructor(xIndex: number, yIndex: number, start: Vector2f, end: Vector2f, type: EdgeType, fillOnRight: boolean){
        this.index = ++Path.runningIndex;
        this.fillOnRight = fillOnRight;
        //Note: FirstElement is always top right.
        this.front = new PathEnd(this, xIndex, yIndex, new Element<Vector2f>(end), type, false);
        this.back = new PathEnd(this, xIndex, yIndex, new Element<Vector2f>(start), type, true);
        this.front.vertex.setChild(this.back.vertex, true);
        this.length = 2;
        this.closed = false;
    }

    /**
     * Method implemented from the Iterable<Vector2f>-interface.</br>
     * This returns an iterator that will iterate over the vertices either</br>
     * from front to back = counter clockwise direction, if fillOnRight is true</br>
     * from back to front = clockwise direction, if fillOnRight is false</br>
     * </br>
     * If fillOnRight is true, the path represents contour's outer boundaries. <br>
     * If fillOnRight is false, the contour represents boundaries of a hole within a contour. <br>
     * @returns An iterator over the vertices of this path
     */
    [Symbol.iterator](): Iterator<Vector2f> {
        return new PathIterator(this.fillOnRight ? this.front : this.back);
    }

    public incrementLength(amount?: number){
        this.length += amount || 1;
    }

    /**
     * 
     * @returns Path's front and back ends, at index 0 and 1 respectively
     */
    public getEnds(): PathEnd[] {
        return [this.front, this.back];
    }

    public close(){
        this.closed = true;
    }

    public isClosed(): boolean {
        return this.closed;
    }

    public updateEnds(constructor: PathConstructor){
        constructor.updateEnd(this.front);
        constructor.updateEnd(this.back);
    }

    public getCentroid(): Vector2f {
        let total = new Vector2f();
        let iterator = new PathIterator(this.front);
        //skip the first element as first and last are the same
        iterator.next();
        let value: IteratorResult<Vector2f>;
        while((value = iterator.next()) && !value.done){
            total.add(value.value);
        }
        return total.scale(1.0/(this.length-1));
    }

    public getBoundaries(): BoundingBox {
        let iterator = new PathIterator(this.front);
        let bb = new BoundingBox();
        let current = iterator.next();
        bb.minI = bb.maxI = current.value.x;
        bb.minJ = bb.maxJ = current.value.y;
        while(!(current = iterator.next()).done){
            if(current.value.x < bb.minI){
                bb.minI = current.value.x;
            } else if(current.value.x > bb.maxI){
                bb.maxI = current.value.x;
            }
            if(current.value.y < bb.minJ){
                bb.minJ = current.value.y;
            } else if(current.value.y > bb.maxJ){
                bb.maxJ = current.value.y;
            }
        }
        return bb;
    }
}

class Trail {

    public pathEnds: Map<Point, PathEnd>;

    constructor(pathEnds: PathEnd[]){
        this.pathEnds = new Map<Point, PathEnd>();
        for(let end of pathEnds){
            this.pathEnds.set(end.getConnectionPoint(), end);
        }
    }

    /**
     * Method to get PathEnd, the end point of which is at the given point.
     * The method automatically deletes the returned pathend from the trail's memory.
     * @param endPoint the point where path must end in order for new edge to be linked to it
     * @returns PathEnd the end of which is at the given point or null if no such endPoint exists
     */
    getPathEnd(endPoint: Point): PathEnd | undefined {
        const value = this.pathEnds.get(endPoint);
        if(value){
            this.pathEnds.delete(endPoint);
        }
        return value;
    }

    isEmpty(): boolean {
        return !this.pathEnds || this.pathEnds.size < 1;
    }

    public addEnds(ends: PathEnd[]){
        for(const end of ends){
            this.pathEnds.set(end.getConnectionPoint(), end);
        }
    }
}

export class PathConstructor {

    private edges: (Trail|undefined)[][] = [];
    private paths: Array<Path>;
    private readonly xScale: number;
    private readonly minX: number;
    private readonly yScale: number;
    private readonly minY: number;

    private openEnds: Map<Point, PathEnd>;
    private maxXIndex: number;
    private maxYIndex: number;
    
    constructor(minX: number, minY: number, xScale: number, yScale: number, maxXIndex: number, maxYIndex: number){
        this.minX = minX;
        this.minY = minY;
        this.xScale = xScale;
        this.yScale = yScale;
        this.maxXIndex = maxXIndex;
        this.maxYIndex = maxYIndex;
        this.edges = [];
        this.openEnds = new Map<Point, PathEnd>();
        this.paths = new Array<Path>();
    }

    public getPaths(debug: boolean = false): Array<Path> {
        if(debug){
            let top;
            if(top = this.openEnds.get(Point.TOP)){
                let right = this.openEnds.get(Point.RIGHT);
                if(right){
                    const path = top.mergePath(right, this);
                    if(path.isClosed()){
                        this.paths.push(path);
                    } else {
                        path.updateEnds(this);
                    }
                    this.openEnds.clear();
                }
            }
            let openPaths = 0;
            for(let row of this.edges){
                if(row){
                    for(let trail of row){
                        if(trail && !trail.isEmpty()){
                            openPaths += trail.pathEnds.size;
                        }
                    }
                }
            }
            if(openPaths > 0){
                console.log("Paths retrieved with " +openPaths +" open paths.");
            }
        }
        return this.paths;
    }

    protected getEnd(xIndex: number, yIndex: number, endPoint: Point): PathEnd | undefined {
        let trail: Trail | undefined;
        let row = this.edges[yIndex];
        if(row && (trail = row[xIndex])){
            let pathEnd = trail.getPathEnd(endPoint);
            if(pathEnd){
                if(trail.isEmpty()){
                    // delete to only set to undefined.
                    // splice would shift all the elements
                    delete this.edges[yIndex][xIndex];
                }
            }
            return pathEnd;
        }
        return undefined;
    }

    public setEnd(xIndex: number, yIndex: number, end: PathEnd){
        end.setIndex(xIndex, yIndex);
        let row = this.edges[yIndex] || (this.edges[yIndex] = []);
        let trail = row[xIndex];
        if(trail){
            trail.pathEnds.set(end.getConnectionPoint(), end);
        } else {
            row[xIndex] = new Trail([end]);
        }
    }

    public updateEnd(end: PathEnd){
        if(end.isOutbound()){
            this.openEnds.set(end.getConnectionPoint(), end);
        } else {
            let row = this.edges[end.getYIndex()];
            if(row){
                const trail = row[end.getXIndex()];
                if(trail){
                    trail.pathEnds.set(end.getConnectionPoint(), end);
                } else {
                    row[end.getXIndex()] = new Trail([end]);
                }
            } else {
                row = (this.edges[end.getYIndex()] = []);
                row[end.getXIndex()] = new Trail([end]);
            }
        }
    }

    private handleOpenEnd(end: PathEnd){
        let oldEnd = this.openEnds.get(end.getConnectionPoint());
        if(oldEnd){
            const path = oldEnd.mergePath(end, this);
            if(path.isClosed()){
                this.paths.push(path);
            } else {
                path.updateEnds(this);
            }
            this.openEnds.delete(end.getConnectionPoint());
        } else {
            switch(end.getConnectionPoint()){
                case Point.TOP:
                    let previous = this.openEnds.get(Point.LEFT);
                    if(previous){
                        const path = previous.mergePath(end, this);
                        if(path.isClosed()){
                            this.paths.push(path);
                        } else {
                            path.updateEnds(this);
                        }
                        this.openEnds.delete(Point.LEFT);
                        return;
                    }
                    break;
                case Point.BOTTOM:
                    if(end.isBack ? !end.path.fillOnRight : end.path.fillOnRight){
                        this.openEnds.set(Point.LEFT, end.setOutbound());
                        return;
                    }
                    break;
                case Point.RIGHT:
                    if(end.isBack ? !end.path.fillOnRight : end.path.fillOnRight){
                        oldEnd = this.openEnds.get(Point.BOTTOM);
                        if(oldEnd){
                            oldEnd.extend(new Vector2f(this.minX + (this.maxXIndex+1.5) * this.xScale, this.minY + 0.5 * this.yScale), EdgeType.VERTICAL, Point.RIGHT);
                            const path = oldEnd.mergePath(end, this);
                            if(path.isClosed()){
                                this.paths.push(path);
                            } else {
                                path.updateEnds(this);
                            }
                            this.openEnds.delete(Point.BOTTOM);
                            return;
                        }
                        oldEnd = this.openEnds.get(Point.LEFT);
                        if(oldEnd){
                            oldEnd.extend(new Vector2f(this.minX + 0.5 * this.xScale, this.minY + 0.5 * this.yScale), EdgeType.VERTICAL, Point.BOTTOM);
                            oldEnd.extend(new Vector2f(this.minX + (this.maxXIndex+1.5) * this.xScale, this.minY + 0.5 * this.yScale), EdgeType.HORIZONTAL, Point.RIGHT);
                            const path = oldEnd.mergePath(end, this);
                            if(path.isClosed()){
                                this.paths.push(path);
                            } else {
                                path.updateEnds(this);
                            }
                            this.openEnds.delete(Point.LEFT);
                            return;
                        }
                    }
                    break;
                case Point.LEFT:
                    if(end.isBack ? end.path.fillOnRight : !end.path.fillOnRight){
                        oldEnd = this.openEnds.get(Point.RIGHT);
                        if(oldEnd){
                            end.extend(new Vector2f(this.minX + 0.5 * this.xScale, this.minY + 0.5 * this.yScale), EdgeType.VERTICAL, Point.BOTTOM);
                            end.extend(new Vector2f(this.minX + (this.maxXIndex+1.5) * this.xScale, this.minY + 0.5 * this.yScale), EdgeType.HORIZONTAL, Point.RIGHT);
                            const path = oldEnd.mergePath(end, this);
                            if(path.isClosed()){
                                this.paths.push(path);
                            } else {
                                path.updateEnds(this);
                            }
                            this.openEnds.delete(Point.RIGHT);
                            return;
                        }
                    }
                    break;
            }
            this.openEnds.set(end.getConnectionPoint(), end.setOutbound());
        }
    }

    public add(xIndex: number, yIndex: number, type: EdgeType, fillOnRight: boolean): boolean {
        switch(type){
            case EdgeType.TOP_RIGHT:
                //start new path
                const start = this.getStartCoordinates(type, xIndex, yIndex);
                const end = this.getEndCoordinates(type, xIndex, yIndex);
                const path = new Path(xIndex, yIndex, start, end, type, fillOnRight);
                let unhandled: PathEnd[] = [];
                if(xIndex === this.maxXIndex){
                    this.handleOpenEnd(path.back);
                } else {
                    unhandled.push(path.back);
                }
                if(yIndex === this.maxYIndex){
                    this.handleOpenEnd(path.front);
                } else {
                    unhandled.push(path.front);
                }
                if(unhandled.length > 0){
                    const row = this.edges[yIndex] || (this.edges[yIndex] = []);
                    const trail = row[xIndex];
                    if(trail){
                        //edges are handled individually,
                        //so if pixel type is 6 trail might already exist even if edge type is TOP_RIGHT
                        trail.addEnds(unhandled);
                    } else {
                        row[xIndex] = new Trail(unhandled);
                    }
                }
                return true;
            case EdgeType.BOTTOM_LEFT:
                //combine edges
                if(xIndex === 0){
                    if(yIndex === 0){
                        const start = this.getStartCoordinates(type, xIndex, yIndex);
                        const end = this.getEndCoordinates(type, xIndex, yIndex);
                        const path = new Path(xIndex, yIndex, start, end, type, fillOnRight);
                        this.handleOpenEnd(path.front);
                        this.handleOpenEnd(path.back);
                        return true;
                    } else {
                        const below = this.getEnd(xIndex, yIndex-1, Point.TOP);
                        if(below){
                            let coordinates = this.getEndCoordinates(type, xIndex, yIndex);
                            below.extend(coordinates, type, Point.LEFT);
                            this.handleOpenEnd(below);
                            return true;
                        }
                    }
                } else if(yIndex === 0){
                    const left = this.getEnd(xIndex-1, yIndex, Point.RIGHT);
                    if(left){
                        let coordinates = this.getStartCoordinates(type, xIndex, yIndex);
                        left.extend(coordinates, type, Point.BOTTOM);
                        this.handleOpenEnd(left);
                        return true;
                    }
                } else {
                    const left = this.getEnd(xIndex-1, yIndex, Point.RIGHT);
                    if(left){
                        let coordinates = this.getStartCoordinates(type, xIndex, yIndex);
                        left.extend(coordinates, type, Point.BOTTOM);
                        const below = this.getEnd(xIndex, yIndex-1, Point.TOP);
                        if(below){
                            const path = left.mergePath(below, this);
                            if(path.isClosed()){
                                this.paths.push(path);
                            } else {
                                path.updateEnds(this);
                            }
                            return true;
                        }
                    }
                }
                console.error("Edge of type BOTTOM_LEFT that does not extend from left/below " +xIndex +", " +yIndex);
                return false;
            case EdgeType.BOTTOM_RIGHT:
                if(yIndex === 0){
                    const start = this.getStartCoordinates(type, xIndex, yIndex);
                    const end = this.getEndCoordinates(type, xIndex, yIndex);
                    const path = new Path(xIndex, yIndex, start, end, type, fillOnRight);
                    this.handleOpenEnd(path.back);
                    if(xIndex === this.maxXIndex){
                        this.handleOpenEnd(path.front);
                    } else {
                        this.setEnd(xIndex, yIndex, path.front);
                    }
                    return true;
                } else {
                    const below = this.getEnd(xIndex, yIndex-1, Point.TOP);
                    if(below){
                        below.extend(this.getEndCoordinates(type, xIndex, yIndex), type, getEndPoint(type));
                        if(xIndex === this.maxXIndex){
                            this.handleOpenEnd(below);
                        } else {
                            this.setEnd(xIndex, yIndex, below);
                        }
                        return true;
                    }
                }
                console.error("Edge of type BOTTOM_RIGHT that does not extend from below " +xIndex +", " +yIndex);
                return false;
            case EdgeType.VERTICAL:
                if(yIndex === 0){
                    const start = this.getStartCoordinates(type, xIndex, yIndex);
                    const end = this.getEndCoordinates(type, xIndex, yIndex);
                    const path = new Path(xIndex, yIndex, start, end, type, fillOnRight);
                    this.handleOpenEnd(path.back);
                    this.setEnd(xIndex, yIndex, path.front);
                    return true;
                } else {
                    const below = this.getEnd(xIndex, yIndex-1, Point.TOP);
                    if(below){
                        below.extend(this.getEndCoordinates(type, xIndex, yIndex), type, getEndPoint(type));
                        if(yIndex === this.maxYIndex){
                            this.handleOpenEnd(below);
                        } else {
                            this.setEnd(xIndex, yIndex, below);
                        }
                        return true;
                    }
                }
                console.error("Edge of type VERTICAL that does not extend from below " +xIndex +", " +yIndex);
                return false;
            case EdgeType.TOP_LEFT:
                if(xIndex === 0){
                    const start = this.getStartCoordinates(type, xIndex, yIndex);
                    const end = this.getEndCoordinates(type, xIndex, yIndex);
                    const path = new Path(xIndex, yIndex, start, end, type, fillOnRight);
                    this.handleOpenEnd(path.back);
                    if(yIndex === this.maxYIndex){
                        this.handleOpenEnd(path.front);
                    } else {
                        this.setEnd(xIndex, yIndex, path.front);
                    }
                    return true;
                } else {
                    const left = this.getEnd(xIndex-1, yIndex, Point.RIGHT);
                    if(left){
                        left.extend(this.getEndCoordinates(type, xIndex, yIndex), type, getEndPoint(type));
                        if(yIndex === this.maxYIndex){
                            this.handleOpenEnd(left);
                        } else {
                            this.setEnd(xIndex, yIndex, left);
                        }
                        return true;
                    }
                }
                console.error("Edge of type TOP_LEFT that does not extend from left " +xIndex +", " +yIndex);
                return false;
            case EdgeType.HORIZONTAL:
                if(xIndex === 0){
                    const start = this.getStartCoordinates(type, xIndex, yIndex);
                    const end = this.getEndCoordinates(type, xIndex, yIndex);
                    const path = new Path(xIndex, yIndex, start, end, type, fillOnRight);
                    this.handleOpenEnd(path.back);
                    this.setEnd(xIndex, yIndex, path.front);
                    return true;
                } else {
                    const left = this.getEnd(xIndex-1, yIndex, Point.RIGHT);
                    if(left){
                        left.extend(this.getEndCoordinates(type, xIndex, yIndex), type, getEndPoint(type));
                        if(xIndex === this.maxXIndex){
                            this.handleOpenEnd(left);
                        } else {
                            this.setEnd(xIndex, yIndex, left);
                        }
                        return true;
                    }
                }
                console.error("Edge of type HORIZONTAL that does not extend from left " +xIndex +", " +yIndex);
                return false;
        }
    }

    protected pointToCoordinates(point: Point, xIndex: number, yIndex: number){
        //TODO: Could try to find branchless approach here
        switch(point){
            case Point.TOP:
                return new Vector2f(this.minX + (xIndex+1.0) * this.xScale, this.minY + (yIndex+1.5) * this.yScale);
            case Point.RIGHT:
                return new Vector2f(this.minX + (xIndex+1.5) * this.xScale, this.minY + (yIndex+1.0) * this.yScale);
            case Point.BOTTOM:
                return new Vector2f(this.minX + (xIndex+1.0) * this.xScale, this.minY + (yIndex+0.5) * this.yScale);
            case Point.LEFT:
                return new Vector2f(this.minX + (xIndex+0.5) * this.xScale, this.minY + (yIndex+1.0) * this.yScale);
        }
    }

    public getStartCoordinates(type: EdgeType, xIndex: number, yIndex: number): Vector2f {
        return this.pointToCoordinates(getStartPoint(type), xIndex, yIndex);
    }

    public getEndCoordinates(type: EdgeType, xIndex: number, yIndex: number): Vector2f {
        return this.pointToCoordinates(getEndPoint(type), xIndex, yIndex);
    }
    
}

export class PathEnd {

    public path: Path;
    public vertex: Element<Vector2f>;
    public type: EdgeType;
    public isBack: boolean;
    private connectionPoint: Point;
    private xIndex: number;
    private yIndex: number;

    private outBound: boolean;

    constructor(path: Path, xIndex: number, yIndex: number, vertex: Element<Vector2f>, type: EdgeType, back: boolean){
        this.path = path;
        this.vertex = vertex;
        this.type = type;
        this.isBack = back;
        this.xIndex = xIndex;
        this.yIndex = yIndex;
        this.outBound = false;
        this.connectionPoint = back ? getStartPoint(type) : getEndPoint(type);
    }

    protected setEqualTo(end: PathEnd){
        this.vertex = end.vertex;
        this.type = end.type;
        this.xIndex = end.xIndex;
        this.yIndex = end.yIndex;
        this.outBound = end.outBound;
        this.connectionPoint = end.connectionPoint;
    }

    public setOutbound(): PathEnd {
        this.outBound = true;
        return this;
    }

    public isOutbound(): boolean {
        return this.outBound;
    }

    public getConnectionPoint(): Point {
        return this.connectionPoint;
    }

    public getXIndex(): number {
        return this.xIndex;
    }

    public getYIndex(): number {
        return this.yIndex;
    }

    public setIndex(xIndex: number, yIndex: number){
        this.xIndex = xIndex;
        this.yIndex = yIndex;
    }

    /**
     * A method to append path with vertex.
     * If edge is parallel to previous edge, previous edge is just stretched.
     * Otherwise new edge is created and appended.
     * @param edge 
     * @returns 
     */
    public extend(vertex: Vector2f, type: EdgeType, connectionPoint: Point): PathEnd {
        //perform stretching
        if(shouldStretch(this.type, type)){
            this.vertex.setValue(vertex);
        } else {
            this.path.incrementLength();
            if(this.isBack){
                this.vertex = this.vertex.setChild(new Element<Vector2f>(vertex), true);
            } else {
                this.vertex = this.vertex.setParent(new Element<Vector2f>(vertex), true);
            }
        }
        this.connectionPoint = connectionPoint;
        this.type = type;
        return this;
    }

    /**
     * this is left, end is below
     * @param end 
     */
    public mergePath(end: PathEnd, constructor: PathConstructor): Path{
        let currentTarget: PathEnd;
        let targetVertex: Element<Vector2f> | undefined;
        let currentSource: PathEnd;
        if(this.path.index < end.path.index){
            currentTarget = this;
            currentSource = end;
        } else if(this.path.index > end.path.index){
            currentTarget = end;
            currentSource = this;
        } else {
            if(!this.outBound){
                if(end.type === EdgeType.TOP_RIGHT){
                    const child = this.vertex.getChild();
                    if(child){
                        child.setParent(undefined);
                        end.vertex.setValue(child.getValue());
                        this.path.front.vertex = child;
                        //we don't need to update the rest of the front's parameters
                        //as front is no longer used for anything but looping
                        this.path.length--;
                    }
                }
            } else {
                this.path.incrementLength();
                if(this.isBack){
                    this.vertex = this.vertex.setChild(new Element<Vector2f>(end.vertex.getValue()), true);
                } else {
                    this.vertex = this.vertex.setParent(new Element<Vector2f>(end.vertex.getValue()), true);
                }
            }
            this.path.close();
            return this.path;
        }
        let lengthIncrease: number;
        let sourceVertex: Element<Vector2f> | undefined;
        if(this.outBound){
            targetVertex = currentTarget.vertex;
            sourceVertex = currentSource.vertex;
            lengthIncrease = currentSource.path.length;
        } else {
            if(end.type === EdgeType.TOP_RIGHT){
                //if we must stretch, target vertex is set to parent/child
                targetVertex = currentTarget.isBack ? currentTarget.vertex.getParent() : currentTarget.vertex.getChild();
                lengthIncrease = currentSource.path.length - 2;
            } else {
                targetVertex = currentTarget.vertex;
                lengthIncrease = currentSource.path.length - 1;
            }
            sourceVertex = currentSource.isBack ? currentSource.vertex.getParent() : currentSource.vertex.getChild();
        }

        if(currentSource.isBack){
            if(currentTarget.isBack){
                while(sourceVertex && targetVertex){
                    const nextElement = sourceVertex.getParent();
                    targetVertex = targetVertex.setChild(sourceVertex, true);
                    sourceVertex = nextElement;
                }
                if(targetVertex){
                    targetVertex.setChild(undefined);
                }
            } else if(currentSource.path.front.outBound){
                while(sourceVertex && targetVertex){
                    const nextElement = sourceVertex.getParent();
                    targetVertex = targetVertex.setParent(sourceVertex, true);
                    sourceVertex = nextElement;
                }
                if(targetVertex){
                    targetVertex = targetVertex.setParent(sourceVertex, true);
                }
            } else if(targetVertex){
                targetVertex = targetVertex.setParent(sourceVertex, true);
            }
        } else {
            if(!currentTarget.isBack){
                while(sourceVertex && targetVertex){
                    const nextElement = sourceVertex.getChild();
                    targetVertex = targetVertex.setParent(sourceVertex, true);
                    sourceVertex = nextElement;
                }
                if(targetVertex){
                    targetVertex.setParent(undefined);
                }
            } else if(currentSource.path.back.outBound){
                while(sourceVertex && targetVertex){
                    const nextElement = sourceVertex.getChild();
                    targetVertex = targetVertex.setChild(sourceVertex, true);
                    sourceVertex = nextElement;
                }
                if(targetVertex){
                    targetVertex.setChild(undefined);
                }
            } else if(targetVertex){
                targetVertex = targetVertex.setChild(sourceVertex, true);
            }
        }
        currentTarget.setEqualTo(currentSource.isBack ? currentSource.path.front : currentSource.path.back);
        if(lengthIncrease > 0){
            currentTarget.path.incrementLength(lengthIncrease);
        }

        return currentTarget.path;
    }

    public hasEndPoint(endPoint: Point): boolean {
        switch(this.type){
            case EdgeType.TOP_RIGHT:
                return (endPoint === Point.RIGHT && this.isBack) || (endPoint === Point.TOP && !this.isBack);
            case EdgeType.TOP_LEFT:
                return endPoint === Point.TOP || endPoint === Point.LEFT;
            case EdgeType.BOTTOM_LEFT:
                return endPoint === Point.BOTTOM || endPoint === Point.LEFT;
            case EdgeType.BOTTOM_RIGHT:
                return endPoint === Point.BOTTOM || endPoint === Point.RIGHT;
            case EdgeType.HORIZONTAL:
                return endPoint === Point.LEFT || endPoint === Point.RIGHT;
            case EdgeType.VERTICAL:
                return endPoint === Point.TOP || endPoint === Point.BOTTOM;
        }
    }
}

function shouldStretch(src: EdgeType, dest: EdgeType): boolean {
    if(src < 4 && dest < 4){
        if(Math.abs(src-dest) == 2){
            return true;
        }
    } else {
        if(src === dest){
            return true;
        }
    }
    return false;
}

function getStartPoint(type: EdgeType): Point{
    switch(type){
        case EdgeType.BOTTOM_LEFT:
        case EdgeType.VERTICAL:
        case EdgeType.BOTTOM_RIGHT:
            return Point.BOTTOM;
        case EdgeType.HORIZONTAL:
        case EdgeType.TOP_LEFT:
            return Point.LEFT;
        case EdgeType.TOP_RIGHT:
            return Point.RIGHT;
    }
}

function getEndPoint(type: EdgeType): Point{
    switch(type){
        case EdgeType.TOP_LEFT:
        case EdgeType.VERTICAL:
        case EdgeType.TOP_RIGHT:
            return Point.TOP;
        case EdgeType.HORIZONTAL:
        case EdgeType.BOTTOM_RIGHT:
            return Point.RIGHT;
        case EdgeType.BOTTOM_LEFT:
            return Point.LEFT;
    }
}