import { Sdf } from '../sdf/sdf';
import { FrameBufferObject, TextureRenderTarget } from '../objects/FrameBufferObject';
import { ShaderProgram } from '../rendering/ShaderProgram';
import { UniformVec2 } from '../rendering/uniforms/UniformVec2';
import { VAO } from '../objects/VertexArrayObject';
import { UniformFloat } from '../rendering/uniforms/UniformFloat';
import { Path, PathConstructor } from './Path';
import { Plane } from '../../view';
import { UniformInt } from '../rendering/uniforms/UniformInt';

/**
 * Edge type represents the direction of the edge in a 2x2 grid
 * @example TOP_RIGHT //represents an edge that goes from the top edge to the right edge
 */
export enum EdgeType {
    TOP_RIGHT = 0,
    TOP_LEFT = 1,
    BOTTOM_LEFT = 2,
    BOTTOM_RIGHT = 3,
    HORIZONTAL = 4,
    VERTICAL = 5
};

/**
 * Point represents the ending-/starting point in a single 2x2 grid.
 */
export enum Point {
    TOP = 0,
    RIGHT = 1,
    BOTTOM = 2,
    LEFT = 3
};

/**
 * Class used to perform the rendering tasks of the MarchingSquares -operation.
 */
export class MarchingSquaresRenderer {

    private gl: WebGL2RenderingContext;

    private roundingShader2D: ShaderProgram;
    private roundingShader3D: ShaderProgram;
    private textureSize2D: UniformVec2;
    private textureSize3D: UniformVec2;

    private static ROUNDING2D_VERTEX_CONTENT = `#version 300 es
    
    precision highp float;

    in vec2 position;

    out vec2 pass_textureCoordinates;
    
    uniform vec2 textureSize;

    void main() {
        vec2 halfTexSize = textureSize * 0.5;
        gl_Position = vec4(position * halfTexSize / (halfTexSize + vec2(1.0, 1.0)), 0.0, 1.0);
        pass_textureCoordinates = vec2((position.x+1.0)/2.0, (1.0-position.y)/2.0);
    }
    `;

    private static ROUNDING3D_VERTEX_CONTENT = `#version 300 es
    #define transversal 0
    #define coronal 1
    #define sagittal 2

    precision highp float;

    in vec2 position;

    out vec3 pass_textureCoordinates;
    
    uniform int plane;
    uniform float sliceCoordinate;
    uniform vec2 textureSize;

    vec3 getCoordinate(vec2 coord){
        switch(plane){
            case transversal:
                return vec3(coord, sliceCoordinate);
            case coronal:
                return vec3(coord.x, sliceCoordinate, coord.y);
            case sagittal:
                return vec3(sliceCoordinate, coord.x, coord.y);
        }
    }

    void main() {
        vec2 halfTexSize = textureSize * 0.5;
        gl_Position = vec4(position * halfTexSize / (halfTexSize + vec2(1.0, 1.0)), 0.0, 1.0);
        pass_textureCoordinates = getCoordinate(vec2((position.x+1.0)/2.0, (1.0-position.y)/2.0));
    }
    `

    private static ROUNDING3D_FRAGMENT_CONTENT = `#version 300 es

    precision highp float;
    precision lowp sampler3D;

    in vec3 pass_textureCoordinates;

    out vec4 color;

    uniform sampler3D sampler;

    void main() {
        color = vec4(vec3(1.0, 1.0, 1.0)-round(texture(sampler, pass_textureCoordinates).rgb), 1.0);
    }
    `;

    private static ROUNDING2D_FRAGMENT_CONTENT = `#version 300 es
    
    precision highp float;
    precision lowp sampler2D;
    
    in vec2 pass_textureCoordinates;
    
    out vec4 color;
    
    uniform sampler2D sampler;

    void main() {
        color = vec4(round(texture(sampler, pass_textureCoordinates).rgb), 1.0);
    }
    `;

    /**
     * marchingSquareShader renders values in 2x2 area so that each color
     * is retrieved as a weighted sum between the 4 pixels
     * The resulting pixel color is between 0 and 1.
     */
    private marchingSquareShader: ShaderProgram;

    private static MARCHING_VERTEX_CONTENT = `#version 300 es
    
    precision highp float;

    in vec2 position;

    void main() {
        gl_Position = vec4(position, 0.0, 1.0);
    }
    `;

    private static MARCHING_FRAGMENT_CONTENT = `#version 300 es

    precision highp float;

    out vec4 color;

    uniform sampler2D rounded;
    uniform highp vec2 textureSize;

    //const float majorX = 2.0/3.0;
    //const float minorX = 1.0-majorX; // 5/15
    //const float majorY = 0.8;
    //const float minorY = 0.2; //3/15

    void main() {
        vec2 weightedCoordinates = vec2(gl_FragCoord.x + 1.0/3.0, gl_FragCoord.y + 0.2) / textureSize;
        color = texture(rounded, weightedCoordinates);
    }
    `;

    private quadVAO: VAO;
    private textureSize: UniformVec2;

    private roundingFBO?: FrameBufferObject;
    private marchingFBO?: FrameBufferObject;

    private zCoordinateUniform: UniformFloat;
    private planeUniform: UniformInt;

    constructor(gl: WebGL2RenderingContext){
        this.gl = gl;

        this.roundingShader3D = new ShaderProgram(this.gl, MarchingSquaresRenderer.ROUNDING3D_VERTEX_CONTENT, MarchingSquaresRenderer.ROUNDING3D_FRAGMENT_CONTENT, "position");
        this.zCoordinateUniform = new UniformFloat(this.gl, "sliceCoordinate");
        this.planeUniform = new UniformInt(this.gl, "plane");
        this.textureSize3D = new UniformVec2(this.gl, "textureSize");
        this.roundingShader3D.storeUniformLocations(this.textureSize3D, this.zCoordinateUniform, this.planeUniform);

        this.roundingShader2D = new ShaderProgram(this.gl, MarchingSquaresRenderer.ROUNDING2D_VERTEX_CONTENT, MarchingSquaresRenderer.ROUNDING2D_FRAGMENT_CONTENT, "position");
        this.textureSize2D = new UniformVec2(this.gl, "textureSize");
        this.roundingShader2D.storeUniformLocations(this.textureSize2D);

        this.marchingSquareShader = new ShaderProgram(gl, MarchingSquaresRenderer.MARCHING_VERTEX_CONTENT, MarchingSquaresRenderer.MARCHING_FRAGMENT_CONTENT, "position");
        this.textureSize = new UniformVec2(gl, "textureSize");
        this.marchingSquareShader.storeUniformLocations(this.textureSize);

        this.quadVAO = new VAO(gl);
        this.quadVAO.bind();
        this.quadVAO.createAttribute(0, this.gl.FLOAT, [-1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0, -1.0], 2);
        this.quadVAO.unbind();
    }

    /**
     * Sets up roundingFBO and marchingFBO ready for use.
     * If FrameBuffers exist and have the required dimensions, the data contained within is just erased.
     * Otherwise old FrameBuffers are completely deleted and new FBOs created for upcoming operations.
     * @param width FrameBuffers' width
     * @param height FrameBuffers' height
     */
    private prepare(width: number, height: number){
        this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
        if(this.roundingFBO && this.roundingFBO.getWidth() === width && this.roundingFBO.getHeight() === height && this.marchingFBO){
            this.roundingFBO.bind();
            this.gl.clear(this.gl.COLOR_BUFFER_BIT);
            this.marchingFBO.bind();
            this.gl.clear(this.gl.COLOR_BUFFER_BIT);
            this.marchingFBO.unbind();
        } else {
            this.deleteFrameBuffers();

            this.roundingFBO = new FrameBufferObject(this.gl, width, height);
            this.roundingFBO.createTextureAttachment(this.gl.COLOR_ATTACHMENT0, this.gl.R8, this.gl.RED, this.gl.UNSIGNED_BYTE);
            this.roundingFBO.checkStatus();

            this.marchingFBO = new FrameBufferObject(this.gl, width, height);
            this.marchingFBO.createTextureAttachment(this.gl.COLOR_ATTACHMENT0, this.gl.R8, this.gl.RED, this.gl.UNSIGNED_BYTE);
            this.marchingFBO.checkStatus();
            this.marchingFBO.unbind();
        }
    }

    /**
     * A separate method to free the memory allocated by FrameBufferObjects.<br>
     * This functionality is required in a couple of places within the class.<br>
     */
    private deleteFrameBuffers(){
        if(this.roundingFBO){
            this.roundingFBO.delete();
        }
        if(this.marchingFBO){
            this.marchingFBO.delete();
        }
    }

    /**
     * A method to generate MarchingSquares path data from an SDF.<br><br>
     * Returns an Uint8Array, where each value represents the edge type
     * at specified 2x2 pixel. Values are multiples of 17.
     * @param sdf SDF the data is generated for.
     * @param plane Plane which the operation is performed on. Transversal/Coronal/Sagittal
     * @param coordinate Coordinate of the slice at which the operation will be performed. Value between 0-1.
     * @param destWidth Width of the destination texture in pixels.
     * @param destHeight Height of the destination texture in pixels.
     * @returns Uint8Array where each value represents the edge type of each 2x2 pixel combination.
     */
    public renderSDF(sdf: Sdf, plane: Plane, coordinate: number, destWidth: number, destHeight: number): Uint8Array | null {
        let array: Uint8Array;
        /**
         * roundingShader renders fragments so that the value is rounded
         * to nearest whole number, 0 if value < 0.5 and 1 if value >= 0.5
         */
        if(coordinate < 0.0 || coordinate > 1.0){
            return null;
        }
        this.prepare(destWidth, destHeight);
        if(!this.roundingFBO || !this.marchingFBO){
            return null;
        }
        this.roundingShader3D.start();
        this.textureSize3D.loadVec2([destWidth - 2.0, destHeight - 2.0]);
        this.zCoordinateUniform.loadFloat(coordinate);
        this.planeUniform.loadInt(plane);
        this.gl.bindTexture(this.gl.TEXTURE_3D, sdf.data);
        this.quadVAO.bind(0);
        this.prepare(destWidth, destHeight);
        this.roundingFBO.bind();
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
        this.roundingShader3D.stop();
        this.roundingFBO.unbind();
        this.marchingFBO.bind();
        this.marchingSquareShader.start();
        this.roundingFBO.bindRenderTarget(this.gl.COLOR_ATTACHMENT0);
        this.textureSize.loadVec2({x: destWidth, y: destHeight});
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
        this.marchingSquareShader.stop();
        array = new Uint8Array(destWidth * destHeight);
        this.gl.pixelStorei(this.gl.PACK_ALIGNMENT, 1);
        this.gl.readPixels(0, 0, destWidth, destHeight, this.gl.RED, this.gl.UNSIGNED_BYTE, array, 0);
        this.marchingFBO.unbind();

        this.quadVAO.unbind(0);
        return array;
    }

    /**
     * A method to generate MarchingSquares path data from a FBO.<br><br>
     * Returns an Uint8Array, where each value represents the edge type
     * at specified 2x2 pixel. Values are multiples of 17.
     * @param fbo FrameBufferObject whose 0th color attachment the Array is generated based on.
     * @param destWidth Width of the destination texture in pixels.
     * @param destHeight Height of the destination texture in pixels.
     * @returns Uint8Array where each value represents the edge type of each 2x2 pixel combination.
     */
    public renderFBO(fbo: FrameBufferObject, destWidth: number, destHeight: number): Uint8Array | null {
        let array: Uint8Array;
        /**
         * roundingShader renders fragments so that the value is rounded
         * to nearest whole number, 0 if value < 0.5 and 1 if value >= 0.5
         */
        this.prepare(destWidth, destHeight);
        if(!this.roundingFBO || !this.marchingFBO){
            return null;
        }
        this.roundingShader2D.start();
        this.textureSize2D.loadVec2([destWidth - 2.0, destHeight - 2.0]);
        this.quadVAO.bind(0);

        this.roundingFBO.bind();
        fbo.bindRenderTarget(this.gl.COLOR_ATTACHMENT0);
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);

        this.roundingShader2D.stop();
        this.roundingFBO.unbind();
        this.marchingSquareShader.start();
        this.roundingFBO.bindRenderTarget(this.gl.COLOR_ATTACHMENT0);
        this.textureSize.loadVec2({x: destWidth, y: destHeight});
        this.marchingFBO.bind();
        this.gl.drawArrays(this.gl.TRIANGLE_STRIP, 0, 4);
        this.marchingSquareShader.stop();
        array = new Uint8Array(destWidth * destHeight);
        this.gl.pixelStorei(this.gl.PACK_ALIGNMENT, 1);
        this.gl.readPixels(0, 0, destWidth, destHeight, this.gl.RED, this.gl.UNSIGNED_BYTE, array, 0);
        this.marchingFBO.unbind();

        this.quadVAO.unbind(0);
        return array;
    }

    /**
     * A method to free memory allocated by the MarchingSquaresRenderer.<br>
     * One should always remember to call this after MarchingSquaresRenderer is no longer needed.<br>
     */
    public delete(): void {
        this.marchingSquareShader.delete();
        this.roundingShader2D.delete();
        this.roundingShader3D.delete();
        this.quadVAO.delete();
        this.deleteFrameBuffers();
    }
}

/**
 * Class that is used to generate Path data from texture.
 */
export class MarchingSquares {

    /**
     * Static readonly variable that defines the accuracy of the path generation.
     */
    static readonly RESOLUTION = 4.0;

    /**
     * Reference to WebGL2RenderingContext
     */
    readonly gl: WebGL2RenderingContext;
    /**
     * Renderers used by this MarchingSquares object to generate path data from textures.
     */
    readonly renderer: MarchingSquaresRenderer;

    constructor(gl: WebGL2RenderingContext){
        this.gl = gl;
        this.renderer = new MarchingSquaresRenderer(gl);
    }

    /**
     * One should always remember to delete MarchingSquares once it is no longer needed,<br>
     * as it's renderer allocates quite some memory that is not automatically cleared.
     */
    public delete(): void {
        this.renderer.delete();
    }

    /**
     * Method signature with FrameBufferObject as the texture source. <br>
     * Outputs path representing the contour boundaries.
     * @param texture FrameBufferObject that contains the texture data
     * @param minX Minimum horizontal value
     * @param minY Minimum vertical value
     * @param resolutionMm The resolution of each pixel in mm. (Value stored in SDF)
     */
    //TODO: Might need separate scale for horizontal and vertical
    public isoLines(texture: FrameBufferObject, minX: number, minY: number, resolutionMm: number): Array<Path> | null;

    /**
     * Method signature where SDF contains the texture data and coordinate space information. <br>
     * Boundary is number representing Z-coordinate of the slice that is being processed. Value between 0 and 1.<br>
     * Outputs path reprenseting the contour boundaries.
     * @param texture SDF for which the path is generated
     * @param boundary Z-coordinate of the slice on which the operation is performed
     */
    public isoLines(texture: Sdf, plane: Plane, coordinate: number): Array<Path> | null;

    /**
     * Method that will perform marching squares algorithm on SDF/FrameBufferObject to convert
     * from SDF texture form into a vertex path
     * @param texture The SDF/FBO the operation should be performed on
     * @param param1 Plane in the case of SDF or minX in the case of FBO
     * @param param2 Depth coordinate of the slice the operation should be performed on. Value between 0 and 1. or minY in the case of FBO
     * @param resolutionMm The resolution of each pixel in mm. Only needed in the case of FrameBufferObject.
     * @returns Array of path-objects, each path representing edges of a single contour object.
     */
    public isoLines(texture: Sdf | FrameBufferObject, param1: Plane | number, param2: number, resolutionMm?: number): Array<Path> | null{
        /**
         * An array with values between 0 and 255 with values being multiplications of 17.
         **/
        let array: Uint8Array | null;
        let constructor: PathConstructor;
        let xIndex = 0;
        let yIndex = 0;
        let destWidth: number;
        let destHeight: number;
        let horizontalMin: number;
        let verticalMin: number;
        let horizontalScale: number;
        let verticalScale: number;
        if(texture instanceof Sdf){
            switch(param1){
                default:
                case Plane.Transversal:
                    destWidth = texture.size[0] * MarchingSquares.RESOLUTION + 2;
                    destHeight = texture.size[1] * MarchingSquares.RESOLUTION + 2;
                    horizontalMin = texture.boundingBox.minI;
                    verticalMin = texture.boundingBox.minJ;
                    horizontalScale = verticalScale = texture.resolutionMm/MarchingSquares.RESOLUTION;
                    break;
                case Plane.Coronal:
                    destWidth = texture.size[0] * MarchingSquares.RESOLUTION + 2;
                    destHeight = texture.size[2] * MarchingSquares.RESOLUTION + 2;
                    horizontalMin = texture.boundingBox.minI;
                    verticalMin = texture.boundingBox.minK;
                    horizontalScale = texture.resolutionMm/MarchingSquares.RESOLUTION;
                    verticalScale = texture.viewManager.image.kSpacing/MarchingSquares.RESOLUTION;
                    break;
                case Plane.Sagittal:
                    destWidth = texture.size[1] * MarchingSquares.RESOLUTION + 2;
                    destHeight = texture.size[2] * MarchingSquares.RESOLUTION + 2;
                    horizontalMin = texture.boundingBox.minJ;
                    verticalMin = texture.boundingBox.minK;
                    horizontalScale = texture.resolutionMm/MarchingSquares.RESOLUTION;
                    verticalScale = texture.viewManager.image.kSpacing/MarchingSquares.RESOLUTION;
                    break;
            }
            array = this.renderer.renderSDF(texture, param1, param2, destWidth, destHeight);
            if(!array){
                console.log("IsoLines SDF array is null");
                return null;
            }

            constructor = new PathConstructor(horizontalMin, verticalMin, horizontalScale, verticalScale, destWidth-1, destHeight-1);
        } else if(texture instanceof FrameBufferObject && resolutionMm != undefined){
            destWidth = texture.getWidth() * MarchingSquares.RESOLUTION + 2;
            destHeight = texture.getHeight() * MarchingSquares.RESOLUTION + 2;
            array = this.renderer.renderFBO(texture, destWidth, destHeight);
            if(!array){
                console.log("IsoLines FBO array is null");
                return null;
            }

            constructor = new PathConstructor(param1, param2, resolutionMm as number/MarchingSquares.RESOLUTION, resolutionMm as number/MarchingSquares.RESOLUTION, destWidth-1, destHeight-1);
        } else {
            throw new Error("Attempting to perform MarchingSquares isoLines with incorrect parameters");
        }
        for(let number of array){
            number /= 17; //divide by 17 to get values between 0-15
            const types = MarchingSquares.getEdgeTypes(number);
            if(types.length > 0){ //skip grids with no edges
                for(const type of types){
                    // fillOnRight is only relevant when starting a new path
                    // TOP_RIGHT is the only type capable of starting a new path
                    // and out of TOP_RIGHT numbers, 1, 6 and 14, only 1 has fill on right.
                    constructor.add(xIndex, yIndex, type, MarchingSquares.fillOnRightSide(number, type));
                }
            }
            if(++xIndex >= destWidth){
                xIndex = 0;
                ++yIndex;
            }
        }
        return constructor.getPaths();
    }

    private static isFillOnRightSide(number: number, type: EdgeType): boolean {
        switch(number){
            case 1: return true;
            case 2: return false;
            case 3: return false;
            case 4: return true;
            case 5: return true;
            case 6: return type == EdgeType.TOP_RIGHT ? false : true;
            case 7: return true;
            case 8: return false;
            case 9: return type == EdgeType.TOP_LEFT ? true : false;
            case 10: return false;
            case 11: return false;
            case 12: return true;
            case 13: return true;
            case 14: return false;
            default:
                throw new Error("MarchingSquares: Checking fill with edge number " +number +", which is out of the boundaries [1 - 14]!");
        }
    }

    /**
     * Method to convert the color number (0-15) to the type of edge(s) it represents.
     * @note It would have been possible to come up with some mathematical function to generate these edge types
     * but I guess the performance of this kind of switch-statement should be almost identical.
     * @param color The color number of the 2x2 grid. Number 0 - 15.
     * @returns The types of edges that should be generated for the given color.
     */
    public static getEdgeTypes(color: number): EdgeType[]{
        switch(color){
            case 1:
            case 14:
                return [EdgeType.TOP_RIGHT];
            case 2:
            case 13:
                return [EdgeType.TOP_LEFT];
            case 3:
            case 12:
                return [EdgeType.HORIZONTAL];
            case 4:
            case 11:
                return [EdgeType.BOTTOM_RIGHT];
            case 5:
            case 10:
                return [EdgeType.VERTICAL];
            case 6:
                //order is important here, first check left so previous is still correct
                return [EdgeType.BOTTOM_LEFT, EdgeType.TOP_RIGHT];
            case 7:
            case 8:
                return [EdgeType.BOTTOM_LEFT];
            case 9:
                //order is important here, first check left so previous is still correct
                return [EdgeType.TOP_LEFT, EdgeType.BOTTOM_RIGHT];
            default:
                //0 and 15 don't have any edges as those are completely filled or completely empty.
                return [];
        }
    }

    public static fillOnRightSide(color: number, type: EdgeType): boolean {
        switch(color){
            case 1:
            case 4:
            case 5:
            case 7:
            case 12:
            case 13:
                return true;
            case 6:
            case 9:
                return type === EdgeType.BOTTOM_RIGHT || type === EdgeType.TOP_RIGHT;
        }
        return false;
    }
}