export function range(start: number, stop: number, step: number): number[] {
    let arr = []
    const n = Math.abs(stop - start) / Math.abs(step)
    for (let i=0; i<n; i++) {
        arr.push(start + i * step)
    }
    return arr
}

export function range2(a: number, b: number, n: number): number[] {
    // generate "n" equally spaced points in range [a, b]
    const step = (b - a) / n
    let pts
    if (step === 0) {
        pts = Array(n).fill(a)
    } else {
        pts = range(a, b, step)  // same as d3.range
    }
    return pts
}

export function getDistance(a: number[], b: number[]): number {
    return  Math.sqrt((a[0] - b[0]) ** 2 + (a[1] - b[1]) ** 2 + (a[2] - b[2]) ** 2)
}
