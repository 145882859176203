import { getUnixTime } from 'date-fns';
import React from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { isDemo } from '../../environments';
import { SERVICE_AGREEMENT_FILE_URI } from '../../files';
import { getHasAgreedToServiceTerms, setHasAgreedToServiceTerms } from '../../local-storage';
import ModalDialog from './ModalDialog';

import './ServiceAgreement.css';

type OwnState = {
    userHasAgreedCheckboxChecked: boolean,
    hasAgreedToServiceTermsStateFromLocalStorage: boolean,

}

/*
    Demo users must accept the service agreement terms before they can proceed.

    By completely blocking them from using the app we can be sure that they must have agreed to the terms
    if they are using the demo successfully (as observed by us from the backend).

    The 'agreement' to the service agreement is stored in user's web client local storage as a date of when
    they agreed to it.
*/


/**
 * The date on which the service agreement was last updated.
 * Change this value to force users who have already agreed to the service to read and agree
 * to an updated version of the service agreement.
 * 
 * Note: this is a unix timestamp -- without milliseconds.
 */
export const SERVICE_DEMO_AGREEMENT_UPDATED = getUnixTime(new Date(2021, 4, 23));


export default class ServiceAgreementModal extends React.Component<{}, OwnState> {

    constructor(props: any) {
        super(props);

        this.state = { userHasAgreedCheckboxChecked: false, hasAgreedToServiceTermsStateFromLocalStorage: getHasAgreedToServiceTerms() };
    }

    handleAgreementCheckboxChecked = () => {
        this.setState({ userHasAgreedCheckboxChecked: !this.state.userHasAgreedCheckboxChecked });
    }

    handleProceedClick = () => {
        setHasAgreedToServiceTerms(this.state.userHasAgreedCheckboxChecked);
        this.setState({ hasAgreedToServiceTermsStateFromLocalStorage: getHasAgreedToServiceTerms() });
    }

    renderLicenseAgreement = () => {
        return (
            <>
                <h1>MVision Web Demo Service Agreement</h1>

                <h2>Data processing, personal data collected, de-identification and anonymization</h2>

                <p>
                    MVision provides the User a web demo service (&quot;RTViewer&quot;). This service is a secure cloud access
                    to radiotherapy CT and MRI image auto-segmentation tools for testing and demoing purposes.
                    User loads image data to the web demo service and receives auto-segmented structure sets.
                </p>

                <p>
                    Collected personal data are Computed Tomography and Magnetic Resonance images of cancer
                    sites and, if applicable, registrations and delineations referencing the images. They are DICOM
                    datasets of which all metadata attributes containing personal data are destroyed. Table E.1-1 of
                    the DICOM standard PS3.15 specifies the list of attributes considered to contain personal data in
                    this context. MVision has a right to anonymize personal data collected through the service.
                </p>

                <p>
                    RTViewer uploads CT scans where DICOM image metadata (DICOM tags) are pseudonymized
                    and retained according to Table 1.
                </p>

                <div className="caption">Table 1. Processing of metadata (CT image DICOM tags)</div>
                <table className="metadata-table">
                    <tbody>
                        <tr><td>PatientID</td><td>Pseudonymized</td></tr>
                        <tr><td>SOPClassUID</td><td>Retained</td></tr>
                        <tr><td>SOPInstanceUID</td><td>Pseudonymized</td></tr>
                        <tr><td>SpecificCharacterSet</td><td>Retained</td></tr>
                        <tr><td>ScanningSequence</td><td>Retained</td></tr>
                        <tr><td>SequenceVariant</td><td>Retained</td></tr>
                        <tr><td>ImageType</td><td>Retained</td></tr>
                        <tr><td>SamplesPerPixel</td><td>Retained</td></tr>
                        <tr><td>PhotometricInterpretation</td><td>Retained</td></tr>
                        <tr><td>BitsAllocated</td><td>Retained</td></tr>
                        <tr><td>BitsStored</td><td>Retained</td></tr>
                        <tr><td>HighBit</td><td>Retained</td></tr>
                        <tr><td>RescaleIntercept</td><td>Retained</td></tr>
                        <tr><td>RescaleSlope</td><td>Retained</td></tr>
                        <tr><td>RescaleType</td><td>Retained</td></tr>
                        <tr><td>KVP</td><td>Retained</td></tr>
                        <tr><td>Rows</td><td>Retained</td></tr>
                        <tr><td>Columns</td><td>Retained</td></tr>
                        <tr><td>PixelRepresentation</td><td>Retained</td></tr>
                        <tr><td>PixelData</td><td>Retained</td></tr>
                        <tr><td>PixelPaddingRangeLimit</td><td>Retained</td></tr>
                        <tr><td>PixelSpacing</td><td>Retained</td></tr>
                        <tr><td>ImageOrientationPatient</td><td>Retained</td></tr>
                        <tr><td>ImagePositionPatient</td><td>Retained</td></tr>
                        <tr><td>SliceThickness</td><td>Retained</td></tr>
                        <tr><td>InstanceNumber</td><td>Retained</td></tr>
                        <tr><td>PatientOrientation</td><td>Retained</td></tr>
                        <tr><td>Manufacturer</td><td>Retained</td></tr>
                        <tr><td>ManufacturerModelName</td><td>Retained</td></tr>
                        <tr><td>PixelPaddingValue</td><td>Retained</td></tr>
                        <tr><td>FrameOfReferenceUID</td><td>Pseudonymized</td></tr>
                        <tr><td>PositionReferenceIndicator</td><td>Retained</td></tr>
                        <tr><td>Modality</td><td>Retained</td></tr>
                        <tr><td>SeriesInstanceUID</td><td>Pseudonymized</td></tr>
                        <tr><td>SeriesNumber</td><td>Retained</td></tr>
                        <tr><td>Laterality</td><td>Retained</td></tr>
                        <tr><td>PatientPosition</td><td>Retained</td></tr>
                        <tr><td>StudyInstanceUID</td><td>Pseudonymized</td></tr>
                        <tr><td>StudyID</td><td>Pseudonymized</td></tr>
                        <tr><td>PatientName</td><td>Pseudonymized</td></tr>
                    </tbody>
                </table>

                <p>
                    Original DICOM tag values for the pseudonymized tags are stored in the RTViewer web client for
                    the duration of the session and are not sent over the network. Only the pseudonymized DICOM
                    files are transferred over the network to the delineation cloud service. Once a structure set has
                    been received back, it&#39;s linked back to the original scan using the original DICOM information
                    (such as series instance uid and frame of reference uid).
                </p>

                <p>
                    De-identification follows &quot;Basic Application Confidentiality Profile&quot;.
                </p>

                <p>
                    Transfer protocol: HTTPS is used for secure communication between RTViewer and MVision
                    delineation service in the cloud. Azure Active Directory is used as an authentication provider.
                </p>

                <p>
                    The User has read and accepted this Web Demo Service Agreement before loading data to
                    MVision web demo service.
                </p>

                <p>
                    {/* NOTE: don't forget to update the SERVICE_DEMO_AGREEMENT_UPDATED const above. */}
                    Document date: 23rd April 2021.
                </p>
            </>
        );
    }

    render() {

        // only show service agreement to demo users
        if (!isDemo()) { return null; }

        // don't show modal if user has agreed to service terms
        if (this.state.hasAgreedToServiceTermsStateFromLocalStorage) { return null; }

        return (
            <ModalDialog
                show={true}
                backdrop="static"
                keyboard={false}
                size="lg"
                className="service-agreement"
            >
                <Modal.Header>
                    <Modal.Title>MVision Web Demo Service Agreement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Before uploading images to the Service the User has acquired all proper permissions and accepted the terms written in this Web Demo Service Agreement.</p>

                    <p>These terms can also be downloaded from <a href={SERVICE_AGREEMENT_FILE_URI}>this link</a> or from the front page of the Web Demo.</p>

                    <div className="invisible-divider" />

                    {/* rest of the text is directly from the service agreement file */}
                    {this.renderLicenseAgreement()}

                </Modal.Body>
                <Modal.Footer>
                    <Form>
                        <Form.Check
                            type="checkbox" id="service-agreement-check" label="Agree to the terms of service"
                            checked={this.state.userHasAgreedCheckboxChecked} onChange={this.handleAgreementCheckboxChecked} />
                    </Form>
                    <Button
                        variant={this.state.userHasAgreedCheckboxChecked ? "primary" : "secondary"}
                        disabled={!this.state.userHasAgreedCheckboxChecked}
                        onClick={this.handleProceedClick}>Proceed</Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export class ServiceAgreementSummary extends React.Component {
    render() {

        // only show service agreement to demo users
        if (!isDemo()) { return null; }

        return (
            <div className="service-agreement">
                <div className="summary">
                    <p>Before uploading images to the Service the User has acquired all proper permissions and accepted the terms written in the Web Demo Service Agreement.</p>

                    <p>The terms can be downloaded from <a href={SERVICE_AGREEMENT_FILE_URI}>this link</a>.</p>
                </div>
            </div>
        );
    }
}
