import '../LocalPage.css';
import React from 'react';
import { Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as sagas from '../../store/sagas';
import RTViewer from '../rtviewer/RTViewer';
import { StoreState } from '../../store/store';

import { isDemo } from '../../environments';
import { rtViewerApiClient } from '../../web-apis/rtviewer-api-client';
import { RouteComponentProps } from 'react-router';
import { StructureSet } from '../../dicom/structure-set';
import { FileLoadError } from '../../store/file-load-error';
import { User } from '../../store/user';
import WorkState, { Workspace } from '../../store/work-state';
import AccessForbidden from '../common/AccessForbidden';

type OwnProps = {}
type DispatchProps = {
    unloadScan(scanId: string): void,
    unloadStructureSet(scanId: string, structureSetId: string): void,
    storeLocalScan(files: File[], cbReturnId: (scanId: string) => void): void,
    storeLocalStructureSet(file: File, currentScanId: string, cbReturnId: (scanId: string, ssId: string, structureSet: StructureSet) => void): void,
    clearFileLoadErrors(): void,
    updateCurrentWorkState(updatedWorkStateProps: Partial<WorkState>): void,
}

type AllProps = OwnProps & StoreState & DispatchProps & RouteComponentProps;
type OwnState = {
    showRTViewer: boolean,
    immediatelyOpenScan: boolean,
    scanId?: string | null,
    structureSetId?: string | null,
}

const PREVENT_OPEN_IF_ERRORS = true;

export class VerifyPage extends React.Component<AllProps, OwnState> {
    displayName = VerifyPage.name

    constructor(props: AllProps) {
        super(props);

        this.state = { showRTViewer: false, immediatelyOpenScan: true };
    }

    componentDidMount = () => {
        // make sure correct workspace is set
        if (this.props.currentWorkState && this.props.currentWorkState.workspace !== Workspace.QATool) {
            this.props.updateCurrentWorkState({ workspace: Workspace.QATool });
        }
    }

    openScan = (scanId: string) => {
        this.setState({ scanId: scanId });
        if (this.state.immediatelyOpenScan && (!this.props.fileLoadErrors || this.props.fileLoadErrors.length === 0)) {
            this.setState({ showRTViewer: true });
        }
    }

    openStructureSet = (scanId: string, structureSetId: string) => {
        if (this.state.scanId !== scanId) {
            alert("Structure set doesn't belong to current image");
        }
        else {
            this.setState({ structureSetId: structureSetId });
        }
    }

    handleImageFileChange = (e: any) => {
        this.props.clearFileLoadErrors();
        if (this.state.scanId) {
            this.props.unloadScan(this.state.scanId);
        }
        e.persist();
        this.setState({ scanId: null, structureSetId: null }, () => {
            let files = [];
            for (let i = 0; i < e.target.files.length; i++) {
                files.push(e.target.files[i])
            }
            if (files.length > 0) {
                this.props.storeLocalScan(files, this.openScan);
            }
        });
    }

    handleStructureSetFileChange = (e: any) => {
        if (!this.state.scanId) return;
        let scanId: string = this.state.scanId;

        if (this.state.structureSetId) {
            this.props.unloadStructureSet(scanId, this.state.structureSetId);
        }
        e.persist();
        this.setState({ structureSetId: null }, () => {
            this.props.storeLocalStructureSet(e.target.files[0], scanId, this.openStructureSet);
        })
    }


    handleShowRTViewerClick = () => {
        this.setState({ showRTViewer: true });
    }

    handleBack = () => {
        this.setState({ showRTViewer: false });
    }

    handleImmediatelyOpenChange = (e: any) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ immediatelyOpenScan: value });
    }

    renderFileForm = (isVisible: boolean) => {

        const { fileLoadErrors, user }: { fileLoadErrors?: FileLoadError[], user?: User } = this.props;

        if (!user || !user.permissions.qaToolAccess) {
            setTimeout(() => this.props.history.push('/'), 3500);
            return <AccessForbidden />;
        }

        const isOpenPrevented = !PREVENT_OPEN_IF_ERRORS || (fileLoadErrors !== undefined && fileLoadErrors.length > 0);

        // by hiding instead of not rendering the file form we're able to save the state of the browser's built-in
        // file load forms, allowing us to show the number and names of files loaded in the UI after user presses
        // back on the main rtviewer view
        const hiddenStyle = isVisible ? '' : 'hide-file-form';

        return (
            <>
            <div className={`local-page-container delineation-page ${hiddenStyle}`}>
                <div className='left flex'>
                    <div className='description-text'>
                        <img src={"/img/verify-title-new.svg"} alt="logo" className="logo-img" />
                            <h2 style={{ marginBottom: "0.5em", color: "#02b1c0", width: "80%" }}>Contour Comparison Tool</h2>
                            
                            <h5 style={{ marginBottom: "0.5em", color: "#f49800", width: "80%" }}>Visual and quantitative comparison of regions-of-interest</h5>

                            <p className='info1'>MVision Verify can be used to visualize differences between two structures or calculate similarity metrics between two structure sets.</p>
                        <p className='info1'>When calculating similarity metrics the structures under comparison must have identical names.</p>
                        <p className='info1'>Structure sets are anonymized before sending them to the similarity metrics calculation service.</p>

                            <h4 style={{ marginTop: "1.3em", marginBottom: "0.5em", color: "#02b1c0", width: "50%" }}>Select images and associated structure sets for comparison</h4>

                        <div className="image-form info1">
                            <p>Select DICOM files (CT/MR and structure sets):</p>
                            <br />
                            <div className="dark-file-input-container">
                                <label className="dark-file-input-label turquoise">
                                    Select files
                                    <input
                                        type="file"
                                        className="dark-file-input"
                                        onChange={this.handleImageFileChange}
                                        multiple
                                    />
                                </label>
                            </div>

                        </div>
                        <div> <input name="OpenFileSelection" type="checkbox"
                            checked={this.state.immediatelyOpenScan} onChange={this.handleImmediatelyOpenChange} /> Immediately open selected files</div>

                        {this.state.scanId ? <div className="rtstruct-form info1"><p>Select a DICOM structure set file from another location (optional):</p>
                            <div className="dark-file-input-container">
                                <label className="dark-file-input-label turquoise" style={{
                                    width: "max-content" }}>
                                    Add file<input type="file" ref="structureSetInput"
                                        className="dark-file-input" onChange={this.handleStructureSetFileChange} />
                                </label>
                            </div></div> : null
                        }
                        {this.state.scanId && !isOpenPrevented ?
                                <Button variant="light" className="open-rtviewer-button btn btn-default col-card-btn turquoise" onClick={this.handleShowRTViewerClick}><img width="23" style={{ marginRight: '2rem'}} src="/img/icon-quality-assurance-sm.svg" alt="Verify"/>Open MVision Verify</Button>
                            : null
                        }
                    </div>
                    <br />

                    <div className='right'>
                        <img src={"/img/verify-monitor.svg"} alt="logo" className="example-img" />
                    </div>

                    {fileLoadErrors && fileLoadErrors.length > 0 && (
                        <Alert variant="danger" className="file-load-errors-alert">
                            <Alert.Heading>Errors occurred when trying to load following files:</Alert.Heading>
                            {fileLoadErrors.map(e => (
                                <div key={e.fileName} className="file-load-errors">
                                    <div className="filename"><b>{e.fileName}</b></div>
                                    <div className="error-message">{e.errorMessage}</div>
                                </div>
                            ))}
                        </Alert>
                    )}
                </div>
            </div>
            {!this.state.showRTViewer &&
                <div className="footer-apps">Verify is part of MVision AI GBS™</div>
            }

            </>
        );
    }

    render() {
        const user = rtViewerApiClient.username;
        const scanId = this.state.scanId;
        const canEdit = !isDemo() ||
            (user === "demo-strang1" || user === "demo-strang2" || user === "demo-berlin1");

        return (
            <>
                {this.renderFileForm(!(this.state.showRTViewer && scanId))}
                {this.state.showRTViewer && scanId &&
                    (
                        <RTViewer scanId={scanId}
                            canEdit={canEdit} canCreateRtstruct={true} hideSaveButtons={true} handleBack={this.handleBack} history={this.props.history} location={this.props.location} 
                            match={this.props.match} />
                    )}
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(VerifyPage);



