import * as shaders from './sdf-shaders';
import * as twgl from 'twgl.js';
import {Tessellator} from './tessellator';
import {Image} from '../../../dicom/image';
import { BoundingBox } from '../../../math/bounding-box';
import { ViewManager } from '../../view-manager';

class SDFBuffer {
    points: twgl.BufferInfo;
    edges: twgl.BufferInfo;
    triangles: twgl.BufferInfo;
    constructor(points: twgl.BufferInfo, edges: twgl.BufferInfo, triangles: twgl.BufferInfo) {
        this.points = points;
        this.edges = edges;
        this.triangles = triangles;
    }
}

export class SDFGenerator {
    viewManager: ViewManager;
    image: Image;
    pointProg: twgl.ProgramInfo;
    edgeProg: twgl.ProgramInfo;
    triangleProg: twgl.ProgramInfo;
    storedGLSettings: any;

    constructor(vm: ViewManager, img: Image) {
        this.viewManager = vm;
        this.image = img;
        let gl = vm.getWebGlContext() as any;

        this.pointProg = twgl.createProgramInfo(gl, [shaders.SDF_POINT_VS, shaders.SDF_POINT_FS])
        this.edgeProg = twgl.createProgramInfo(gl,[shaders.SDF_EDGE_VS, shaders.SDF_EDGE_FS])
        this.triangleProg = twgl.createProgramInfo(gl,[shaders.SDF_TRIANGLE_VS, shaders.SDF_TRIANGLE_FS])
    }

  prepareContours(contours: number[][]) : SDFBuffer
  {
    let vm = this.viewManager;
    let gl = vm.getWebGlContext() as any;
      function repeatItems(src: any, offset: number, stride: number, objectCount: number, elementCount: number, repeatCount: number) {
          const result = new Array(objectCount * elementCount * repeatCount)
          let o = 0;
          for (let i = 0; i < objectCount; i++) {
            for (let j = 0; j < repeatCount; j++) {
              for (let k = 0; k < elementCount; k++) {
                result[o] = src[offset + i * stride + k];
                o++;
              }
            }
          }
          return result;
      }
      let arrays = new Tessellator().tessellate(contours, [1,0,0,0,1,0]);  // todo: bug, this.image.orientationMatrix
      return new SDFBuffer(
            // Points
            twgl.createBufferInfoFromArrays(gl, {
                a_point: repeatItems(arrays.points, 0, 3, arrays.points.length / 3, 3, 6)
            }),
            // Edges
            twgl.createBufferInfoFromArrays(gl, {
                a_p0: repeatItems(arrays.edges, 0, 6, arrays.edges.length / 6, 3, 12),
                a_p1: repeatItems(arrays.edges, 3, 6, arrays.edges.length / 6, 3, 12)
            }),
            // Triangles
            twgl.createBufferInfoFromArrays(gl, {
                a_point: arrays.triangles
            })
      );
  }

  drawBuffer(maxDistanceMM: number, preparedContours: SDFBuffer, zPos: number, bb: BoundingBox, size: number[])
  {
    let vm = this.viewManager;
    let gl = vm.getWebGlContext() as any;
      const img = this.image;
      const uniforms = {
          u_orientationPatient: this.image.orientationMatrix,
          u_inverseSizeMM: [
              1 / bb.getXSize(),
              1 / bb.getYSize(),
              1 / img.kSpacing
          ],
          //u_positionPatient: [img.iMin, img.jMin, img.kMin],
          u_boundingBoxMin: [
            bb.minI,
            bb.minJ,
            zPos
          ],
          u_maxDistanceMM: maxDistanceMM,
          u_offByOneCorrection: [
            (size[0] - 1) / size[0],
            (size[1] - 1) / size[1],
            1
          ],
          u_inverseMaxDistanceMM: 1 / maxDistanceMM
      }
      
      gl.useProgram(this.pointProg.program);
      twgl.setBuffersAndAttributes(gl, this.pointProg, preparedContours.points)
      twgl.setUniforms(this.pointProg, uniforms)
      twgl.drawBufferInfo(gl, preparedContours.points)

      gl.useProgram(this.edgeProg.program);
      twgl.setBuffersAndAttributes(gl, this.edgeProg, preparedContours.edges)
      twgl.setUniforms(this.edgeProg, uniforms)
      twgl.drawBufferInfo(gl, preparedContours.edges)


      // enable blending mode
      gl.blendEquation(gl.FUNC_ADD)
      gl.blendFunc(gl.ONE_MINUS_DST_COLOR, gl.ZERO)

      gl.useProgram(this.triangleProg.program)
      twgl.setBuffersAndAttributes(gl, this.triangleProg, preparedContours.triangles)
      twgl.setUniforms(this.triangleProg, uniforms)
      twgl.drawBufferInfo(gl, preparedContours.triangles)

      gl.blendEquation(gl.MIN)
  }

  
  begin() {
    let vm = this.viewManager;
    let gl = vm.getWebGlContext();
    this.storedGLSettings = {
        oldBlendEnabled: gl.isEnabled(gl.BLEND),
        oldBlendEquationRGB: gl.getParameter(gl.BLEND_EQUATION_RGB),
        oldBlendEquationAlpha: gl.getParameter(gl.BLEND_EQUATION_ALPHA),
        oldProgram: gl.getParameter(gl.CURRENT_PROGRAM)
    };
    gl.enable(gl.BLEND)
    gl.blendEquation(gl.MIN);
  }

  end() {
    let vm = this.viewManager;
    let gl = vm.getWebGlContext();
    let s = this.storedGLSettings;
    // restore GL settings
    if(s) {
        if (!s.oldBlendEnabled) {
            gl.disable(gl.BLEND)
        }
        gl.blendEquationSeparate(s.oldBlendEquationRGB, s.oldBlendEquationAlpha)
        if (s.oldProgram) {
            gl.useProgram(s.oldProgram)
        }
    }
  }

}


