import React from "react";
import { Button, Modal } from "react-bootstrap";

import * as structureSet from "../../../dicom/structure-set";
import ModalDialog from "../../common/ModalDialog";



type OwnProps = {
    isVisible: boolean;
    onClose: () => void;
    handleDeleteStructureSetClick: (ss: structureSet.StructureSet) => void;
    ss: structureSet.StructureSet | null;
    
};

type OwnState = {};

class DeleteStructureSetDialog extends React.Component<OwnProps, OwnState> {
    constructor(props: OwnProps) {
        super(props);

        this.state = {};
    }

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        if ( this.props.ss === null){
            return null
        }
        let label = this.props.ss.dataset.StructureSetLabel;
        return (
            <ModalDialog
                size='lg'
                show={this.props.isVisible}
                onHide={this.handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Structure set</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete structure set "{label}"?</p>
                </Modal.Body>
                <Modal.Footer>
                    
                    <Button
                        variant="danger"
                        onClick={(event: any) =>
                            this.props.handleDeleteStructureSetClick(
                                this.props.ss as structureSet.StructureSet
                            )
                        }
                    >
                        Delete
                    </Button>
                    <Button
                        variant="light"
                        onClick={this.handleClose}
                    >
                        Cancel
                    </Button>
                </Modal.Footer>
            </ModalDialog>
        );
    }
}

export default DeleteStructureSetDialog;
