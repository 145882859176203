import * as viewManager from './view-manager';
import * as m4 from '../math/m4';
import { Sdf } from './webgl/sdf/sdf';
import * as mathjs from 'mathjs';
import { transformPoint2 } from "../dicom/coords";

export enum Plane {
    Transversal = 0,
    Coronal = 1,
    Sagittal = 2
}

export enum Axis {
    Y, X, Z
}

export const AxisNames = {
    // [Axis.X]: "X", [Axis.Y]: "Y", [Axis.Z]: "Z"  // THIS IS WRONG FOR MANY MR SCANS, USE THE IJK names below. It is
    // being used since annotators have difficulty grasping IJK (image) coordinate system.
    [Axis.X]: "I", [Axis.Y]: "J", [Axis.Z]: "K"
}

export class Direction {
    public axis: Axis;
    public inverted: boolean;
    constructor(axis: Axis, inverted: boolean) {
        this.axis = axis;
        this.inverted = inverted;
    }
}

export class Viewport {
    public left: number; 
    public top: number;
    public width: number; 
    public height: number;
    constructor(left: number, top: number, width: number, height: number) {
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
    }
}

export class View {
    public viewManager: viewManager.ViewManager;
    public availableViewport: Viewport; // Area in the canvas reserved for this view
    public drawnViewport: Viewport; // Area where the image is drawn, values are relative to availableViewport
    public plane: Plane;
    public directions: Direction[];
    public depthAxis: Axis;
    private readonly scroll: number;
    public slice: number;
    public panning: number[];
    public pixelsPerMm: number;

    public constructor(vm: viewManager.ViewManager, viewport: Viewport, plane: Plane) {
        const img = vm.image;
        this.viewManager = vm;
        this.availableViewport = viewport;
        this.plane = plane;
        const dirs = img.getDirections()[plane];
        this.directions = dirs;
        this.depthAxis = dirs[2].axis;
        this.scroll = vm.getScrollPositions()[dirs[2].axis];
        this.slice = vm.getScrolledSlice(dirs[2].axis);
        let panHorizontal = vm.panning[dirs[0].axis] * (dirs[0].inverted ? -1 : 1);
        let panVertical = vm.panning[dirs[1].axis] * (dirs[1].inverted ? -1 : 1);
        this.panning = [panHorizontal, panVertical];
        this.pixelsPerMm = 0; // Real value will be assigned in getRectangle()
        this.drawnViewport = new Viewport(0,0,0,0);// Real value will be assigned in getRectangle()
        this.getRectangle(null);
    }

    // Gets rectangle that defines the drawn area. Fits to the viewport maintaining image's aspect ratio
    // When sdf is not null, returns a rectangle for rendering a ROI.
    public getRectangle(sdf: Sdf | null): number[] {

        const vm = this.viewManager;
        const image = vm.image;
        const imgBb = image.getRealBoundingBox();

        let wMm = imgBb.getXSize();
        let hMm = imgBb.getYSize();
        if(this.plane === Plane.Coronal) {
            wMm = imgBb.getXSize();
            hMm = imgBb.getZSize();
        }
        else if(this.plane === Plane.Sagittal) {
            wMm = imgBb.getYSize();
            hMm = imgBb.getZSize();
        }
    
        let scale = wMm / this.availableViewport.width; // Fit to full viewport width
        if(hMm > this.availableViewport.height * scale) {
            scale = hMm / this.availableViewport.height;  // Fit to full viewport height
        }
        let x = wMm / ( this.availableViewport.width * scale );
        let y = hMm / ( this.availableViewport.height * scale );
        this.pixelsPerMm = vm.zooming / scale;

        if(sdf) { 
            const bb = sdf.boundingBox;  //.withHalfPixelCorrection(img);

            // let diff = [
            //     Math.abs(bb.minI - imgBb.minI) % image.iSpacing,
            //     Math.abs(bb.minJ - imgBb.minJ) % image.jSpacing,
            //     Math.abs(bb.minK - imgBb.minK) % image.kSpacing
            // ]
            // diff = [
            //     Math.min(Math.abs(diff[0] - image.iSpacing), diff[0]),
            //     Math.min(Math.abs(diff[1] - image.jSpacing), diff[1]),
            //     Math.min(Math.abs(diff[2] - image.kSpacing), diff[2])
            // ]
            // console.log(diff)
            // Get a rectangle for a ROI using the bounding box

            let bbZoomX = 0, bbZoomY = 0, bbOffsetX = 0, bbOffsetY = 0;
            const xInv = image.orientationMatrix[0] < 0;
            const yInv = image.orientationMatrix[4] < 0;
            if(this.plane === Plane.Transversal) {
                bbZoomX = bb.getXSize() / imgBb.getXSize();
                bbZoomY = bb.getYSize() / imgBb.getYSize();
                bbOffsetX = (bb.minI - imgBb.minI) / imgBb.getXSize();
                if(xInv) bbOffsetX = 1 - bbOffsetX - bb.getXSize()/imgBb.getXSize();
                bbOffsetY = (bb.minJ - imgBb.minJ) / imgBb.getYSize();
                if (yInv) bbOffsetY = 1 - bbOffsetY - bb.getYSize()/imgBb.getYSize();
            }
            else if(this.plane === Plane.Coronal) {
                bbZoomX = bb.getXSize() / imgBb.getXSize();
                bbZoomY = bb.getZSize() / imgBb.getZSize();
                bbOffsetX = (bb.minI - imgBb.minI) / imgBb.getXSize();
                if(xInv) bbOffsetX = 1 - bbOffsetX - bb.getXSize()/imgBb.getXSize();
                bbOffsetY = 1 - (bb.minK - imgBb.minK) / imgBb.getZSize() - bb.getZSize()/imgBb.getZSize();
            }
            else if(this.plane === Plane.Sagittal) {
                bbZoomX = bb.getYSize() / imgBb.getYSize();
                bbZoomY = bb.getZSize() / imgBb.getZSize();
                bbOffsetX = (bb.minJ - imgBb.minJ) / imgBb.getYSize();
                if(!yInv) bbOffsetX = 1 - bbOffsetX - bb.getYSize()/imgBb.getYSize();
                bbOffsetY = 1 - (bb.minK - imgBb.minK) / imgBb.getZSize() - bb.getZSize()/imgBb.getZSize();
            }

            let xp = this.panning[0] + (x*bbOffsetX - (1-bbZoomX)*x/2 ) * vm.zooming;
            let yp = this.panning[1] + (y*bbOffsetY - (1-bbZoomY)*y/2 ) * vm.zooming;

            x = x * vm.zooming * bbZoomX;
            y = y * vm.zooming * bbZoomY;

            return [
                -x + xp*2, -y - yp*2,
                x + xp*2, -y - yp*2,
                x + xp*2, y - yp*2,
                x + xp*2, y - yp*2,
                -x + xp*2, y - yp*2,
                -x + xp*2, -y - yp*2
            ]
        }

        // Get a rectangle for the image rendering, no bounding box
        x = (x * vm.zooming );
        y = (y * vm.zooming );
        let xp = this.panning[0];
        let yp = this.panning[1];

        let left = (1-x) / 2 + xp;
        let top = (1-y) / 2 + yp;
        let width = x;
        let height = y;
        this.drawnViewport = new Viewport(left, top, width, height);

        return [
            -x + xp*2, -y - yp*2,
            x + xp*2, -y - yp*2,
            x + xp*2, y - yp*2,
            x + xp*2, y - yp*2,
            -x + xp*2, y - yp*2,
            -x + xp*2, -y - yp*2
        ]
    }

    getImageSamplingMatrix(): number[] {
        let img = this.viewManager.image;
        let m = m4.identity();
        let scrollNegative = false;

        // Rotate to get the correct intersection
        if(this.plane === Plane.Coronal) {
            m = m4.xRotate(m, -Math.PI / 2 );
            m = m4.translate(m, 0, -1, 0);
            scrollNegative = img.orientationMatrix[4] < 0;
        }
        else if(this.plane === Plane.Sagittal) {
            m = m4.translate(m, 0, 1, 1);
            m = m4.yRotate(m, Math.PI / 2);
            m = m4.zRotate(m, -Math.PI / 2);
            scrollNegative = img.orientationMatrix[0] < 0;
        }
    
        m = m4.translate(m, 0, 0, scrollNegative ? 1 - this.scroll : this.scroll);
        return m;
    }

    // Returns null if bounding box not in the view
    getRoiSamplingMatrix(sdf: Sdf) : number[] | null {
        let vm = this.viewManager;
        let img = vm.image;
        let m = m4.identity();
        let firstRoiSlice = 0; let lastRoiSlice = 0;
        let slice = vm.getScrolledSlice(this.depthAxis);
        let roiBb = sdf.boundingBox;
        let imgBb = img.getRealBoundingBox();
        // Don't draw contours where image is not drawn
        if(slice < 0 || slice > vm.maxSlice[this.depthAxis]) return null;

        if(this.plane === Plane.Transversal) {
            // scrolling
            firstRoiSlice = Math.round( ( roiBb.minK - imgBb.minK) / img.kSpacing );
            lastRoiSlice = firstRoiSlice + sdf.size[2] - 1;
        }
        else if(this.plane === Plane.Coronal) {
            // intersection
            m = m4.xRotate(m, -Math.PI / 2 );
            m = m4.translate(m, 0, -1, 0);

            // scrolling
            firstRoiSlice = Math.round( ( roiBb.minJ - imgBb.minJ) / img.jSpacing );
            lastRoiSlice = firstRoiSlice + sdf.size[1] - 1;
        }
        else if(this.plane === Plane.Sagittal) {

            // intersection
            m = m4.translate(m, 0, 1, 1);
            m = m4.yRotate(m, Math.PI / 2);
            m = m4.zRotate(m, -Math.PI / 2);

            // scrolling
            firstRoiSlice = Math.round( ( roiBb.minI - imgBb.minI) / img.iSpacing );
            lastRoiSlice = firstRoiSlice + sdf.size[0] - 1;
        }

        if(slice < firstRoiSlice || slice > lastRoiSlice) return null;
        let step = 1 / (lastRoiSlice - firstRoiSlice + 1 );
        let scroll = 0.5*step + (slice - firstRoiSlice) * step;

        m = m4.translate(m, 0, 0, scroll);
        return m;
    }

    getPointInMm(ptClipCoord: number[]): number[] {
        const vm = this.viewManager;
        const img = this.viewManager.viewerState.image;
        const imgBb = img.getRealBoundingBox();
        const vp = this.drawnViewport;
        const xIm = (ptClipCoord[0] - vp.left) / vp.width;
        const yIm = (ptClipCoord[1] - vp.top) / vp.height;
        // console.log(ptClipCoord)
        // console.log([xIm, yIm])
        let x = 0, y = 0, z = 0;
        if(this.plane === Plane.Transversal) {
            x = xIm;
            y = yIm;
            z = (0.5 + vm.getScrolledSlice(Axis.Z)) * (1 / (vm.maxSlice[Axis.Z] + 1 ));

            if(img.orientationMatrix[0] < 0) x = 1 - x;
            if(img.orientationMatrix[4] < 0) y = 1 - y;
        }
        else if(this.plane === Plane.Coronal) {
            x = xIm;
            y = (0.5 + vm.getScrolledSlice(Axis.Y)) * (1 / (vm.maxSlice[Axis.Y] + 1 ));
            z = 1 - yIm;

            if(img.orientationMatrix[0] < 0) x = 1 - x;
        }
        else if(this.plane === Plane.Sagittal) {
            x = (0.5 + vm.getScrolledSlice(Axis.X)) * (1 / (vm.maxSlice[Axis.X] + 1 ));
            y = 1 - xIm;
            z = 1 - yIm;
            
            if(img.orientationMatrix[4] < 0) y = 1 - y;
        }

        const xMM = imgBb.minI + x * imgBb.getXSize();
        const yMM = imgBb.minJ + y * imgBb.getYSize();
        const zMM = imgBb.minK + z * imgBb.getZSize();
        return [xMM, yMM, zMM];
    }

    getPointInImageIndices(pt: number[]): number[] {
        // return point (in image/scan indices) in image/scan coordinate system
        const img = this.viewManager.viewerState.image;
        return [Math.round(pt[0]/img.iSpacing), Math.round(pt[1]/img.jSpacing), Math.round(pt[2]/img.kSpacing)]
    }

    getPointInPatientMM(pt: number[]): number[] {
        // return point (in mm) in patient coordinate system
        const img = this.viewManager.viewerState.image;
        const tIM2patient = mathjs.inv(img.T);
        return transformPoint2([pt[0] / img.iSpacing, pt[1] / img.jSpacing, pt[2] / img.kSpacing], tIM2patient);
    }

    getPointInClipCoord(ptMm: number[]): number[] {
        const img = this.viewManager.viewerState.image;
        const imgBb = img.getRealBoundingBox();
        const vp = this.drawnViewport;

        const x = ptMm[0] - imgBb.minI;
        const y = ptMm[1] - imgBb.minJ;
        const z = ptMm[2] - imgBb.minK;
        const xInv = img.orientationMatrix[0] < 0;
        const yInv = img.orientationMatrix[4] < 0;

        let xIm = 0, yIm = 0;
        if(this.plane === Plane.Transversal) {
            xIm = x / imgBb.getXSize();
            if(xInv) xIm = 1 - xIm;
            yIm = y / imgBb.getYSize();
            if(yInv) yIm = 1 - yIm;
        }
        else if(this.plane === Plane.Coronal) {
            xIm = x / imgBb.getXSize();
            if(xInv) xIm = 1 - xIm;
            yIm = 1 - ( z / imgBb.getZSize() );
        }
        else if(this.plane === Plane.Sagittal) {
            xIm = 1 - ( y / imgBb.getYSize() );
            if(yInv) xIm = 1 - xIm;
            yIm = 1 - ( z / imgBb.getZSize() );
        }

        return [
            vp.left + xIm * vp.width,
            vp.top + yIm * vp.height
        ];
    }


    getPointInCanvasCoord(canvas: HTMLCanvasElement, ptMm: number[]) {
        let vp = this.availableViewport;
        let ptViewCoord = this.getPointInClipCoord(ptMm);
        return [
            vp.left + ptViewCoord[0] * vp.width,
            (canvas.height - vp.top - vp.height )  + ptViewCoord[1] * vp.height
        ];
    }

}
