export const IMAGE_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec3 v_texcoord;
uniform mat4 orientation;

void main()
{
    v_texcoord = (orientation * vec4(in_texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const IMAGE_FS = `#version 300 es
precision mediump float;
precision mediump int;

//precision highp isampler3D;
//uniform isampler3D textureData;
 precision mediump sampler3D;
 uniform sampler3D textureData;

uniform float ww;
uniform float wc;

in vec3 v_texcoord;
out vec4 color;
void main()
{
     if(v_texcoord.x < 0.0 || v_texcoord.y < 0.0 || v_texcoord.z < 0.0 || v_texcoord.x > 1.0 || v_texcoord.y > 1.0 || v_texcoord.z > 1.0)
     { // Instead of default clamp effect, we want to draw background color when sampling outside of the texture.
       color = vec4(0.1, 0.1, 0.1, 1.0);
     }
     else {
      vec4 col = texture(textureData, v_texcoord);
      float r = (col.r - wc + (ww / 2.0)) / ww;

      r = clamp(r, 0.0, 1.0);
      color = vec4(r, r, r, 1.0);
    }
}
`;