export const INTERPOLATION_VS = `#version 300 es
#define POSITION_LOCATION 0
#define TEXCOORD_LOCATION 1

precision highp float;
precision highp int;

layout(location = POSITION_LOCATION) in vec2 position;
layout(location = TEXCOORD_LOCATION) in vec2 in_texcoord;

out vec3 v_texcoordTop;
out vec3 v_texcoordBottom;
uniform mat4 orientationTop;
uniform mat4 orientationBottom;

void main()
{
    v_texcoordTop = (orientationTop * vec4(in_texcoord, 0.0, 1.0)).stp;
    v_texcoordBottom = (orientationBottom * vec4(in_texcoord, 0.0, 1.0)).stp;
    gl_Position = vec4(position, 0.0, 1.0);
}
`;

export const INTERPOLATION_FS = `#version 300 es
precision mediump float;
precision mediump int;

precision mediump sampler3D;
uniform sampler3D textureData;

in vec3 v_texcoordTop;
in vec3 v_texcoordBottom;
uniform float interpolationWeight;

out vec4 color;

void main()
{
    vec4 colTop = texture(textureData, v_texcoordTop);
    vec4 colBottom = texture(textureData, v_texcoordBottom);
    float r = mix(colTop.r, colBottom.r, interpolationWeight);
    color = vec4(r, r, r, 1.0);
}
`;