import './LoggedOutPage.css';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import LandingPageLayout from '../LandingPageLayout';
import { setLatestActiveMsalAccount } from '../../local-storage';




class LoggedOutPage extends React.Component {

    async componentDidMount() {
        // // we COULD handle MSAL redirect promise here (since this is where you end up after a logoutRedirect),
        // // but it seems to be enough to handle that in the main app flow when you log back in again
        // const appAuth = new PublicClientApplication(backendTierAppAuths.nonclinical.config);
        // await appAuth.handleRedirectPromise();

        // clear latest MSAL account entry from local storage
        setLatestActiveMsalAccount(undefined);
    }

    render() {
        return (
            <Container fluid className="home-page">
                <Row>
                    <LandingPageLayout>
                        <p>You are now logged out.</p>
                        <p><a href="./">Log back in</a></p>
                    </LandingPageLayout>
                </Row>
            </Container>
        );
    }
}

export default LoggedOutPage;
