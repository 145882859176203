// Dragger to change intersection of the planar views.

import React from 'react';
import { View } from '../../rtviewer-core/view';
import * as util from '../../util';
import './PositionDragger.css';

type OwnProps = {
    horizontal: boolean,
    view: View,
    refreshSwitch: any,
}

type OwnState = {

}

export class PositionDragger extends React.Component<OwnProps, OwnState> {
    displayName = PositionDragger.name
    

    constructor(props: OwnProps) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        let horizontal = this.props.horizontal;
        let draggerBox1: HTMLElement = (this.refs.DraggerBox1 as HTMLElement);
        let draggerBox2: HTMLElement = (this.refs.DraggerBox2 as HTMLElement);

        let view = this.props.view;
        let vp = view.drawnViewport;
        let vm = view.viewManager;
        let xStart = 0, yStart = 0, maxPos = 0;
        let i = this.props.horizontal ? 1 : 0;
        let direction = view.directions[i];
        draggerBox1.onmousedown = dragMouseDown;
        draggerBox2.onmousedown = dragMouseDown;
        let that = this;
        let origPos: any = null;

        function dragMouseDown(e: any) {
            e = e || window.event;
            e.preventDefault();
            e.stopPropagation();
            view = that.props.view;
            vp = view.drawnViewport;
            direction = view.directions[i];
            maxPos = horizontal ? view.availableViewport.height : view.availableViewport.width;
            xStart = e.clientX;
            yStart = e.clientY;
            document.onmouseup = closeDragElement;
            document.onmousemove = elementDrag;
            origPos = that.getScrollPos();
            return false;
        }

        function elementDrag(e: any) {
            e = e || window.event;
            e.preventDefault();
            let diffX = xStart - e.clientX;
            let diffY = yStart - e.clientY;
            let diff = horizontal ? diffY : diffX;
            let r = origPos - (diff / maxPos);
            r = util.clamp(r, 0, 1);
            if( (horizontal && vp.height === 0) || (!horizontal && vp.width === 0) ) return;
            r = horizontal ? ( (r - vp.top) / vp.height ) : ( (r - vp.left) / vp.width );
            r = direction.inverted ? 1 - r : r;
            vm.scrollToPosition(direction.axis, r);
        }

        function closeDragElement() {
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    getScrollPos = (): number => {
        let view = this.props.view;
        let vm = view.viewManager;
        let i = this.props.horizontal ? 1 : 0;
        let direction = view.directions[i];
        let scroll = vm.getScrollPositions()[direction.axis];
        scroll = direction.inverted ? 1 - scroll : scroll;
        let vp = view.drawnViewport;
        if(this.props.horizontal) {
            scroll = vp.top + scroll * vp.height;
        }
        else {
            scroll = vp.left + scroll * vp.width;
        }
        return scroll;
    }

    render() {
        let scroll = this.getScrollPos();
        let visibility = (scroll < 0 || scroll > 1) ? "hidden" : "visible";
        let pos = scroll * 100 + "%";

        if(this.props.horizontal) { // Horizontal dragger
            return (
                <div ref="Dragger" className={"position-dragger position-dragger-horizontal "} style={{top: pos, visibility: visibility} as React.CSSProperties} >
                    <div ref="DraggerBox1" className="position-dragger-box position-dragger-box-left">
                        <svg className="position-dragger-box-svg"><rect className="position-dragger-box-rect" /></svg>
                    </div>
                    <div ref="DraggerBox2" className="position-dragger-box position-dragger-box-right">
                        <svg className="position-dragger-box-svg"><rect className="position-dragger-box-rect" /></svg>
                    </div>
                    <div className="position-dragger-line position-dragger-line-horizontal">
                        <svg className="position-dragger-line-svg">
                            <path className="position-dragger-line-path" strokeDasharray="5,5" d="M 0 6 H 3000" />
                        </svg>
                    </div>
                </div>
            );
        }
        else { // Vertical dragger
            return (
                <div ref="Dragger" className={"position-dragger position-dragger-vertical"} style={{left: pos, visibility: visibility} as React.CSSProperties}>
                    <div ref="DraggerBox1" className="position-dragger-box position-dragger-box-top">
                        <svg className="position-dragger-box-svg"><rect className="position-dragger-box-rect" /></svg>
                    </div>
                    <div ref="DraggerBox2" className="position-dragger-box position-dragger-box-bottom">
                        <svg className="position-dragger-box-svg"><rect className="position-dragger-box-rect" /></svg>
                    </div>
                    <div className="position-dragger-line position-dragger-line-vertical">
                        <svg className="position-dragger-line-svg">
                            <path className="position-dragger-line-path" strokeDasharray="5,5" d="M 6 0 V 2000" />
                        </svg>
                    </div>
                </div>
            );
        }
    }
}
