import { LiveReviewQueryV1, LiveReviewQueryV2 } from "../store/live-review-query";
import _ from "lodash";
import BackendClient from "./backend-client";
import { LiveReviewV1FileRequest } from "../store/requests";
import { AzureFileClient } from "./azure-file-client";
import { isRutherford } from "../environments";

export class GetFilePathsResult {
    success: boolean;
    paths: string[];
    errorMessage: string;

    constructor(paths: string[] | null, errorMessage: string = '') {
        this.success = false;
        this.paths = [];
        this.errorMessage = errorMessage;

        if (paths !== null) {
            this.success = true;
            this.paths = paths;
        }
    }
}

const LIVE_REVIEW_ROOT = '/livereview';
const SCAN_SHARE = 'incoming';
const RTSTRUCT_SHARE = 'outgoing';
const RTSTRUCT_EXTENSION = '.gz';
const RTSTRUCT_SHARE_FALLBACK = 'contours';
const RTSTRUCT_EXTENSION_FALLBACK = '.dcm';

// Client for mvbackend livereview API
export class LiveReviewClient {
    // backend client is set to null for V1 queries (those don't use a contouring backend)
    private backendClient: BackendClient | null;

    constructor(backendClient: BackendClient | null) {
        this.backendClient = backendClient;
    }

    public async getLiveReviewFilesV1(query: LiveReviewQueryV1): Promise<LiveReviewV1FileRequest[]> {

        if (!query.isValid()) throw new Error("Invalid query");
        const path = (query.daemonId || query.clientId) + '/' + query.seriesId;

        // collect all the file requests we're going to need
        let requests: LiveReviewV1FileRequest[] = [];

        try {

            const azureClient = new AzureFileClient(query.storageAccountName as string);

            // get structure set file(s) -- default to 'outgoing' dir, fall back to 'contours'
            const useFallbackSettings = !await azureClient.doesShareExist(RTSTRUCT_SHARE);
            const outgoingShare = !useFallbackSettings ? RTSTRUCT_SHARE : RTSTRUCT_SHARE_FALLBACK;
            const fileExtension = !useFallbackSettings ? RTSTRUCT_EXTENSION : RTSTRUCT_EXTENSION_FALLBACK;

            const outgoingFiles = await azureClient.listFiles(outgoingShare, path);
            if (!outgoingFiles.some(fileInfo => fileInfo.filename.toLowerCase().endsWith('.json'))) {
                // only get anything from the folder if there's NO json files in it
                // TODO: adapted from previous code -- is this desired behaviour?
                const structureSetRequests = outgoingFiles.filter(
                    fileInfo => fileInfo.filename.toLowerCase().endsWith(fileExtension))
                    .map(fileInfo => new LiveReviewV1FileRequest(azureClient.getFile(outgoingShare, path, fileInfo.filename)));

                requests = structureSetRequests;
            }

            // get scan files
            const incomingShare = SCAN_SHARE;
            const imageFiles = await azureClient.listFiles(incomingShare, path);
            for (const fileInfo of imageFiles) {
                const lower = fileInfo.filename.toLowerCase();
                if (lower.endsWith(".dcm") || lower.endsWith(".gz") || lower.startsWith("ct.") || lower.startsWith("mr.")) {
                    const request = new LiveReviewV1FileRequest(azureClient.getFile(incomingShare, path, fileInfo.filename));
                    requests.push(request);
                }
            }
        }
        catch (error) {
            console.error(`An error occurred when trying to get V1 livereview files from ${query.storageAccountName}, ${path}`);
            console.log(error);
        }

        return requests;
    }

    public async getLiveReviewFilesV2Paths(query: LiveReviewQueryV2): Promise<string[]> {
        if (!query.isValid()) { throw new Error("Invalid query"); }
        const result = await this.getFilePaths(query);
        if (!result.success) {
            throw new Error(result.errorMessage);
        }

        return result.paths;
    };

    public async getFilePaths(query: LiveReviewQueryV2): Promise<GetFilePathsResult> {
        if (this.backendClient === null) {
            throw new Error('Proper non-null backend client is required to fetch v2 query data');
        }

        const url = LIVE_REVIEW_ROOT + "/getFilePaths";
        const options = {
            headers: {
                'path': query.path,
            },
        }

        const response = await this.backendClient.get(url, options);
        const responseData = await response.json();
        if (response.status === 200) {
            return new GetFilePathsResult(responseData as string[]);
        }

        const errorDetail = _.get(responseData, 'detail', null);
        const errorMessage = `${response.status}: ${response.statusText}${errorDetail && ` (Detail: ${errorDetail})`}`;
        console.log("Error response from live review api", response);
        console.log(errorMessage);
        return new GetFilePathsResult(null, errorMessage);
    }

    public async getFile(query: LiveReviewQueryV2, filePath: string): Promise<ArrayBuffer | null> {
        if (this.backendClient === null) {
            throw new Error('Proper non-null backend client is required to fetch v2 query data');
        }

        const url = LIVE_REVIEW_ROOT + "/getFile";
        const options = {
            headers: {
                'path': filePath,
            },
        }

        let response = await this.backendClient.get(url, options);
        if (response.status === 200) {
            return await response.arrayBuffer();
        }
        console.log("Error response from live review api", response);
        return null;
    }

    public async exportStructureSet(dicomBlob: Blob, dicomFilename: string, taskBlob: Blob, taskFilename: string): Promise<boolean> {
        if (!isRutherford()) {
            throw new Error('Exporting structure sets is not supported in current configuration.');
        }

        if (this.backendClient === null) {
            throw new Error('Proper non-null backend client is required to export structure sets.');
        }

        const data = new FormData();
        data.append('file', dicomBlob, dicomFilename);
        data.append('task', taskBlob, taskFilename);

        const url = LIVE_REVIEW_ROOT + '/set-result';
        const options = {
            // headers: {},
            // config: {
            //     headers: {
            //         "Content-Type": "multipart/form-data",
            //     },
            // },
            body: data,
        };

        let response: Response | undefined = undefined;

        try {
            response = await this.backendClient.post(url, options);
            if (!response.ok) {
                throw new Error('Response was not ok');
            }

            return true;
        }
        catch (err) {
            console.error('An error occurred during structure set export:');
            if (err.message) { console.log(err.message); }
            console.log(err);
            if (response) {
                console.log('Received response:');
                console.log(response);
            }
            alert('An error occurred during structure set export. Please see console (F12) for details.');

            return false;
        }
    }

}
