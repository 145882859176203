import * as mathjs from 'mathjs';


export enum Coordinates { Patient, Image}

export function getK(topLeftFirstSlice: number[], topLeftLastSlice: number[]) {
    // get the dimension along which slices are stacked
    const pos = mathjs.concat([topLeftFirstSlice], [topLeftLastSlice], 0);
    const maxPos = mathjs.max(pos, 0);
    const minPos = mathjs.min(pos, 0);
    const diffPos = mathjs.subtract(maxPos, minPos);
    //@ts-ignore : mathjs
    return diffPos.indexOf(mathjs.max(diffPos));
}


export function getCubePatient(shape: number[], t: number[][]): number[][] {
    // get the scan corner points (cube) in patient coordinates
    let cubeImage = getCubeImage(shape);
    const tInv = mathjs.inv(t);
    //@ts-ignore : mathjs
    cubeImage = mathjs.concat(cubeImage, [[1], [1], [1], [1], [1], [1], [1], [1]], 1);  // to homogeneous coordinates
    let cubePatient = mathjs.transpose(mathjs.multiply(tInv, mathjs.transpose(cubeImage)));
    for (let i=0; i < cubePatient.length; i++) {
        cubePatient[i].pop();  // from homogeneous coordinates
    }
    return cubePatient
}


export function getCubeImage(shape: number[]): number[][] {
    // get the scan corner points (cube) in image coordinates
    const shape_ = mathjs.subtract(shape, 1);
    return [[0, 0, 0],
        //@ts-ignore : mathjs
        [0, shape_[1], 0],
        //@ts-ignore : mathjs
        [shape_[2], 0, 0],
        //@ts-ignore : mathjs
        [shape_[2], shape_[1], 0],
        //@ts-ignore : mathjs
        [0, 0, shape_[0]],
        //@ts-ignore : mathjs
        [0, shape_[1], shape_[0]],
        //@ts-ignore : mathjs
        [shape_[2], 0, shape_[0]],
        //@ts-ignore : mathjs
        [shape_[2], shape_[1], shape_[0]]]
}


export function getTransformation(imageOrientation: number[], pixelSpacing: number[], topLeftFirstSlice: number[], topLeftLastSlice: number[], nSlices: number) {
    // get the transformation matrix for converting between image/patient coordinates
    let cosines = mathjs.transpose([[imageOrientation[3], imageOrientation[4], imageOrientation[5]], [imageOrientation[0], imageOrientation[1], imageOrientation[2]]]);
    const t = mathjs.divide(mathjs.subtract(topLeftLastSlice, topLeftFirstSlice), nSlices - 1);
    //@ts-ignore : mathjs
    cosines = mathjs.dotMultiply(cosines, [pixelSpacing, pixelSpacing, pixelSpacing]);
    //@ts-ignore : mathjs
    cosines = mathjs.concat(cosines, [[t[0]], [t[1]], [t[2]]], 1);
    //@ts-ignore : mathjs
    cosines = mathjs.concat(cosines, [[topLeftFirstSlice[0]], [topLeftFirstSlice[1]], [topLeftFirstSlice[2]]], 1);
    //@ts-ignore : mathjs
    cosines = mathjs.concat(cosines, [[0, 0, 0, 1]], 0);
    return mathjs.inv(cosines)
}


export function transformPoint(pt: number[], t: number[][]): number[] {
    // transforms points from XYZ (patient) -> IJK (scan/image)
    const pt2 = [pt[0], pt[1], pt[2], 1];  // XY need to be switched
    // pt2 = mathjs.concat([pt], [[1]], 1)
    // console.log(pt2)
    let transformedPt = mathjs.transpose(mathjs.multiply(t, mathjs.transpose(pt2)));
    // transformedPt.pop();
    //@ts-ignore : mathjs
    return [transformedPt[1], transformedPt[0], transformedPt[2]];  // IJ need to be switched
}


export function transformPoint2(pt: number[], t: number[][]): number[] {
    // transforms points from IJK (scan/image) -> XYZ (patient)
    const pt2 = [pt[0], pt[1], pt[2], 1];
    // pt2 = mathjs.concat([pt], [[1]], 1)
    // console.log(pt2)
    // transformedPt.pop();
    // transformedPt = [transformedPt[1], transformedPt[0], transformedPt[2]]
    let transformedPt = mathjs.transpose(mathjs.multiply(t, mathjs.transpose(pt2)))
    //@ts-ignore : mathjs
    return [transformedPt[0], transformedPt[1], transformedPt[2]];
}
