export class UserPermissions {
    canApproveRTStructsForTraining: boolean;
    canSetRTStructsAsObsolete: boolean;
    canSetRTStructsAsExpertApproved: boolean;
    canSetRTStructsAsGroundTruth: boolean;
    canChangeBackend: boolean;
    allowDebugMode: boolean;
    allowForceUnlock: boolean;
    allowStructureSetDownload: boolean;
    isTrainee: boolean;
    isSupervisor: boolean;
    allowScanDownload: boolean;
    qaToolAccess: boolean;
    tpAccess: boolean;
    configUIAccess: boolean;
    importExportUIAccess: boolean;
    annotationgAccess: boolean;
    liveReviewAccess: boolean;


    constructor(basePermissions: any = {}) {
        // default values
        this.canApproveRTStructsForTraining = false;
        this.canSetRTStructsAsObsolete = false;
        this.canSetRTStructsAsExpertApproved = false;
        this.canSetRTStructsAsGroundTruth = false;
        this.canChangeBackend = false;
        this.allowDebugMode = false;
        this.allowForceUnlock = false;
        this.allowStructureSetDownload = false;
        this.isTrainee = false;
        this.isSupervisor = false;
        this.allowScanDownload = false;
        this.qaToolAccess = false;
        this.tpAccess = false;
        this.configUIAccess = false;
        this.importExportUIAccess = false;
        this.annotationgAccess = false;
        this.liveReviewAccess = false;

        // overrides
        if (Array.isArray(basePermissions)) {
            if (basePermissions.includes('canApproveRTStructsForTraining')) {
                this.canApproveRTStructsForTraining = true;
                this.canSetRTStructsAsObsolete = true;
                this.canSetRTStructsAsGroundTruth = true;
            }

            if (basePermissions.includes('canSetExpertApproved')) {
                this.canSetRTStructsAsExpertApproved = true;
                this.canSetRTStructsAsGroundTruth = true;
            }

            if (basePermissions.includes('canChangeBackend')) {
                this.canChangeBackend = true;
            }

            if (basePermissions.includes('allowDebugMode')) {
                this.allowDebugMode = true;
            }

            if (basePermissions.includes('allowForceUnlock')) {
                this.allowForceUnlock = true;
            }

            if (basePermissions.includes('allowStructureSetDownload')) {
                this.allowStructureSetDownload = true;
            }

            if (basePermissions.includes('traineeAccess')) {
                this.isTrainee = true;
                this.tpAccess = true;
            }

            if (basePermissions.includes('supervisorAccess')) {
                this.isSupervisor = true;
                this.tpAccess = true;
            }
            
            if (basePermissions.includes('allowScanDownload')) {
                this.allowScanDownload = true;
            }

            if (basePermissions.includes(('traineeAccess' || 'supervisorAccess') && 'tpAccess')) {
                this.tpAccess = true;
            }

            if (basePermissions.includes('qaToolAccess')) {
                this.qaToolAccess = true;
            }

            if (basePermissions.includes('configUIAccess')) {
                this.configUIAccess = true;
            }

            if (basePermissions.includes('importExportUIAccess')) {
                this.importExportUIAccess = true;
            }
        }
    }

}