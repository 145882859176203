import React from 'react';
// import { MdCheckBoxOutlineBlank, MdIndeterminateCheckBox, MdCheckBox } from 'react-icons/io';

// the react-three-state-checkbox package is used here because by default react's <input> element does not support the 'indeterminate' prop 
import Checkbox from 'react-three-state-checkbox';

import './TriStateCheckbox.css';
import Immerable from '../../store/immerable';

export enum TriStateCheckboxChecked {
    Unchecked, Indeterminate, Checked
}

// states of tri-state checkboxes are to be stored in a flat collection with string ID links between items
export class TriStateCheckboxState extends Immerable {
    id: string;
    state: TriStateCheckboxChecked;
    childIds: string[];
    parentId: string | null;

    constructor(id: string) {
        super();
        this.id = id;
        this.state = TriStateCheckboxChecked.Unchecked;
        this.childIds = [];
        this.parentId = null;
    }
}

export function handleHierarchicalTriStateChange(targetId: string, nextChecked: TriStateCheckboxChecked, collection: {[checkboxId: string]: TriStateCheckboxState}) {
    const targetCheckbox: TriStateCheckboxState = collection[targetId];

    // set current checkbox
    targetCheckbox.state = nextChecked;

    // go down the checkbox tree and set any children to the same checked state
    const childIds: string[] = [];
    childIds.push(...targetCheckbox.childIds);
    while (childIds.length > 0) {
        const childId = childIds.pop();
        if (childId) {
            const child: TriStateCheckboxState = collection[childId];
            child.state = nextChecked;
            childIds.push(...child.childIds);
        }
    }

    // go up the checkbox tree and set them as appropriate state, possibly including the 'indeterminate' state
    let parentId = targetCheckbox.parentId;
    while (parentId != null) {
        const parent: TriStateCheckboxState = collection[parentId];
        const immediateChildrenOfParent: TriStateCheckboxState[] = [];
        parent.childIds.map((id: string) => immediateChildrenOfParent.push(collection[id]));

        if (immediateChildrenOfParent.every(c => c.state === TriStateCheckboxChecked.Checked)) {
            parent.state = TriStateCheckboxChecked.Checked;
        } else if (immediateChildrenOfParent.every(c => c.state === TriStateCheckboxChecked.Unchecked)) {
            parent.state = TriStateCheckboxChecked.Unchecked;
        } else {
            parent.state = TriStateCheckboxChecked.Indeterminate;
        }

        parentId = parent.parentId;
    }
}

export default class TriStateCheckbox extends React.PureComponent<{id: string, checked: TriStateCheckboxChecked, onCheckboxChange: (checkboxId: string, event: any) => void}> {

    handleCheckboxChange = (evt: any) => {
        evt.stopPropagation();
        this.props.onCheckboxChange(this.props.id, evt);
    }

    handleClick = (evt: any) => {
        evt.stopPropagation();
    }

    render() {
        const { checked } = this.props;
        return (
            <div>
                <label onClick={this.handleClick}>
                <Checkbox 
                    checked={(checked === TriStateCheckboxChecked.Checked || checked === TriStateCheckboxChecked.Indeterminate)} 
                    indeterminate={checked === TriStateCheckboxChecked.Indeterminate}
                    onChange={this.handleCheckboxChange}
                    className="tri-state-checkbox" />
                {' '}
                {this.props.children}
                </label>
            </div>
        );
    }
}