import React from 'react';
import { Button } from 'react-bootstrap';
import { MdExpandMore, MdExpandLess } from 'react-icons/md';
import { connect } from 'react-redux';

import * as sagas from '../../../store/sagas';

import { TrainingTask, TrainingTaskGroup, TrainingTaskState } from '../../../datasets/training-task';
import TasksTable from './TasksTable';
import { StoreState } from '../../../store/store';


type DispatchProps = {
    setAutoOpenTaskItem(taskId: string | undefined): void,
}


type OwnProps = {
    trainingTaskGroup: TrainingTaskGroup,
    rowIndex: number,
    handleViewTaskClick: (task: TrainingTask) => void,
}

type OwnState = {
    isExpanded: boolean,
}


type AllProps = OwnProps & StoreState & DispatchProps;

class TaskGroupRow extends React.Component<AllProps, OwnState> {
    displayName = TaskGroupRow.name;

    constructor(props: AllProps) {
        super(props);

        this.state = {
            isExpanded: false
        }
    }

    componentDidUpdate(prevProps: AllProps) {
        if (this.props.autoOpenTaskItem && !prevProps.autoOpenTaskItem && this.props.trainingTaskGroup.trainingTasks.find(t => t.id === this.props.autoOpenTaskItem)) {
            this.props.setAutoOpenTaskItem(undefined);
            this.setState({ isExpanded: true });
        }
    }

    handleViewTasksClick = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    }

    render() {
        const { trainingTaskGroup, rowIndex } = this.props;

        const { name, collection, trainingTasks } = trainingTaskGroup;

        // TODO: move these to the trainingTaskGroup object?
        const finishedTasksCount = trainingTasks.filter(t => t.state === TrainingTaskState.Finished).length;
        const gradedTasksCount = trainingTasks.filter(t => t.state === TrainingTaskState.Graded).length;
        const notStartedTasksCount = trainingTasks.filter(t => t.state === TrainingTaskState.NotStarted).length;
        const startedTasksCount = trainingTasks.filter(t => t.state === TrainingTaskState.Started).length;
        const archivedTasksCount = trainingTasks.filter(t => t.state === TrainingTaskState.Archived).length;
        const totalTasksCount = trainingTasks.length - archivedTasksCount;

        return (
            <>
            <tr className={`${rowIndex % 2 ? "odd-row task-row" : "even-row task-row"} ${this.state.isExpanded ? 'is-expanded' : ''}`} data-cy="task-group-row">
                <td style={{ fontSize: '14px', fontWeight: 700}}>{name}</td>
                <td>{collection}</td>
                    <td>Total: <b data-cy="total-tasks">{totalTasksCount}</b> {totalTasksCount === 1 ? 'task' : 'tasks'}</td>
                    <td>Not started: <b>{notStartedTasksCount}</b></td>
                <td>Started: <b>{startedTasksCount}</b></td>
                    <td>Finished: <b>{finishedTasksCount}</b></td>
                        <td>Graded: <b>{gradedTasksCount}</b></td>
                <td><Button data-cy='task-group-row-btn' className='primary-button' variant={this.state.isExpanded ? 'secondary' : 'outline-dark'} onClick={this.handleViewTasksClick}>{this.state.isExpanded ? (<span>Hide tasks <MdExpandLess /></span>) : (<span>View tasks <MdExpandMore /></span>)}</Button></td>
            </tr>
            {this.state.isExpanded && (
                <tr className="expanded-task-group">
                    <td colSpan={12}>
                        <TasksTable 
                            trainingTaskGroup={trainingTaskGroup}
                            handleViewTaskClick={this.props.handleViewTaskClick}
                        />
                    </td>
                </tr>
                )}
            </>
        );
    }
}

export default connect(
    state => Object.assign({}, state),
    sagas.mapDispatchToProps
)(TaskGroupRow);
